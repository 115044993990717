// @ts-nocheck
import { hideModal, showModal } from '@bottega52/redux-modals';
import { Divider, IconButton, withStyles } from '@material-ui/core';
import { Collapse } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Search } from '@material-ui/icons';
import {
  FormControl
} from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { LICENSE_TYPES, ORANGE, PERMISSIONS, PERMISSION_ENTITIES, STORE_ITEMS_CATEGORIES, STORE_ITEMS_CATEGORIES_MAP, STORE_ITEMS_TYPES, STORE_ITEMS_TYPES_MAP } from '../../_config/consts.js';
import { getPlantName } from '../../_config/utils.js';
import CheckOutlineIconCustom from '../../components/CustomIcons/CheckOutlineIconCustom.jsx';
import CloseCircleIconCustom from '../../components/CustomIcons/CloseCircleIconCustom.jsx';
import CreditsIconCustom from '../../components/CustomIcons/CreditsIconCustom.jsx';
import PinIconCustom from '../../components/CustomIcons/PinIconCustom.jsx';
import RemoveIconCustom from '../../components/CustomIcons/RemoveIconCustom.jsx';
import EmptyView from '../../components/EmptyView.jsx';
import StoreActivationView from '../../components/Store/StoreActivationView.jsx';
import StoreItemCard from '../../components/Store/StoreItemCard.jsx';
import StoreItemDescriptionView from '../../components/Store/StoreItemDescriptionView.jsx';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import * as CloudCreditsActions from '../../redux/actions/cloudCredits.actions';
import * as ModalActions from '../../redux/actions/modal.actions';
import * as UserActions from '../../redux/actions/user.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import * as AmplitudeService from '../../services/AmplitudeService.js';

const styles = () => ({
  container: {
    backgroundColor: 'white',
    position: 'relative',
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
    overflowY: 'scroll',
    flexWrap: 'wrap',
    paddingBottom: 200,
    position: 'absolute',
    left: 260,
    right: 0,
    backgroundColor: 'white',
    height: '120vh'
  },
  innerContainer: {
    flexDirection: 'row',
    display: 'flex',
    position: 'relative',
    backgroundColor: 'white',
    marginLeft: 10,
    height: '120vh'
  },
  headerText: {
    color: '#3f3f3f',
    fontWeight: 'bold',
    margin: 0,
    marginBottom: 10
  },
  subtitleText: {
    color: '#3f3f3f',
    marginTop: 0,
  },
  filterHeader: {
    marginTop: 20,
    width: 251,
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundColor: 'white',
  },
  filterText: {
    margin: 0,
    marginRight: 20,
    marginTop: 10,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: 17,
    color: '#157495',
  },
  stateFilterHeader: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 18,
    marginTop: 20,
  },
  headerOuterContainer: {
    padding: 20,
    paddingRight: 35,
    paddingTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row'
  }
});

const MenuItem = ({ isSelected, title, number, icon, onClick, iconStyle, containerStyle }) => (
  <div className={`filter-row ${isSelected ? 'filter-row-active' : ''}`} style={{ ...containerStyle }}>
    <ListItem button selected={isSelected} style={{ borderRadius: 5, height: 50, margin: 0, padding: 0, paddingLeft: 10 }} onClick={() => onClick()}>
      {icon ? <ListItemIcon className={`filter-row-icon ${isSelected ? 'filter-row-icon-active' : ''}`} style={{ width: 20, ...iconStyle }}>{icon}</ListItemIcon> : null}
      <h5 className={`filter-row-text ${isSelected ? 'filter-row-text-active' : ''}`} style={{ fontSize: 15 }}>{title}{` (${number})`}</h5>
    </ListItem>
  </div>
);

const WalletCreditHeader = ({ wallet }) => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 20 }}>
      <h4 style={{ margin: 0, color: '#3f3f3f', marginRight: 3 }}><Entity style={{ fontWeight: 'bold' }} entity="totalCredits" />: <Entity entity="creditsWithNumber" data={{ credits: wallet.availableCredits}} /></h4>
      <CreditsIconCustom style={{ color: ORANGE, width: 23 }} />
    </div>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <h4 style={{ margin: 0, color: '#3f3f3f', marginRight: 3 }}><Entity style={{ fontWeight: 'bold' }} entity="currentBurnRate" />: <Entity entity="walletCreditsConsumptionText" data={{ credits: wallet.burnRate }} /></h4>
      <CreditsIconCustom style={{ color: ORANGE, width: 23 }} />
    </div>
  </div>
);

const CollapsibleMenu = ({ isOpen, headerIcon, headerTitle, headerNumber, children, isSelected, onToggleOpen }) => (
  <div>
    <div className={`filter-row ${isSelected ? 'filter-row-active' : ''}`}>
      <ListItem button selected={isSelected} onClick={() => onToggleOpen(!isOpen)} style={{ borderRadius: 5, height: 50, margin: 0, padding: 0, paddingLeft: 10 }}>
        <ListItemIcon className={`sidebar-row-icon ${isSelected ? 'sidebar-row-icon-active' : ''}`}>
          {headerIcon}
        </ListItemIcon>
        <h4 className={`filter-row-text ${isSelected ? 'filter-row-text-active' : ''}`}  style={{ margin: 0, marginRight: 10, fontSize: 15, fontWeight: isSelected ? 'bold' : 'normal' }}>{headerTitle}{` (${headerNumber})`}</h4>
        {isOpen ? <ExpandLess style={{ marginLeft: 'auto', marginRight: 10 }} /> : <ExpandMore style={{ marginLeft: 'auto', marginRight: 10 }} />}
      </ListItem>
    </div>
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      <List component="div" disablePadding style={{ paddingLeft: 5, marginTop: 10 }}>
        {children}
      </List>
    </Collapse>
  </div>
);

@connect(state => ({
  marketPlace: state.cloudCredits.marketPlace,
  companyInfo: state.user.companyInfo,
  walletSubscriptions: state.cloudCredits.wallet.subscriptions.content,
  wallet: state.cloudCredits.wallet,
  viewLoading: state.utils.viewLoading,
  themeName: state.settings.items.theme.data.themeName,
  languange: state.settings.language,
}))
class LuckeyStorePage extends React.Component {

  constructor(props) {
    super(props);
    const subscriptionsData = props.marketPlace && props.marketPlace.subscriptions && props.marketPlace.subscriptions.content ? props.marketPlace.subscriptions.content : [];
    this.state = {
      activeTypeFilter: 'ALL',
      activeCategoryFilter: null,
      itemsToDisplay: subscriptionsData,
      searchText: '',
      isPluginDropdownOpened: false,
    };
  }

  async componentDidMount() {
    const { marketPlace } = this.props;
    await this.onRefreshMarketPlace();
    AmplitudeService.logEvent('LUCKEY_STORE_PAGE_VIEW', { plantName: getPlantName()});
  }

  async onRefreshMarketPlace() {
    const { dispatch} = this.props;
    const subscriptionsData = await dispatch(CloudCreditsActions.fetchMarketPlaceSubscriptions());
    const canEditSubscription = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.DOMAIN_CLOUD_CREDITS);
    if (canEditSubscription) {
      await dispatch(CloudCreditsActions.fetchWalletAvailableCredits());
      await dispatch(CloudCreditsActions.fetchWalletSubscriptions());
    }
    this.setState({ itemsToDisplay: subscriptionsData });
  }

  async componentWillReceiveProps(nextProps) {
    const { languange: oldLanguage, dispatch } = this.props;
    const { languange: newLanguage } = nextProps;
    if (oldLanguage !== newLanguage) {
      setTimeout(() => this.onRefreshMarketPlace(), 400);
    }
  }

  async onRequestDemo(itemName) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(UserActions.requestPluginDemo(itemName));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_MODAL',
        modalProps: { message: 'demoRequestSuccess' },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="demoRequestError" /></h6>),
        },
      }));
    }
  }

  async onRequestActivation(itemName) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(UserActions.requestPluginActivation(itemName));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_MODAL',
        modalProps: { message: 'activationRequestSuccess' },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="demoRequestError" /></h6>),
        },
      }));
    }
  }

  async onRequestQuotation(itemName) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(UserActions.requestPluginQuotation(itemName));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_MODAL',
        modalProps: { message: 'quotationRequestSuccess' },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="demoRequestError" /></h6>),
        },
      }));
    }
  }

  async onRequestSemiAutomaticActivation(itemName) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(UserActions.requestSemiAutomaticActivation(itemName));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_MODAL',
        modalProps: { message: 'semiAutomaticActivationRequestSuccess' },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="demoRequestError" /></h6>),
        },
      }));
    }
  }


  async onShowPluginDetails(item) {
    const { dispatch, companyInfo, walletSubscriptions} = this.props;
    AmplitudeService.logEvent('LUCKEY_STORE_OPEN_EXTENSION_DETAILS', { extensionName: item.name, extensionResource: item.resource, plantName: getPlantName() });
    const canEditSubscription = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.DOMAIN_CLOUD_CREDITS) || AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.ENGINE]);
    const walletInnerSubscriptions = _.map(walletSubscriptions, sub => sub.subscription);
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        modalCustomClass: 'large-modal',
        content: (
          <StoreItemDescriptionView
            item={item}
            isCompatible={item.isCompatible && item.isCompatible(companyInfo, walletInnerSubscriptions)}
            canEditSubscription={canEditSubscription}
            onClose={() => dispatch(hideModal('PLUGIN_DETAILS_MODAL'))}
            onRequestDemo={() => this.onRequestDemo(item.resource)}
            onRequestActivation={() => this.onRequestActivation(item.resource)}
            onRequestSemiAutomaticActivation={() => this.onRequestSemiAutomaticActivation(item.resource)}
            onRequestQuotation={() => this.onRequestQuotation(item.resource)}
            onActivateSubscription={() => this.onActivateSubscriptionRequest(item)}
          />
        ),
        modalContainerStyle: { padding: 0 },
        hideHeader: true,
        onOutsideClick: () => dispatch(hideModal('PLUGIN_DETAILS_MODAL')),
      },
    };
    dispatch(showModal('PLUGIN_DETAILS_MODAL', params));
  }

  async onActivateSubscriptionRequest(item) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        content: (
          <StoreActivationView
            item={item}
            onClose={() => dispatch(hideModal('PLUGIN_ACTIVATE_CONFIRM_MODAL'))}
            onActivateSubscription={() => this.onActivateSubscription(item)}
            onRechargeWalletRequest={() => this.onRechargeWalletRequest()}
          />
        ),
        modalContainerStyle: { padding: 0 },
        hideHeader: true,
        onOutsideClick: () => dispatch(hideModal('PLUGIN_ACTIVATE_CONFIRM_MODAL')),
      },
    };
    dispatch(showModal('PLUGIN_ACTIVATE_CONFIRM_MODAL', params));
  }

  async onRechargeWalletRequest() {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(CloudCreditsActions.sendRechargeWalletRequest());
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="demoRequestSuccess" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="demoRequestError" /></h6>),
        },
      }));
    }
  }
  
  async onActivateSubscription(item) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(CloudCreditsActions.createWalletSubscription(item));
      await dispatch(UserActions.fetchLicenseInfo());
      this.onRefreshMarketPlace();
      dispatch(hideModal('PLUGIN_ACTIVATE_CONFIRM_MODAL'));
      dispatch(hideModal('PLUGIN_DETAILS_MODAL'));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="activateSubscriptionSuccess" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(hideModal('PLUGIN_DELETE_CONFIRM_MODAL'));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="activateSubscriptionError" /></h6>),
        },
      }));
    }
  }


  onFilterSelected(value) {
    const { marketPlace } = this.props;
    this.setState({ activeTypeFilter: value, activeCategoryFilter: null, });
    const storeItemsData = marketPlace.subscriptions && marketPlace.subscriptions.content ? marketPlace.subscriptions.content : [];
    switch(value) {
      case 'ALL': {
        this.setState({ itemsToDisplay: storeItemsData });
        break;
      }
      case 'ACTIVE': {
        const groupedItems = _.partition(_.filter(storeItemsData, item => item.type !== STORE_ITEMS_TYPES.HARDWARE), ['isActive', false]);
        this.setState({ itemsToDisplay: groupedItems[1] });
        break;
      }
      case 'INACTIVE': {
        const groupedItems = _.partition(_.filter(storeItemsData, item => item.type !== STORE_ITEMS_TYPES.HARDWARE), ['isActive', false]);
        const inactiveItems = _.first(groupedItems);
        this.setState({ itemsToDisplay: inactiveItems });
        break;
      }
      case STORE_ITEMS_TYPES.ADDON: {
        const addonItems = _.filter(storeItemsData, item => item.type === STORE_ITEMS_TYPES.ADDON);
        this.setState({ itemsToDisplay: addonItems });
        break;
      }
      case STORE_ITEMS_TYPES.INTEGRATION: {
        const integrationsItems = _.filter(storeItemsData, item => item.type === STORE_ITEMS_TYPES.INTEGRATION);
        this.setState({ itemsToDisplay: integrationsItems });
        break;
      }
      case STORE_ITEMS_TYPES.PROFESSIONAL_SERVICES: {
        const servicesItems = _.filter(storeItemsData, item => item.type === STORE_ITEMS_TYPES.PROFESSIONAL_SERVICES);
        this.setState({ itemsToDisplay: servicesItems });
        break;
      }
      case STORE_ITEMS_TYPES.HARDWARE: {
        const hardwareItems = _.filter(storeItemsData, item => item.type === STORE_ITEMS_TYPES.HARDWARE);
        this.setState({ itemsToDisplay: hardwareItems });
        break;
      }
      default:
        this.setState({ itemsToDisplay: storeItemsData });
        break;
    }
  }

  onCategoryFilterSelected(categoryType) {
    const { marketPlace } = this.props;
    this.setState({ activeCategoryFilter: categoryType });
    const storeItemsData = marketPlace.subscriptions && marketPlace.subscriptions.content ? marketPlace.subscriptions.content : [];
    const filteredItems = _.filter(storeItemsData, item => item.category === categoryType);
    this.setState({ itemsToDisplay: filteredItems });
  }

  onSearchTextChange(text) {
    this.setState({ searchText: text });
    const { marketPlace } = this.props;
    const storeItemsData = marketPlace.subscriptions && marketPlace.subscriptions.content ? marketPlace.subscriptions.content : [];
    if (text && _.size(text) >= 0) {
      const filteredItems = _.filter(storeItemsData, item => ((item.renderedTitle && item.renderedTitle.toLowerCase().includes(text.toLowerCase())) || (item && item.resource && item.resource.toLowerCase().includes(text.toLowerCase()))));
      this.setState({ itemsToDisplay: filteredItems, activeTypeFilter: 'ALL' });
    } else {
      this.setState({ itemsToDisplay: storeItemsData });
    }
  }


  render() {
    const { classes, marketPlace, wallet, languange, walletSubscriptions, companyInfo } = this.props;
    const { activeTypeFilter, activeCategoryFilter, searchText, itemsToDisplay } = this.state;
    const storeItemsData = marketPlace.subscriptions && marketPlace.subscriptions.content ? marketPlace.subscriptions.content : [];
    const groupedItems = _.partition(_.filter(storeItemsData, item => item.type !== STORE_ITEMS_TYPES.HARDWARE), ['isActive', false]);
    const inactiveItems = _.first(groupedItems);
    const activeItems = groupedItems[1];
    const orderedStoreItemsData = _.orderBy(itemsToDisplay, item => {
      // Define type order: Addon -> Services -> Integrations -> Hardware
      switch(item.type) {
        case STORE_ITEMS_TYPES.ADDON:
          return 1;
        case STORE_ITEMS_TYPES.PROFESSIONAL_SERVICES:
          return 2;
        case STORE_ITEMS_TYPES.INTEGRATION:
          return 3;
        case STORE_ITEMS_TYPES.HARDWARE:
          return 4;
        default:
          return 5;
      }
    });
    const walletInnerSubscriptions = _.map(walletSubscriptions, sub => sub.subscription);
    const addonsNumber = _.size(_.filter(storeItemsData, item => item.type === STORE_ITEMS_TYPES.ADDON && item.type !== STORE_ITEMS_TYPES.HARDWARE));
    const integrationsNumber = _.size(_.filter(storeItemsData, item => item.type === STORE_ITEMS_TYPES.INTEGRATION && item.type !== STORE_ITEMS_TYPES.HARDWARE));
    const hardwareItemsNumber = _.size(_.filter(storeItemsData, item => item.type === STORE_ITEMS_TYPES.HARDWARE));
    const pmsNumber = _.size(_.filter(storeItemsData, item => item.category === STORE_ITEMS_CATEGORIES.PMS));
    const tenantAppsNumber = _.size(_.filter(storeItemsData, item => item.category === STORE_ITEMS_CATEGORIES.TENANT_APP));
    const authTechnologiesNumber = _.size(_.filter(storeItemsData, item => item.category === STORE_ITEMS_CATEGORIES.AUTHENTICATION_TECHNOLOGIES));
    const bookingSystemsNumber = _.size(_.filter(storeItemsData, item => item.category === STORE_ITEMS_CATEGORIES.BOOKING_SYSTEMS));
    const timeAttendanceNumber = _.size(_.filter(storeItemsData, item => item.category === STORE_ITEMS_CATEGORIES.TIME_ATTENDANCE));
    const bimTechnologiesNumber = _.size(_.filter(storeItemsData, item => item.category === STORE_ITEMS_CATEGORIES.BIM_TECHNOLOGIES));
    const buildingTechnologiesNumber = _.size(_.filter(storeItemsData, item => item.category === STORE_ITEMS_CATEGORIES.BUILDING_TECHNOLOGIES_IOT));
    const propertyManagementNumber = _.size(_.filter(storeItemsData, item => item.category === STORE_ITEMS_CATEGORIES.PROPERTY_MANAGEMENT));
    const accessManagementNumber = _.size(_.filter(storeItemsData, item => item.category === STORE_ITEMS_CATEGORIES.ACCESS_MANAGEMENT));
    const customizationsNumber = _.size(_.filter(storeItemsData, item => item.category === STORE_ITEMS_CATEGORIES.CUSTOMIZATIONS));
    const bluetoothRfidNumber = _.size(_.filter(storeItemsData, item => item.category === STORE_ITEMS_CATEGORIES.BLUETOOTH_RFID_DEVICES));
    const networkDevicesNumber = _.size(_.filter(storeItemsData, item => item.category === STORE_ITEMS_CATEGORIES.NETWORK_DEVICES));
    const mechatronicNumber = _.size(_.filter(storeItemsData, item => item.category === STORE_ITEMS_CATEGORIES.MECHATRONIC));
    const sensorsAccessoryNumber = _.size(_.filter(storeItemsData, item => item.category === STORE_ITEMS_CATEGORIES.SENSORS_AND_ACCESSORIES));
    const professionalServicesNumber = _.size(_.filter(storeItemsData, item => item.type === STORE_ITEMS_TYPES.PROFESSIONAL_SERVICES));
    const customerSupportNumber = _.size(_.filter(storeItemsData, item => item.category === STORE_ITEMS_CATEGORIES.CUSTOMER_SUPPORT));
    return (
      <div className={classes.container}>
        <div className={classes.headerOuterContainer}>
          <div>
            <h2 className={classes.headerText}><Entity entity="luckeyStore" /></h2>
            <h4 className={classes.subtitleText}><Entity entity="luckeyStorePayoff" /></h4>
          </div>
          <WalletCreditHeader
            wallet={wallet}
          />
        </div>
        <div className={classes.innerContainer}>
          <div className={classes.filterHeader}>
            <div style={{ marginBottom: 10, display: 'flex', position: 'relative', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <FormControl
                type="text"
                name="search"
                value={searchText}
                placeholder={L20NContext.getSync('search')}
                className="form-control-rounded"
                onChange={e => this.onSearchTextChange(e.target.value)}
              />
              {searchText ? (
                <IconButton style={{ position: 'absolute', right: '0px', bottom: -5 }} onClick={() => this.onSearchTextChange('')}>
                  <CloseCircleIconCustom style={{ width: 20 }} />
                </IconButton>
              ) : (
                <Search style={{ position: 'absolute', right: '10px', bottom: 6, fontSize: 20 }} />
              )}
            </div>
            <MenuItem
              isSelected={activeTypeFilter === 'ALL'}
              icon={<PinIconCustom style={{ width: 20 }}/>}
              title={<Entity entity="all" />}
              number={_.size(storeItemsData)}
              onClick={() => this.onFilterSelected('ALL')}
            />
            <MenuItem
              isSelected={activeTypeFilter === 'ACTIVE'}
              icon={<CheckOutlineIconCustom style={{ width: 20 }}/>}
              title={<Entity entity="actives" />}
              onClick={() => this.onFilterSelected('ACTIVE')}
              number={_.size(activeItems)}
            />
            <MenuItem
              isSelected={activeTypeFilter === 'INACTIVE'}
              icon={<RemoveIconCustom style={{ width: 20 }}/>}
              title={<Entity entity="inactives" />}
              onClick={() => this.onFilterSelected('INACTIVE')}
              number={_.size(inactiveItems)}
            />
            <h5 className="sidebar-header-custom">
              <Entity entity="luckeyStoreItemType" />
            </h5>
            <CollapsibleMenu
              isOpen={this.state.isAddonDropdownOpened}
              isSelected={activeTypeFilter === STORE_ITEMS_TYPES.ADDON}
              headerIcon={STORE_ITEMS_TYPES_MAP.ADDON.icon}
              headerTitle={<Entity entity={STORE_ITEMS_TYPES_MAP.ADDON.name} />}
              headerNumber={addonsNumber}
              onToggleOpen={isOpen => {
                this.setState({ isAddonDropdownOpened: isOpen });
                this.setState({ isPluginDropdownOpened: false, isProfessionalServicesDropdownOpened: false, isHardwareDropdownOpened: false });
                this.onFilterSelected(STORE_ITEMS_TYPES.ADDON);
              }}
            >
              <MenuItem
                isSelected={activeCategoryFilter === STORE_ITEMS_CATEGORIES.PROPERTY_MANAGEMENT}
                icon={STORE_ITEMS_CATEGORIES_MAP.PROPERTY_MANAGEMENT.icon}
                title={<Entity entity={STORE_ITEMS_CATEGORIES_MAP.PROPERTY_MANAGEMENT.nameShort} />}
                number={propertyManagementNumber}
                onClick={() => this.onCategoryFilterSelected(STORE_ITEMS_CATEGORIES.PROPERTY_MANAGEMENT)}
              />
              <MenuItem
                isSelected={activeCategoryFilter === STORE_ITEMS_CATEGORIES.ACCESS_MANAGEMENT}
                icon={STORE_ITEMS_CATEGORIES_MAP.ACCESS_MANAGEMENT.icon}
                title={<Entity entity={STORE_ITEMS_CATEGORIES_MAP.ACCESS_MANAGEMENT.nameShort} />}
                number={accessManagementNumber}
                onClick={() => this.onCategoryFilterSelected(STORE_ITEMS_CATEGORIES.ACCESS_MANAGEMENT)}
              />
            </CollapsibleMenu>
            <Divider style={{ margin: 5 }} />
            <CollapsibleMenu
              isOpen={this.state.isProfessionalServicesDropdownOpened}
              isSelected={activeTypeFilter === STORE_ITEMS_TYPES.PROFESSIONAL_SERVICES}
              headerIcon={STORE_ITEMS_TYPES_MAP.PROFESSIONAL_SERVICES.icon}
              headerTitle={<Entity entity={STORE_ITEMS_TYPES_MAP.PROFESSIONAL_SERVICES.name} />}
              headerNumber={professionalServicesNumber}
              onToggleOpen={isOpen => {
                this.setState({ isProfessionalServicesDropdownOpened: isOpen });
                this.setState({ isAddonDropdownOpened: false, isPluginDropdownOpened: false, isHardwareDropdownOpened: false });
                this.onFilterSelected(STORE_ITEMS_TYPES.PROFESSIONAL_SERVICES);
              }}
            >
              {customerSupportNumber > 0 ? (
                <MenuItem
                  isSelected={activeCategoryFilter === STORE_ITEMS_CATEGORIES.CUSTOMER_SUPPORT}
                  icon={STORE_ITEMS_CATEGORIES_MAP.CUSTOMER_SUPPORT.icon}
                  title={<Entity entity={STORE_ITEMS_CATEGORIES_MAP.CUSTOMER_SUPPORT.nameShort} />}
                  number={customerSupportNumber}
                  onClick={() => this.onCategoryFilterSelected(STORE_ITEMS_CATEGORIES.CUSTOMER_SUPPORT)}
                />
              ) : null}
              <MenuItem
                isSelected={activeCategoryFilter === STORE_ITEMS_CATEGORIES.CUSTOMIZATIONS}
                icon={STORE_ITEMS_CATEGORIES_MAP.CUSTOMIZATIONS.icon}
                title={<Entity entity={STORE_ITEMS_CATEGORIES_MAP.CUSTOMIZATIONS.nameShort} />}
                number={customizationsNumber}
                onClick={() => this.onCategoryFilterSelected(STORE_ITEMS_CATEGORIES.CUSTOMIZATIONS)}
              />
            </CollapsibleMenu>
            <Divider style={{ margin: 5 }} />
            <CollapsibleMenu
              isOpen={this.state.isPluginDropdownOpened}
              isSelected={activeTypeFilter === STORE_ITEMS_TYPES.INTEGRATION}
              headerIcon={STORE_ITEMS_TYPES_MAP.INTEGRATION.icon}
              headerTitle={<Entity entity={STORE_ITEMS_TYPES_MAP.INTEGRATION.name} />}
              headerNumber={integrationsNumber}
              onToggleOpen={isOpen => {
                this.setState({ isPluginDropdownOpened: isOpen });
                this.setState({ isAddonDropdownOpened: false, isProfessionalServicesDropdownOpened: false, isHardwareDropdownOpened: false });
                this.onFilterSelected(STORE_ITEMS_TYPES.INTEGRATION);
              }}
            >
              <MenuItem
                isSelected={activeCategoryFilter === STORE_ITEMS_CATEGORIES.PMS}
                icon={STORE_ITEMS_CATEGORIES_MAP.PMS.icon}
                title={<Entity entity={STORE_ITEMS_CATEGORIES_MAP.PMS.nameShort} />}
                number={pmsNumber}
                onClick={() => this.onCategoryFilterSelected(STORE_ITEMS_CATEGORIES.PMS)}
              />  
              <MenuItem
                isSelected={activeCategoryFilter === STORE_ITEMS_CATEGORIES.TENANT_APP}
                icon={STORE_ITEMS_CATEGORIES_MAP.TENANT_APP.icon}
                title={<Entity entity={STORE_ITEMS_CATEGORIES_MAP.TENANT_APP.nameShort} />}
                number={tenantAppsNumber}
                onClick={() => this.onCategoryFilterSelected(STORE_ITEMS_CATEGORIES.TENANT_APP)}
              />  
              <MenuItem
                isSelected={activeCategoryFilter === STORE_ITEMS_CATEGORIES.AUTHENTICATION_TECHNOLOGIES}
                icon={STORE_ITEMS_CATEGORIES_MAP.AUTHENTICATION_TECHNOLOGIES.icon}
                title={<Entity entity={STORE_ITEMS_CATEGORIES_MAP.AUTHENTICATION_TECHNOLOGIES.nameShort} />}
                number={authTechnologiesNumber}
                onClick={() => this.onCategoryFilterSelected(STORE_ITEMS_CATEGORIES.AUTHENTICATION_TECHNOLOGIES)}
              />
              <MenuItem
                isSelected={activeCategoryFilter === STORE_ITEMS_CATEGORIES.BOOKING_SYSTEMS}
                icon={STORE_ITEMS_CATEGORIES_MAP.BOOKING_SYSTEMS.icon}
                title={<Entity entity={STORE_ITEMS_CATEGORIES_MAP.BOOKING_SYSTEMS.nameShort} />}
                number={bookingSystemsNumber}
                onClick={() => this.onCategoryFilterSelected(STORE_ITEMS_CATEGORIES.BOOKING_SYSTEMS)}
              />
              <MenuItem
                isSelected={activeCategoryFilter === STORE_ITEMS_CATEGORIES.TIME_ATTENDANCE}
                icon={STORE_ITEMS_CATEGORIES_MAP.TIME_ATTENDANCE.icon}
                title={<Entity entity={STORE_ITEMS_CATEGORIES_MAP.TIME_ATTENDANCE.nameShort} />}
                number={timeAttendanceNumber}
                onClick={() => this.onCategoryFilterSelected(STORE_ITEMS_CATEGORIES.TIME_ATTENDANCE)}
              />
              <MenuItem
                isSelected={activeCategoryFilter === STORE_ITEMS_CATEGORIES.BIM_TECHNOLOGIES}
                icon={STORE_ITEMS_CATEGORIES_MAP.BIM_TECHNOLOGIES.icon}
                title={<Entity entity={STORE_ITEMS_CATEGORIES_MAP.BIM_TECHNOLOGIES.nameShort} />}
                number={bimTechnologiesNumber}
                onClick={() => this.onCategoryFilterSelected(STORE_ITEMS_CATEGORIES.BIM_TECHNOLOGIES)}
              />
              <MenuItem
                isSelected={activeCategoryFilter === STORE_ITEMS_CATEGORIES.BUILDING_TECHNOLOGIES_IOT}
                icon={STORE_ITEMS_CATEGORIES_MAP.BUILDING_TECHNOLOGIES_IOT.icon}
                title={<Entity entity={STORE_ITEMS_CATEGORIES_MAP.BUILDING_TECHNOLOGIES_IOT.nameShort} />}
                number={buildingTechnologiesNumber}
                onClick={() => this.onCategoryFilterSelected(STORE_ITEMS_CATEGORIES.BUILDING_TECHNOLOGIES_IOT)}
              />
            </CollapsibleMenu>
            <Divider style={{ margin: 5 }} />
            <CollapsibleMenu
              isOpen={this.state.isHardwareDropdownOpened}
              isSelected={activeTypeFilter === STORE_ITEMS_TYPES.HARDWARE}
              headerIcon={STORE_ITEMS_TYPES_MAP.HARDWARE.icon}
              headerTitle={<Entity entity={STORE_ITEMS_TYPES_MAP.HARDWARE.name} />}
              headerNumber={hardwareItemsNumber}
              onToggleOpen={isOpen => {
                this.setState({ isHardwareDropdownOpened: isOpen });
                this.setState({ isPluginDropdownOpened: false, isProfessionalServicesDropdownOpened: false, isAddonDropdownOpened: false });
                this.onFilterSelected(STORE_ITEMS_TYPES.HARDWARE);
              }}
            >
              <MenuItem
                isSelected={activeCategoryFilter === STORE_ITEMS_CATEGORIES.BLUETOOTH_RFID_DEVICES}
                icon={STORE_ITEMS_CATEGORIES_MAP.BLUETOOTH_RFID_DEVICES.icon}
                title={<Entity entity={STORE_ITEMS_CATEGORIES_MAP.BLUETOOTH_RFID_DEVICES.nameShort} />}
                number={bluetoothRfidNumber}
                onClick={() => this.onCategoryFilterSelected(STORE_ITEMS_CATEGORIES.BLUETOOTH_RFID_DEVICES)}
              />
              <MenuItem
                isSelected={activeCategoryFilter === STORE_ITEMS_CATEGORIES.NETWORK_DEVICES}
                icon={STORE_ITEMS_CATEGORIES_MAP.NETWORK_DEVICES.icon}
                title={<Entity entity={STORE_ITEMS_CATEGORIES_MAP.NETWORK_DEVICES.nameShort} />}
                number={networkDevicesNumber}
                onClick={() => this.onCategoryFilterSelected(STORE_ITEMS_CATEGORIES.NETWORK_DEVICES)}
              />
              <MenuItem
                isSelected={activeCategoryFilter === STORE_ITEMS_CATEGORIES.MECHATRONIC}
                icon={STORE_ITEMS_CATEGORIES_MAP.MECHATRONIC.icon}
                title={<Entity entity={STORE_ITEMS_CATEGORIES_MAP.MECHATRONIC.nameShort} />}
                number={mechatronicNumber}
                onClick={() => this.onCategoryFilterSelected(STORE_ITEMS_CATEGORIES.MECHATRONIC)}
              />
              <MenuItem
                isSelected={activeCategoryFilter === STORE_ITEMS_CATEGORIES.SENSORS_AND_ACCESSORIES}
                icon={STORE_ITEMS_CATEGORIES_MAP.SENSORS_AND_ACCESSORIES.icon}
                title={<Entity entity={STORE_ITEMS_CATEGORIES_MAP.SENSORS_AND_ACCESSORIES.nameShort} />}
                number={sensorsAccessoryNumber}
                onClick={() => this.onCategoryFilterSelected(STORE_ITEMS_CATEGORIES.SENSORS_AND_ACCESSORIES)}
              />
            </CollapsibleMenu>
          </div>
          <div>
            {!_.isEmpty(orderedStoreItemsData) ? (
              <div className={classes.gridContainer}>
                {_.map(orderedStoreItemsData, item => (
                  <StoreItemCard
                    item={item}
                    key={`${item.id}_${languange}`}
                    isCompatible={item.isCompatible && item.isCompatible(companyInfo, walletInnerSubscriptions)}
                    onPress={() => this.onShowPluginDetails(item)}
                  />
                  ),
                )}
              </div>
            ) : (
              <div className={classes.gridContainer}>
                <EmptyView
                  externalContainerStyle={{ width: '100%' }}
                  title="noStoreItemWithFilter"
                  subtitle="noStoreItemWithFilterDescription"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(LuckeyStorePage);
