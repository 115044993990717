import { IconButton } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { createTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { Domain } from '@material-ui/icons';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { reduxForm } from 'redux-form';
import { BLUE, RESOURCE_IMAGE_MAP } from '../../../_config/consts';
import { getHeaderColorFromThemeName } from '../../../_config/utils';
import ArrowLeftIconCustom from '../../CustomIcons/ArrowLeftIconCustom.jsx';
import ArrowRightIconCustom from '../../CustomIcons/ArrowRightIconCustom.jsx';
import ShareIconCustom from '../../CustomIcons/ShareIconCustom.jsx';
import EmptyView from '../../EmptyView.jsx';
import MDTablePaginationActions from '../MDTable/MDTablePaginationActions.jsx';


const theme = createTheme({
  overrides: {
    MuiTablePagination: {
      spacer: {
        flex: 'none'
      }
    }
  }
});

const styles = theme => ({
  table: {
    overflow: 'scroll',
  },
  tableColumn: {
    borderRight: `1px solid ${BLUE}`,
  },
  tableColumnSlot: {
    maxWidth: '160px',
    minWidth: '160px',
    width: '0px',
    paddingRight: '16px',
    paddingLeft: '16px',
  },
  tableColumnText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  tableColumnTextSlot: {
    fontWeight: 'bold',
    color: '#3f3f3f',
    textAlign: 'center'
  },
  tableCell: {
    borderRight: `1px solid ${BLUE}`,
  },
  tableCellSlot: {
    cursor: 'pointer',
    paddingRight: '16px',
    paddingLeft: '16px',
    borderRadius: '10px',
  },
  tableCellSlotHoverOK: {
    '&:hover': {
      background: 'rgba(27,151,194,0.2)',
    },
  },
  tableCellTextSlot: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold'
  },

  disabledInput: {
    display: 'none',
  },
  guestTag: {
    fontSize: 10,
  },
  rowSelected: {
    backgroundColor: 'aliceblue !important',
    color: 'aliceblue !important',
  },
  rowHovered: {
    cursor: 'pointer',
  },
  cellHovered: {
    cursor: 'pointer',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  button: {
    color: BLUE,
    fontSize: 13,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: -15,
    marginRight: 10,
    width: 'max-content',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  lockRowContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 5
  },
  rowWithAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowActionButton: {
    width: 40,
    height: 40,
  },
  rowActionButtonIcon: {
    fontSize: 20,
    color: '#E53935',
  },
  rowBatteryLevel: {
    marginLeft: 10,
    display: 'flex',
    flexDirection: 'row',
    justifycontent: 'center',
    alignItems: 'center',
  },
  paginationText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
});

const validate = (values) => {
  const errors = {};
  return errors;
};

@reduxForm({ form: 'CapacityTableView', validate })
@connect((state) => ({ form: state.form.CapacityTableView, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName }))
class CapacityTableView extends React.Component {

  render() {
    const { classes, form, themeName, resourcesOccupancies, startingSlotIndex, deltaSlots, slotSize,
      resources: { content: resourcesData, pagination: { number: currentPage, size, totalPages, totalElements } }, 
      onFetchResourcesOnPage,
      currentDate, 
      onChangeStartingSlotIndex,
      onCellClick,
    } = this.props;
    const headerColor = getHeaderColorFromThemeName(themeName);
  
    const slotSizeHours = slotSize/60
    const shownSlotsNumber = 8
    const shownSlots = deltaSlots.slice(Math.min(startingSlotIndex,deltaSlots.length-shownSlotsNumber),startingSlotIndex+shownSlotsNumber)

    const currentDateIsBeforeToday = moment(currentDate).startOf('day').valueOf()<moment().startOf('day').valueOf()
    const currentDateIsToday = moment(currentDate).startOf('day').valueOf()===moment().startOf('day').valueOf()
    const firstReservableSlotToday = currentDateIsBeforeToday ? 9999 : 
      currentDateIsToday ? Math.ceil((moment().diff(moment().startOf('day'), 'minutes') / 60)/slotSizeHours) : 
      0

    return (
      <MuiThemeProvider theme={theme}>
        <div className="list-view details-section" style={{ top: 120, paddingBottom: 140, width: '88%', paddingLeft: 0 }}>
          <div>
            {!_.isEmpty(resourcesData) ?
              <Card style={{ overflow: 'scroll' }}>
                <Table stickyHeader size="small" className={classes.table}>
                <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableColumn} style={{borderRight:0}}>
                        <h4 className={classes.tableColumnText}>
                          <Entity entity="resourceName" />
                        </h4>
                      </TableCell>
                      <TableCell className={classes.tableColumn}>
                        <h4 className={classes.tableColumnText}>
                        </h4>
                      </TableCell>
                      {_.map(shownSlots, (slot,index) => {
                        return (
                          <TableCell className={classes.tableColumnSlot}>
                            {index==0 && startingSlotIndex!=0 &&
                              <IconButton 
                                onClick={() => onChangeStartingSlotIndex(Math.max(startingSlotIndex-4,0))} 
                                style={{ margin:0, marginLeft: -16, marginTop: 6, padding:4, position: 'absolute' }}
                              >
                                <ArrowLeftIconCustom style={{ color: BLUE, width: 25 }} />
                              </IconButton>
                            }
                            {index==shownSlots.length-1 && startingSlotIndex!=(deltaSlots.length-shownSlotsNumber) && 
                              <IconButton 
                                onClick={() => onChangeStartingSlotIndex(Math.min(startingSlotIndex+4,deltaSlots.length-shownSlotsNumber))} 
                                style={{ margin:0, marginLeft: 112, marginTop: 6, padding:4, position: 'absolute' }}
                              >
                                <ArrowRightIconCustom style={{ color: BLUE, width: 25 }} />
                              </IconButton>
                            }
                            <h4 className={classes.tableColumnTextSlot} style={{userSelect:'none'}}>
                              {`${moment.utc(slot.fromDate).format('HH:mm')}-${moment.utc(slot.toDate).format('HH:mm')}`}
                            </h4>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                    </TableHead>
                  <TableBody>
                    {_.map(resourcesData, (resource) => {
                      let imageSource = resource.imageUrl && RESOURCE_IMAGE_MAP[resource.imageUrl] ? RESOURCE_IMAGE_MAP[resource.imageUrl] : null;
                      imageSource = resource.media ? `data:image/jpeg;base64,${resource.media}` : imageSource;

                      const isRowLarge = resource.description && resource.description.length && resource.description.length > 2;

                      const timeIntervalFrom = moment().startOf('day').add(resource.timeInterval.from, 'seconds').valueOf();
                      const timeIntervalTo = moment().startOf('day').add(resource.timeInterval.to, 'seconds').valueOf();
                      const additionalTimeIntervals = []
                      if (resource && resource.additionalTimeIntervals) {
                        resource.additionalTimeIntervals.forEach((item) => {
                          const additionalTimeIntervalFrom = moment().startOf('day').add(item.from, 'seconds').valueOf();
                          const additionalTimeIntervalTo = moment().startOf('day').add(item.to, 'seconds').valueOf();
                          additionalTimeIntervals.push({
                            from: additionalTimeIntervalFrom,
                            to: additionalTimeIntervalTo,
                          })
                        });
                      }
                      const timeIntervals = [{from: timeIntervalFrom, to: timeIntervalTo}, ...additionalTimeIntervals].sort((a, b) => a.from - b.from);
                      const timeIntervalsStrings = timeIntervals.map(e=>`${moment(e.from).format('LT')} - ${moment(e.to).format('LT')}`)

                      const hideTimeAvailability = timeIntervalFrom===0 && timeIntervalTo===(24*60*60 - 1) && !additionalTimeIntervals.length

                      const timeIntervalFromSeconds = resource.timeInterval.from;
                      const timeIntervalToSeconds = resource.timeInterval.to;
                      const additionalTimeIntervalsSeconds = []
                      if (resource && resource.additionalTimeIntervals) {
                        resource.additionalTimeIntervals.forEach((item) => {
                          const additionalTimeIntervalFrom = item.from;
                          const additionalTimeIntervalTo = item.to;
                          additionalTimeIntervalsSeconds.push({
                            from: additionalTimeIntervalFrom,
                            to: additionalTimeIntervalTo,
                          })
                        });
                      }
                      const timeIntervalsSeconds = [{from: timeIntervalFromSeconds, to: timeIntervalToSeconds}, ...additionalTimeIntervalsSeconds].sort((a, b) => a.from - b.from);

                      const reservableSlotIntervals = []
                      timeIntervalsSeconds.forEach((interval) => {
                        const reservableHourFrom = interval.from / 3600
                        const reservableHourTo = (interval.to + 1) / 3600
                        reservableSlotIntervals.push({
                          from: Math.ceil(reservableHourFrom/slotSizeHours),
                          to: Math.floor(reservableHourTo/slotSizeHours)
                        })
                      })

                      const occupancyRowSearch = _.filter(resourcesOccupancies,e=>e.resourceId===resource.id)
                      const occupancyRow = occupancyRowSearch.length?occupancyRowSearch[0]:undefined

                      const capacity = resource && resource.capacity

                      return (
                        <TableRow
                          key={resource.id}
                          className={classes.row}
                          onClick={event => {}}
                          selected={false}
                        >
                          <TableCell className={classes.tableCell} style={{paddingTop: 0, borderRight:0}}>
                            <div className={classes.lockRowContainer} style={{ margin: 5, marginLeft: 0  }}>
                              {imageSource ? <img alt="resource" className="lock-icon" style={{ width: 45, height: 45, marginRight: 15 }} src={imageSource} /> : <div className="lock-icon" style={{ width: 45, height: 45, marginRight: 15, backgroundColor: 'white' }} />}
                              <div style={{ marginRight: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'max-content' }}>
                                  <h5 style={{ fontWeight: 'bold', fontSize: 19 }}>{resource.name}</h5>
                                  <div style={{ marginTop: 12.5, marginBottom: 12.5, marginLeft: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                  {resource.shared || resource.sharedFromHostname ? (
                                    <p style={{ margin: 0, marginTop: 2, alignSelf: 'flex-start', display: 'flex' }} data-tip={L20NContext.getSync('resourceIsSharedWithDomain')}><ShareIconCustom style={{ width: 18, alignSelf: 'flex-end', color: '#3f3f3f', marginTop: -2, marginBottom: 0, marginRight: 15 }} /></p>
                                  ) : null}
                                  {resource.workspace && resource.workspace.length ? (
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 5 }}>
                                      <Domain className="resourceInfoRow-info-col-icon" style={{ fontSize: 18 }} />
                                      <h6 style={{ color: '#3f3f3f', margin: 0, marginLeft: 5, textAlign: 'center' }}>{resource.workspace}</h6>
                                    </div>
                                      ): null}
                                  </div>
                                </div>
                                {isRowLarge ? <h5 style={{ fontSize: 13, marginTop: 0 }}>{resource.description}</h5> : null}
                                <div style={{display:'flex', flexDirection:'column', paddingLeft:5, marginLeft:5, borderLeft:`1px solid ${BLUE}`}}>
                                  {resource && resource.capacity > 1 &&
                                    <h5 style={{ color: '#3f3f3f', margin: 0, marginBottom: 5, fontWeight: 'bold' }}>
                                      {`${resource.capacity} ${L20NContext.getSync('resourceCapacityTableText')}`}
                                    </h5>
                                  }
                                  <h5 style={{ color: '#3f3f3f', margin: 0, marginRight: 5 }}>
                                    {resource.minimumReservationTimeMinutes ? `
                                      ${L20NContext.getSync('resourceReservationParametersMinTime')}: 
                                      ${(resource.minimumReservationTimeMinutes>=60)?(resource.minimumReservationTimeMinutes/60):(resource.minimumReservationTimeMinutes)}
                                      ${(resource.minimumReservationTimeMinutes>=60)?(L20NContext.getSync('hours')):(L20NContext.getSync('minutes'))}
                                    ` : `${L20NContext.getSync('resourceReservationParametersMinTime')}: ${L20NContext.getSync('resourceReservationParametersNoLimit')}`}
                                  </h5>
                                  <h5 style={{ color: '#3f3f3f', margin: 0, marginRight: 5 }}>
                                    {resource.maximumReservationTimeMinutes ? `
                                      ${L20NContext.getSync('resourceReservationParametersMaxTime')}: 
                                      ${(resource.maximumReservationTimeMinutes>=60)?(resource.maximumReservationTimeMinutes/60):(resource.maximumReservationTimeMinutes)}
                                      ${(resource.maximumReservationTimeMinutes>=60)?(L20NContext.getSync('hours')):(L20NContext.getSync('minutes'))}
                                    ` : `${L20NContext.getSync('resourceReservationParametersMaxTime')}: ${L20NContext.getSync('resourceReservationParametersNoLimit')}`}
                                  </h5>
                                  {!hideTimeAvailability && (
                                    <h5 style={{ color: '#3f3f3f', margin: 0, marginRight: 5 }}>
                                      {`
                                        ${L20NContext.getSync('resourceTimeAvailability')}: 
                                        ${timeIntervalsStrings.join(" | ")}
                                      `}
                                    </h5>
                                  )}
                                </div>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className={classes.tableCell} style={{paddingTop: 0}}>
                            <div className={classes.lockRowContainer} style={{ margin: 5, marginLeft: 0, display: 'flex', justifyContent: 'end' }}>
                              <h5 style={{ color: BLUE, margin: 0, marginRight: 5, textAlign: 'right' }}>
                                {`
                                  ${L20NContext.getSync('resourceAvailableSlots')}
                                `}
                              </h5>
                            </div>
                          </TableCell>
                          {_.map(shownSlots, (slot) => {
                            const cellInsideReservableTime = _.some(reservableSlotIntervals, e=>(slot.index>=e.from && slot.index<e.to)) && 
                              slot.index>=firstReservableSlotToday
                              const occupancy = occupancyRow && occupancyRow.slots && occupancyRow.slots[slot.index] && occupancyRow.slots[slot.index].occupancy ? occupancyRow.slots[slot.index].occupancy : 0;
                            const cellEnabled = occupancyRow && occupancyRow.slots && occupancyRow.slots[slot.index] && occupancyRow.slots[slot.index].occupancy < capacity && cellInsideReservableTime;
                            return (
                              <TableCell 
                                className={`${cellEnabled ? classes.tableCellSlot:''} ${occupancyRow && cellInsideReservableTime ? classes.tableCellSlotHoverOK:''}`}
                                onClick={()=>{
                                  if(cellEnabled) 
                                    onCellClick(resource,occupancyRow.slots[slot.index])
                                }}
                                style={{backgroundColor:cellEnabled?'':'#DDD'}}
                              >
                                <h4 className={classes.tableCellTextSlot} style={{ color: cellEnabled ? BLUE : 'grey' }}>
                                  {occupancyRow && cellInsideReservableTime ? (Math.max(0, capacity - occupancy)) : "-"}
                                </h4>
                              </TableCell>
                            )
                          })}
                          <ReactTooltip delayShow={200} effect="float" />
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter colSpan={1}>
                    <TableRow style={{ background: headerColor }}>
                      <TablePagination
                        count={!_.isUndefined(totalElements) ? totalElements : 0}
                        rowsPerPage={!_.isUndefined(size) ? size : 0}
                        page={!_.isUndefined(currentPage) ? currentPage : 0}
                        labelRowsPerPage=" "
                        classes={{
                          input: classes.disabledInput,
                        }}
                        labelDisplayedRows={({ from, to, count }) => (
                          <h4 className={classes.paginationText}>
                            <Entity
                              key={`${from}-${totalPages}-${count}`}
                              entity="resourcesTablePagination"
                              data={{
                                currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                                totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                                count: !_.isUndefined(count) ? count : 0,
                              }}
                            />
                          </h4>
                        )}
                        onChangePage={(event, page) => onFetchResourcesOnPage(page)}
                        ActionsComponent={() => (
                          <MDTablePaginationActions
                            count={totalElements}
                            page={currentPage}
                            iconStyle={{ fontSize: 22 }}
                            lastPage={totalPages - 1}
                            rowsPerPage={size}
                            iconColor="white"
                            onChangePage={(event, page) => onFetchResourcesOnPage(page)}
                          />
                        )}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </Card>
              : (
                <EmptyView
                  title="noResources"
                  newEntityTitle="createResource"
                  subtitle="startCreatingResource"
                  onNewEntity={null}
                />
              )}
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(CapacityTableView);
