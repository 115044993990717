import React from 'react';
import { STORE_ITEMS_PROVISIONING_TYPES } from '../../../_config/consts';
import StoreItemAutomaticActivationView from './StoreItemAutomaticActivationView.jsx';
import StoreItemIncompatibleView from './StoreItemIncompatibleView.jsx';
import StoreItemManualActivationView from './StoreItemManualActivationView.jsx';
import StoreItemSemiAutoActivationView from './StoreItemSemiAutoActivationView.jsx';

const provisioningComponents = {
  [STORE_ITEMS_PROVISIONING_TYPES.AUTOMATIC]: StoreItemAutomaticActivationView,
  [STORE_ITEMS_PROVISIONING_TYPES.MANUAL]: StoreItemManualActivationView,
  [STORE_ITEMS_PROVISIONING_TYPES.SEMI_AUTOMATIC]: StoreItemSemiAutoActivationView,
};

export default class BaseStoreItemsActivationView extends React.Component {
  render() {
    const { provisioningType, isCompatible } = this.props;

    if (!provisioningType) {
      return null;
    }
    if (!isCompatible) {
      return <StoreItemIncompatibleView {...this.props} />;
    }

    const Component = provisioningComponents[provisioningType];

    return (
      Component ? <Component {...this.props} /> : null
    );
  }
}
