import React from 'react';
import PinInput from 'react-pin-input';


export default class CodeInputField extends React.Component {
  
  onChange(text) {
    const { input, onChangeText } = this.props;
    input.onChange(text);
    if (onChangeText) onChangeText(text);
  }

  render() {
    const { length, type, meta, inputStyle, inputFocusStyle, showInputValues, input, codeInputStyle } = this.props;
    const hasError = meta.touched && meta.error;
    
    return (
      <PinInput
        length={length || 6}
        type={type || 'numeric'}
        secret={!showInputValues}
        style={{ padding: '10px', color: '#3f3f3f', ...codeInputStyle }}
        inputStyle={{ fontSize: 20, marginLeft: 10, borderColor: hasError ? 'red' : '#d2d2d2', borderRadius: 5, borderWidth: 2, ...inputStyle }}
        inputFocusStyle={{ borderWidth: 2, borderColor: '#0767DB', color: '#3f3f3f', ...inputFocusStyle }}
        onChange={(value, index) => this.onChange(value)}
      />
    );
  }
}
