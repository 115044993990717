import { resourceActions as ResourceActions, resourceTypeActions as ResourceTypeActions } from '@bottega52/bookey-redux-module';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { change, initialize, submit } from 'redux-form';
import { LICENSE_TYPES, MATCH_TAG_MODE } from '../../_config/consts';
import * as ResourceTypesEpic from '../../epics/resourcesTypes.epics';
import * as AccessoriesActions from '../../redux/actions/accessories.actions';
import * as CredentialActions from '../../redux/actions/credential.actions';
import * as TagActions from '../../redux/actions/tag.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import MDButton from '../MDButton/MDButton.jsx';
import BasicModal from '../Modals/BasicModal.jsx';
import ResourceTypeForm from '../forms/Bookey/ResourceTypeForm.jsx';
import ResourcesForm from '../forms/Bookey/ResourcesForm.jsx';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';

const checkTimeIntervals = (timeIntervals) => {
  const minimumGap = 60 * 1000;
  for (let i=0; i<timeIntervals.length; i++) {
    if (timeIntervals[i].timeIntervalFrom>=timeIntervals[i].timeIntervalTo)
      return false;
    for (let j = i + 1; j < timeIntervals.length; j++) {
      if (timeIntervals[i].timeIntervalFrom<timeIntervals[j].timeIntervalTo && timeIntervals[i].timeIntervalTo>timeIntervals[j].timeIntervalFrom)
        return false;
      const gap1 = Math.abs(timeIntervals[i].timeIntervalTo - timeIntervals[j].timeIntervalFrom);
      const gap2 = Math.abs(timeIntervals[j].timeIntervalTo - timeIntervals[i].timeIntervalFrom);
      if (gap1<minimumGap || gap2<minimumGap)
        return false;
    }
  }
  return true;
};

@connect((state) => ({ 
  resource: state.resources.selectedResource,
  resources: state.resources,
  resourcesWorkspaces: state.resources.workspaces,
  resourcesTypes: state.resourceTypes.data.content,
  credential: state.credentials.selectedCredential,
  userTags: state.tags.user.data,
  lockTags: state.tags.lock.data,
  language: state.settings.language,
  form: state.form.ResourcesForm,
  accessoriesData: state.accessories.data,
  themeName: state.settings.items.theme.data.themeName,
  companyConfigurations: state.user.companyConfigurations
}))
class ResourcesOperationalSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workspaces: props.resourcesWorkspaces,
    }
  }
  componentWillMount() {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.fetchGuestTags(includeSpecialTags, null, 0, 20));
    dispatch(TagActions.fetchLockTags(includeSpecialTags, 0, 100));
    this.fetchResourceTypes();
  }

  componentDidUpdate(prevProps) {
    const { credential: oldCredential } = prevProps;
    const { credential: newCredential, form } = this.props;

    if (oldCredential && newCredential && oldCredential.locksFilteredByTags && newCredential.locksFilteredByTags &&
      oldCredential.locksFilteredByTags.data !== newCredential.locksFilteredByTags.data) {
      if (form && form.values && form.values.checkInSmartLockId && _.findIndex(newCredential.locksFilteredByTags.data, { id: form.values.checkInSmartLockId }) === -1) {
        this.selectCheckInLock(null);
      }
    }
  }

  fetchGuestsByTags(tags, page, guestTagMatchingMode = MATCH_TAG_MODE.EVERY_TAG, append = false) {
    const { dispatch } = this.props;
    dispatch(CredentialActions.cancelFetchGuestsByTags());
    dispatch(CredentialActions.fetchGuestsByTags(tags, guestTagMatchingMode, page, append));
  }

  filterGuestTags(value) {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.cancelFetchGuestTagsByFilter());
    dispatch(TagActions.fetchGuestTagsByFilter(value, 100, includeSpecialTags));
  }

  fetchLocksByTags(tags = [], page = 0, lockTagMatchingMode = MATCH_TAG_MODE.EVERY_TAG, append = false) {
    const { dispatch } = this.props;
    dispatch(CredentialActions.cancelFetchLocksByTags());
    dispatch(CredentialActions.fetchLocksByTags(tags, lockTagMatchingMode, page, append));
    if (_.isEmpty(tags)) {
      dispatch(change('ResourcesForm', 'checkInSmartLockId', null));
    }
    dispatch(change('ResourcesForm', 'resourceSmartLockTags', tags));
  }

  filterLockTags(value) {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.cancelFetchLockTagsByFilter());
    dispatch(TagActions.fetchLockTagsByFilter(value, 100, includeSpecialTags));
  }

  async createResourceType(values) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const resourceType = await dispatch(ResourceTypeActions.createResourceType(values));
      dispatch(change('ResourcesForm', 'type', resourceType));
      this.newResourceTypeModal.close();
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  fetchResourceTypes(value) {
    const { dispatch } = this.props;
    dispatch(ResourceTypesEpic.fetchResourcesTypesByFilter(value, 5));
  }

  showResourceTypeModal(values) {
    const { dispatch } = this.props;
    dispatch(initialize('ResourceTypeForm', { name: values && values.name }));
    this.newResourceTypeModal.open();
  }

  selectCheckInLock(lock) {
    const { dispatch } = this.props;
    dispatch(change('ResourcesForm', 'checkInSmartLockId', lock ? (_.size(lock) && lock[0].id) : null));
  }

  async onFetchMoreResources(page) {
    const { dispatch } = this.props;
    await dispatch(ResourceActions.fetchResourcesWithMedia(page, 20, {}, true));
  }

  async onFetchMoreAccessories(page) {
    const { dispatch } = this.props;
    await dispatch(AccessoriesActions.fetchAccessories(page));
  }

  onWorkspaceSelected(option) {
    const { dispatch } = this.props;
    const finalValue = option && option.value && option.value.length ? option.value : null;
    this.setState({ workspaces: [...this.state.workspaces, finalValue] });
    dispatch(change('ResourcesForm', 'workspace', finalValue));
  }

  render() {
    const { userTags, subcompanies, lockTags, resource, onOpenResourceSharingSettings, resources: { data: resourcesData }, accessoriesData, onSubmitResourceForm, credential, form, resourcesWorkspaces, resourcesTypes, dispatch, onDeleteResource, onSaveResource, companyConfigurations } = this.props;
    const { workspaces } = this.state;
    
    const formData = form && form.values;
    const timeIntervalFrom = formData && formData.timeIntervalFrom
    const timeIntervalTo = formData && formData.timeIntervalTo
    const additionalTimeIntervals = formData && formData.additionalTimeIntervals
    const timeIntervals = [...(timeIntervalFrom && timeIntervalTo ? [{timeIntervalFrom, timeIntervalTo}]: []), ...(additionalTimeIntervals || [])]
    const timeIntervalsOK = checkTimeIntervals(timeIntervals)

    const canEditResource = !AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.ENGINE]);
    const canSubmitForm = timeIntervalsOK && canEditResource;

    return (
      <div>
        <ResourcesForm
          isMultidayBookeyReservationsEnabled={companyConfigurations.multidayBookeyReservations}
          timeIntervalsOK={timeIntervalsOK}
          resource={resource}
          subcompanies={subcompanies}
          availableUserTags={userTags}
          availableLockTags={lockTags}
          guestsFilteredByTags={credential.guestsFilteredByTags}
          locksFilteredByTags={credential && credential.locksFilteredByTags ? credential.locksFilteredByTags[0] : []}
          availableTypesTags={resourcesTypes}
          availableWorkspaces={workspaces}
          resourcesOptions={resourcesData}
          accessoriesData={accessoriesData}
          onFetchMoreGuests={page => this.fetchGuestsByTags(form && form.values ? form.values.resourceUserTags : [], page, form && form.values && form.values.userTagMatchingMode, true)}
          onGuestTagsChange={values => this.fetchGuestsByTags(values, 0, form && form.values && form.values.userTagMatchingMode)}
          onGuestInputChange={value => this.filterGuestTags(value)}
          onGuestFilterModeChange={mode => this.fetchGuestsByTags(form && form.values && form.values.resourceUserTags, 0, mode)}
          onFetchMoreLocks={page => this.fetchLocksByTags(form && form.values && form.values.resourceSmartLockTags, page, form && form.values && form.values.lockTagMatchingMode, true)}
          onLockTagsChange={values => this.fetchLocksByTags(values, 0, form && form.values && form.values.lockTagMatchingMode)}
          onLockInputChange={value => this.filterLockTags(value)}
          onLockFilterModeChange={mode => this.fetchLocksByTags(form && form.values && form.values.resourceSmartLockTags, 0, mode)}
          onSubmit={values => onSaveResource(values)}
          onNewTag={values => this.showResourceTypeModal(values)}
          onWorkspaceSelected={values => this.onWorkspaceSelected(values)}
          onTypeInputChange={value => this.fetchResourceTypes(value)}
          onDeleteResource={resource => onDeleteResource(resource)}
          onSelectCheckInLock={lock => this.selectCheckInLock(lock)}
          onFetchMoreResources={page => this.onFetchMoreResources(page)}
          onFetchMoreAccessories={page => this.onFetchMoreAccessories(page)}
          onOpenResourceSharingSettings={resource => onOpenResourceSharingSettings(resource)}
        />
        <MDButton
          title={<Entity entity="save" />}
          containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
          style={{ 
            height: 45, borderRadius: 0,
            opacity: canSubmitForm?1:0.5, cursor: canSubmitForm?'pointer':'default', pointerEvents: canSubmitForm?'unset':'none'
          }}
          disabled={!canSubmitForm}
          onClick={() => onSubmitResourceForm()}
        />
        <BasicModal
          ref={(m) => { this.newResourceTypeModal = m; }}
          title={<Entity entity="newType" />}
          body={
            <div>
              <ResourceTypeForm
                onSubmit={values => this.createResourceType(values)}
              />
              <MDButton
                containerStyle={{ marginTop: 30 }}
                title={<Entity entity="save" />}
                onClick={() => dispatch(submit('ResourceTypeForm'))}
              />
            </div>
          }
        />
      </div>
    );
  }
} 

export default ResourcesOperationalSection;
