import axios from 'axios';

const luckeyStoreInstance = axios.create({
  timeout: 30000,
  baseURL: 'https://luckeystore.sofialocks.com/',
});

export function fetchStoreItemContent(itemId, languagePrexif) {
  return luckeyStoreInstance.get(`/wp-json/wp/v2/posts?_fields=_embedded,id,title,content,excerpt,_links&_embed&lang=${languagePrexif}&include[]=${itemId}`);
}

export function fetchAllStoreItemContent(languagePrexif, params) {
  const restParams = {
    params,
  };
  return luckeyStoreInstance.get(`/wp-json/wp/v2/posts?_fields=id,excerpt,title,content,_embedded,_links&_embed&per_page=100&lang=${languagePrexif}`, restParams);
}

export function fetchBannerContent(itemId, languagePrexif) {
  return luckeyStoreInstance.get(`/wp-json/wp/v2/banner?_fields=_embedded,id,title,content,excerpt,_links&_embed&lang=${languagePrexif}&include[]=${itemId}`);
}

export function fetchBannersContent(languagePrexif) {
  return luckeyStoreInstance.get(`/wp-json/wp/v2/banner?_embedded,per_page=100&lang=${languagePrexif}`);
}
