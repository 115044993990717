import React, { Component } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { Entity, ctx as L20NContext  } from '@sketchpixy/rubix/lib/L20n';
import { quillSnowCss } from './quillcss.jsx';
import { BLUE } from '../../../../_config/consts.js';

const InlineBlot = Quill.import('blots/inline');
class NonEditableSpanBlot extends InlineBlot {
  static create(value) {
    const node = super.create();
    node.setAttribute('style', `background-color: ${BLUE}; color: white; padding: 2px 5px; user-select: none; font-weight: bold; border-radius: 5px;`);
    node.setAttribute('contenteditable', 'false');
    node.textContent = value || 'PLACEHOLDER';
    return node;
  }
  static value(node) {
    return node.textContent;
  }
}
NonEditableSpanBlot.blotName = 'nonEditableSpan';
NonEditableSpanBlot.tagName = 'span';
NonEditableSpanBlot.className = 'non-editable-span';
Quill.register(NonEditableSpanBlot);

const CUSTOM_QUILL_CSS = `
  .ql-editor {
    font-size: 11pt !important;
    line-height: 1.2;
    padding: 0;
    background-color: white;
    font-family: 'Oxygen' !important;
    max-height: 216px !important;
    overflow-y: auto !important;
    height: 216px !important;
  }
  .ql-editor p {
    font-size: 11pt !important;
  }
  .ql-container {
    height: 250px !important;
  }
  .ql-editor h1 {
    font-size: 24pt !important;
  }
  .ql-editor h2 {
    font-size: 20pt !important;
  }
  .ql-editor h3 {
    font-size: 16pt !important;
  }
  .ql-editor .ql-size-small {
    font-size: 9pt !important;
  }
  .ql-editor .ql-size-large {
    font-size: 14pt !important;
  }
  .ql-editor .ql-size-huge {
    font-size: 20pt !important;
  }
  .ql-tooltip {
    left: 0 !important;
    margin-left: 16px !important;
    z-index: 1000;
  }
  .ql-tooltip.ql-editing {
    left: 0 !important;
    margin-left: 16px !important;
  }
`;



const CustomToolbar = ({ id, customPlaceholders, quill, toolbarContainerStyle, disabled }) => {
  const insertCustomPlaceholder = (placeholderText) => {
    if (quill) {
      const range = quill.getSelection(true);
      if (range) {
        quill.insertEmbed(range.index, 'nonEditableSpan', placeholderText, Quill.sources.USER);
        quill.setSelection(range.index + 0);
      }
    }
  };

  if (disabled)
    return null;

  return (
    <div id={`toolbar-${1}`} style={{backgroundColor:'white', ...toolbarContainerStyle}}>
      <div>
        <select className="ql-size"></select>
        <button className="ql-bold"></button>
        <button className="ql-italic"></button>
        <button className="ql-underline"></button>
        <button className="ql-strike"></button>
        <select className="ql-color"></select>
        <select className="ql-background"></select>
        <button className="ql-link"></button>
        {false?<button className="ql-header" value="1"></button>:null}
        {false?<button className="ql-header" value="2"></button>:null}
        {false?<button className="ql-header" value="3"></button>:null}
        <button className="ql-list" value="ordered"></button>
        <button className="ql-list" value="bullet"></button>
        {/* NOT SUPPORTED BY HtmlRenderer in Luckey App --> <select className="ql-align"></select>*/}
        <button className="ql-clean" title="Reset format"></button>
      </div>
      {customPlaceholders && customPlaceholders.length ? (
        <div style={{ marginTop: '5px', borderTop: '1px solid #ccc', paddingTop: '5px', display:'flex', flexDirection:'row', width:'770px', flexWrap:'wrap' }}>
          {customPlaceholders.map((placeholder, index) => (
            <button
              key={index}
              className="ql-customPlaceholder"
              onClick={(e) => {e.stopPropagation();e.preventDefault();insertCustomPlaceholder(placeholder.value)}}
              style={{
                margin: '0 4px',
                backgroundColor: BLUE, color:'white', fontWeight:'bold',
                border: '1px solid #ccc',
                borderRadius: '3px',
                cursor: 'pointer', float:'unset', width:'fit-content', height:'26px', padding:'2px', 
              }}
            >
              {placeholder.value}
            </button>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default class HtmlEditor extends Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.state = {
      quill: null,
    };
  }

  componentDidMount() {
    const { customPlaceholders } = this.props;
    const customPlaceholdersParsed = (customPlaceholders || []).map(e=> {
      return {
        ...e,
        value: L20NContext.getSync(e.value),
      }
    })
    this.setState({ customPlaceholdersParsed })

    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = CUSTOM_QUILL_CSS;
    document.head.appendChild(styleSheet);

    const quill = new Quill(this.editorRef.current, {
      theme: 'snow',
      modules: {
        toolbar: {
          container: `#toolbar-${1}`,
          handlers: {
            'nonEditablePlaceholder': this.insertNonEditablePlaceholder,
          }
        },
        keyboard: {
          bindings: {}
        }
      },
      readOnly: this.props.disabled
    });

    quill.on('text-change', () => {
      const { input, plainTextInput } = this.props;
      if (input && input.onChange) {
        input.onChange(this.getEditorContent())
      }
      if (plainTextInput && plainTextInput.onChange) {
        plainTextInput.onChange(this.getPlainTextContent());
      }
    });

    this.setState({ quill }, () => {
      const editorContent = quill.container.querySelector('.ql-editor');
      if (this.props.input && this.props.input.value) {
        if (this.props.isGenerateMode) {
          const parsedInitialValue = this.replacePlaceholders(this.props.input.value)
          editorContent.innerHTML = parsedInitialValue;
        }
        else {
          editorContent.innerHTML = this.props.input.value;
        }
        quill.update();
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { input, quill } = this.state;
    if (input && input.value !== prevProps.input.value && quill) {
      const editorContent = quill.container.querySelector('.ql-editor');
      if (input.value !== editorContent.innerHTML) {
        editorContent.innerHTML = input.value || '';
        quill.update();
      }
    }
    if (prevProps.disabled !== this.props.disabled && quill) {
      quill.enable(!this.props.disabled);
    }
  }
  
  insertNonEditablePlaceholder = () => {
    const { quill } = this.state;
    if (quill) {
      const range = quill.getSelection(true);
      if (range) {
        quill.insertEmbed(range.index, 'nonEditableSpan', 'PLACEHOLDER', Quill.sources.USER);
      }
    }
  };

  getEditorContent = () => {
    return this.state.quill ? this.state.quill.root.innerHTML : '';
  };

  getPlainTextContent = () => {
    if (!this.state.quill) return '';
    return this.state.quill.getText().trim();
  };

  replacePlaceholders = (editorContent) => {
    const { customPlaceholders, placeholderObjRef } = this.props
    const { customPlaceholdersParsed } = this.state
    const customPlaceholdersMapped = customPlaceholders ? customPlaceholdersParsed.map((placeholder) => {
      const foundPlaceholder = customPlaceholders.find(e=>L20NContext.getSync(e.value)===placeholder.value)
      return {...(foundPlaceholder || {}), value: placeholder.value}
    }) : customPlaceholdersParsed
    return editorContent.replace(
      /<span class="non-editable-span"[^>]*>(.*?)<\/span>/g,
      (_, content) => {
        const foundPlaceholder = customPlaceholdersMapped.find(item => item.value === content);
        if (foundPlaceholder && foundPlaceholder.objFunc && placeholderObjRef)
          return foundPlaceholder.objFunc(placeholderObjRef)
        return content;
      }
    );
  }

  render() {
    const { containerStyle, toolbarContainerStyle, editorContainerStyle, disabled } = this.props;
    const { quill, customPlaceholdersParsed } = this.state;

    return (
      <div style={{ height: 'auto', ...containerStyle}}>
        <div style={{ backgroundColor: 'white' }}>
          <CustomToolbar 
            id={1} 
            customPlaceholders={customPlaceholdersParsed} 
            quill={quill}
            toolbarContainerStyle={toolbarContainerStyle}
            disabled={disabled}
          />
          <div
            ref={this.editorRef}
            style={{ 
              height: '250px', maxHeight: '250px', overflowY: 'auto', 
              padding: '16px',
              ...editorContainerStyle 
            }}
            quill={this.state.quill}
          />
        </div>
      </div>
    );
  }
}