// @ts-nocheck
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import * as SettingActions from '../../redux/actions/setting.actions';
import * as UserActions from '../../redux/actions/user.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import ProfileSection from '../ProfileSection.jsx';
import SettingDetailsRow from './SettingDetailsRow.jsx';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import { LICENSE_TYPES, PERMISSIONS, PERMISSION_ENTITIES, SUBSCRIPTION_TYPES } from '../../_config/consts';

@connect((state) => ({ settings: state.settings, userLicense: state.user.license, themeName: state.settings.items.theme.data.themeName }))
class SettingDetailsSection extends React.Component {


  componentWillUnmount() {
    this.closeOperationalSection();
  }

  selectSetting(setting) {
    const { dispatch } = this.props;
    dispatch(SettingActions.selectSetting(setting));
    dispatch(SettingActions.setOperationalMode(true));
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(SettingActions.setOperationalMode(false));
    dispatch(SettingActions.selectSetting({}));
  }


  render() {
    const { dispatch, settings, settings: { selectedSetting }, userLicense, onOpenLicenseSetting } = this.props;
    const canReadCompanyData = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.COMPANY);
    const canEditCompanyData = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], PERMISSION_ENTITIES.COMPANY);
    const canEditSubscription = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.DOMAIN_CLOUD_CREDITS);
    const isCustomFieldActive = dispatch(UtilsActions.isCustomFieldActive());
    const isTicketCategoriesActive = dispatch(UtilsActions.isTicketingManagementActive(SUBSCRIPTION_TYPES.TICKETING_MANAGEMENT)) && AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.TICKET);
    const isACLPluginActive = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.ENTERPRISE]);
    const isMarketPlaceEnabled = dispatch(UserActions.isMarketPlaceEnabled());
    const isFakeCloudCredits = dispatch(UserActions.isFakeCloudCredits());
    const isLicenseUnknown = userLicense && userLicense.isUnknown;
    const isLuckeyLite = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
    const isLuckeyRFID = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.RFID]);
    const isNexudusIntegrationActive = dispatch(UtilsActions.isNexudusIntegrationActive());
    const isEagleEyeIntegrationActive = dispatch(UtilsActions.isEagleEyeIntegrationActive());
    const isCobotIntegrationActive = dispatch(UtilsActions.isCobotIntegrationActive());
    const isOfficeRndIntegrationActive = dispatch(UtilsActions.isOfficeRndIntegrationActive());
    const isChainelsIntegrationActive = dispatch(UtilsActions.isChainelsIntegrationActive());
    const isAndcardsActive = dispatch(UtilsActions.isAndcardsIntegrationActive());
    const isOffice365IntegrationActive = dispatch(UtilsActions.isOffice365IntegrationActive());
    const isMewsIntegrationActive = dispatch(UtilsActions.isMewsIntegrationActive());
    const isCloudBedsActive = dispatch(UtilsActions.isCloudBedsIntegrationActive());
    const isV364Domain = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.V364));
    const isOptixAppIntegrationActive = dispatch(UtilsActions.isOptixIntegrationActive());
    const isTenupIntegrationActive = dispatch(UtilsActions.isTenupIntegrationActive());
    const isZapfloorIntegrationActive = dispatch(UtilsActions.isZapfloorIntegrationActive());
    const isOctorateIntegrationActive = dispatch(UtilsActions.isOctorateIntegrationActive());
    const isResHarmonicsIntegrationActive = dispatch(UtilsActions.isResHarmonicsIntegrationActive());
    const isDeviceManagementActive = dispatch(UtilsActions.isDeviceManagementActive());
    const isInvitationActive = dispatch(UserActions.userHasSubscriptions(SUBSCRIPTION_TYPES.INVITATIONS));
    const isPrivateUnitsActive = dispatch(UtilsActions.isPrivateUnitsActive());
    const isHolidaySettingActive = dispatch(UtilsActions.isHolidaySettingActive());
    const isFluidaIntegrationActive = dispatch(UtilsActions.isFluidaIntegrationActive());
    // TODO CHECK HERE IF FAKE CREDITS!!
    return (
      <div className="list-view details-section" style={{ width: '87%'}}>
        <ProfileSection />
        <Divider style={{ height: 2, marginTop: 20, marginBottom: 20 }} />
        <Grid container spacing={2} style={{ flexWrap: 'wrap', marginBottom: 10 }}>
          {_.map(_.keys(settings.items), (setting, index) => {
            if (setting === 'company' && !canReadCompanyData) return null;
            if (setting === 'license' && (!canReadCompanyData || isLicenseUnknown || (isMarketPlaceEnabled && !isFakeCloudCredits))) return null;
            if (setting === 'customFields' && (!isCustomFieldActive || isLuckeyLite)) return null;
            if (setting === 'ticketCategories' && (!isTicketCategoriesActive || isLuckeyLite)) return null;
            if (setting === 'systemSettings' && (!canReadCompanyData || !isDeviceManagementActive || !isInvitationActive)) return null;
            if (setting === 'companyNotifications' && (!canReadCompanyData || !canEditCompanyData)) return null;
            if (setting === 'privateUnits' && (!isPrivateUnitsActive || isLuckeyLite)) return null;
            // CHECK FOR WALLET HERE
            if (setting === 'wallet' && (!canReadCompanyData || !isMarketPlaceEnabled || isFakeCloudCredits)) return null;
            if (setting === 'theme' && (isLuckeyLite || isLuckeyRFID || !canReadCompanyData || !canEditCompanyData)) return null;
            if (setting === 'permissions' && isACLPluginActive) return null;
            if (setting === 'nexudus' && !isNexudusIntegrationActive) return null;
            if (setting === 'eagleEye' && !isEagleEyeIntegrationActive) return null;
            if (setting === 'cobot' && !isCobotIntegrationActive) return null;
            if (setting === 'officeRnd' && !isOfficeRndIntegrationActive) return null;
            if (setting === 'chainels' && !isChainelsIntegrationActive) return null;
            if (setting === 'andcards' && !isAndcardsActive) return null;
            if (setting === 'cloudbeds' && !isCloudBedsActive) return null;
            if (setting === 'devicesDefaults' && !isV364Domain) return null;
            if (setting === 'office365' && !isOffice365IntegrationActive) return null;
            if (setting === 'mews' && !isMewsIntegrationActive) return null;
            if (setting === 'optix' && !isOptixAppIntegrationActive) return null;
            if (setting === 'tenup' && !isTenupIntegrationActive) return null;
            if (setting === 'zapfloor' && !isZapfloorIntegrationActive) return null;
            if (setting === 'octorate' && !isOctorateIntegrationActive) return null;
            if (setting === 'resharmonics' && !isResHarmonicsIntegrationActive) return null; 
            if (setting === 'holidays' && !isHolidaySettingActive) return null;
            if (setting === 'fluida' && !isFluidaIntegrationActive) return null;
            return (
              <Grid item md={3} xs={12}>
                <SettingDetailsRow
                  key={index}
                  isSelected={selectedSetting && settings.items[setting] && selectedSetting.name === settings.items[setting].name}
                  setting={settings.items[setting]}
                  onClick={() => this.selectSetting(settings.items[setting])}
                />
              </Grid>
            );
          })
          }
        </Grid>
        {process.env.REACT_APP_FRONTEND_VERSION && (
          <div style={{ marginTop: 50 }}>
            <h5 style={{ textAlign: 'center', fontWeight: 100 }}>
              {`Luckey Web ${process.env.REACT_APP_FRONTEND_VERSION} ©sofia ${moment().format('YYYY')}`}
            </h5>
            <h6
              className="link-label"
              style={{ textAlign: 'center', color: '#89949B', textDecoration: 'underline', fontWeight: 100 }}
              onClick={() => onOpenLicenseSetting()}
            >
            <Entity entity="softwareLicenses" />
          </h6>
          </div>
        )}
      </div>
    );
  }
} 

export default SettingDetailsSection;
