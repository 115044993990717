// @ts-nocheck
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Cancel from '@material-ui/icons/Cancel';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { initialize, submit } from 'redux-form';
import { ACCESS_METHODS, BLUE, LICENSE_TYPES, MAX_GUEST_TAGS_LITE_LIMIT, PERMISSION_ENTITIES, PERMISSIONS } from '../../_config/consts';
import { hasFormWritePermission } from '../../_config/utils';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import * as GuestActions from '../../redux/actions/guest.actions';
import * as LogEventsActions from '../../redux/actions/logEvents.actions';
import * as ModalActions from '../../redux/actions/modal.actions';
import * as TagActions from '../../redux/actions/tag.actions';
import * as TagsManagementActions from '../../redux/actions/tagsManagement.actions';
import * as UserActions from '../../redux/actions/user.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import AdminEvents from '../../routes/LogEvents/AdminEvents.jsx';
import SmartLocksEvents from '../../routes/LogEvents/SmartLocksEvents.jsx';
import AdminEventsIconCustom from '../CustomIcons/AdminEventsIconCustom.jsx';
import GuestCredentialIconCustom from '../CustomIcons/GuestCredentialIconCustom.jsx';
import SmartLocksEventsIconCustom from '../CustomIcons/SmartLocksEventsIconCustom.jsx';
import GuestForm from '../forms/GuestForm.jsx';
import TagDatesForm from '../forms/TagDatesForm.jsx';
import TagForm from '../forms/TagForm.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import BasicModal from '../Modals/BasicModal.jsx';
import OperationalView from '../OperationalView/OperationalView.jsx';
import TagsExplanationView from '../TagsManagement/TagsExplanationView.jsx';
import GuestCredentialsList from './GuestCredentialsList.jsx';
import GuestDeviceDescriptionView from './GuestDeviceDescriptionView.jsx';
import GuestProfileView from './GuestProfileView.jsx';

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});
@connect(state => ({
  guest: state.guests.selectedGuest,
  userTags: state.tags.user,
  language: state.settings.language,
  themeName: state.settings.items.theme.data.themeName,
  isLoadingCredentials: state.guests.isLoadingCredentials,
  userTagsNumber: state.tagsManagement.userTags.number,
}))
class GuestsOperationalSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      isEditGuestDataOpen: false,
    };
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(TagActions.fetchGuestTags());
    dispatch(LogEventsActions.resetAdminEventsData());
    dispatch(LogEventsActions.resetAdminEventsFilters());
    dispatch(LogEventsActions.resetSmartLocksEventsData());
    dispatch(LogEventsActions.resetSmartLocksEventsFilters());
    dispatch(TagsManagementActions.fetchGuestsSpecialTagsNumber());
  }

  async onCreateGuest(values) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(GuestActions.createGuest(values));
      dispatch(GuestActions.setOperationalMode(false));
    } finally {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(GuestActions.selectGuest({}));
    }
  }

  async onEditGuestTagsProfile(values) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(GuestActions.updateGuest(values));
      dispatch(GuestActions.cacheGuestDetails(values.id));
    } finally {
      dispatch(GuestActions.setOperationalMode(false));
      dispatch(GuestActions.selectGuest({}));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onEditGuestProfile(values) {
    const { dispatch, guestListCredentialCategorySelected } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      let newGuest = await dispatch(GuestActions.updateGuest(values));
      newGuest = {
        ...newGuest,
        ...newGuest.customAttributes,
      };
      dispatch(GuestActions.selectGuest(newGuest));
      dispatch(GuestActions.cacheGuestDetails(values.id));
      dispatch(GuestActions.fetchGuestCredentials(newGuest, 0, guestListCredentialCategorySelected));
      dispatch(initialize('GuestProfileViewForm', newGuest));
      dispatch(initialize('GuestForm', newGuest));
    } finally {
      this.setState({ isEditGuestDataOpen: false });
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  enableGuest(guestId) {
    const { dispatch } = this.props;
    dispatch(UserActions.activateUser(guestId));
  }

  async createTag(values) {
    const { dispatch, userTagsNumber } = this.props;
    const addTagToForm = true;
    try {
      await dispatch(TagActions.createGuestTag(values, addTagToForm));
      dispatch(TagsManagementActions.fetchGuestsSpecialTagsNumber());
      this.newTagModal.close();
    } catch (error) {

    }
  }

  editTag(values) {
    const { dispatch } = this.props;
    dispatch(TagActions.updateGuestTag(values))
      .then((data) => {
        dispatch(GuestActions.fetchGuests());
        const updateTagInForm = true;
        dispatch(GuestActions.updateSelectedGuestTag(data, updateTagInForm));
        this.editTagModal.close();
      });
  }

  editTagDates(values) {
    const { dispatch } = this.props;
    dispatch(GuestActions.saveTagDates(values))
      .then(() => {
        this.tagDatesModal.close();
      });
  }

  filterGuestTags(value) {
    const { dispatch } = this.props;
    dispatch(TagActions.cancelFetchGuestTagsByFilter());
    dispatch(TagActions.fetchGuestTagsByFilter(value, 5));
  }

  showTagModal(values, mode) {
    const { dispatch } = this.props;
    dispatch(TagActions.fetchGuestTagsCategories());
    if (mode === 'NEW') {
      dispatch(initialize('TagForm', { name: values.name }));
      this.newTagModal.open();
    } else {
      dispatch(initialize('TagForm', { ...values, type: { name: values.type }, color: { hex: values.color } }));
      this.editTagModal.open();
    }
  }

  showTagDatesModal(values) {
    const { dispatch } = this.props;

    let initValues = { ...values, timeFrom: moment().hours(0).minutes(0).valueOf(), timeTo: moment().hours(0).minutes(0).valueOf() };
    if (values && (values.timeFrom || values.timeTo)) {
      initValues = { ...values, timedTagEnabled: true };
    }

    dispatch(initialize('TagDatesForm', initValues));
    this.tagDatesModal.open();
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(GuestActions.setOperationalMode(false));
    setTimeout(() => dispatch(GuestActions.selectGuest({})), 600);
  }

  async selectTab(tabIndex) {
    const { dispatch, guest } = this.props;
    this.setState({ selectedTab: tabIndex });
    if (tabIndex === 1) {
      dispatch(LogEventsActions.setSmartLocksEventsFilter('actorId', guest.id));
    }
    if (tabIndex === 2) {
      dispatch(LogEventsActions.setAdminEventsFilter('entityId', guest.id));
      dispatch(LogEventsActions.setAdminEventsFilter('entityType', 'USER'));
    }
  }

  async onAppendUserCredentials(page, credentialCategory) {
    const { dispatch, guest } = this.props;
    await dispatch(GuestActions.fetchAndAppendGuestCredentials(guest, page, credentialCategory));
  }

  async onFetchUserCredentials(credentialCategory) {
    const { dispatch, guest } = this.props;
    // dispatch(GuestActions.resetGuestCredentialsData());
    dispatch(GuestActions.fetchGuestCredentials(guest, 0, credentialCategory));
  }

  async onOpenEditGuestForm() {
    const { guest, dispatch } = this.props;
    dispatch(GuestActions.selectGuest(guest));
    dispatch(initialize('GuestForm', guest));
    this.setState({ isEditGuestDataOpen: true });
  }

  onShowDeviceInfo() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        title: <Entity entity="deviceUUID" />,
        content: <GuestDeviceDescriptionView />,
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  onShowUserTagsInfo() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        title: <Entity entity="guestTagsInfoTitle" />,
        content: <TagsExplanationView tagType="guests" />,
        modalStyle: { height: '90% !important' },
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  onShowMobileUUIDInformation() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        title: <Entity entity="deviceUUID" />,
        content: <GuestDeviceDescriptionView />,
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  onAccessMethodRowClick(accessMethodType, guest) {
    const { dispatch } = this.props;
    const guestToSend = JSON.stringify({ firstname: guest.firstname, lastname: guest.lastname, email: guest.email, id: guest.id })
    switch (accessMethodType) {
      case ACCESS_METHODS.SMART: {
        dispatch(push(`/credentials?searchGuest=${guestToSend}`));
        break;
      }
      case ACCESS_METHODS.STANDARD: {
        dispatch(push(`/cards?searchGuest=${guestToSend}`));
        break;
      }
      case ACCESS_METHODS.ITEM: {
        dispatch(push(`/itemRegistry?searchGuest=${guestToSend}`));
        break;
      }
      case ACCESS_METHODS.PIN: {
        dispatch(push(`/pins?searchGuest=${guestToSend}`));
        break;
      }
      case ACCESS_METHODS.F9000: {
        dispatch(push(`/hyperKeys?searchGuest=${guestToSend}`));
        break;
      }
      default:
        break;
    }
  }

  onCreateNewAccessMethod(accessMethodType, guest) {
    const { dispatch } = this.props;
    switch (accessMethodType) {
      case ACCESS_METHODS.SMART: {
        dispatch(push(`/credentials?newElementForGuest=${guest.userTag.id}`));
        break;
      }
      case ACCESS_METHODS.STANDARD: {
        dispatch(push(`/cards?newElementForGuest=${guest.userTag.id}`));
        break;
      }
      case ACCESS_METHODS.PIN: {
        dispatch(push(`/pins?newElementForGuest=${guest.userTag.id}`));
        break;
      }
      case ACCESS_METHODS.ITEM: {
        dispatch(push(`/itemRegistry?newElementForGuest=${guest.userTag.id}`));
        break;
      }
      case ACCESS_METHODS.F9000: {
        dispatch(push(`/hyperKeys?newElementForGuest=${guest.userTag.id}`));
        break;
      }
      default:
        break;
    }
  }

  render() {
    const {
      dispatch,
      userTags,
      guest,
      themeName,
      onGuestFormSubmit,
      onDeleteGuestRequest,
      onRemoveDeviceRequest,
      onDisableGuestRequest,
      onResetGuestPassword,
      isLoadingCredentials,
      onAllowAlwaysValid,
      onSelectedUserCredentialListType,
      onLimitDeviceUUIDForUser,
      onGeneratePasswordRecoveryLink
    } = this.props;
    const { selectedTab, isEditGuestDataOpen } = this.state;
    const canReadLog = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.LOG);
    const canReadLocks = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.SMART_LOCK);
    const canReadCredential = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.CREDENTIAL);
    return (
      <MuiThemeProvider theme={theme}>
        <div>
          {!guest || _.isEmpty(guest) ? (
            <div style={{ padding: 20, paddingBottom: '30%' }}>
              <GuestForm
                guest={guest}
                availableTags={userTags.data}
                onNewTag={values => this.showTagModal(values, 'NEW')}
                onEditTag={values => this.showTagModal(values, 'EDIT')}
                onEditTagDates={values => this.showTagDatesModal(values)}
                onGuestInputChange={value => this.filterGuestTags(value)}
                onDeleteGuest={guest.id ? () => onDeleteGuestRequest(guest) : null}
                onSubmit={values => this.onCreateGuest(values)}
                onShowUserTagsInfo={() => this.onShowUserTagsInfo()}
                onShowMobileUUIDInformation={() => this.onShowMobileUUIDInformation()}
              />
              {hasFormWritePermission(PERMISSION_ENTITIES.USER, guest.id) && (
                <MDButton
                  title={<Entity entity="save" />}
                  containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
                  style={{ height: 45, borderRadius: 0 }}
                  onClick={() => onGuestFormSubmit()}
                />
              )}
            </div>
          ) : (
            <div>
              <GuestProfileView
                guest={guest}
                availableTags={userTags.data}
                themeName={themeName}
                onResetPassword={() => onResetGuestPassword(guest.id)}
                onGeneratePasswordRecoveryLink={() => onGeneratePasswordRecoveryLink(guest.id)}
                onEnableGuest={() => this.enableGuest(guest.id)}
                onAccessMethodRowClick={accessMethodType => this.onAccessMethodRowClick(accessMethodType, guest)}
                onDisableGuest={() => onDisableGuestRequest(guest)}
                onResetDevice={() => onRemoveDeviceRequest(guest)}
                onLimitDeviceUUIDForUser={() => onLimitDeviceUUIDForUser(guest)}
                onAllowAlwaysValid={() => onAllowAlwaysValid(guest)}
                onDeleteGuest={guest.id ? () => onDeleteGuestRequest(guest) : null}
                onOpenGuestForm={() => this.onOpenEditGuestForm()}
                onNewTag={values => this.showTagModal(values, 'NEW')}
                onEditTag={values => this.showTagModal(values, 'EDIT')}
                onGuestInputChange={value => this.filterGuestTags(value)}
                onEditTagDates={values => this.showTagDatesModal(values)}
                onSubmit={(values) => this.onEditGuestTagsProfile(values)}
                onShowDeviceInfo={() => this.onShowDeviceInfo()}
                onShowUserTagsInfo={() => this.onShowUserTagsInfo()}
                onCreateNewAccessMethod={accessMethodType => this.onCreateNewAccessMethod(accessMethodType, guest)}
                onGuestFormSubmit={() => dispatch(submit('GuestProfileViewForm'))}
              />
              <AppBar position="sticky" color="default">
                <Tabs
                  value={selectedTab}
                  textColor="primary"
                  indicatorColor="primary"
                  scrollable
                  TabIndicatorProps={{
                    style: { display: 'none' }
                  }}
                  onChange={(e, index) => this.selectTab(index)}
                >
                  {guest.id && canReadCredential &&
                    <Tab icon={<GuestCredentialIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="credentials" /></h5>} />  
                  }
                  {guest.id && canReadLog && canReadLocks &&
                    <Tab icon={<SmartLocksEventsIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="guestSmartLocksLogs" /></h5>} />
                  }
                  {guest.id && canReadLog &&
                    <Tab icon={<AdminEventsIconCustom style={{ width: 24 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="guestAdminLogs" /></h5>} />
                  }
                </Tabs>
              </AppBar>
              {guest.id && selectedTab === 0 && (
                <div style={{ zIndex: -3000, overflow: 'auto' }}>
                  <GuestCredentialsList
                    guest={guest}
                    credentials={guest.credentials}
                    isLoading={isLoadingCredentials}
                    appendUserCredential={(page, credentialCategory) => this.onAppendUserCredentials(page, credentialCategory)}
                    fetchUserCredential={credentialCategory => this.onFetchUserCredentials(credentialCategory)}
                    onSelectedUserCredentialListType={credentialCategory => onSelectedUserCredentialListType(credentialCategory)}
                  />
                </div>
              )}
              {guest.id && selectedTab === 1 && (
                <div style={{ padding: 20 }}>
                  <SmartLocksEvents
                    hideFilters
                    avoidReloading
                    containerStyle={{ marginTop: 0 }}
                    listContainerStyle={{ marginTop: 0, marginLeft: -20, marginBottom: 80, paddingLeft: 0 }}
                  />
                </div>
              )}
              {guest.id && selectedTab === 2 && (
                <div style={{ padding: 20 }}>
                  <AdminEvents
                    hideFilters
                    avoidReloading
                    containerStyle={{ marginTop: 0 }}
                    listContainerStyle={{ marginTop: 0, marginLeft: -20, marginBottom: 80, paddingLeft: 0 }}
                  />
                </div>
              )}
            </div>
          )}
          <BasicModal
            ref={(m) => { this.newTagModal = m; }}
            hideCloseButton
            body={
              <div>
                <div style={{ display: 'flex', marginBottom: 20, alignItems: 'center', justifyContent: 'space-between' }}>
                  <h3 style={{ fontWeight: 'bold', margin: 0, color: '#3f3f3f' }} ><Entity entity="newTag" /></h3>
                  <IconButton onClick={() => this.newTagModal.close()}>
                    <Cancel style={{ color: '#e95841', fontSize: 35 }} />
                  </IconButton>
                </div>
                <TagForm
                  tagType="guest"
                  doAsyncValidate
                  categories={userTags.categories}
                  onSubmit={values => this.createTag(values)}
                />
                <MDButton
                  containerStyle={{ marginTop: 30 }}
                  title={<Entity entity="save" />}
                  onClick={() => dispatch(submit('TagForm'))}
                />
              </div>
            }
          />
          <BasicModal
            ref={(m) => { this.editTagModal = m; }}
            hideCloseButton
            body={
              <div>
                <div style={{ display: 'flex', marginBottom: 20, alignItems: 'center', justifyContent: 'space-between' }}>
                  <h3 style={{ fontWeight: 'bold', margin: 0, color: '#3f3f3f' }} ><Entity entity="editTag" /></h3>
                  <IconButton onClick={() => this.editTagModal.close()}>
                    <Cancel style={{ color: '#e95841', fontSize: 35 }} />
                  </IconButton>
                </div>
                <TagForm
                  tagType="guest"
                  doAsyncValidate={false}
                  categories={userTags.categories}
                  onSubmit={values => this.editTag(values)}
                />
                <MDButton
                  containerStyle={{ marginTop: 30 }}
                  title={<Entity entity="save" />}
                  onClick={() => dispatch(submit('TagForm'))}
                />
              </div>
            }
          />
          <BasicModal
            ref={(m) => { this.tagDatesModal = m; }}
            hideCloseButton
            body={
              <div>
                <div style={{ display: 'flex', marginBottom: 20, alignItems: 'center', justifyContent: 'space-between' }}>
                  <h3 style={{ fontWeight: 'bold', margin: 0, color: '#3f3f3f' }} ><Entity entity="timedTags" /></h3>
                  <IconButton onClick={() => this.tagDatesModal.close()}>
                    <Cancel style={{ color: '#e95841', fontSize: 35 }} />
                  </IconButton>
                </div>
                <TagDatesForm
                  onSubmit={values => this.editTagDates(values)}
                />
                <MDButton
                  containerStyle={{ marginTop: 30 }}
                  title={<Entity entity="save" />}
                  onClick={() => dispatch(submit('TagDatesForm'))}
                />
              </div>
            }
          />
          <OperationalView
            themeName={themeName}
            isVisible={isEditGuestDataOpen}
            onClose={() => this.setState({ isEditGuestDataOpen: false })}
            style={{ margin: 0, padding: 0 }}
            operationaViewStyle={{ zIndex: 10000 }}
            title={<Entity entity="sectionTitle" data={{ name: 'guestsOperational' }} />}
          >
            <div style={{ padding: 10, paddingBottom: '30%' }}>
              <GuestForm
                guest={guest}
                availableTags={userTags.data}
                onNewTag={values => this.showTagModal(values, 'NEW')}
                onEditTag={values => this.showTagModal(values, 'EDIT')}
                onEditTagDates={values => this.showTagDatesModal(values)}
                onSubmit={values => this.onEditGuestProfile(values)}
                onGuestInputChange={value => this.filterGuestTags(value)}
                onResetPassword={() => onResetGuestPassword(guest.id)}
                onDeleteGuest={guest.id ? () => onDeleteGuestRequest(guest) : null}
                onShowUserTagsInfo={() => this.onShowUserTagsInfo()}
                onShowMobileUUIDInformation={() => this.onShowMobileUUIDInformation()}
              />
              {hasFormWritePermission(PERMISSION_ENTITIES.USER, guest.id) && (
                <MDButton
                  title={<Entity entity="save" />}
                  containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
                  style={{ height: 45, borderRadius: 0 }}
                  onClick={() => onGuestFormSubmit()}
                />
              )}
            </div>
          </OperationalView>
        </div>
      </MuiThemeProvider>
    );
  }
} 

export default GuestsOperationalSection;
