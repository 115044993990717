// @ts-nocheck
import { createTheme, Divider, IconButton, withStyles } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/CheckCircle';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { BLUE, GREEN, ORANGE, STORE_ITEMS_STATUS_MAP, STORE_ITEMS_TYPES } from '../../_config/consts';
import CloseCircleIconCustom from '../CustomIcons/CloseCircleIconCustom.jsx';
import CreditsIconCustom from '../CustomIcons/CreditsIconCustom.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import BaseStoreItemsActivationView from './StoreItemsActivationViews/BaseStoreItemsActivationView.jsx';
import RemoveIconCustom from '../CustomIcons/RemoveIconCustom.jsx';
import { isDemoSystem } from '../../_config/utils.js';

const styles = theme => ({
  titleText: {
    margin: 0,
    fontWeight: 'bold',
    color: 'white',
    fontSize: 60,
  },
  container: {
    borderRadius: 10,
    justifyContent: 'center',
  },
  headerContainer: {
    position: 'relative',
  },
  cardBodyContainer: {
    paddingBottom: 0,
  },
  descriptionText: {
    color: '#3f3f3f',
    margin: 0,
    fontWeight: '100',
    marginTop: 10,
    textAlign: 'left',
    lineHeight: '1.3em',
  },
  sectionContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  closeContainer: {
    position: 'absolute',
    height: 27,
    right: 5,
    top: 10,
    zIndex: 2000,
  },
  media: {
    height: 250,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    backgroundColor: 'black',
  },
  activeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10
  },
  activeText: {
    color: GREEN,
    margin: 0,
    marginRight: 5,
    fontWeight: 'bold',
    fontSize: 20,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'left',
    padding: 15,
    position: 'absolute',
    left: 20,
    right: 0,
    bottom: 50,
    zIndex: 10000,
  },
  requestDemoContainer: {
    marginTop: 10,
    paddingTop: 10,
  },
  requestDemoText: {
    color: 'gray',
    fontWeight: '100',
    textAlign: 'center',
    fontSize: 16,
  },
  itemAdditionalContainer: {
    marginBottom: 40,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: 20,
  },
  itemAdditionalText: {
    margin: 0,
    fontWeight: 'bold',
    color: '#3f3f3f',
    marginBottom: 5,
    fontSize: 18,
    marginLeft: 30,
  },
  costText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
    textAlign: 'center',
  },
  mask: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    zIndex: 100,
    backgroundColor: 'rgba(0,0,0, 0.6)',
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const StoreItemDescriptionView = ({ isCompatible, canEditSubscription, onRequestQuotation, onRequestActivation, onRequestSemiAutomaticActivation, onRequestDemo, classes, item, onActivateSubscription, onClose }) => {
  const isFreeItem = item.fixedPrice === 0 && item.burnRate === 0 && !isDemoSystem();
  return (
    <MuiThemeProvider theme={theme}>
      <div style={{ padding: 0 }}>
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            <div className={classes.titleContainer}>
              <h2 className={classes.titleText}>{item.renderedTitle ? item.renderedTitle : <Entity entity={item.title} />}</h2>
              {item.type !== STORE_ITEMS_TYPES.HARDWARE ? (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                  <div>
                    {item.isActive ? (
                      <div className={classes.activeContainer}>
                        <Entity className={classes.activeText} entity="storeItemActive" />
                        <CheckIcon style={{ color: GREEN, fontSize: 18 }} />
                      </div>
                    ) : (
                    <div className={classes.activeContainer}>
                      <Entity className={classes.activeText} style={{ color: '#bdbdbd' }} entity="inactive" />
                      <RemoveIconCustom style={{ color: '#bdbdbd', width: 18 }} />
                    </div>
                    )}
                  </div>
                  <div>
                    {item.status ? (
                      <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                        <h4 className={classes.activeText} style={{ color: '#bdbdbd' }}><Entity entity={STORE_ITEMS_STATUS_MAP[item.status].name} /></h4>
                        <span style={{ color: '#bdbdbd', marginTop: 2 }}>{STORE_ITEMS_STATUS_MAP[item.status].icon}</span>
                      </div>
                    ) : null}
                    {item.provider ? (
                      <h4 className={classes.activeText} style={{ fontSize: 15, color: '#bdbdbd' }}><Entity entity="providedBy" />: {item.provider}</h4>
                    ): null}
                  </div>
                </div>
              ) : <div className={classes.activeContainer} />}
              {!isCompatible ? (
                <div className={classes.activeContainer} style={{ marginTop: 0 }}>
                  <Entity className={classes.activeText} entity="storeItemNotCompatible" style={{ color: '#bdbdbd' }} />
                  <CloseCircleIconCustom style={{ width: 22, color: '#bdbdbd' }} />
                </div>
              ) : null}
              
            </div>
            <div className={classes.mask} />
            <CardMedia
              className={classes.media}
              image={item && item.previewImage ? item.previewImage : item.coverImage}
            />
            <IconButton className={classes.closeContainer} onClick={() => onClose()}>
              <CloseIcon style={{ color: 'white', fontSize: 35 }} />
            </IconButton>
          </div>
          <div className={classes.cardBodyContainer}>
            {item && item.renderedContent ? (
              <div className="storeContentContainer" style={{ maxHeight: !item.isActive ? 450 : 600, overflow: 'scroll', padding: 25, marginBottom: 0 }} dangerouslySetInnerHTML={{ __html: item.renderedContent }} />
              ) : (
                <div style={{ padding: 25 }}>
                  <h4 className={classes.descriptionText}><Entity entity={item.longDescription} /></h4>
                  <div style={{ marginTop: 25 }}>
                    {_.map(item.additionals, itemAdditional => (
                      <div className={classes.itemAdditionalContainer}>
                        {itemAdditional.icon}
                        <Entity className={classes.itemAdditionalText} entity={itemAdditional.title} />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            {canEditSubscription ? (
              <div>
                {!item.hidePrice ? (
                  <>
                  <Divider />
                  {!item.requestToQuote ? (
                    <div>
                      {!isFreeItem ? (
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                          {!item.isActive ? (
                            <h3 className={classes.costText}><Entity entity="activationCost" />: <span style={{ color: ORANGE }}><Entity entity="creditsWithNumber" key={item.fixedPrice} data={{ credits: item.fixedPrice }} /></span><CreditsIconCustom style={{ color: ORANGE, width: 22, marginBottom: -3, marginLeft: 5 }} /></h3>
                          ) : null}
                          <h3 className={classes.costText}><Entity entity="monthlyConsumption" />: <span style={{ color: ORANGE }}><Entity entity="walletCreditsConsumptionText" key={item.burnRate} data={{ credits: item.burnRate }} /></span><CreditsIconCustom style={{ color: ORANGE, width: 22, marginBottom: -3, marginLeft: 5 }} /></h3>
                        </div>
                      ) : (
                        <h3 className={classes.costText}><Entity entity="itemFreeOfCharge" /></h3>
                      )}
                    </div>
                  ) : null}
                  </>
                ): null}
                {!item.isActive ? (
                  <div>
                    <BaseStoreItemsActivationView
                      provisioningType={item.provisioningType}
                      requestToQuote={item.requestToQuote}
                      isCompatible={isCompatible}
                      isFreeItem={isFreeItem}
                      incompatibleAdditionalNotes={item.incompatibleAdditionalNotes}
                      onActivateSubscription={() => onActivateSubscription()}
                      onRequestActivation={() => onRequestActivation()}
                      onRequestQuotation={() => onRequestQuotation()}
                      onRequestSemiAutomaticActivation={() => onRequestSemiAutomaticActivation()}
                    />
                    <Divider style={{ marginTop: 10 }} />
                    <h4 style={{ color: 'gray', fontWeight: '100', textAlign: 'center', fontSize: 16 }}>
                      <Entity entity="requestADemoDescription" />
                    </h4>
                    <MDButton
                      containerStyle={{ marginTop: 0 }}
                      title={<Entity entity="requestADemoTitle" />}
                      backgroundColor={BLUE}
                      titleStyle={{ fontWeight: 'bold' }}
                      style={{ marginTop: 0 }}
                      onClick={() => onRequestDemo()}
                    />
                  </div>
                ) : (
                  <div style={{ height: 5 }} />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </MuiThemeProvider>
  );
};
export default withStyles(styles)(StoreItemDescriptionView);
