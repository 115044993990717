import React from 'react';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import _ from 'lodash';
import MDButton from '../../MDButton/MDButton.jsx';
import LoginForm from './LoginForm.jsx';
import { isMobileBrowser } from '../../../_config/utils';
import { GREEN } from '../../../_config/consts.js';
import moment from 'moment';

const LoginFormContainer = ({ 
  dispatch, 
  onSubmit, 
  onForgotPassword, 
  onInvitationLogin,
  externalIDP,
  invitationsActive,
  getIDPLoginButton,
  isHTMLContentAvailable
}) => {
  const isMobile = isMobileBrowser();

  return (
    <div style={{
      width: '100%',
      maxWidth: 500,
      padding: '20px 40px',
      backgroundColor: isMobile ? '#157495' : 'white',
      borderRadius: 8,
      boxShadow: isMobile ? 'none' : '0px 4px 14px rgba(0, 0, 0, 0.1)'
    }}>
      <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        marginBottom: 32 
      }}>
      {isHTMLContentAvailable || isMobile ? (
        <img 
          src={isMobile ? "/imgs/common/logoJagoWhite.png" : "/imgs/common/logoJagoDark.png"}
          alt="Luckey" 
          style={{ 
            height: isMobile ? 60 : 70,
            marginBottom: 8
          }} 
        />
      ) : null}
      </div>

      <div style={{ marginBottom: 20 }}>
        <h1 style={{ 
          fontSize: 30, 
          fontWeight: 600,
          marginTop: isMobile ? 20: 10,
          color: isMobile ? 'white' : '#333',
          marginBottom: 8 
        }}>
          <Entity entity="welcomeBack" />
        </h1>
        <p style={{ 
          color: isMobile ? 'rgba(255,255,255,0.8)' : '#666',
          fontSize: 14,
          lineHeight: '1.5'
        }}>
          <Entity entity="pleaseEnterCredentialsToAccess" />
        </p>
      </div>

      <LoginForm onSubmit={onSubmit} />
      
      <div style={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        marginTop: 10,
        marginBottom: 24
      }}>
        <Entity 
          onClick={onForgotPassword} 
          entity="passwordForget" 
          className="link-label" 
          style={{ 
            color: isMobile ? 'white' : '#157495',
            textDecoration: 'underline',
            cursor: 'pointer'
          }} 
        />
      </div>

      <MDButton
        title="LOGIN"
        titleStyle={{ fontWeight: 'bold' }}
        backgroundColor={isMobile ? GREEN : '#157495'}
        containerStyle={{ 
          margin: 0,
          marginBottom: 16,
          width: '100%'
        }}
        onClick={() => dispatch(submit('LoginForm'))}
      />

      {invitationsActive && isMobile && (
        <MDButton
          type="submit"
          backgroundColor="white"
          title={<Entity entity="loginWithInvitationCode" />}
          titleStyle={{ 
            color: '#3f3f3f', 
            fontWeight: 'bold'
          }}
          containerStyle={{ 
            margin: 0,
            marginBottom: 16,
            width: '100%'
          }}
          onClick={onInvitationLogin}
        />
      )}

      {_.map(externalIDP, idp => getIDPLoginButton(idp))}
    </div>
  );
};

export default connect()(LoginFormContainer); 