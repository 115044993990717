import { Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { COMMUNICATION_EMAIL_STATUSES_PARAMETERS, COMMUNICATION_STATUSES_PARAMETERS, COMMUNICATION_STATUSES } from '../../_config/consts';
import CommunicationIconCustom from '../CustomIcons/CommunicationIconCustom.jsx';
import { SmartphoneOutlined } from '@material-ui/icons';
import ReservationEmailIconCustom from '../CustomIcons/ReservationEmailIconCustom.jsx';

export default class CommunicationRow extends React.Component {
  render() {
    const { communication: { name, status, emailStatus, description, fromDate, toDate, showInApp, emailDate, emailSentDate }, onClick, isSelected } = this.props;
    const cardClassName = isSelected ? 'card-selected' : '';
    const appStatusVariables = status && COMMUNICATION_STATUSES_PARAMETERS[status] ? COMMUNICATION_STATUSES_PARAMETERS[status] : null;
    const emailStatusVariables = emailStatus && COMMUNICATION_EMAIL_STATUSES_PARAMETERS[emailStatus] ? COMMUNICATION_EMAIL_STATUSES_PARAMETERS[emailStatus] : null;
    const showAppSection = showInApp && fromDate && toDate
    const showEmailSection = emailStatusVariables && !emailStatusVariables.hiddenInTable
    const appNotVisibleYet = appStatusVariables && status && status===COMMUNICATION_STATUSES.NOT_YET_VISIBLE
    return (
      <Card elevation={2} onClick={() => onClick()} style={{ borderRadius: 10, padding: 30, paddingLeft: 20 }} className={`card card-rounded ${cardClassName}`} >
        <div style={{ paddingRight: 10, display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
          <CommunicationIconCustom
            style={{ width: 30, marginTop: 6, color: '#3f3f3f', alignSelf: 'flex-start' }}
          />
          <div style={{ flex: 1, marginLeft: 10, display: 'flex', flexDirection: 'column' }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <h4 className="card-title" style={{ margin: 0, marginTop: 10, color: '#3f3f3f', whiteSpace: 'pre-wrap' }}>{name}</h4>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {showAppSection && 
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignSelf: 'end' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                      {appStatusVariables && appStatusVariables.string && appStatusVariables.color ? (
                        <h5 style={{ fontSize: 15, color: appStatusVariables.color, fontWeight: 'bold', margin: 0, marginBottom: 4, marginRight: 5 }}>
                          <Entity key={appStatusVariables.string} entity={appStatusVariables.string} />
                        </h5>
                      ) : null}
                      {appNotVisibleYet ? (
                        <h5 style={{ color: '#3f3f3f', margin: 0, marginRight: 5 }}>
                          {`${moment(fromDate).format('LLL')}`}
                        </h5>
                      ) : (
                        <h5 style={{ color: '#3f3f3f', margin: 0, marginRight: 5 }}>
                          {`${moment(fromDate).format('DD MMMM YYYY')} - ${moment(toDate).format('DD MMMM YYYY')}`}
                        </h5>
                      )}
                    </div>
                    <SmartphoneOutlined style={{ fontSize: 22, marginTop: 0, marginRight: 6, color: '#3f3f3f' }} />
                  </div>
                }
                {showEmailSection && showAppSection &&
                  <Divider style={{margin: 8, marginTop: 9, marginLeft: 28, backgroundColor: '#ddd', maxWidth: 300, minWidth: 'unset'}}/>
                }
                {showEmailSection && 
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignSelf: 'end' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                      {emailStatusVariables && emailStatusVariables.string && emailStatusVariables.color ? (
                        <h5 style={{ fontSize: 15, color: emailStatusVariables.color, fontWeight: 'bold', margin: 0, marginBottom: 4, marginRight: 5 }}>
                          <Entity key={emailStatusVariables.string} entity={emailStatusVariables.string} />
                        </h5>
                      ) : null}
                      {emailSentDate || emailDate ? (
                        <h5 style={{ color: '#3f3f3f', margin: 0, marginRight: 5 }}>
                          {`${moment(emailSentDate || emailDate).format('LLL')}`}
                        </h5>
                      ) : null}
                    </div>
                    <ReservationEmailIconCustom style={{ width: 22, marginTop: 0, marginRight: 6, color: '#3f3f3f' }} />
                  </div>
                }
              </div>
            </div>
            <Divider style={{ marginTop: 10, marginRight: 5 }} />
            {description ? (
              <div style={{ marginTop: 15 }}>
                <h5 className="card-description" style={{ margin: 0, marginRight: 5, color: '#3f3f3f', whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                  {_.truncate(description, { length: 300 })}
                </h5>
              </div>
            ) : null}
          </div>
        </div>
      </Card>
    );
  }
}
