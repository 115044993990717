import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import LauncIcon from '@material-ui/icons/Launch';
import RefreshIcon from '@material-ui/icons/Autorenew';
import NewFromTemplateIcon from '@material-ui/icons/FileCopyOutlined';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import * as CardActions from '../../redux/actions/card.actions';
import { BLUE, COMMUNICATION_EMAIL_STATUSES, COMMUNICATION_EMAIL_STATUSES_PARAMETERS, COMMUNICATION_STATUSES, COMMUNICATION_STATUSES_PARAMETERS, USER_COMMUNICATION_EMAIL_STATUSES_PARAMETERS } from '../../_config/consts';
import { getColorFromThemeName, isUserWithoutEmail } from '../../_config/utils';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import MDTablePaginationActions from '../MDTable/MDTablePaginationActions.jsx';
import { SmartphoneOutlined } from '@material-ui/icons';
import ReservationEmailIconCustom from '../CustomIcons/ReservationEmailIconCustom.jsx';
import { Divider } from 'antd';

const styles = theme => ({
  table: {
    overflow: 'scroll',
  },
  tableColumnText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  disabledInput: {
    display: 'none',
  },
  guestTag: {
    padding: 3,
  },
  rowSelected: {
    backgroundColor: 'aliceblue !important',
    color: 'aliceblue !important',
  },
  rowHovered: {
    cursor: 'default',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    minHeight: 80,
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  guestRowContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowWithAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowActionButton: {
    width: 40,
    height: 40,
  },
  rowActionButtonIcon: {
    fontSize: 20,
    color: '#E53935',
  },
  paginationText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
  button: {
    color: 'red',
    fontSize: 13,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: -8,
    marginTop: 5,
    marginBottom: 5,
    width: 'max-content',
  },
  buttonPositive: {
    color: BLUE,
    fontSize: 13,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginLeft: -8,
    marginTop: 5,
    marginBottom: 5,
    width: 'max-content',
  },
});

@connect((state) => ({ viewLoading: state.utils.viewLoading, communications: state.communications, themeName: state.settings.items.theme.data.themeName }))
class CommunicationUsersTableView extends React.Component {


  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(CardActions.setOperationalMode(false));
  }

  onOpenGuestDetails(guestId) {
    window.open(`/guests?guestId=${guestId}`);
  }

  render() {
    const {
      classes,
      viewLoading,
      themeName,
      communications: { selectedCommunicationUsers: { content: communicationUsersData, filters, pagination: { number: currentPage, size, totalPages, totalElements } }},
      onFetchCommunicationUsersOnPage,
      communicationHasEmail,
    } = this.props;
    const headerColor = 'unset'; // getColorFromThemeName(themeName);
    
    return (
      <div className="list-view details-section" style={{ position: 'unset', paddingBottom: 140, padding: 0, borderRadius: 0, width: '100%' }}>
        <div>
          {!_.isEmpty(communicationUsersData) ?
            <Card style={{ overflow: 'scroll', borderRadius: 0 }}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow style={{ backgroundColor: headerColor, zoom: '90%' }}>
                    <TableCell colSpan={1}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      </div>
                    </TableCell>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText} style={{ color: BLUE }}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="communicationUsersTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchCommunicationUsersOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          iconColor={BLUE/*"white"*/}
                          disabledIconColor={'#ccc'}
                          page={currentPage}
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchCommunicationUsersOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                  {/* 
                  <TableRow>
                    <TableCell colSpan={2}><h4 className={classes.tableColumnText}><Entity entity="userCommunicationUser" /></h4></TableCell>
                    {communicationHasEmail ? (
                      <TableCell style={{display: 'flex'}} colSpan={1}>
                        <ReservationEmailIconCustom style={{ width: 22, marginTop: 0, marginRight: 6, color: '#3f3f3f' }} />
                        <h4 className={classes.tableColumnText}><Entity entity="userCommunicationEmailStatus" /></h4>
                      </TableCell>
                    ) : null}
                  </TableRow>
                  */}
                </TableHead>
                <TableBody>
                  {_.map(communicationUsersData, (communicationUser) => {
                    const emailStatus = communicationUser.emailStatus;
                    const emailSentDate = communicationUser.emailSentDate;

                    const emailStatusVariables = emailStatus && USER_COMMUNICATION_EMAIL_STATUSES_PARAMETERS[emailStatus] ? USER_COMMUNICATION_EMAIL_STATUSES_PARAMETERS[emailStatus] : null;
                    return (
                      <TableRow
                        key={communicationUser.id}
                        className={classes.row}
                        hover
                        classes={{
                          selected: classes.rowSelected,
                          hover: classes.rowHovered,
                        }}
                        onClick={() => {}}
                        selected={false}
                      >
                        <TableCell colSpan={2}>
                          <div style={{ display: 'flex', alignItems: 'center', wordBreak: 'break-all', flexGrow: 1 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <h5 style={{ color: '#3f3f3f', marginLeft: 10 }}>{`${communicationUser.firstName} ${communicationUser.lastName} ${isUserWithoutEmail(communicationUser) ? '': ` - ${communicationUser.email}`}`}</h5>
                              {<IconButton style={{ marginLeft: 10 }} onClick={() => this.onOpenGuestDetails(communicationUser.userId)}><LauncIcon style={{ width: 20, color: '#90CAF9' }} /></IconButton>}
                            </div>
                          </div>
                        </TableCell>
                        {communicationHasEmail ? (
                          <TableCell colSpan={1}>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 5, paddingBottom: 8 }}>
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  {emailStatusVariables && emailStatusVariables.string && emailStatusVariables.color ? (
                                    <h5 style={{ fontSize: 15, color: emailStatusVariables.color, fontWeight: 'bold', margin: 0, marginBottom: 4, marginRight: 5 }}>
                                      <Entity key={emailStatusVariables.string} entity={emailStatusVariables.string} />
                                    </h5>
                                  ) : null}
                                  {emailSentDate ? (
                                    <h5 style={{ color: '#3f3f3f', margin: 0, marginRight: 5 }}>
                                      {`${moment(emailSentDate).format('LLL')}`}
                                    </h5>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  {/* 
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText} style={{ color: BLUE }}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="communicationUsersTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchCommunicationUsersOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          page={currentPage}
                          iconColor="white"
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchCommunicationUsersOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                  */}
                </TableFooter>
              </Table>
            </Card>
            : (
              <div>
                {!viewLoading ? (
                  <EmptyView
                    title={filters && !filters.search && !filters.emailStatus ? 'noCommunicationUsers' : 'noCommunicationUsersFound'}
                    subtitle={filters && !filters.search && !filters.emailStatus ? 'noCommunicationUsersDescription' : 'noCommunicationUsersFoundDescription'}
                    onNewEntity={null}
                    newEntityTitle={null}
                    iconName="icon-simple-line-icons-user-following"
                    containerStyle={{ marginTop: 20, marginBottom: 10 }}
                />) : <ListLoadingView />}
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CommunicationUsersTableView);
