
import { AppBar, IconButton, Toolbar } from '@material-ui/core';
import qs from 'qs';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import { LICENSE_TYPES, VENDORS_LIST } from '../_config/consts.js';
import { getHeaderColorFromThemeName, getLuckeyLogoSource } from '../_config/utils.js';
import LockRemoteOpenListView from '../components/Locks/LockRemoteOpenListView.jsx';
import LockRemoteOpenView from '../components/Locks/LockRemoteOpenView.jsx';
import BasicModal from '../components/Modals/BasicModal.jsx';
import AbilityProvider from '../permissionsUtils/AbilityProvider.js';
import * as GatewayActions from '../redux/actions/gateways.actions';
import * as LockActions from '../redux/actions/lock.actions';
import * as ModalActions from '../redux/actions/modal.actions';
import * as UserActions from '../redux/actions/user.actions.js';
import * as UtilsActions from '../redux/actions/utils.actions';
import LockRemoteTogglePassageModeView from '../components/Locks/LockRemoteTogglePassageModeView.jsx';

@connect((state) => ({ routing: state.router, locks: state.locks.remoteOpenSmartLocks, userData: state.user.data, companyConfigurations: state.user.companyConfigurations, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName, customFields: state.customFields.locks.content, floorPlans: state.floorPlans }))
class GuestRemoteOpenView extends React.Component {
  
  async componentWillMount() {
    const { routing, dispatch } = this.props;
    // const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
    // if (parsed && parsed.code) {
    //   const code = parsed.code;
    //   await dispatch(UserActions.loginWithInvitationCode(code));
    // }
    this.onFetchLocks();
  }


  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(LockActions.selectLock({}));
    dispatch(LockActions.resetLocksFilters());
    dispatch(LockActions.setOperationalMode(false));
  }

  async onFetchLocks() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      await dispatch(LockActions.fetchLocksFromAccessCredentials());
      setInterval(() => {
        dispatch(LockActions.fetchLocksFromAccessCredentials());
      }, 10000);
    } finally {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  async onOpenLockRequest(lock) {
    const { dispatch } = this.props;
    const canLockBeOpenedRemotely = await dispatch(LockActions.canLockBeOpenedRemotely(lock.geoHash, lock.geoHashPrecision))
    if (canLockBeOpenedRemotely) {
      if (lock.connectionType === 'SMART_CLASSIC_OFFLINE') {
        this.onOpenLockRemotely(lock);
      } else {
        this.onOpenLockByGateway(lock);
      }
    } else {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="errorLocationPermissionDeniedByUserOrFar" /></h6>),
        },
      }));
    }
  }

  async onOpenLockRemotely(lock) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(LockActions.onOpenFullOnlineLockRemote(lock));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="doorOpenSuccess" /></h6>),
        },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(LockActions.selectLock({}));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="doorOpenFailed" /></h6>),
        },
      }));
    }
  }

  async onOpenLockByGateway(lock) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      dispatch(GatewayActions.setSelectedGateway({ id: lock.gatewayId, highFlex: true }));
      dispatch(LockActions.selectLock(lock));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.lockOpenModal.open();
    } catch (error) {
      dispatch(LockActions.selectLock({}));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onTogglePassageModeLockRequest(lock) {
    const { dispatch, userData } = this.props;
    const canLockBeOpenedRemotely = await dispatch(LockActions.canLockBeOpenedRemotely(lock.geoHash, lock.geoHashPrecision))
    if (canLockBeOpenedRemotely && userData.canTogglePassageMode) {
      if (lock.connectionType === 'SMART_CLASSIC_OFFLINE') {
        dispatch(ModalActions.showModal({
          modalType: 'ERROR_ALERT',
          modalProps: {
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            message: (<h6 className="snack-title"><Entity entity="errorTogglePassageModeLockByGateway" /></h6>),
          },
        }));
        return;
      } else {
        this.onSetPassageModeLockByGateway(lock);
      }
    } else {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: (<h6 className="snack-title"><Entity entity="errorLocationPermissionDeniedByUserOrFar" /></h6>),
        },
      }));
    }
  }

  async onSetPassageModeLockByGateway(lock) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      dispatch(GatewayActions.setSelectedGateway({ id: lock.gatewayId, highFlex: true }));
      dispatch(LockActions.selectLock(lock));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.lockTogglePassageModeModal.open();
    } catch (error) {
      dispatch(LockActions.selectLock({}));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onFetchLocksForPage(page) {
    const { dispatch } = this.props;
    dispatch(LockActions.fetchLocks(page));
  }

  onRefreshLocks() {
    const { dispatch } = this.props;
    const { locks } = this.props;
    const page = locks && locks.data && locks.data.pagination && locks.data.pagination.number;
    dispatch(LockActions.fetchLocks(page));
  }

  onLogout() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'confirmToLogout',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => {
          dispatch(UserActions.logout());
          dispatch(ModalActions.hideModal());
        },
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  render() {
    const { userData, dispatch, themeName } = this.props;
    const color = getHeaderColorFromThemeName(themeName);
    const isLuckeyLite = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
    const isLuckeyRFID = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.RFID]);
    const luckeyLogoSource = getLuckeyLogoSource('white');
    return (
      <div>
        <AppBar position="static"  style={{ backgroundColor: color }}>
          <Toolbar style={{ height: 70 }}>
            <div style={{ display: 'flex', flex: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between'}}>
            <img alt="logo" style={{ marginTop: (isLuckeyLite || isLuckeyRFID) ? 0 : -10, height: (isLuckeyLite || isLuckeyRFID) ? 45 : 55 }} src={luckeyLogoSource} />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <IconButton onClick={() => this.onLogout()}>
                <LogoutIcon style={{ color: 'white', fontSize: 30 }} />
              </IconButton>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <LockRemoteOpenListView
          listContainerStyle={{ top: isLuckeyLite ? 140 : 190 }}
          onOpenLock={lock => this.onOpenLockRequest(lock)}
          onSetPassageModeLockByGateway={userData.canTogglePassageMode ? lock => this.onTogglePassageModeLockRequest(lock) : null}
          onSelectLock={lock => this.onSelectLock(lock)}
        />
        <BasicModal
          ref={(m) => { this.lockTogglePassageModeModal = m; }}
          hideCloseButton
          body={
            <div>
              <LockRemoteTogglePassageModeView
                onCloseModal={() => {
                  this.lockTogglePassageModeModal.close();
                  dispatch(LockActions.selectLock({}));
                }}
              />
            </div>
          }
        />
        <BasicModal
          ref={(m) => { this.lockOpenModal = m; }}
          hideCloseButton
          body={
            <div>
              <LockRemoteOpenView
                onCloseModal={() => {
                  this.lockOpenModal.close();
                  dispatch(LockActions.selectLock({}));
                }}
              />
            </div>
          }
        />
      </div>
    );
  }
} 

export default GuestRemoteOpenView;
