import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { change } from 'redux-form';
import * as XLSX from 'xlsx';
import { ACCESS_METHODS, BACKGROUND_TASK_TYPES, CARD_TYPES, CREDENTIAL_RULE_STATUSES, EXPORT_FORMATS, VALIDATION_MODES_SELECTION_OPTIONS } from '../../_config/consts';
import * as formatter from '../../_config/formatter';
import * as RestService from '../../_config/rest';
import * as StandardDevicesAPI from '../../_config/standardDevicesAPI';
import { elaborateTimeProfileForExport, elaborateTimeRangeForExport, saveDataToLocalStorage } from '../../_config/utils';
import {
  APPEND_CARDS, APPEND_CARD_CREDENTIALS, APPEND_HYPERKEYS, APPEND_KEY_CREDENTIALS, APPEND_PINS, APPEND_PIN_CREDENTIALS, REMOVE_CARD_FROM_STATE,
  REMOVE_KEY_FROM_STATE,
  RESET_CARDS_DATA, RESET_CARD_FILTERS, RESET_CARD_PAGINATION_DATA, RESET_HYPERKEYS_FILTERS, RESET_PINS_FILTERS,
  RESET_PINS_PAGINATION_DATA,
  SAVE_CARD, SAVE_CARDS, SAVE_CARD_CREDENTIALS,
  SAVE_CARD_VALIDATION_STATUS,
  SAVE_HYPERKEY,
  SAVE_HYPERKEYS, SAVE_HYPERKEYS_PAGINATION_DATA,
  SAVE_HYPERKEY_VALIDATION_STATUS,
  SAVE_KEY_CREDENTIALS,
  SAVE_PIN,
  SAVE_PINS, SAVE_PINS_PAGINATION_DATA, SAVE_PIN_CREDENTIALS,
  SAVE_STANDARD_DEVICES_VALIDATION_DEFAULTS,
  SELECT_CARD, SET_CARD_CREDENTIALS_PAGINATION_DATA,
  SET_CARD_FILTER, SET_CARD_OPERATIONAL_MODE, SET_CARD_PAGINATION_DATA, SET_HYPERKEYS_FILTER, SET_KEY_CREDENTIALS_PAGINATION_DATA, SET_PINS_FILTER, SET_PIN_CREDENTIALS_PAGINATION_DATA, SET_SELECTED_HYPERKEY, SET_SELECTED_PIN, UPDATE_CARD_CREDENTIAL_STATUS, UPDATE_CARD_IN_STATE, UPDATE_CARD_NOTES_IN_STATE,
  UPDATE_KEY_CREDENTIAL_STATUS, UPDATE_KEY_IN_STATE, UPDATE_KEY_NOTES_IN_STATE, UPDATE_PIN_CREDENTIAL_STATUS,
  UPDATE_PIN_IN_STATE, UPDATE_PIN_NOTES_IN_STATE,
} from './actionTypes/card';
import * as ModalActions from './modal.actions';
import * as UtilsActions from './utils.actions';

export function saveCards(cards) {
  return {
    type: SAVE_CARDS,
    cards,
  };
}

export function saveCard(card) {
  return {
    type: SAVE_CARD,
    card,
  };
}

export function removeCardFromState(cardId) {
  return {
    type: REMOVE_CARD_FROM_STATE,
    cardId,
  };
}

export function removeKeyFromState(keyId) {
  return {
    type: REMOVE_KEY_FROM_STATE,
    keyId,
  };
}

export function resetCardsData() {
  return { type: RESET_CARDS_DATA };
}

export function setCardFilter(field, value) {
  return {
    type: SET_CARD_FILTER,
    field,
    value,
  };
}

export function resetCardsFilters() {
  return { type: RESET_CARD_FILTERS };
}

export function appendCards(cards) {
  return {
    type: APPEND_CARDS,
    cards,
  };
}

export function selectCard(card) {
  return {
    type: SELECT_CARD,
    card,
  };
}

export function updateCardCredentialStatus(cardId, status) {
  return {
    type: UPDATE_CARD_CREDENTIAL_STATUS,
    cardId,
    status,
  };
}

export function updateCardInState(card) {
  return {
    type: UPDATE_CARD_IN_STATE,
    card,
  };
}

export function saveSelectedCardValidationStatus(data) {
  return {
    type: SAVE_CARD_VALIDATION_STATUS,
    data,
  };
}

export function saveSelectedKeyValidationStatus(data) {
  return {
    type: SAVE_HYPERKEY_VALIDATION_STATUS,
    data,
  };
}


export function updateKeyInState(key) {
  return {
    type: UPDATE_KEY_IN_STATE,
    key,
  };
}

export function updateCardNotesInState(cardId, notes) {
  return {
    type: UPDATE_CARD_NOTES_IN_STATE,
    cardId,
    notes,
  };
}

export function setOperationalMode(value) {
  return {
    type: SET_CARD_OPERATIONAL_MODE,
    value,
  };
}

export function setCardsPaginationData(pagination) {
  return {
    type: SET_CARD_PAGINATION_DATA,
    pagination,
  };
}

export function resetCardsPaginationData() {
  return {
    type: RESET_CARD_PAGINATION_DATA,
  };
}

export function appendCardCredentials(credentials) {
  return {
    type: APPEND_CARD_CREDENTIALS,
    credentials,
  };
}

export function saveCardCredentials(credentials) {
  return {
    type: SAVE_CARD_CREDENTIALS,
    credentials,
  };
}

export function setCardCredentialsPaginationData(currentPage, totalPages) {
  return {
    type: SET_CARD_CREDENTIALS_PAGINATION_DATA,
    currentPage,
    totalPages,
  };
}

export function savePins(pins) {
  return {
    type: SAVE_PINS,
    pins,
  };
}

export function appendPins(pins) {
  return {
    type: APPEND_PINS,
    pins,
  };
}

export function savePin(pin) {
  return {
    type: SAVE_PIN,
    pin,
  };
}

export function savePinsPagination(pagination) {
  return {
    type: SAVE_PINS_PAGINATION_DATA,
    pagination,
  };
}

export function setSelectedPin(pin) {
  return {
    type: SET_SELECTED_PIN,
    pin,
  };
}

export function setPinsFilter(field, value) {
  return {
    type: SET_PINS_FILTER,
    field,
    value,
  };
}

export function resetPinsFilters() {
  return {
    type: RESET_PINS_FILTERS,
  };
}

export function appendPinCredentials(credentials) {
  return {
    type: APPEND_PIN_CREDENTIALS,
    credentials,
  };
}

export function savePinCredentials(credentials) {
  return {
    type: SAVE_PIN_CREDENTIALS,
    credentials,
  };
}

export function setPinCredentialsPaginationData(currentPage, totalPages) {
  return {
    type: SET_PIN_CREDENTIALS_PAGINATION_DATA,
    currentPage,
    totalPages,
  };
}

export function updatePinInState(pin) {
  return {
    type: UPDATE_PIN_IN_STATE,
    pin,
  };
}

export function updatePinNotesInState(pinId, notes) {
  return {
    type: UPDATE_PIN_NOTES_IN_STATE,
    pinId,
    notes,
  };
}

export function updatePinCredentialStatus(pinId, status) {
  return {
    type: UPDATE_PIN_CREDENTIAL_STATUS,
    pinId,
    status,
  };
}

export function resetPinsPaginationData() {
  return {
    type: RESET_PINS_PAGINATION_DATA,
  };
}

export function saveHyperKeys(keys) {
  return {
    type: SAVE_HYPERKEYS,
    keys,
  };
}

export function saveHyperKey(key) {
  return {
    type: SAVE_HYPERKEY,
    key,
  };
}

export function appendHyperKeys(keys) {
  return {
    type: APPEND_HYPERKEYS,
    keys,
  };
}

export function saveHyperKeysPagination(pagination) {
  return {
    type: SAVE_HYPERKEYS_PAGINATION_DATA,
    pagination,
  };
}

export function setSelectedHyperKey(key) {
  return {
    type: SET_SELECTED_HYPERKEY,
    key,
  };
}

export function setHyperKeyFilter(field, value) {
  return {
    type: SET_HYPERKEYS_FILTER,
    field,
    value,
  };
}

export function updateKeyCredentialStatus(keyId, status) {
  return {
    type: UPDATE_KEY_CREDENTIAL_STATUS,
    keyId,
    status,
  };
}


export function resetHyperKeyFilters() {
  return {
    type: RESET_HYPERKEYS_FILTERS,
  };
}

export function appendKeyCredentials(credentials) {
  return {
    type: APPEND_KEY_CREDENTIALS,
    credentials,
  };
}

export function saveKeyCredentials(credentials) {
  return {
    type: SAVE_KEY_CREDENTIALS,
    credentials,
  };
}

export function setKeyCredentialsPaginationData(currentPage, totalPages) {
  return {
    type: SET_KEY_CREDENTIALS_PAGINATION_DATA,
    currentPage,
    totalPages,
  };
}

export function updateKeyNotesInState(keyId, notes) {
  return {
    type: UPDATE_KEY_NOTES_IN_STATE,
    keyId,
    notes,
  };
}

export function saveStandardDevicesValidationDefaults(defaults) {
  return {
    type: SAVE_STANDARD_DEVICES_VALIDATION_DEFAULTS,
    defaults,
  };
}

export function fetchCards(page = 0, append = false, pageSize = 20) {
  return async (dispatch, getState) => {
    const filters = getState().cards.cardsData.filters;
    try {
      const params = {
        page,
        pageSize,
        types: [
          CARD_TYPES.GENERIC_CARD,
          CARD_TYPES.GENERIC_CARD_DESFIRE,
          CARD_TYPES.ISEO_CARD,
          CARD_TYPES.ISEO_CARD_DESFIRE,
        ],
        ...filters,
      };

      const response = await RestService.fetchStandardDevices(params);
      if (response.data && response.data.content) {
        const formattedCards = _.map(response.data.content, card => ({
          ...card,
          credentialRule: card.credentialRule && formatter.formatInputData(formatter.CREDENTIAL, card.credentialRule),
          additionalCredentialRules: card.additionalCredentialRules && _.map(card.additionalCredentialRules, timeProfile => formatter.formatInputData(formatter.CREDENTIAL, timeProfile)),
        }));
        if (append) {
          dispatch(appendCards(formattedCards));
        } else {
          dispatch(saveCards(formattedCards));
        }
        dispatch(setCardsPaginationData(_.omit(response.data, 'content')));
        return formattedCards;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchCardValidationStatus(card) {
  return async (dispatch, getState) => {
    const response = await RestService.fetchStandardDeviceStatus(card.id);
    if (response && response.data) {
      dispatch(saveSelectedCardValidationStatus(response.data));
    }
  };
}

export function fetchHyperKeyValidationStatus(card) {
  return async (dispatch, getState) => {
    const response = await RestService.fetchStandardDeviceStatus(card.id);
    if (response && response.data) {
      dispatch(saveSelectedKeyValidationStatus(response.data));
    }
  };
}

export function fetchPins(page = 0, append = false, pageSize = 20) {
  return async (dispatch, getState) => {
    const filters = getState().cards.pinsData.filters;
    try {
      const params = {
        page,
        pageSize,
        types: [
          CARD_TYPES.ISEO_PIN,
        ],
        ...filters,
      };

      const response = await RestService.fetchStandardDevices(params);
      if (response.data && response.data.content) {
        const formattedPins = _.map(response.data.content, card => ({
          ...card,
          credentialRule: card.credentialRule && formatter.formatInputData(formatter.CREDENTIAL, card.credentialRule),
        }));
        if (append) {
          dispatch(appendPins(formattedPins));
        } else {
          dispatch(savePins(formattedPins));
        }
        dispatch(savePinsPagination(_.omit(response.data, 'content')));
        return formattedPins;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchPinsCredentials(pin, page, append) {
  return async (dispatch, getState) => {
    try {
      const params = {
        page,
        credentialRuleId: pin.credentialRule ? pin.credentialRule.id : 0,
        pageSize: 20,
      };

      const response = await RestService.fetchCredentials(params);
      if (response.data && response.data.content) {
        const { number: currentPage, totalPages: total } = response.data;
        if (append) {
          dispatch(appendPinCredentials(response.data.content));
        } else {
          dispatch(savePinCredentials(response.data.content));
        }
        dispatch(setPinCredentialsPaginationData(currentPage, total));
        return response;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function createPin(values) {
  return async (dispatch) => {
    try {
      const formattedCard = {
        type: values.type,
        notes: values.notes,
        deviceId: _.trim(values.deviceId),
        credentialRule: formatter.formatOutputData(formatter.CREDENTIAL, values),
      };
      const response = await RestService.createStandardDevice(formattedCard);
      if (response && response.data) {
        const card = response.data;
        const formattedNewCard = {
          ...card,
          credentialRule: card.credentialRule && formatter.formatInputData(formatter.CREDENTIAL, card.credentialRule),
        };
        formattedNewCard.credentialRule.state = CREDENTIAL_RULE_STATUSES.NOT_UPDATED;
        dispatch(savePin(formattedNewCard));
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'credentialCreated' }} /></h6>),
          },
        }));
        dispatch(setOperationalMode(false));
        dispatch(setSelectedPin({}));
        return response;
      }
      throw new Error()
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function updatePin(values) {
  return async (dispatch, getState) => {
    try {
      const formattedCard = {
        type: values.type,
        deviceId: values.deviceId,
        credentialRule: formatter.formatOutputData(formatter.CREDENTIAL, values),
      };
      const response = await RestService.editStandardDevice(values.deviceBackendId, formattedCard);
      if (response && response.data) {
        const updatedCard = {
          ...response.data,
          credentialRule: formatter.formatInputData(formatter.CREDENTIAL, response.data.credentialRule),
        };
        updatedCard.credentialRule.state = CREDENTIAL_RULE_STATUSES.NOT_UPDATED;
        dispatch(updatePinInState(updatedCard));
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'credentialUpdated' }} /></h6>),
          },
        }));
        dispatch(setOperationalMode(false));
        dispatch(setSelectedPin({}));
        return response;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchPinsStatuses() {
  return async (dispatch, getState) => {
    const pins = getState().cards.pinsData.data;
    try {
      const updatedKeys = _.map(pins, async (pin) => {
        const pinCredentialRule = pin.credentialRule;
        if (pinCredentialRule && !pinCredentialRule.state) { // fetching state only if missing
          const credentialRuleUpdated = await RestService.fetchCredentialRuleDetails(pin.credentialRule.id);
          if (credentialRuleUpdated && credentialRuleUpdated.data) {
            dispatch(updateKeyCredentialStatus(pin.id, credentialRuleUpdated.data.state));
          }
        }
        return pin;
      });
      const pinsResponse = await Promise.all(updatedKeys);
      return pinsResponse;
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchPinStatus(pin) {
  return async (dispatch, getState) => {
    try {
      const credentialRuleUpdated = await RestService.fetchCredentialRuleDetails(pin.credentialRule.id);
      if (credentialRuleUpdated && credentialRuleUpdated.data) {
        dispatch(updatePinCredentialStatus(pin.id, credentialRuleUpdated.data.state));
        return pin;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}


export function fetchPinsAndStatuses(page = 0, append = false, pageSize = 20) {
  return async (dispatch, getState) => {
    try {
      await dispatch(fetchPins(page, append, pageSize));
      dispatch(fetchPinStatus());
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchPinFromDeviceId(deviceId) {
  return async (dispatch, getState) => {
    try {
      const params = {
        deviceId,
        types: [
          CARD_TYPES.ISEO_PIN,
        ],
      };
      const response = await RestService.fetchStandardDevices(params);
      if (response.data && response.data.content && !_.isEmpty(response.data.content)) {
        const pin = _.first(response.data.content);
        const formattedPin = {
          ...pin,
          credentialRule: pin.credentialRule && formatter.formatInputData(formatter.CREDENTIAL, pin.credentialRule),
        };
        return formattedPin;
      }
      return null;
    } catch (error) {
      return null;
    }
  };
}

export function updatePinNotes(pinId, notes) {
  return async (dispatch, getState) => {
    try {
      const formattedCard = {
        notes,
      };
      const response = await RestService.editStandardDevice(pinId, formattedCard);
      if (response && response.data) {
        dispatch(updatePinNotesInState(pinId, notes));
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'credentialUpdated' }} /></h6>),
          },
        }));
        dispatch(setOperationalMode(false));
        dispatch(setSelectedPin({}));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchF9000FromDeviceId(deviceId) {
  return async (dispatch, getState) => {
    try {
      const params = {
        deviceId,
        type: 'F9000',
      };
      const response = await RestService.fetchStandardDevices(params);
      if (response.data && response.data.content && !_.isEmpty(response.data.content)) {
        const card = _.first(response.data.content);
        const formattedCard = {
          ...card,
          credentialRule: card.credentialRule && formatter.formatInputData(formatter.CREDENTIAL, card.credentialRule),
        };
        return formattedCard;
      }
      return null;
    } catch (error) {
      return null;
    }
  };
}

export function fetchHyperKeys(page = 0, append = false, pageSize = 20) {
  return async (dispatch, getState) => {
    const filters = getState().cards.hyperKeysData.filters;
    try {
      const params = {
        page,
        pageSize,
        ...filters,
        type: 'F9000',
      };

      const response = await RestService.fetchStandardDevices(params);
      if (response.data && response.data.content) {
        const formattedKeys = _.map(response.data.content, key => ({
          ...key,
          credentialRule: key.credentialRule && formatter.formatInputData(formatter.CREDENTIAL, key.credentialRule),
        }));
        if (append) {
          dispatch(appendHyperKeys(formattedKeys));
        } else {
          dispatch(saveHyperKeys(formattedKeys));
        }
        dispatch(saveHyperKeysPagination(_.omit(response.data, 'content')));
        return formattedKeys;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchKeysCredentials(key, page, append) {
  return async (dispatch, getState) => {
    try {
      const params = {
        page,
        credentialRuleId: key.credentialRule ? key.credentialRule.id : 0,
        pageSize: 20,
      };

      const response = await RestService.fetchCredentials(params);
      if (response.data && response.data.content) {
        const { number: currentPage, totalPages: total } = response.data;
        if (append) {
          dispatch(appendKeyCredentials(response.data.content));
        } else {
          dispatch(saveKeyCredentials(response.data.content));
        }
        dispatch(setKeyCredentialsPaginationData(currentPage, total));
        return response;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchKeysStatuses() {
  return async (dispatch, getState) => {
    const keys = getState().cards.keysData.data;
    try {
      const updatedKeys = _.map(keys, async (key) => {
        const keyCredentialRule = key.credentialRule;
        if (keyCredentialRule && !keyCredentialRule.state) { // fetching state only if missing
          const credentialRuleUpdated = await RestService.fetchCredentialRuleDetails(key.credentialRule.id);
          if (credentialRuleUpdated && credentialRuleUpdated.data) {
            dispatch(updateKeyCredentialStatus(key.id, credentialRuleUpdated.data.state));
          }
        }
        return key;
      });
      const cardsResponse = await Promise.all(updatedKeys);
      return cardsResponse;
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchKeyStatus(key) {
  return async (dispatch, getState) => {
    try {
      const credentialRuleUpdated = await RestService.fetchCredentialRuleDetails(key.credentialRule.id);
      if (credentialRuleUpdated && credentialRuleUpdated.data) {
        dispatch(updateKeyCredentialStatus(key.id, credentialRuleUpdated.data.state));
        return key;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}


export function fetchKeysAndStatuses(page = 0, append = false, pageSize = 20) {
  return async (dispatch, getState) => {
    try {
      await dispatch(fetchHyperKeys(page, append, pageSize));
      dispatch(fetchKeysStatuses());
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function createKey(values) {
  return async (dispatch) => {
    try {
      const formattedCard = {
        type: values.type,
        notes: values.notes,
        deviceId: _.trim(values.deviceId),
        credentialRule: formatter.formatOutputData(formatter.CREDENTIAL, values),
      };
      const response = await RestService.createStandardDevice(formattedCard);
      if (response && response.data) {
        const card = response.data;
        const formattedNewCard = {
          ...card,
          credentialRule: card.credentialRule && formatter.formatInputData(formatter.CREDENTIAL, card.credentialRule),
        };
        formattedNewCard.credentialRule.state = CREDENTIAL_RULE_STATUSES.NOT_UPDATED;
        dispatch(saveHyperKey(formattedNewCard));
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'credentialCreated' }} /></h6>),
          },
        }));
        dispatch(setOperationalMode(false));
        dispatch(setSelectedHyperKey({}));
        return response;
      }
      throw new Error()
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function updateKey(values) {
  return async (dispatch, getState) => {
    try {
      const formattedCard = {
        type: values.type,
        deviceId: values.deviceId,
        credentialRule: formatter.formatOutputData(formatter.CREDENTIAL, values),
      };
      const response = await RestService.editStandardDevice(values.deviceBackendId, formattedCard);
      if (response && response.data) {
        const updatedCard = {
          ...response.data,
          credentialRule: formatter.formatInputData(formatter.CREDENTIAL, response.data.credentialRule),
        };
        updatedCard.credentialRule.state = CREDENTIAL_RULE_STATUSES.NOT_UPDATED;
        dispatch(updateKeyInState(updatedCard));
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'credentialUpdated' }} /></h6>),
          },
        }));
        dispatch(setOperationalMode(false));
        dispatch(setSelectedHyperKey({}));
        return response;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


export function updateStandardDeviceValidationMode(deviceData, deviceType = ACCESS_METHODS.F9000) {
  return async (dispatch, getState) => {
     let formattedPeriod = {
      validationMode: deviceData.validationMode,
      validationPeriod: null,
     };
     if (deviceData.validationMode === VALIDATION_MODES_SELECTION_OPTIONS.TIME_CONSTRAINED_VALIDATION) {
      formattedPeriod = {
        validationMode: deviceData.validationPeriodTimeUnit,
        validationPeriod: parseInt(deviceData.validationPeriod, 10),
      }
    }
    const response = await RestService.editStandardDeviceValidationPeriod(deviceData.id, formattedPeriod);
    if (response && response.data) {
      dispatch(setOperationalMode(false));
      const updatedCard = {
        ...response.data,
        credentialRule: formatter.formatInputData(formatter.CREDENTIAL, response.data.credentialRule),
      };
      if (deviceType === ACCESS_METHODS.F9000) {
        dispatch(setSelectedHyperKey({}));
        dispatch(updateKeyInState(updatedCard));
      } else {
        dispatch(selectCard({}));
        dispatch(updateCardInState(updatedCard));
      }
      return response;
    }
  }
}

export function fetchCardsFromDeviceId(deviceId) {
  return async (dispatch, getState) => {
    try {
      const params = {
        deviceId,
        types: [
          CARD_TYPES.GENERIC_CARD,
          CARD_TYPES.GENERIC_CARD_DESFIRE,
          CARD_TYPES.ISEO_CARD,
          CARD_TYPES.ISEO_CARD_DESFIRE,
        ],
      };
      const response = await RestService.fetchStandardDevices(params);
      if (response.data && response.data.content && !_.isEmpty(response.data.content)) {
        const card = _.first(response.data.content);
        const formattedCard = {
          ...card,
          credentialRule: card.credentialRule && formatter.formatInputData(formatter.CREDENTIAL, card.credentialRule),
        };
        return formattedCard;
      }
      return null;
    } catch (error) {
      return null;
    }
  };
}

export function fetchCardsStatuses() {
  return async (dispatch, getState) => {
    const cards = getState().cards.cardsData.data;
    try {
      const updatedCards = _.map(cards, async (card) => {
        const cardCredentialRule = card.credentialRule;
        if (cardCredentialRule && !cardCredentialRule.state) { // fetching state only if missing
          const credentialRuleUpdated = await RestService.fetchCredentialRuleDetails(card.credentialRule.id);
          if (credentialRuleUpdated && credentialRuleUpdated.data) {
            dispatch(updateCardCredentialStatus(card.id, credentialRuleUpdated.data.state));
          }
        }
        return card;
      });
      const cardsResponse = await Promise.all(updatedCards);
      return cardsResponse;
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchCardsStatus(card) {
  return async (dispatch, getState) => {
    try {
      const credentialRuleUpdated = await RestService.fetchCredentialRuleDetails(card.credentialRule.id);
      if (credentialRuleUpdated && credentialRuleUpdated.data) {
        dispatch(updateCardCredentialStatus(card.id, credentialRuleUpdated.data.state));
        return card;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}


export function fetchCardsAndStatuses(page = 0, append = false, pageSize = 20) {
  return async (dispatch, getState) => {
    try {
      await dispatch(fetchCards(page, append, pageSize));
      dispatch(fetchCardsStatuses());
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchCardCredentials(card, page = 0, append) {
  return async (dispatch, getState) => {
    try {
      const additionalCredentialRuleIds = _.map(card.additionalCredentialRules, additionalRule => additionalRule.id);
      let params = {
        page,
        credentialRuleId: card.credentialRule ? card.credentialRule.id : 0,
        pageSize: 50,
      };
      if (additionalCredentialRuleIds && !_.isEmpty(additionalCredentialRuleIds)) {
        params = {
          ..._.omit(params, 'credentialRuleId'),
          credentialRuleIds: [...additionalCredentialRuleIds, params.credentialRuleId ],
        }
      }
      const response = await RestService.fetchCredentials(params);
      if (response.data && response.data.content) {
        const { number: currentPage, totalPages: total } = response.data;
        if (append) {
          dispatch(appendCardCredentials(response.data.content));
        } else {
          dispatch(saveCardCredentials(response.data.content));
        }
        dispatch(setCardCredentialsPaginationData(currentPage, total));
        return response;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function createCard(values) {
  return async (dispatch) => {
    try {
      const additionalTimeRangeDefault = values && values.additionalTimeRange_default && !_.isEmpty(values.additionalTimeRange_default) ? formatter.formatOutputData(formatter.TIME_RANGE, _.first(values.additionalTimeRange_default)) : null;
      const defaultCredentialRule = {...values, additionalTimeRange: additionalTimeRangeDefault };
      let formattedCard = {
        type: values.type,
        notes: values.notes,
        deviceId: _.trim(values.deviceId),
        credentialRule: formatter.formatOutputData(formatter.CREDENTIAL, defaultCredentialRule),
      };
      // Manage additional time profiles
      if (values.additionalTimeProfiles && !_.isEmpty(values.additionalTimeProfiles)) {
        const timeProfilesFormatted = _.map(values.additionalTimeProfiles, timeProfile => formatter.formatOutputData(formatter.TIME_PROFILE, timeProfile));
        formattedCard = {
          ...formattedCard,
          additionalCredentialRules: timeProfilesFormatted,
        };
      }
      
      // HANDLE VALIDATION MODE
      if (values.validationMode === VALIDATION_MODES_SELECTION_OPTIONS.TIME_CONSTRAINED_VALIDATION) {
        formattedCard = {
          ...formattedCard,
          validationMode: values.validationPeriodTimeUnit,
          validationPeriod: values.validationPeriod,
        }
      } else {
        formattedCard = {
          ...formattedCard,
          validationMode: values.validationMode,
          validationPeriod: null,
        }
      }
      const response = await RestService.createStandardDevice(formattedCard);
      if (response && response.data) {
        const card = response.data;
        const formattedNewCard = {
          ...card,
          credentialRule: card.credentialRule && formatter.formatInputData(formatter.CREDENTIAL, card.credentialRule),
        };
        formattedNewCard.credentialRule.state = CREDENTIAL_RULE_STATUSES.NOT_UPDATED;
        if (formattedCard.type === 'F9000') {
          dispatch(saveHyperKey(formattedNewCard));
        } else {
          dispatch(saveCard(formattedNewCard));
        }
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'credentialCreated' }} /></h6>),
          },
        }));
        dispatch(setOperationalMode(false));
        dispatch(selectCard({}));
        return response;
      }
      throw new Error()
    } catch (error) {
      throw new Error(error);
    }
  };
}
// values.timeProfiles = time profiles edit in the form
// values.additionalCredentialRules = original card time profiles
export function manageCardAdditionalTimeProfiles(values) {
  return async (dispatch, getState) => {
    // Manage ADD/EDIT time profiles
    const timeProfilesFormatted = _.map(values.additionalTimeProfiles, timeProfile => {
      const additionalTimeRangeDefault = timeProfile && timeProfile.additionalTimeRange_additional && !_.isEmpty(timeProfile.additionalTimeRange_additional) ? formatter.formatOutputData(formatter.TIME_RANGE, _.first(timeProfile.additionalTimeRange_additional)) : null;
      return {
        ...formatter.formatOutputData(formatter.TIME_PROFILE, timeProfile),
        additionalTimeRange: additionalTimeRangeDefault,
      }
    });
    for (const timeProfile of timeProfilesFormatted) {
      // If card has not additional credentialRules -> create all new
      if (!values.additionalCredentialRules || _.isEmpty(values.additionalCredentialRules)) {
        await RestService.addAdditionaCredentialRuleToStandardDevice(values.deviceBackendId, timeProfile);
      // IF time profile exists -> edit the time profile
      } else if (_.find(values.additionalCredentialRules, additionalCredentialRule => additionalCredentialRule.id === timeProfile.id)) {
        await RestService.editAdditionaCredentialRuleToStandardDevice(values.deviceBackendId, timeProfile.id, timeProfile);
      // IF the time profile is new --> create the new time profile
      } else if (!_.find(values.additionalCredentialRules, additionalCredentialRule => additionalCredentialRule.id === timeProfile.id)) {
        await RestService.addAdditionaCredentialRuleToStandardDevice(values.deviceBackendId, timeProfile);
      }
    }
    // MANAGE TIME PROFILE TO REMOVE
    const timeProfilesToRemove = _.differenceBy(values.additionalCredentialRules, values.additionalTimeProfiles, 'id');
    _.each(timeProfilesToRemove, async(timeProfileToRemove) => {
      await RestService.removeAdditionaCredentialRuleToStandardDevice(values.deviceBackendId, timeProfileToRemove.id);
    })
    return _.difference(timeProfilesFormatted, timeProfilesToRemove);
  };
}

export function updateCard(values) {
  return async (dispatch, getState) => {
    try {
      const additionalTimeRangeDefault = values && values.additionalTimeRange_default && !_.isEmpty(values.additionalTimeRange_default) ? formatter.formatOutputData(formatter.TIME_RANGE, _.first(values.additionalTimeRange_default)) : null;
      const defaultCredentialRule = { ...values, additionalTimeRange: additionalTimeRangeDefault };
      let formattedCard = {
        type: values.type,
        deviceId: values.deviceId,
        credentialRule: formatter.formatOutputData(formatter.CREDENTIAL, defaultCredentialRule),
      };
      const response = await RestService.editStandardDevice(values.deviceBackendId, formattedCard);
      if (response && response.data) {
        let updatedCard = {
          ...response.data,
          credentialRule: formatter.formatInputData(formatter.CREDENTIAL, response.data.credentialRule),
        };
        const updatedTimeProfiles = await dispatch(manageCardAdditionalTimeProfiles(values));

        // FIX HERE: We nee to return the right time profiles from the call manageCardAdditionalTimeProfiles
        updatedCard.credentialRule.state = CREDENTIAL_RULE_STATUSES.NOT_UPDATED;
        if (updatedTimeProfiles && !_.isEmpty(updatedTimeProfiles)) {
          updatedCard = {
            ...updatedCard,
            additionalCredentialRules: updatedTimeProfiles,
          }
        }
        dispatch(updateCardInState(updatedCard));
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'credentialUpdated' }} /></h6>),
          },
        }));
        dispatch(setOperationalMode(false));
        dispatch(selectCard({}));
        return response;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function updateCardNotes(cardId, notes) {
  return async (dispatch, getState) => {
    try {
      const formattedCard = {
        notes,
      };
      const response = await RestService.editStandardDevice(cardId, formattedCard);
      if (response && response.data) {
        dispatch(updateCardNotesInState(cardId, notes));
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'credentialUpdated' }} /></h6>),
          },
        }));
        dispatch(setOperationalMode(false));
        dispatch(selectCard({}));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


export function getStandardDevicesValidationPeriodDefaults() {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.getStandardDeviceValidationModeDefaults();
      if (response && response.data) {
        dispatch(saveStandardDevicesValidationDefaults(response.data));

        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


export function updateStandardDevicesValidationPeriodDefaults(values) {
  return async (dispatch, getState) => {
    try {
      let formattedPeriod = {
        validationMode: values.validationMode,
        validationPeriod: null,
       };
       if (values.validationMode === VALIDATION_MODES_SELECTION_OPTIONS.TIME_CONSTRAINED_VALIDATION) {
        formattedPeriod = {
          validationMode: values.validationPeriodTimeUnit,
          validationPeriod: values.validationPeriod,
        }
      }
      const response = await RestService.updateStandardDevicesValidationModeDefaults(formattedPeriod);
      if (response && response.data) {
        dispatch(saveStandardDevicesValidationDefaults(response.data));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


export function updateKeyNotes(keyId, notes) {
  return async (dispatch, getState) => {
    try {
      const formattedCard = {
        notes,
      };
      const response = await RestService.editStandardDevice(keyId, formattedCard);
      if (response && response.data) {
        dispatch(updateKeyNotesInState(keyId, notes));
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'credentialUpdated' }} /></h6>),
          },
        }));
        dispatch(setOperationalMode(false));
        dispatch(setSelectedHyperKey({}));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function deleteCard(cardId) {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.deleteStandardDevice(cardId);
      if (response && response.data) {
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'cardDeleted' }} /></h6>),
          },
        }));
        dispatch(setOperationalMode(false));
        dispatch(selectCard({}));
        dispatch(removeCardFromState(cardId));
        return true;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function deleteKey(keyId) {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.deleteStandardDevice(keyId);
      if (response && response.data) {
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'cardDeleted' }} /></h6>),
          },
        }));
        dispatch(removeKeyFromState(keyId));
        dispatch(setOperationalMode(false));
        dispatch(setSelectedHyperKey({}));
        return true;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


export function deleteCardAndElectMaster(cardId, plantMaster) {
  return async (dispatch, getState) => {
    try {
      const response = await RestService.deleteStandardDeviceAndElectMaster(cardId, plantMaster);
      if (response && response.data) {
        dispatch(removeCardFromState(cardId));
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'cardDeleted' }} /></h6>),
          },
        }));
        dispatch(setOperationalMode(false));
        dispatch(selectCard({}));
        return true;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function validateDeviceId(cardId) {
  return async (dispatch, getState) => {
    try {
      const { hostname } = getState().user.token;
      const validationResponse = await RestService.validateStandardDevice(cardId);
      if (validationResponse && validationResponse.data && validationResponse.data.dateInterval) {
        const validationDates = validationResponse.data;
        if (validationDates.createdBy) {
          const plantMaster = validationDates.createdBy;
          dispatch(change('CardCredentialForm', 'plantMaster', plantMaster));
        }
        if (validationDates.createdBy !== hostname) {
          const timeIntervalFrom = moment().startOf('day').add(validationDates.timeInterval.from, 'seconds').valueOf();
          const timeIntervalTo = moment().startOf('day').add(validationDates.timeInterval.to, 'seconds').valueOf();
          dispatch(change('CardCredentialForm', 'timeIntervalFrom', timeIntervalFrom));
          dispatch(change('CardCredentialForm', 'timeIntervalTo', timeIntervalTo));
          dispatch(change('CardCredentialForm', 'daysOfTheWeek', validationDates.daysOfTheWeek));
          dispatch(change('CardCredentialForm', 'credentialTimeframe', {
            startDate: validationDates.dateInterval.from,
            endDate: validationDates.dateInterval.to,
          }));
          dispatch(change('CardCredentialForm', 'areDeviceDatesLocked', true));
        }
        return validationResponse.data;
      }
      dispatch(change('CardCredentialForm', 'areDeviceDatesLocked', false));
      return null;
    } catch (error) {
      dispatch(change('CardCredentialForm', 'areDeviceDatesLocked', false));
      return null;
    }
  };
}

export function fetchAllCardsForExport(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    const taskId = moment().valueOf();
    const filters = getState().cards.cardsData.filters;
    let stopFetching = false;
    dispatch(UtilsActions.addBackgroundTaskSingleton({
      id: taskId,
      type: BACKGROUND_TASK_TYPES.DOWNLOAD_LOCKS_CSV,
      title: 'creatingCardsExportFile',
      cancelCallback: () => {
        stopFetching = true;
      },
    }));
    try {
      let params = {
        page,
        pageSize,
        types: [
          CARD_TYPES.GENERIC_CARD,
          CARD_TYPES.GENERIC_CARD_DESFIRE,
          CARD_TYPES.ISEO_CARD,
          CARD_TYPES.ISEO_CARD_DESFIRE,
        ],
        ...filters,
      };
      let cardsResponse = await StandardDevicesAPI.fetchCardsForExport(params);
      if (cardsResponse && cardsResponse.data && cardsResponse.data.content && !_.isEmpty(cardsResponse.data.content)) {
        let formattedCards = _.map(cardsResponse.data.content, card => ({
          ...card,
          credentialRule: card.credentialRule && formatter.formatInputData(formatter.CREDENTIAL, card.credentialRule),
        }));
        const cards = [...formattedCards];
        let pagination = _.omit(cardsResponse.data, 'content');
        while (pagination.number + 1 !== pagination.totalPages && !stopFetching) {
          params = {
            ...params,
            page: pagination.number + 1,
          };
          cardsResponse = await StandardDevicesAPI.fetchCardsForExport(params);
          formattedCards = _.map(cardsResponse.data.content, card => ({
            ...card,
            credentialRule: card.credentialRule && formatter.formatInputData(formatter.CREDENTIAL, card.credentialRule),
          }));
          cards.push(...formattedCards);
          pagination = _.omit(cardsResponse.data, 'data');
        }
        dispatch(UtilsActions.removeBackgroundTask(taskId));
        return { cards, stopFetching };
      }
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw new Error();
    } catch (error) {
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw error;
    }
  };
}

export function exportCards(format = EXPORT_FORMATS.CSV) {
  return async (dispatch, getState) => {
    try {
      const { cards, stopFetching } = await dispatch(fetchAllCardsForExport());
      if (!stopFetching) {
        const exportData = [];
        _.each(cards, (card) => {
          let additionalTimeProfileExportData;
          let dataToExport = {
            ID: card.id,
            'Card Number': card.deviceId,
            Type: L20NContext.getSync('standardDevicesTypes', { type: card.type }),
            User: `${card.user.firstname} ${card.user.lastname} -  ${card.user.email}`,
            FirstName: card.user.firstname,
            LastName: card.user.lastname,
            Email: card.user.email,
            'Smart Locks Tags': _.map(card.credentialRule.lockTags, tag => tag.name).join(', '),
            'Valid From Date (ISO)': moment(card.credentialRule.dateInterval.from).toISOString(),
            'Valid From Date (millis since Epoch)': moment(card.credentialRule.dateInterval.from).valueOf(),
            'Valid From Time': moment(card.credentialRule.timeIntervalFrom).format('LT'),
            'Valid To Date (ISO)': moment(card.credentialRule.dateInterval.to).toISOString(),
            'Valid To Date (millis since Epoch)': moment(card.credentialRule.dateInterval.to).valueOf(),
            'Valid To Time': moment(card.credentialRule.timeIntervalTo).format('LT'),
            Days: _.map(card.credentialRule.daysOfTheWeek, weekday => moment.weekdaysMin(moment().isoWeekday(weekday).isoWeekday())).join(', '),
            Notes: card.notes,
            ...elaborateTimeRangeForExport(card.credentialRule.additionalTimeRange),
          }
          // TODO: CHECK IF ADDON ENABLED
          if (card.additionalCredentialRules) {
            _.each(card.additionalCredentialRules, (credentialRule, index) => (
              additionalTimeProfileExportData = {
                ...additionalTimeProfileExportData,
                ...elaborateTimeProfileForExport(credentialRule, index)
              }
            ));
            dataToExport = {
              ...dataToExport,
              ...additionalTimeProfileExportData,
            }
          }
          if (card.credentialRule) {
            exportData.push(dataToExport);
          }
        });
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = format.toLowerCase();
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = { Sheets: { cards: ws }, SheetNames: ['cards'] };
        const excelBuffer = XLSX.write(wb, { bookType: fileExtension, type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `Cards_Export.${fileExtension}`);
        return cards;
      } else {
        throw new Error();
      }
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}

export function setCardsViewMode(viewMode) {
  saveDataToLocalStorage('cardsViewMode', viewMode);
}

export function fetchAllKeysForExport(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    const taskId = moment().valueOf();
    const filters = getState().cards.hyperKeysData.filters;
    let stopFetching = false;
    dispatch(UtilsActions.addBackgroundTaskSingleton({
      id: taskId,
      type: BACKGROUND_TASK_TYPES.DOWNLOAD_LOCKS_CSV,
      title: 'creatingCardsExportFile',
      cancelCallback: () => {
        stopFetching = true;
      },
    }));
    try {
      let params = {
        page,
        pageSize,
        ...filters,
      };
      let cardsResponse = await StandardDevicesAPI.fetchCardsForExport(params);
      if (cardsResponse && cardsResponse.data && cardsResponse.data.content && !_.isEmpty(cardsResponse.data.content)) {
        let formattedCards = _.map(cardsResponse.data.content, card => ({
          ...card,
          credentialRule: card.credentialRule && formatter.formatInputData(formatter.CREDENTIAL, card.credentialRule),
        }));
        const keys = [...formattedCards];
        let pagination = _.omit(cardsResponse.data, 'content');
        while (pagination.number + 1 !== pagination.totalPages && !stopFetching) {
          params = {
            ...params,
            page: pagination.number + 1,
          };
          cardsResponse = await StandardDevicesAPI.fetchCardsForExport(params);
          formattedCards = _.map(cardsResponse.data.content, card => ({
            ...card,
            credentialRule: card.credentialRule && formatter.formatInputData(formatter.CREDENTIAL, card.credentialRule),
          }));
          keys.push(...formattedCards);
          pagination = _.omit(cardsResponse.data, 'data');
        }
        dispatch(UtilsActions.removeBackgroundTask(taskId));
        return { keys, stopFetching };
      }
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw new Error();
    } catch (error) {
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw error;
    }
  };
}

export function fetchAllPinsForExport(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    const taskId = moment().valueOf();
    const filters = getState().cards.pinsData.filters;
    let stopFetching = false;
    dispatch(UtilsActions.addBackgroundTaskSingleton({
      id: taskId,
      type: BACKGROUND_TASK_TYPES.DOWNLOAD_LOCKS_CSV,
      title: 'creatingPinsExportFile',
      cancelCallback: () => {
        stopFetching = true;
      },
    }));
    try {
      let params = {
        page,
        pageSize,
        types: [
          CARD_TYPES.ISEO_PIN,
        ],
        ...filters,
      };
      let pinsResponse = await StandardDevicesAPI.fetchCardsForExport(params);
      if (pinsResponse && pinsResponse.data && pinsResponse.data.content && !_.isEmpty(pinsResponse.data.content)) {
        let formattedPins = _.map(pinsResponse.data.content, card => ({
          ...card,
          credentialRule: card.credentialRule && formatter.formatInputData(formatter.CREDENTIAL, card.credentialRule),
        }));
        const pins = [...formattedPins];
        let pagination = _.omit(pinsResponse.data, 'content');
        while (pagination.number + 1 !== pagination.totalPages && !stopFetching) {
          params = {
            ...params,
            page: pagination.number + 1,
          };
          pinsResponse = await StandardDevicesAPI.fetchCardsForExport(params);
          formattedPins = _.map(pinsResponse.data.content, card => ({
            ...card,
            credentialRule: card.credentialRule && formatter.formatInputData(formatter.CREDENTIAL, card.credentialRule),
          }));
          pins.push(...formattedPins);
          pagination = _.omit(pinsResponse.data, 'data');
        }
        dispatch(UtilsActions.removeBackgroundTask(taskId));
        return { pins, stopFetching };
      }
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw new Error();
    } catch (error) {
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw error;
    }
  };
}

export function exportHyperKeys(format = EXPORT_FORMATS.CSV) {
  return async (dispatch, getState) => {
    try {
      dispatch(setHyperKeyFilter('type', 'F9000'));
      const { keys, stopFetching } = await dispatch(fetchAllKeysForExport());
      if (!stopFetching) {
        const exportData = [];
        _.each(keys, (key) => {
          if (key.credentialRule) {
            exportData.push({
              ID: key.id,
              'Key ID': key.deviceId,
              User: `${key.user.firstname} ${key.user.lastname} -  ${key.user.email}`,
              FirstName: key.user.firstname,
              LastName: key.user.lastname,
              Email: key.user.email,
              'Smart Locks Tags': _.map(key.credentialRule.lockTags, tag => tag.name).join(', '),
              'Valid From Date': moment(key.credentialRule.dateInterval.from).format('DD MMMM YYYY'),
              'Valid From Time': moment(key.credentialRule.timeIntervalFrom).format('LT'),
              'Valid To Date': moment(key.credentialRule.dateInterval.to).format('DD MMMM YYYY'),
              'Valid To Time': moment(key.credentialRule.timeIntervalTo).format('LT'),
              Days: _.map(key.credentialRule.daysOfTheWeek, weekday => moment.weekdaysMin(moment().isoWeekday(weekday).isoWeekday())).join(', '),
              Notes: key.notes,
            });
          }
        });
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = format.toLowerCase();
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = { Sheets: { cards: ws }, SheetNames: ['cards'] };
        const excelBuffer = XLSX.write(wb, { bookType: fileExtension, type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `Keys_Export.${fileExtension}`);
        dispatch(resetHyperKeyFilters());
        return keys;
      } else {
        throw new Error();
      }
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}


export function exportPins(format = EXPORT_FORMATS.CSV) {
  return async (dispatch, getState) => {
    try {
      const { pins, stopFetching } = await dispatch(fetchAllPinsForExport());
      if (!stopFetching) {
        const exportData = [];
        _.each(pins, (pin) => {
          if (pin.credentialRule) {
            exportData.push({
              ID: pin.id,
              'Code': pin.deviceId,
              User: `${pin.user.firstname} ${pin.user.lastname} -  ${pin.user.email}`,
              FirstName: pin.user.firstname,
              LastName: pin.user.lastname,
              Email: pin.user.email,
              'Smart Locks Tags': _.map(pin.credentialRule.lockTags, tag => tag.name).join(', '),
              'Valid From Date': moment(pin.credentialRule.dateInterval.from).format('DD MMMM YYYY'),
              'Valid From Time': moment(pin.credentialRule.timeIntervalFrom).format('LT'),
              'Valid To Date': moment(pin.credentialRule.dateInterval.to).format('DD MMMM YYYY'),
              'Valid To Time': moment(pin.credentialRule.timeIntervalTo).format('LT'),
              Days: _.map(pin.credentialRule.daysOfTheWeek, weekday => moment.weekdaysMin(moment().isoWeekday(weekday).isoWeekday())).join(', '),
              Notes: pin.notes,
            });
          }
        });
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = format.toLowerCase();
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = { Sheets: { cards: ws }, SheetNames: ['cards'] };
        const excelBuffer = XLSX.write(wb, { bookType: fileExtension, type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `PINS_Export.${fileExtension}`);
        dispatch(resetHyperKeyFilters());
        return pins;
      } else {
        throw new Error();
      }
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}

export function createCardWithAccessProfile(cardData, userId) {
  return async (dispatch, getState) => {
    try {
      const formattedData = formatter.formatOutputData(formatter.CARD_WITH_PROFILE,  { ...cardData, userId });
      const createCardResponse = await RestService.createStandardDeviceWithAccessProfile(formattedData);
      if (createCardResponse && createCardResponse.data) {
        const card = createCardResponse.data;
        const formattedNewCard = {
          ...card,
          credentialRule: card.credentialRule && formatter.formatInputData(formatter.CREDENTIAL, card.credentialRule),
        };
        formattedNewCard.credentialRule.state = CREDENTIAL_RULE_STATUSES.NOT_UPDATED;
        dispatch(saveCard(formattedNewCard));
        dispatch(ModalActions.showModal({
          modalType: 'SUCCESS_ALERT',
          modalProps: {
            message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'credentialCreated' }} /></h6>),
          },
        }));
        dispatch(setOperationalMode(false));
        dispatch(selectCard({}));
        return formattedNewCard;
      }
    } catch (error) {
      throw error;
    }
  }
}