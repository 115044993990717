import _ from 'lodash';
import * as formatter from '../../_config/formatter';
import * as GatewayAPI from '../../_config/gatewayAPI';
import {
  APPEND_GATEWAYS,
  APPEND_WIRED_GATEWAYS,
  REMOVE_GATEWAY,
  RESET_GATEWAYS_DATA,
  RESET_GATEWAYS_FILTERS,
  RESET_GATEWAYS_PAGINATION_DATA,
  RESET_WIRED_GATEWAYS_DATA,
  RESET_WIRED_GATEWAYS_FILTERS,
  SAVE_GATEWAYS,
  SAVE_GATEWAYS_PAGINATION,
  SAVE_WIRED_GATEWAYS,
  SAVE_WIRED_GATEWAYS_PAGINATION,
  SET_GATEWAYS_FILTER,
  SET_GATEWAYS_OPERATIONAL_MODE,
  SET_SELECTED_GATEWAY,
  SET_SELECTED_GATEWAY_NOTIFICATION,
  SET_SELECTED_WIRED_GATEWAY,
  SET_WIRED_GATEWAYS_FILTER,
  UPDATE_GATEWAY,
  UPDATE_WIRED_GATEWAY,
  SET_GATEWAYS_ORDER,
} from './actionTypes/gateways';
import * as UserActions from './user.actions';

export function saveGateways(gateways) {
  return {
    type: SAVE_GATEWAYS,
    gateways,
  };
}

export function appendGateways(gateways) {
  return {
    type: APPEND_GATEWAYS,
    gateways,
  };
}

export function saveGatewaysPagination(pagination) {
  return {
    type: SAVE_GATEWAYS_PAGINATION,
    pagination,
  };
}

export function setGatewaysFilter(field, value) {
  return {
    type: SET_GATEWAYS_FILTER,
    field,
    value,
  };
}

export function setOrder(orderBy, orderDir) {
  return {
    type: SET_GATEWAYS_ORDER,
    orderBy,
    orderDir,
  };
}

export function setSelectedGateway(gateway) {
  return {
    type: SET_SELECTED_GATEWAY,
    gateway,
  };
}

export function updateGatewayInState(gateway) {
  return {
    type: UPDATE_GATEWAY,
    gateway,
  };
}

export function removeGatewayFromState(gatewayId) {
  return {
    type: REMOVE_GATEWAY,
    gatewayId,
  };
}

export function resetGatewaysData() {
  return {
    type: RESET_GATEWAYS_DATA,
  };
}

export function resetGatewaysFilters() {
  return {
    type: RESET_GATEWAYS_FILTERS,
  };
}

export function setOperationalMode(value) {
  return {
    type: SET_GATEWAYS_OPERATIONAL_MODE,
    value,
  };
}

export function saveWiredGateways(gateways) {
  return {
    type: SAVE_WIRED_GATEWAYS,
    gateways,
  };
}

export function resetGatewaysPaginationData() {
  return {
    type: RESET_GATEWAYS_PAGINATION_DATA,
  };
}

export function appendWiredGateways(gateways) {
  return {
    type: APPEND_WIRED_GATEWAYS,
    gateways,
  };
}

export function saveWiredGatewaysPagination(pagination) {
  return {
    type: SAVE_WIRED_GATEWAYS_PAGINATION,
    pagination,
  };
}

export function setSelectedWiredGateway(gateway) {
  return {
    type: SET_SELECTED_WIRED_GATEWAY,
    gateway,
  };
}

export function resetWiredGatewaysData() {
  return {
    type: RESET_WIRED_GATEWAYS_DATA,
  };
}

export function setWiredGatewaysFilter(field, value) {
  return {
    type: SET_WIRED_GATEWAYS_FILTER,
    field,
    value,
  };
}

export function resetWiredGatewayFilters() {
  return {
    type: RESET_WIRED_GATEWAYS_FILTERS,
  };
}

export function updateWiredGatewayInState(gateway) {
  return {
    type: UPDATE_WIRED_GATEWAY,
    gateway,
  };
}

export function fetchGateways(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const { gateways: { data: { filters } } } = getState();
      const params = {
        ...filters,
        page,
        pageSize,
      };
      const response = await GatewayAPI.fetchGateways(params);
      if (response && response.data && response.data.content) {
        dispatch(saveGateways(response.data.content));
        dispatch(saveGatewaysPagination(_.omit(response.data, 'content')));
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchGatewaysWithDetails(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const { gateways: { data: { filters } } } = getState();
      const orderBy = getState().gateways.data.sorting.orderBy;
      const orderDir = orderBy?getState().gateways.data.sorting.orderDir:undefined;
      const params = {
        ...filters,
        page,
        pageSize,
        orderBy,
        orderDir,
      };
      const response = await GatewayAPI.fetchGateways(params);
      if (response && response.data && response.data.content) {
        const formattedGateways = [];
        for (let gateway of response.data.content) {
          try {
            const smartLocks = await dispatch(getGatewaysSmartLocks(gateway.id))
            formattedGateways.push({
              ...gateway,
              smartLocks,
            });
          } catch (error) {
            formattedGateways.push({
              ...gateway,
              smartLocks: [],
            });
          }
        }
        dispatch(saveGateways(formattedGateways));
        dispatch(saveGatewaysPagination(_.omit(response.data, 'content')));
        return formattedGateways;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAndAppendGatewaysWithDetails(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const { gateways: { data: { filters } } } = getState();
      const params = {
        ...filters,
        page,
        pageSize,
      };
      const response = await GatewayAPI.fetchGateways(params);
      if (response && response.data && response.data.content) {
        const formattedGateways = [];
        for (let gateway of response.data.content) {
          try {
            const smartLocks = await dispatch(getGatewaysSmartLocks(gateway.id))
            formattedGateways.push({
              ...gateway,
              smartLocks,
            });
          } catch (error) {
            formattedGateways.push({
              ...gateway,
              smartLocks: [],
            });
          }
        }
        dispatch(appendGateways(formattedGateways));
        dispatch(saveGatewaysPagination(_.omit(response.data, 'content')));
        return formattedGateways;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAndAppendGateways(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const { gateways: { data: { filters } } } = getState();
      const params = {
        ...filters,
        page,
        pageSize,
      };
      const response = await GatewayAPI.fetchGateways(params);
      if (response && response.data && response.data.content) {
        dispatch(appendGateways(response.data.content));
        dispatch(saveGatewaysPagination(_.omit(response.data, 'content')));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function createGateway(gatewayDTO) {
  return async (dispatch, getState) => {
    try {
      const response = await GatewayAPI.createGateway(gatewayDTO);
      if (response.data) {
        dispatch(updateGatewayInState(response.data));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function deleteGateway(gatewayId) {
  return async (dispatch, getState) => {
    try {
      const response = await GatewayAPI.deleteGateway(gatewayId);
      if (response.data) {
        dispatch(removeGatewayFromState(gatewayId));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function addLockToGateway(gatewayId, lockId) {
  return async (dispatch, getState) => {
    try {
      const response = await GatewayAPI.addLockToGateway(gatewayId, lockId);
      if (response.data) {
        dispatch(updateGatewayInState(response.data));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function removeLockFromGateway(gatewayId, lockId) {
  return async (dispatch, getState) => {
    try {
      const response = await GatewayAPI.removeLockToGateway(gatewayId, lockId);
      if (response.data) {
        dispatch(updateGatewayInState(response.data));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function getGatewaysSmartLocks(gatewayId) {
  return async (dispatch, getState) => {
    try {
      const filters = {
        page: 0,
        pageSize: 100, // FIXME
        gatewayId,
      };
      const response = await GatewayAPI.fetchGatewaySmartlocks(filters);
      if (response.data && response.data.content) {
        const formattedLocks = _.map(response.data.content, lock => formatter.formatInputData(formatter.LOCK, lock));
        return formattedLocks;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function getGatewayDetails(gatewayId) {
  return async (dispatch, getState) => {
    try {
      const response = await GatewayAPI.fetchGatewayDetails(gatewayId);
      if (response.data) {
        let gatewayDetailed = response.data;
        const smartLocks = await dispatch(getGatewaysSmartLocks(gatewayId));
        gatewayDetailed = { ...gatewayDetailed, smartLocks };
        dispatch(updateGatewayInState(gatewayDetailed));
        return gatewayDetailed;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function editGatewayDetails(gatewayId, editDTO) {
  return async (dispatch, getState) => {
    try {
      const response = await GatewayAPI.editGatewayDetails(gatewayId, editDTO);
      if (response.data) {
        const gatewayDetailed = await dispatch(getGatewayDetails(gatewayId));
        return gatewayDetailed;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function sendGatewayNotification(gatewayId, payload) {
  return async (dispatch, getState) => {
    try {
      const response = await GatewayAPI.sendGatewayNotification(gatewayId, payload);
      if (response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function openSmartLockHyperGateHighFlex(gatewayId, payload) {
  return async (dispatch, getState) => {
    try {
      const response = await GatewayAPI.openHighFlexGatewaySmartLock(gatewayId, payload);
      if (response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function togglePassageModeSmartLockHyperGateHighFlex(gatewayId, payload) {
  return async (dispatch, getState) => {
    try {
      const response = await GatewayAPI.togglePassageModeSmartLockHyperGateHighFlex(gatewayId, payload);
      if (response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function getGatewayNotificationDetails(gatewayId, notificationId) {
  return async (dispatch, getState) => {
    try {
      const response = await GatewayAPI.fetchGatewayNotification(gatewayId, notificationId);
      if (response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function setSelectedGatewayNotification(notification) {
  return {
    type: SET_SELECTED_GATEWAY_NOTIFICATION,
    notification,
  };
}

export function fetchAtlasGateways(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const { gateways: { wired: { filters } } } = getState();
      const params = {
        ...filters,
        page,
        pageSize,
      };
      const response = await GatewayAPI.fetchAtlasGateways(params);
      if (response && response.data && response.data) {
        const atlasGatewaysData = _.filter(response.data, atlas => atlas.initialized);
        dispatch(saveWiredGateways(atlasGatewaysData));
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function systemHasAtlas() {
  return async (dispatch, getState) => {
    try {
      const params = {
        page: 0,
        pageSize: 1,
      };
      const response = await GatewayAPI.fetchAtlasGateways(params);
      if (response && response.data) {
        dispatch(UserActions.systemHasAtlasGateways(!_.isEmpty(response.data)));
        return response.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}
export function fetchAndAppendAtlasGateways(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const { gateways: { wired: { filters } } } = getState();
      const params = {
        ...filters,
        page,
        pageSize,
      };
      const response = await GatewayAPI.fetchAtlasGateways(params);
      if (response && response.data) {
        const atlasGatewaysData = _.filter(response.data, atlas => atlas.initialized);
        dispatch(appendWiredGateways(atlasGatewaysData));
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function getAtlasGatewayDetails(gatewayId) {
  return async (dispatch, getState) => {
    try {
      const response = await GatewayAPI.fetchAtlasGatewayDetails(gatewayId);
      if (response.data) {
        const gatewayDetailed = response.data;
        dispatch(updateWiredGatewayInState(gatewayDetailed));
        return gatewayDetailed;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}
