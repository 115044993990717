/* eslint-disable no-await-in-loop */
import moment from 'moment';
import _ from 'lodash';
import React from 'react';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import * as RestService from '../../_config/rest';
import {
  SAVE_COMMUNICATIONS,
  APPEND_COMMUNICATIONS,
  SET_COMMUNICATIONS_PAGINATION_DATA,
  SELECT_COMMUNICATION,
  SET_COMMUNICATIONS_FILTER,
  UPDATE_COMMUNICATION,
  RESET_COMMUNICATIONS_FILTERS,
  RESET_COMMUNICATIONS_DATA,
  RESET_COMMUNICATION_PAGINATION_DATA,
  SET_COMMUNICATIONS_OPERATIONAL_MODE,
  SAVE_COMMUNICATION_USERS,
  APPEND_COMMUNICATION_USERS,
  SAVE_COMMUNICATION_USERS_PAGINATION_DATA,
  SAVE_COMMUNICATION_USERS_TOTAL,
  RESET_COMMUNICATION_USERS_FILTERS,
  SET_COMMUNICATION_USERS_FILTER,
} from './actionTypes/communications';
import * as UtilsActions from '../actions/utils.actions';
import * as formatter from '../../_config/formatter';
import * as CommunicationsAPI from '../../_config/communicationsAPI';
import * as ModalActions from '../actions/modal.actions';
import { BACKGROUND_TASK_TYPES, EXPORT_FORMATS, ITEM_DELIVERY_TYPES_NAMES, ITEM_TYPES_NAMES } from '../../_config/consts';
import { saveDataToLocalStorage } from '../../_config/utils';

export function setCommunicationsOperationalMode(value) {
  return {
    type: SET_COMMUNICATIONS_OPERATIONAL_MODE,
    value,
  };
}

export function saveCommunications(communications) {
  return {
    type: SAVE_COMMUNICATIONS,
    communications,
  };
}

export function appendCommunications(communications) {
  return {
    type: APPEND_COMMUNICATIONS,
    communications,
  };
}

export function saveCommunicationsPaginationData(pagination) {
  return {
    type: SET_COMMUNICATIONS_PAGINATION_DATA,
    pagination,
  };
}

export function setCommunicationsFilter(field, value) {
  return {
    type: SET_COMMUNICATIONS_FILTER,
    field,
    value,
  };
}

export function setSelectedCommunication(communication) {
  return {
    type: SELECT_COMMUNICATION,
    communication,
  };
}

export function updateCommunicationOnState(communication) {
  return {
    type: UPDATE_COMMUNICATION,
    communication,
  };
}

export function resetCommunicationsData() {
  return {
    type: RESET_COMMUNICATIONS_DATA,
  };
}

export function resetCommunicationsFilters() {
  return {
    type: RESET_COMMUNICATIONS_FILTERS,
  };
}

export function resetCommunicationsPagination() {
  return {
    type: RESET_COMMUNICATION_PAGINATION_DATA,
  };
}

export function saveCommunicationUsers(users) {
  return {
    type: SAVE_COMMUNICATION_USERS,
    users,
  };
}

export function appendCommunicationUsers(users) {
  return {
    type: APPEND_COMMUNICATION_USERS,
    users,
  };
}

export function saveCommunicationUsersPaginationData(pagination) {
  return {
    type: SAVE_COMMUNICATION_USERS_PAGINATION_DATA,
    pagination,
  };
}

export function saveCommunicationUsersTotal(total) {
  return {
    type: SAVE_COMMUNICATION_USERS_TOTAL,
    total,
  };
}

export function resetCommunicationUsersFilters() {
  return {
    type: RESET_COMMUNICATION_USERS_FILTERS,
  };
}

export function setCommunicationUsersFilter(field, value) {
  return {
    type: SET_COMMUNICATION_USERS_FILTER,
    field,
    value,
  };
}

export function fetchCommunications(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().communications.data.filters;
      const orderBy = "id";
      const orderDir = "DESC";
      const params = {
        page,
        pageSize,
        ...filters,
        orderBy,
        orderDir,
      };
      const communicationResponse = await CommunicationsAPI.getCommunications(params);
      if (communicationResponse && communicationResponse.data && communicationResponse.data.content) {
        const formattedCommunications = _.map(communicationResponse.data.content, communication => formatter.formatInputData(formatter.COMMUNICATION, communication));
        dispatch(saveCommunications(formattedCommunications));
        dispatch(saveCommunicationsPaginationData(_.omit(communicationResponse.data, 'content')));
        return communicationResponse.data.content;
      }
      throw new Error();

    } catch (error) {
      throw error;
    }
  }
}

export function fetchAndAppendCommunications(page = 0, pageSize = 50) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().communications.data.filters;
      const orderBy = "id";
      const orderDir = "desc";
      const params = {
        page,
        pageSize,
        ...filters,
        orderBy,
        orderDir,
      };
      const communicationResponse = await CommunicationsAPI.getCommunications(params);
      if (communicationResponse && communicationResponse.data && communicationResponse.data.content) {
        const formattedCommunications = _.map(communicationResponse.data.content, communication => formatter.formatInputData(formatter.COMMUNICATION, communication));
        dispatch(appendCommunications(formattedCommunications));
        dispatch(saveCommunicationsPaginationData(_.omit(communicationResponse.data, 'content')));
        return communicationResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchAllCommunicationsForExport(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    const taskId = moment().valueOf();
    const filters = getState().communications.data.filters;
    let stopFetching = false;
    dispatch(UtilsActions.addBackgroundTaskSingleton({
      id: taskId,
      type: BACKGROUND_TASK_TYPES.DOWNLOAD_LOCKS_CSV,
      title: 'creatingCardsExportFile',
      cancelCallback: () => {
        stopFetching = true;
      },
    }));
    try {
      const orderBy = "id";
      const orderDir = "desc";
      let params = {
        page,
        pageSize,
        ...filters,
        orderBy,
        orderDir,
      };
      let communicationsResponse = await CommunicationsAPI.getCommunications(params);
      if (communicationsResponse && communicationsResponse.data && communicationsResponse.data.content && !_.isEmpty(communicationsResponse.data.content)) {
        const communications = [...communicationsResponse.data.content];
        let pagination = _.omit(communicationsResponse.data, 'content');
        while (pagination.number + 1 !== pagination.totalPages && !stopFetching) {
          params = {
            ...params,
            page: pagination.number + 1,
          };
          communicationsResponse = await CommunicationsAPI.getCommunications(params);
          communications.push(...communicationsResponse.data.content);
          pagination = _.omit(communicationsResponse.data, 'data');
        }
        dispatch(UtilsActions.removeBackgroundTask(taskId));
        return { communications, stopFetching };
      }
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw new Error();
    } catch (error) {
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw error;
    }
  };
}

export function createCommunication(communicationData) {
  return async (dispatch, getState) => {
    try {
      const formattedItemOutDTO = formatter.formatOutputData(formatter.COMMUNICATION, communicationData);
      const createResponse = await CommunicationsAPI.createCommunication(formattedItemOutDTO);
      if (createResponse && createResponse.data) {
        return createResponse.data;
      }
    } catch (error) {
      throw error;
    }
  };
}

export function updateCommunication(communicationId, communicationData) {
  return async (dispatch, getState) => {
    try {
      const formattedItemOutDTO = formatter.formatOutputData(formatter.COMMUNICATION, communicationData);
      const updateResponse = await CommunicationsAPI.updateCommunication(communicationId, formattedItemOutDTO);
      if (updateResponse && updateResponse.data) {
        return updateResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function deleteCommunication(communicationId) {
  return async (dispatch, getState) => {
    try {
      const deleteResponse = await CommunicationsAPI.deleteCommunication(communicationId);
      if (deleteResponse && deleteResponse.data) {
        return deleteResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function getCommunicationDetails(communicationId) {
  return async (dispatch, getState) => {
    try {
      const detailsResponse = await CommunicationsAPI.getCommunicationDetails(communicationId);
      if (detailsResponse && detailsResponse.data) {
        return detailsResponse.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

export function exportCommunications(format = EXPORT_FORMATS.CSV) {
  return async (dispatch, getState) => {
    try {
      const { communications, stopFetching } = await dispatch(fetchAllCommunicationsForExport());
      if (!stopFetching) {
        const exportData = [];
        _.each(communications, (communication) => {
          exportData.push({
            ID: communication.id,
            Title: communication.name,
            Message: communication.description,
            'Communication Date': communication.fromDate ? `${moment(communication.fromDate).format('DD MMMM YYYY HH:mm:ss')}` : '---',
            'Target audience': communication.guestTags && communication.guestTags.length ? 'TARGET' : 'BROADCAST',
            'App visibility': communication.showInApp ? 'Yes' : 'No',
            'App end date': communication.showInApp && communication.toDate ? `${moment(communication.toDate).format('DD MMMM YYYY HH:mm:ss')}` : '---',
            'Email status': communication.emailStatus ? communication.emailStatus : '---',
            'Email scheduled date': communication.emailDate ? `${moment(communication.emailDate).format('DD MMMM YYYY HH:mm:ss')}` : '---',
            'Email object': communication.emailObject || '---',
            'Email sent date': communication.emailSentDate ? `${moment(communication.emailSentDate).format('DD MMMM YYYY HH:mm:ss')}` : '---',
          });
        });
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = format.toLowerCase();
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = { Sheets: { communications: ws }, SheetNames: ['communications'] };
        const excelBuffer = XLSX.write(wb, { bookType: fileExtension, type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `Communication_Export.${fileExtension}`);
        return communications;
      } else {
        throw new Error();
      }
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}

export function setCommunicationsViewMode(viewMode) {
  saveDataToLocalStorage('communicationsViewMode', viewMode);
}

export function fetchCommunicationUsersTotal(communicationId) {
  return async (dispatch, getState) => {
    try {
      const params = {
        page: 0,
        pageSize: 1,
      };
      const response = await RestService.fetchCommunicationUsers(communicationId, params);
      const totalElements = response.data ? (response.data.totalElements || 0) : 0
      dispatch(saveCommunicationUsersTotal(totalElements));
      return totalElements;
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchCommunicationUsers(communicationId, page = 0, append = false, pageSize = 20) {
  return async (dispatch, getState) => {
    try {
      const filters = getState().communications.selectedCommunicationUsers.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const response = await RestService.fetchCommunicationUsers(communicationId, params);
      if (response.data && response.data.content) {
        const formattedUsers = _.map(response.data.content, user => formatter.formatInputData(formatter.COMMUNICATION_USER, { ...user }));
        if (append) {
          dispatch(appendCommunicationUsers(formattedUsers));
        } else {
          dispatch(saveCommunicationUsers(formattedUsers));
        }
        dispatch(saveCommunicationUsersPaginationData(_.omit(response.data, 'content')));
        return formattedUsers;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchAllCommunicationUsersForExport(communicationId, page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    const taskId = moment().valueOf();
    const filters = getState().communications.selectedCommunicationUsers.filters;
    let stopFetching = false;
    dispatch(UtilsActions.addBackgroundTaskSingleton({
      id: taskId,
      type: BACKGROUND_TASK_TYPES.DOWNLOAD_LOCKS_CSV,
      title: 'creatingCardsExportFile',
      cancelCallback: () => {
        stopFetching = true;
      },
    }));
    try {
      let params = {
        page,
        pageSize,
        ...filters,
      };
      let communicationUsersResponse = await RestService.fetchCommunicationUsers(communicationId, params);
      if (communicationUsersResponse && communicationUsersResponse.data && communicationUsersResponse.data.content && !_.isEmpty(communicationUsersResponse.data.content)) {
        const communicationUsers = [...communicationUsersResponse.data.content];
        let pagination = _.omit(communicationUsersResponse.data, 'content');
        while (pagination.number + 1 !== pagination.totalPages && !stopFetching) {
          params = {
            ...params,
            page: pagination.number + 1,
          };
          communicationUsersResponse = await RestService.fetchCommunicationUsers(communicationId, params);
          communicationUsers.push(...communicationUsersResponse.data.content);
          pagination = _.omit(communicationUsersResponse.data, 'data');
        }
        dispatch(UtilsActions.removeBackgroundTask(taskId));
        return { communicationUsers, stopFetching };
      }
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw new Error();
    } catch (error) {
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw error;
    }
  };
}

export function exportCommunicationUsers(communicationId, format = EXPORT_FORMATS.CSV) {
  return async (dispatch, getState) => {
    try {
      const { communicationUsers, stopFetching } = await dispatch(fetchAllCommunicationUsersForExport(communicationId));
      if (!stopFetching) {
        const exportData = [];
        _.each(communicationUsers, (communicationUser) => {
          exportData.push({
            ID: communicationUser.id,
            'User ID': communicationUser.userId,
            Firstname: communicationUser.firstName || "",
            Lastname: communicationUser.lastName || "",
            Email: communicationUser.email || "",
            'Email status': communicationUser.emailStatus || "",
            'Email sent date': communicationUser.emailSentDate ? moment(communicationUser.emailSentDate).format('DD MMMM YYYY HH:mm:ss') : '---',
          });
        });
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = format.toLowerCase();
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = { Sheets: { communicationUsers: ws }, SheetNames: ['communicationUsers'] };
        const excelBuffer = XLSX.write(wb, { bookType: fileExtension, type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `Communication_Users_${communicationId}_Export.${fileExtension}`);
        return communicationUsers;
      } else {
        throw new Error();
      }
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}