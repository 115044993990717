import { AbilityBuilder, PureAbility } from '@casl/ability';
import _ from 'lodash';
import { LICENSE_TYPES } from '../../_config/consts';

export default class AbilityHelper {
  constructor() {
    this.ability = new PureAbility([], {
      detectSubjectType: (object) => object.type || object
    });
    this.userRole = null;
    this.licenseType = 'ENTERPRISE';
  }

  setUserRole(role) {
    this.userRole = role;
  }

  setLicenseType(licenseType) {
    this.licenseType = licenseType;
  }

  getLicenseType() {
    return this.licenseType;
  }

  getAbility() {
    return this.ability;
  }

  updateAbilities(abilities) {
    const rules = abilities.map(rule => ({
      action: rule.action,
      subject: rule.subject
    }));
    this.ability.update(rules);
  }

  hasRolesAndPermissions(roles, checkPermissions, entity) {
    if (!this.hasRoles(roles)) {
      return false;
    }

    return this.hasPermission(checkPermissions, entity);
  }

  hasPermission(checkPermissions, entity) {
    if (!checkPermissions) return true;
    for (let i = 0; i < checkPermissions.length; i += 1) {
      if (this.ability.can(checkPermissions[i], entity)) return true;
    }
    return false;
  }

  hasRoles(roles) {
    if (!this.userRole) return false;
    return _.includes(roles, this.userRole);
  }

  hasLicenseType(licenseTypes) {
    if (!this.licenseType) return false;
    if (!licenseTypes) return true;
    return _.includes(licenseTypes, this.licenseType);
  }
}
