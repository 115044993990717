import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ListViewIcon from '@material-ui/icons/ViewList';
import CardViewIcon from '@material-ui/icons/ViewStream';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { initialize, submit, change } from 'redux-form';
import CommunicationListView from '../components/Communications/CommunicationListView.jsx';
import CommunicationTableView from '../components/Communications/CommunicationTableView.jsx';
import CommunicationIconCustom from '../components/CustomIcons/CommunicationIconCustom.jsx';
import CommunicationForm from '../components/forms/Communications/CommunicationForm.jsx';
import MDButton from '../components/MDButton/MDButton.jsx';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import PresentationalViewHeader from '../components/PresentationalView/PresentationalViewHeader.jsx';
import AbilityProvider from '../permissionsUtils/AbilityProvider.js';
import * as CommunicationsActions from '../redux/actions/communications.actions';
import * as CredentialActions from '../redux/actions/credential.actions';
import * as ModalActions from '../redux/actions/modal.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import { COMMUNICATION_EMAIL_STATUSES, COMMUNICATION_TARGET_AUDIENCE_VALUES, MATCH_TAG_MODE, PERMISSIONS, PERMISSION_ENTITIES, VIEW_MODES } from '../_config/consts';
import { localizeHelpCenterLink } from '../_config/utils.js';


let filterTimeout;
@connect(state => ({ form: state.form.CommunicationForm, communications: state.communications, routing: state.router, languange: state.settings.language, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName }))
class Communications extends React.Component {

  constructor(props) {
    super(props);
    const cachedViewMode = localStorage.getItem('communicationsViewMode');
    this.state = {
      activeTab: cachedViewMode && cachedViewMode === VIEW_MODES.CARDS ? 1 : 0,
    };
  }

  async componentWillMount() {
    const { dispatch, routing } = this.props;
    const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
    this.onFetchCommunications();
    const pin = parsed.pin;
    if (pin) {
      try {
        const pinInfo = JSON.parse(pin);
        const communicationInitDTO = {
          name: L20NContext.getSync('pinCode'),
          description: `${L20NContext.getSync('sharePinMessage')} "${pinInfo.deviceId}" \n${L20NContext.getSync('theValidity')}: ${moment(pinInfo.dateFrom).format('LL')} - ${moment(pinInfo.dateTo).format('LL')}`,
          communicationTimeFrame: {
            startDate: pinInfo.dateFrom,
            endDate: pinInfo.dateTo,
          },
        };
        dispatch(initialize('CommunicationForm', communicationInitDTO));
        dispatch(CommunicationsActions.setSelectedCommunication({}));
        dispatch(CommunicationsActions.setCommunicationsOperationalMode(true));
      } catch (error) {}
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(CredentialActions.selectCredential({}));
    dispatch(CommunicationsActions.setSelectedCommunication({}));
    dispatch(CommunicationsActions.setCommunicationsOperationalMode(false));
  }

  async onFetchCommunications() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      await dispatch(CommunicationsActions.fetchCommunications());
    } finally {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  onNewCommunicationButtonClicked(communicationToCopy) {
    const { dispatch } = this.props;
    dispatch(CommunicationsActions.setSelectedCommunication({}));
    try {
      const formData = communicationToCopy ? {
        ...communicationToCopy,
        id: undefined,
        name: (communicationToCopy.name || '') + ' - Copy',
        startDate: moment().valueOf(),
        startTime: moment().valueOf(),
        endDate: moment().add(7, 'days').endOf('day'),
        scheduleSending: false,
        showInApp: true,
        emailStatus: COMMUNICATION_EMAIL_STATUSES.DISABLED,
        targetAudienceMode: (communicationToCopy.guestTags && communicationToCopy.guestTags.length > 0) ? 
          COMMUNICATION_TARGET_AUDIENCE_VALUES.TARGET : null,
      } : {
        startDate: moment().valueOf(),
        startTime: moment().valueOf(),
        endDate: moment().add(7, 'days').endOf('day'),
        scheduleSending: false,
        showInApp: true,
        emailStatus: COMMUNICATION_EMAIL_STATUSES.DISABLED,
        guestTags: [],
        guestTagMatchingMode: MATCH_TAG_MODE.AT_LEAST_ONE_TAG,
        targetAudienceMode: null,
      };
      dispatch(initialize('CommunicationForm', formData));
      dispatch(CommunicationsActions.setCommunicationsOperationalMode(true));
    } catch (error) {
      dispatch(CommunicationsActions.setSelectedCommunication({}));
    }
  }


  onCloseOperationalSection() {
    const { dispatch } = this.props;
    dispatch(CommunicationsActions.setSelectedCommunication({}));
    dispatch(CommunicationsActions.setCommunicationsOperationalMode(false));
  }

  onSetFilter(field, value) {
    const { dispatch } = this.props;
    dispatch(CommunicationsActions.setCommunicationsFilter(field, value));
    if (filterTimeout) clearTimeout(filterTimeout);
    filterTimeout = setTimeout(async () => {
      await this.onFetchCommunications();
    }, 500);
  }

  onSearchReset() {
    const { dispatch, form } = this.props;
    dispatch(CommunicationsActions.resetCommunicationsFilters());
    dispatch(CommunicationsActions.resetCommunicationUsersFilters());
    this.onFetchCommunications();
    const communicationId = form && form.values && form.values.id;
    if (communicationId) {
      try {
        dispatch(CommunicationsActions.fetchCommunicationUsers(communicationId));
      } 
      catch (error) {}
      finally {}
    }
  }

  onExportElements(format) {
    const { dispatch } = this.props;
    dispatch(CommunicationsActions.exportCommunications(format));
  }
  
  onCommunicationsListModeChange(activeTab) {
    const { dispatch } = this.props;
    const selectedViewMode = activeTab === 0 ? VIEW_MODES.TABLE : VIEW_MODES.CARDS;
    this.setState({ activeTab });
    CommunicationsActions.setCommunicationsViewMode(selectedViewMode);
    if (activeTab === 0) {
      dispatch(CommunicationsActions.resetCommunicationsPagination());
      dispatch(CommunicationsActions.fetchCommunications());
    }
  }

  onFetchCommunicationsOnPage(page) {
    const { dispatch } = this.props;
    dispatch(CommunicationsActions.fetchCommunications(page));
  }

  async onSelectCommunication(communication) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(change('SearchBarForm', 'userCommunicationSearch', undefined));
      await dispatch(change('SearchBarForm', 'emailStatus', undefined));
      await dispatch(CommunicationsActions.resetCommunicationUsersFilters())
      await dispatch(CommunicationsActions.setSelectedCommunication(communication));
      await dispatch(CommunicationsActions.fetchCommunicationUsersTotal(communication.id));
      await dispatch(CommunicationsActions.fetchCommunicationUsers(communication.id));
      const communicationParsed = {
        ...communication,  
        targetAudienceMode: communication.guestTags && communication.guestTags.length > 0 ? 
          COMMUNICATION_TARGET_AUDIENCE_VALUES.TARGET : COMMUNICATION_TARGET_AUDIENCE_VALUES.BROADCAST,
      }
      await dispatch(initialize('CommunicationForm', communicationParsed));
      dispatch(CommunicationsActions.setCommunicationsOperationalMode(true));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(CommunicationsActions.setSelectedCommunication({}));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onDeleteCommunication(communicationId) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'deleteCommunicationConfirm',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onDeleteCommunicationConfirmed(communicationId),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onDeleteCommunicationConfirmed(communicationId) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(CommunicationsActions.deleteCommunication(communicationId));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(CommunicationsActions.setCommunicationsOperationalMode(false));
      this.onFetchCommunications();
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="communicationDeleteSuccess" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(CommunicationsActions.setCommunicationsOperationalMode(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="communicationDeleteError" /></h6>),
        },
      }));
      this.onFetchCommunications();
    }
  }

  async onCreateNewCommunicationSendImmediately(communicationData) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="communicationSaveSendNowRequest" />,
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onCreateNewCommunication(communicationData),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onCreateNewCommunication(communicationData) {
    const { dispatch } = this.props;
    try {
      const communicationDataParsed = {
        ...communicationData,
        guestTagsIds: communicationData.guestTags && communicationData.guestTags.length > 0 ? communicationData.guestTags.map(tag => tag.id) : undefined,
        
        guestTags: undefined,
        selectedGuestTags: undefined,
        targetAudienceMode: undefined,
      }
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(CommunicationsActions.createCommunication(communicationDataParsed));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.onFetchCommunications();
      dispatch(CommunicationsActions.setCommunicationsOperationalMode(false));
      dispatch(CommunicationsActions.setSelectedCommunication({}));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="communicationCreatedSuccessFully" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="communicationActionError" /></h6>),
        },
      }));
    }
  }

  async onEditCommunication(communicationData) {
    const { dispatch } = this.props;
    try {
      const emailSent = communicationData.emailStatus && (
        communicationData.emailStatus === COMMUNICATION_EMAIL_STATUSES.SUCCESS || 
        communicationData.emailStatus === COMMUNICATION_EMAIL_STATUSES.SUCCESS_WITH_FAILURES || 
        communicationData.emailStatus === COMMUNICATION_EMAIL_STATUSES.FAIL
      )
      const communicationDataParsed = emailSent ? {
        name: communicationData.name,
        showInApp: communicationData.showInApp,
        endDate: communicationData.endDate,
      } : {
        ...communicationData,
        guestTagsIds: communicationData.guestTags && communicationData.guestTags.length > 0 ? communicationData.guestTags.map(tag => tag.id) : [],

        guestTags: undefined,
        selectedGuestTags: undefined,
        targetAudienceMode: undefined,
        userCommunications: undefined,
      }
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(CommunicationsActions.updateCommunication(communicationData.id, communicationDataParsed));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.onFetchCommunications();
      dispatch(CommunicationsActions.setCommunicationsOperationalMode(false));
      dispatch(CommunicationsActions.setSelectedCommunication({}));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="communicationUpdatedSuccessFully" /></h6>),
        },
      }));
    } catch (error) {
      console.log(error);
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="communicationActionError" /></h6>),
        },
      }));
    }
  }

  onCommunicationFormSubmit(communicationData) {
    if (communicationData && communicationData.id)
      this.onEditCommunication(communicationData);
    else {
      if (communicationData.scheduleSending)
        this.onCreateNewCommunication(communicationData);
      else
        this.onCreateNewCommunicationSendImmediately(communicationData);
    }
  }

  onOpenInfo() {
    const helpLink = localizeHelpCenterLink('luckey-communication-addon');
    window.open(helpLink);
  }

  render() {
    const { communications: { isOperationalMode, selectedCommunication, data: { pagination } }, form, themeName, dispatch, viewLoading } = this.props;
    const { activeTab } = this.state;
    const numberOfCommunications = pagination && pagination.totalElements ? pagination.totalElements : 0;
    const canCreateItem = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.COMMUNICATION);

    const disableFormSaveButton = form && form.values && 
      (!form.values.id && !form.values.showInApp && form.values.emailStatus === COMMUNICATION_EMAIL_STATUSES.DISABLED)

    const formSaveButtonEntity = form && form.values ? (
      form.values.id ? "save" : 
      form.values.scheduleSending ? "communicationSaveScheduled" : 
      "communicationSaveSendNow"
    ) : "save";
    return (
      <div>
        <PresentationalViewHeader
          themeName={themeName}
          onFilterChange={value => this.onSetFilter('name', _.trim(value))}
          onNewEntity={canCreateItem ? () => this.onNewCommunicationButtonClicked() : null}
          onSearchSubmit={() => this.onFetchCommunications()}
          onSearchReset={() => this.onSearchReset()}
          searchGuestContainerStyle={{ width: 280 }}
          newEntityTitle="createNewF"
          searchPlaceholderEntityName="communicationName"
          newEntityIconName="icon-simple-line-icons-user-following"
          isLoading={viewLoading}
          onInfo={() => this.onOpenInfo()}
          onExportElements={format => this.onExportElements(format)}
          numberOfElements={numberOfCommunications}
          entitiesNumberSection={
            <div style={{ marginRight: 20, marginTop: 13, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <CommunicationIconCustom style={{ width: 22 }} />
              <h4 style={{ marginTop: 5 }}>{numberOfCommunications}</h4>
            </div>
          }
        />
        <div style={{ top: 140, position: 'fixed', backgroundColor: 'white' }}>
          <Tabs
            value={activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, index) => this.onCommunicationsListModeChange(index)}
            TabIndicatorProps={{
              style: { display: 'none' }
            }}
          >
            <Tab icon={<ListViewIcon style={{ fontSize: 30 }} />} />
            <Tab icon={<CardViewIcon style={{ fontSize: 30 }} />} />
          </Tabs>
        </div>
        {activeTab === 0 ? (
          <CommunicationTableView
            canCreateItem={canCreateItem}
            onNewCommunication={() => this.onNewCommunicationButtonClicked()}
            onSelectCommunication={communication => this.onSelectCommunication(communication)}
            onRefreshCommunications={() => this.onFetchCommunications()}
            onFetchCommunicationsOnPage={page => this.onFetchCommunicationsOnPage(page)}
            onDeleteCommunication={communicationId => this.onDeleteCommunication(communicationId)}
            onCreateCommunicationFromModel={(communication) => this.onNewCommunicationButtonClicked(communication)}
          />
          ) : null}
        {activeTab === 1 ? (
          <CommunicationListView
            canCreateItem={canCreateItem}
            onSelectCommunication={communication => this.onSelectCommunication(communication)}
            onNewCommunication={() => this.onNewCommunicationButtonClicked()}
          />
          ) : null}
        <OperationalView
          themeName={themeName}
          isVisible={isOperationalMode}
          onClose={() => this.onCloseOperationalSection()}
          title={<Entity entity={(selectedCommunication && selectedCommunication.name) || "communicationOperationalTitle"} key={(selectedCommunication?.name || '')} />}
          onDelete={selectedCommunication && selectedCommunication.id ? ()=>{this.onDeleteCommunication(selectedCommunication.id)} : null}
        >
          <div style={{ paddingBottom: '10%' }}>
            <CommunicationForm
              onSubmit={communicationData => this.onCommunicationFormSubmit(communicationData)}
              onFetchCommunications={() => this.onFetchCommunications()}
            />
            <MDButton
              title={<Entity entity={formSaveButtonEntity} key={formSaveButtonEntity} />}
              containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
              style={{ height: 45, borderRadius: 0, opacity: disableFormSaveButton ? 0.5 : 1 }}
              onClick={() => dispatch(submit('CommunicationForm'))}
              disabled={disableFormSaveButton}
            />
          </div>
        </OperationalView>
      </div>
    );
  }
} 

export default Communications;
