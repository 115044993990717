import { withStyles } from '@material-ui/core';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { submit } from 'redux-form';
import { GREEN, ORANGE } from '../../_config/consts.js';
import { getLuckeyLogoSource, isMobileBrowser } from '../../_config/utils';
import InsertCodeForm from '../../components/forms/LoginForm/InsertCodeForm.jsx';
import MDButton from '../../components/MDButton/MDButton.jsx';
import * as UserActions from '../../redux/actions/user.actions';

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    overflow: 'hidden',
    backgroundColor: '#f5f5f5',
  },
  leftPanel: {
    flex: 1,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '0 80px',
    backgroundColor: '#157495',
    backgroundImage: 'url(/imgs/common/loginBackground.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'linear-gradient(to right, rgba(21, 116, 149, 0.9), rgba(21, 116, 149, 0.95))',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      width: '40px',
      height: '100%',
      background: 'linear-gradient(to right, rgba(21, 116, 149, 0), rgba(245, 245, 245, 0.2))',
      zIndex: 2,
    }
  },
  rightPanel: {
    flex: 1,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    position: 'relative',
    boxShadow: '-12px 0 20px -10px rgba(0, 0, 0, 0.15)',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '40px',
      height: '100%',
      background: 'linear-gradient(to right, rgba(0, 0, 0, 0.03), transparent)',
      zIndex: 1,
    }
  },
  mobileContainer: {
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#157495',
    padding: 20
  },
  formCard: {
    width: '100%',
    maxWidth: 500,
    padding: '20px 40px',
    backgroundColor: props => props.isMobile ? '#157495' : 'white',
    borderRadius: 8,
    boxShadow: props => props.isMobile ? 'none' : '0px 4px 14px rgba(0, 0, 0, 0.1)'
  },
  copyright: {
    position: 'absolute',
    bottom: 20,
    color: '#666',
    fontSize: 12,
    textAlign: 'center',
    width: '100%'
  },
  description: {
    color: 'rgba(255,255,255,0.9)',
    fontSize: 18,
    maxWidth: 500,
    position: 'relative',
    zIndex: 1
  }
});

@withRouter
@connect(state => ({ userData: state.user.data, companyConfigurations: state.user.companyConfigurations }))
class LoginTwoFactorView extends React.Component {
  
  componentDidMount() {
    const { dispatch, userData } = this.props;
    if (_.isEmpty(userData)) {
      dispatch(push('/login'));
    }
  }

  onSubmitPressed(e) {
    if (e.keyCode === 13) {
      const { dispatch } = this.props;
      dispatch(submit('InsertCodeForm'));
    }
  }

  onValidateLoginCode(loginData) {
    const { dispatch, userData } = this.props;
    const { twoFactorCode } = loginData;
    dispatch(UserActions.verifyOTPCode(userData.id, twoFactorCode ));
  }

  onBackToLogin() {
    const { dispatch } = this.props;
    dispatch(UserActions.logout());
  }

  renderForm() {
    const { dispatch } = this.props;
    const isMobile = isMobileBrowser();
    return (
      <div>
        <div style={{ marginBottom: 20 }}>
          <h1 style={{ 
            fontSize: 30,
            fontWeight: 600,
            color: '#333',
            marginBottom: 8,
            textAlign: 'center'
          }}>
            <Entity entity="verificationCode" />
          </h1>
          <p style={{ 
            color: '#666',
            fontSize: 14,
            lineHeight: '1.5',
            textAlign: 'center'
          }}>
            <Entity entity="verificationCodeDescription" />
          </p>
        </div>
        <InsertCodeForm
          inputStyle={{ backgroundColor: 'white', borderRadius: 10 }}
          codeInputStyle={{ padding: isMobile ? 0 : 10 }}
          onSubmit={loginData => this.onValidateLoginCode(loginData)}
          showInputValues
        />
        <MDButton
          title={<Entity entity="confirm" />}
          containerStyle={{ margin: 0, marginTop: 20, marginBottom: 20, width: '100%' }}
          backgroundColor={GREEN}
          onClick={() => dispatch(submit('InsertCodeForm'))}
        />
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const isMobile = isMobileBrowser();
    const luckeyLogoSource = getLuckeyLogoSource('white');

    if (isMobile) {
      return (
        <div className={classes.mobileContainer}>
          <div style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            marginBottom: 32 
          }}>
            <img src={luckeyLogoSource} alt="Sofia" style={{ height: 60, marginBottom: 8 }} />
            <img src="/imgs/common/tagLine.png" alt="SofiaTagline" style={{ height: 13 }} />
          </div>
          <div className={classes.formCard}>
            {this.renderForm()}
            <MDButton
              title={<Entity entity="backToLogin" />}
              backgroundColor={ORANGE}
              containerStyle={{ margin: 0, marginTop: 20, marginBottom: 20, width: '100%' }}
              onClick={() => this.onBackToLogin()}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
            <img src="/imgs/common/logoSofiaWhite.png" alt="Sofia" style={{ height: 12, marginRight: 4 }} />
            <h6 style={{ fontSize: 10, margin: 0, marginTop: 3, color: 'white' }}>©{moment().year()}</h6>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <div className={classes.leftPanel}>
          <div style={{ position: 'relative', zIndex: 1 }}>
            <div className={classes.description}>
              <img src="/imgs/common/logoJagoWhite.png" alt="Logo" style={{ width: 250, height: 'auto' }} />
              <img src="/imgs/common/tagLine.png" alt="Logo" style={{ width: 500, height: 'auto', marginTop: 10 }} />
              <p style={{ marginTop: 20, color: 'white', fontSize: 18 }}><Entity entity="loginDescription" /></p>
            </div>
          </div>
        </div>
        <div className={classes.rightPanel}>
          <div className={classes.formCard}>
            {this.renderForm()}
            <MDButton
              title={<Entity entity="backToLogin" />}
              backgroundColor={ORANGE}
              containerStyle={{ margin: 0, marginTop: 20, marginBottom: 20, width: '100%' }}
              onClick={() => this.onBackToLogin()}
            />
          </div>
          <div className={classes.copyright}>
            © {moment().year()} Sofia part of ISEO
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(LoginTwoFactorView);
