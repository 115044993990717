/* eslint-disable max-len */
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import FileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { change } from 'redux-form';
import * as XLSX from 'xlsx';
import { BACKGROUND_TASK_TYPES, CREDENTIALS_TYPES, EXPORT_FORMATS, LOCKS_OPEN_MODE, MATCH_TAG_MODE, PERMISSIONS, PERMISSION_ENTITIES, SMARTLOCKS_PROGRAM_TYPES } from '../../_config/consts';
import * as formatter from '../../_config/formatter';
import * as LocksAPI from '../../_config/locksAPI';
import * as LuckeyHelpCenterAPI from '../../_config/luckeyHelpCenterAPI';
import * as RestService from '../../_config/rest';
import { currentPositionMatchesGeoHash, decodeUnicode, elaborateCustomFieldsEntityAttributes, getHelpCenterLanguagePrefix, getWeekdayListFromCredendial, saveDataToLocalStorage } from '../../_config/utils';
import * as AccessoriesActions from './accessories.actions';
import {
  APPEND_LOCKS,
  APPEND_SHARED_SMART_LOCKS,
  CACHE_LOCK_DETAILS, RESET_LOCKS_DATA, RESET_LOCKS_FILTERS, RESET_LOCKS_PAGINATION_DATA,
  RESET_SHARED_SMART_LOCKS_DATA,
  SAVE_LOCK, SAVE_LOCKS,
  SAVE_SHARED_SMART_LOCKS,
  SAVE_SHARED_SMART_LOCKS_PAGINATION_DATA,
  SELECT_LOCK, SET_LOCKS_PAGINATION_DATA,
  SAVE_OR_UPDATE_LOCK,
  SET_LOCK_FILTER, SET_LOCK_OPERATIONAL_MODE, SAVE_REMOTE_OPEN_LOCKS, UPDATE_LOCK, UPDATE_SELECTED_LOCK_TAG, SAVE_REMOTE_OPEN_LOCK, SET_LOCK_ORDER,
  SAVE_ALL_LOCKS
} from './actionTypes/lock';
import * as ModalActions from './modal.actions';
import * as UtilsActions from './utils.actions';
import * as CredentialsAPI from '../../_config/credentialsAPI';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';

export function resetLocksData() {
  return { type: RESET_LOCKS_DATA };
}

export function resetLocksPaginationData() {
  return { type: RESET_LOCKS_PAGINATION_DATA };
}

export function saveLocks(locks) {
  return {
    type: SAVE_LOCKS,
    locks,
  };
}

export function saveSharedSmartLocks(locks) {
  return {
    type: SAVE_SHARED_SMART_LOCKS,
    locks,
  };
}

export function saveSharedSmartLocksPagination(pagination) {
  return {
    type: SAVE_SHARED_SMART_LOCKS_PAGINATION_DATA,
    pagination,
  };
}

export function appendSharedSmartLocks(locks) {
  return {
    type: APPEND_SHARED_SMART_LOCKS,
    locks,
  };
}

export function resetSharedSmartLocks() {
  return {
    type: RESET_SHARED_SMART_LOCKS_DATA,
  };
}


export function updateLockInState(lock) {
  return {
    type: UPDATE_LOCK,
    lock,
  };
}

export function saveOrUpdateLockInState(lock) {
  return {
    type: SAVE_OR_UPDATE_LOCK,
    lock,
  };
}

export function appendLocks(locks) {
  return {
    type: APPEND_LOCKS,
    locks,
  };
}

export function saveLock(lock) {
  return {
    type: SAVE_LOCK,
    lock,
  };
}

export function selectLock(lock) {
  return {
    type: SELECT_LOCK,
    lock,
  };
}

export function setOperationalMode(value) {
  return {
    type: SET_LOCK_OPERATIONAL_MODE,
    value,
  };
}

export function setFilter(name, value) {
  return {
    type: SET_LOCK_FILTER,
    name,
    value,
  };
}

export function setOrder(orderBy, orderDir) {
  return {
    type: SET_LOCK_ORDER,
    orderBy,
    orderDir,
  };
}

export function resetLocksFilters() {
  return {
    type: RESET_LOCKS_FILTERS,
  };
}

export function setLocksPaginationData(pagination) {
  return {
    type: SET_LOCKS_PAGINATION_DATA,
    pagination,
  };
}

export function cacheLock(lockId, lockDetails) {
  return {
    type: CACHE_LOCK_DETAILS,
    lockId,
    lockDetails,
  };
}

export function addRemoteOpenLockIfNotExists(lock) {
  return {
    type: SAVE_REMOTE_OPEN_LOCK,
    lock
  }
}

export function saveRemoteOpenSmartLocks(locks) {
  return {
    type: SAVE_REMOTE_OPEN_LOCKS,
    locks
  }
}

export function saveAllLocks(locks) {
  return {
    type: SAVE_ALL_LOCKS,
    locks,
  };
}

export function updateSelectedLockTag(tag, updateTagInForm = false) {
  return (dispatch, getState) => {
    if (updateTagInForm) {
      const lockTagsForm = getState().form.LockTagsForm.values;
      const index = _.findIndex(lockTagsForm.tags, { id: tag.id });
      const updatedTags = [...lockTagsForm.tags];
      updatedTags[index] = tag;
      dispatch(change('LockTagsForm', 'tags', updatedTags));
    }
    dispatch({
      type: UPDATE_SELECTED_LOCK_TAG,
      tag,
    });
  };
}

export function fetchLocks(page = 0, append = false, pageSize = 20) {
  return async (dispatch, getState) => {
    try {
      await dispatch(AccessoriesActions.fetchAccessories());
    } catch (error) {
    }
    try {
      const filters = getState().locks.data.filters;
      const orderBy = getState().locks.data.sorting.orderBy;
      const orderDir = orderBy?getState().locks.data.sorting.orderDir:undefined;
      const params = {
        page,
        pageSize,
        ...filters,
        orderBy,
        orderDir,
      };
      const response = await RestService.fetchLocks(params);
      if (response.data && response.data.content) {
        const customFields = getState().customFields.locks.content;
        const accessories = getState().accessories.data.content;
        const formattedLocks = _.map(response.data.content, lock => formatter.formatInputData(formatter.LOCK, { ...lock, accessories, customFields }));
        dispatch(setLocksPaginationData(_.omit(response.data, 'content')));
        if (append) {
          dispatch(appendLocks(formattedLocks));
        } else {
          dispatch(saveLocks(formattedLocks));
        }
        return formattedLocks;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchAllLocks(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    const filters = getState().locks.data.filters;
    try {
      let params = {
        page,
        pageSize,
        ...filters,
      };
      let locksResponse = await RestService.fetchLocks(params);
      if (locksResponse && locksResponse.data && locksResponse.data.content && !_.isEmpty(locksResponse.data.content)) {
        const customFields = getState().customFields.locks.content;
        const formattedLocks = _.map(locksResponse.data.content, lock => formatter.formatInputData(formatter.LOCK, { ...lock, customFields }));
        const locks = [...formattedLocks];
        let pagination = _.omit(locksResponse.data, 'content');
        while (pagination.number + 1 !== pagination.totalPages) {
          params = {
            ...params,
            page: pagination.number + 1,
          };
          locksResponse = await RestService.fetchLocks(params);
          const formattedLocksData = _.map(locksResponse.data.content, lock => formatter.formatInputData(formatter.LOCK, { ...lock, customFields }));
          locks.push(...formattedLocksData);
          pagination = _.omit(locksResponse.data, 'content');
        }
        dispatch(saveLock(locks));
        dispatch(setLocksPaginationData(pagination));
        return { locks };
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchLocksOnFloorPlan(floorPlanId) {
  return async (dispatch, getState) => {
    try {
      await dispatch(AccessoriesActions.fetchAccessories());
    } catch (error) {
    }
    try {
      const params = {
        page:0,
        pageSize: 100,
        locationMediaId: floorPlanId,
      };
      const response = await RestService.fetchLocks(params);
      if (response.data && response.data.content) {
        const customFields = getState().customFields.locks.content;
        const accessories = getState().accessories.data.content;
        const formattedLocks = _.map(response.data.content, lock => {
          const formattedLock = formatter.formatInputData(formatter.LOCK, { ...lock, accessories, customFields });
          dispatch(saveOrUpdateLockInState(formattedLock));
          return formattedLock;
        });
        return formattedLocks;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchLocksByTags(tags, page = 0, pageSize = 20, append = false) {
  return async (dispatch, getState) => {
    try {
      const params = {
        tagIds: _.map(tags, tagValue => tagValue.id || tagValue),
        append,
        page,
        pageSize,
        lockTagMatchingMode: MATCH_TAG_MODE.EVERY_TAG,
      };
      const response = await RestService.fetchLocksByTags(params);
      if (response.data) {
        const { page: currentPage, total } = response.data;
        const customFields = getState().customFields.locks.content;
        const accessories = getState().accessories.data.content;
        const formattedLocks = _.map(response.data.data, lock => formatter.formatInputData(formatter.LOCK, { ...lock, customFields, accessories }));
        dispatch(setLocksPaginationData({ currentPage, total }));
        if (append) {
          dispatch(appendLocks(formattedLocks));
        } else {
          dispatch(saveLocks(formattedLocks));
        }
        return formattedLocks;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}


export function fetchAllLocksByTags(tags, lockTagMatchingMode = MATCH_TAG_MODE.AT_LEAST_ONE_TAG, page = 0, pageSize = 20) {
  return async (dispatch, getState) => {
    try {
      let params = {
        tagIds: _.map(tags, tagValue => tagValue.id || tagValue),
        page,
        pageSize,
        lockTagMatchingMode,
      };
      let response = await RestService.fetchLocksByTags(params);
      if (response && response.data && response.data.content && !_.isEmpty(response.data.content)) {
        const locks = [...response.data.content];
        let pagination = _.omit(response.data, 'content');
        while (pagination.number + 1 !== pagination.totalPages) {
          params = {
            ...params,
            page: pagination.number + 1,
          };
          response = await RestService.fetchLocks(params);
          locks.push(...response.data.content);
          pagination = _.omit(response.data, 'data');
        }
        return { locks, pagination };
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function updateLock(values) {
  return async (dispatch, getState) => {
    const customFields = getState().customFields.locks.content;
    const accessories = getState().accessories.data.content;
    const preformattedValues = {
      ...values,
      customFields,
    };
    const formattedLock = formatter.formatOutputData(formatter.LOCK, preformattedValues);
    const lockId = formattedLock.id;
    try {
      const response = await RestService.updateLock(lockId, formattedLock);
      if (response.data) {
        const updatedLock = formatter.formatInputData(formatter.LOCK, { ...response.data, accessories, customFields });
        dispatch(updateLockInState(updatedLock));
        return updatedLock;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function updateLockLocationMedia(lockId, updateDTO) {
  return async (dispatch, getState) => {
    try {
      const customFields = getState().customFields.locks.content;
      const accessories = getState().accessories.data.content;
      const response = await RestService.updateLock(lockId, updateDTO);
      if (response.data) {
        const updatedLock = formatter.formatInputData(formatter.LOCK, { ...response.data, accessories, customFields });
        dispatch(updateLockInState(updatedLock));
        return updatedLock;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function updateLocksBatch(locks) {
  return async (dispatch, getState) => {
    const locksUpdated = [];
    const approvationCalls = _.map(locks, async (lock) => {
      try {
        const lockUpdated = await dispatch(updateLock(lock));
        locksUpdated.push(lockUpdated);
      } catch (error) {
      }
    });
    try {
      await Promise.all(approvationCalls);
      return locksUpdated;
    } catch (error) {
      throw error;
    }
  };
}

export function updateLockName(lockId, lockName) {
  return async (dispatch, getState) => {
    try {
      const lockEditResponse = await RestService.updateLock(lockId, { name: lockName });
      if (lockEditResponse && lockEditResponse.data) {
        const customFields = getState().customFields.locks.content;
        const accessories = getState().accessories.data.content;
        const lockModified = formatter.formatInputData(formatter.LOCK, { ...lockEditResponse.data, accessories, customFields });
        dispatch(updateLockInState(lockModified));
        return lockModified;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


function cacheLockDetails(lockId) {
  return async (dispatch) => {
    try {
      const lockDetails = await RestService.getLockDetails(lockId);
      if (lockDetails && lockDetails.data) dispatch(cacheLock(lockId, lockDetails.data));
      return lockDetails.data;
    } catch (error) {
      throw error;
    }
  };
}

export function cacheLockDetailsIfNeeded(lockId) {
  return async (dispatch, getState) => {
    try {
      if (lockId) {
        const cachedLocksMap = getState().locks.cachedLocksMap;
        if (!(lockId in cachedLocksMap)) {
          await dispatch(cacheLockDetails(lockId));
        }
      }
    } catch (error) {
      throw error;
    }
  };
}


export function fetchLocksTagsByName(name) {
  return async (dispatch, getState) => {
    try {
      const params = {
        name,
        pageSize: 500,
        includeSpecialTags: true,
      };
      const tagsResponse = await RestService.fetchLockTags(params);
      if (tagsResponse && tagsResponse.data && tagsResponse.data.data) return tagsResponse.data.data;
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchSmartLockDetails(lockId) {
  return async (dispatch, getState) => {
    try {
      await dispatch(AccessoriesActions.fetchAccessories());
    } catch (error) {
    }
    try {
      const lockResponse = await LocksAPI.getSmartLockDetails(lockId);
      if (lockResponse && lockResponse.data) {
        const customFields = getState().customFields.locks.content;
        const accessories = getState().accessories.data.content;
        const lockDetails = formatter.formatInputData(formatter.LOCK, { ...lockResponse.data, accessories, customFields });
        return lockDetails;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


export function updateSmartLockOfflineAvailability(lockId, isAvailableOffline, offlineAvailableMinutes) {
  return async (dispatch, getState) => {
    const updateDTO = {
      availableOffline: isAvailableOffline,
      availableOfflineMinutes: offlineAvailableMinutes,
    };
    try {
      const lockResponse = await RestService.updateLock(lockId, updateDTO);
      if (lockResponse && lockResponse.data) {
        const customFields = getState().customFields.locks.content;
        const accessories = getState().accessories.data.content;
        const lockDetails = formatter.formatInputData(formatter.LOCK, { ...lockResponse.data, accessories, customFields });
        dispatch(updateLockInState(lockDetails));
        return lockDetails;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


export function fetchAllLocksForExport(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    const taskId = moment().valueOf();
    const filters = getState().locks.data.filters;
    let stopFetching = false;
    dispatch(UtilsActions.addBackgroundTaskSingleton({
      id: taskId,
      type: BACKGROUND_TASK_TYPES.DOWNLOAD_LOCKS_CSV,
      title: 'creatingLockCSV',
      cancelCallback: () => {
        stopFetching = true;
      },
    }));
    try {
      let params = {
        page,
        pageSize,
        ...filters,
      };
      let locksResponse = await RestService.fetchLocks(params);
      if (locksResponse && locksResponse.data && locksResponse.data.content && !_.isEmpty(locksResponse.data.content)) {
        const customFields = getState().customFields.locks.content;
        const formattedLocks = _.map(locksResponse.data.content, lock => formatter.formatInputData(formatter.LOCK, { ...lock, customFields }));
        const locks = [...formattedLocks];
        let pagination = _.omit(locksResponse.data, 'content');
        while (pagination.number + 1 !== pagination.totalPages && !stopFetching) {
          params = {
            ...params,
            page: pagination.number + 1,
          };
          locksResponse = await RestService.fetchLocks(params);
          const formattedLocksData = _.map(locksResponse.data.content, lock => formatter.formatInputData(formatter.LOCK, { ...lock, customFields }));
          locks.push(...formattedLocksData);
          pagination = _.omit(locksResponse.data, 'content');
        }
        dispatch(UtilsActions.removeBackgroundTask(taskId));
        return { locks, stopFetching };
      }
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw new Error();
    } catch (error) {
      dispatch(UtilsActions.removeBackgroundTask(taskId));
      throw error;
    }
  };
}


export function exportSmartLocks(format = EXPORT_FORMATS.CSV) {
  return async (dispatch, getState) => {
    try {
      const { locks, stopFetching } = await dispatch(fetchAllLocksForExport());
      const stateCustomFields = getState().customFields.locks.content;
      if (!stopFetching) {
        const exportData = [];
        _.each(locks, (lock) => {
          const customFields = elaborateCustomFieldsEntityAttributes(stateCustomFields, lock.customAttributes);
          exportData.push({
            Name: lock.name,
            Model: lock.model,
            'Serial Number': lock.serialNumber,
            Battery: lock.battery,
            ...customFields,
          });
        });
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = format.toLowerCase();
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = { Sheets: { smartlocks: ws }, SheetNames: ['smartlocks'] };
        const excelBuffer = XLSX.write(wb, { bookType: format.toLowerCase(), type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `SmartLocks_Export.${fileExtension}`);
        return locks;
      } else {
        throw new Error();
      }
    } catch (error) {
      let errorMessage = 'errorCreatingCSV';
      if (error.message === 'TOO_MANY_TASKS_SAME_TYPE') errorMessage = 'taskAlreadyExecuting';
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity={errorMessage} /></h6>),
        },
      }));
      throw new Error(error);
    }
  };
}

export function setLocksViewMode(viewMode) {
  saveDataToLocalStorage('lockViewMode', viewMode);
}

export function fetchOfflineLocksNumber(page = 0, pageSize = 1) {
  return async (dispatch, getState) => {
    try {
      const params = {
        page,
        pageSize,
        isAvailableOffline: true,
      };
      const response = await RestService.fetchLocksMetrics(params);
      if (response.data && response.data.content) {
        const { totalElements } = response.data;
        return totalElements;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}


export function onOpenFullOnlineLockRemote(lock) {
  return async (dispatch) => {
    try {
      await LocksAPI.openLockRemote({ serialNumber: lock.serialNumber });
      return true;
    } catch (error) {
      throw error;
    }
  };
}


export function fetchSmartLockPrograms(smartLock, page = 0, pageSize = 20) {
  return async (dispatch, getState) => {
    try {
      const filters = {
        page,
        pageSize,
      };
      const lockResponse = await LocksAPI.getSmartLockPrograms(smartLock.id, filters);
      if (lockResponse && lockResponse.data && lockResponse.data.content) {
        const programs = lockResponse.data.content;
        const smartLocksFormattedPrograms = _.map(programs, (program) => {
          if (program.type !== SMARTLOCKS_PROGRAM_TYPES.OFFICE_MODE) return program;
          const subprograms = program.data && program.data.programs;
          const anyOneCanDisableOfficeMode = program.anyOneCanDisableOfficeMode;
          const formattedSubprograms = _.map(subprograms, (subProgram, index) => formatter.formatInputData(formatter.OFFICE_MODE_PROGRAM, { ...subProgram, index: index + 1 }));
          return {
            ..._.omit(program, 'data'),
            anyOneCanDisableOfficeMode,
            data: [
              ...formattedSubprograms,
            ],
          };
        });
        const smartLockToSave = {
          ...smartLock,
          programs: smartLocksFormattedPrograms,
        };
        dispatch(updateLockInState(smartLockToSave));
        return smartLockToSave;
      }
      return smartLock;
    } catch (error) {
      return smartLock;
    }
  };
}

/* IN DTO programsData = programs[{daysOfTheWeek, enableOnFirstAccess, programStartTime, programEndTime, programType }]
  OUT DTO
  "programs": [ {
    "automatic": true,
    "semiAutomatic": false,
    "daysOfTheWeek": []
    "enableOnFirstAccess": false
    "timeInterval": {
    "from": 0,
    "to": 100
    }
  }
  ]
  },
  "type": "OFFICE_MODE"
} */
export function createOfficeModeSmartLockSubPrograms(smartLock, programsInDTO) {
  return async (dispatch, getState) => {
    try {
      const formattedPrograms = _.map(programsInDTO.programs, program => formatter.formatOutputData(formatter.OFFICE_MODE_PROGRAM, program));
      const programOutDTO = {
        data: {
          programs: formattedPrograms,
        },
        type: SMARTLOCKS_PROGRAM_TYPES.OFFICE_MODE,
      };
      const lockResponse = await LocksAPI.createSmartLockProgram(smartLock.id, programOutDTO);
      if (lockResponse && lockResponse.data && lockResponse.data) {
        const smartLockUpdated = await dispatch(fetchSmartLockPrograms(smartLock));
        return smartLockUpdated;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function editOfficeModeSmartLockProgram(smartLock, programsInDTO, mainProgramId) {
  return async (dispatch, getState) => {
    try {
      const formattedPrograms = _.map(programsInDTO.programs, program => formatter.formatOutputData(formatter.OFFICE_MODE_PROGRAM, program));
      const programOutDTO = {
        data: {
          programs: formattedPrograms,
        },
        type: SMARTLOCKS_PROGRAM_TYPES.OFFICE_MODE,
      };
      const lockResponse = await LocksAPI.editSmartLockProgram(smartLock.id, mainProgramId, programOutDTO);
      if (lockResponse && lockResponse.data && lockResponse.data) {
        const smartLockUpdated = await dispatch(fetchSmartLockPrograms(smartLock));
        return smartLockUpdated;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function deleteSmartLockProgram(smartLock, programId) {
  return async (dispatch, getState) => {
    try {
      const lockResponse = await LocksAPI.deleteSmartLockProgram(smartLock.id, programId);
      if (lockResponse && lockResponse.data && lockResponse.data) {
        const smartLockUpdated = await dispatch(fetchSmartLockPrograms(smartLock));
        return smartLockUpdated;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function enableSmartLockProgram(smartLock, programId) {
  return async (dispatch, getState) => {
    try {
      const lockResponse = await LocksAPI.enableSmartLockProgram(smartLock.id, programId);
      if (lockResponse && lockResponse.data && lockResponse.data) {
        const smartLockUpdated = await dispatch(fetchSmartLockPrograms(smartLock));
        return smartLockUpdated;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function disbleSmartLockProgram(smartLock, programId) {
  return async (dispatch, getState) => {
    try {
      const lockResponse = await LocksAPI.disableSmartLockProgram(smartLock.id, programId);
      if (lockResponse && lockResponse.data && lockResponse.data) {
        const smartLockUpdated = await dispatch(fetchSmartLockPrograms(smartLock));
        return smartLockUpdated;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


export function fetchSmartLockOptions(smartLock, deviceId = null, page = 0, pageSize = 20) {
  return async (dispatch, getState) => {
    try {
      const params = {
        page,
        pageSize,
        deviceId,
      };
      const lockResponse = await LocksAPI.fetchSmartLocksOptions(smartLock.id, params);
      if (lockResponse && lockResponse.data && lockResponse.data.content) {
        return lockResponse.data.content;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function createSmartLockOptions(smartLock, feature, deviceId = null) {
  return async (dispatch, getState) => {
    try {
      const optionDTO = {
        feature,
        deviceId,
      };
      const lockResponse = await LocksAPI.createSmartLocksOption(smartLock.id, optionDTO);
      if (lockResponse && lockResponse.data) {
        return lockResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function deleteSmartLockOptions(smartLock, optionId) {
  return async (dispatch, getState) => {
    try {
      const lockResponse = await LocksAPI.deleteSmartLocksOption(smartLock.id, optionId);
      if (lockResponse && lockResponse.data) {
        return lockResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}


export function getSharedSmartLocks() {
  return async (dispatch, getState) => {
    try {
      const lockResponse = await LocksAPI.fetchSharedSmartLocks();
      if (lockResponse && lockResponse.data) {
        dispatch()
        return lockResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchSharedSmartLocks(page = 0, pageSize = 20) {
  return async (dispatch, getState) => {
    try {
      const response = await LocksAPI.fetchSharedSmartLocks();
      if (response.data && response.data.content) {
        const formattedLocks = _.map(response.data.content, lock => formatter.formatInputData(formatter.LOCK, lock));
        dispatch(setLocksPaginationData(_.omit(response.data, 'content')));
        dispatch(saveSharedSmartLocks(formattedLocks));
        return formattedLocks;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchAndAppendSharedSmartLocks(page = 0, pageSize = 20) {
  return async (dispatch, getState) => {
    try {
      const response = await LocksAPI.fetchSharedSmartLocks();
      if (response.data && response.data.content) {
        const formattedLocks = _.map(response.data.content, lock => formatter.formatInputData(formatter.LOCK, lock));
        dispatch(setLocksPaginationData(_.omit(response.data, 'content')));
        dispatch(appendSharedSmartLocks(formattedLocks));
        return formattedLocks;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function shareSmartLockToSubCompanies(companiesIds, lockId) {
  return async (dispatch, getState) => {
    try {
      for (const companyId of companiesIds) {
        try {
          await dispatch(shareSmartLockToSubCompany({ lockId, companyId }));
        } catch (error) {
          throw new Error(error);
        }
      }
    } catch (error) {
      throw new Error(error);
    }
  }
}

export function shareSmartLockToSubCompany(sharedDTO) {
  return async (dispatch, getState) => {
    try {
      const response = await LocksAPI.shareSmartLockWithCompany(sharedDTO);
      if (response.data && response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function removeSharedLockFromSubCompanies(lockIds) {
  return async (dispatch, getState) => {
    try {
      for (const lockId of lockIds) {
        try {
          await dispatch(removeSharedLockFromSubCompany(lockId));
        } catch (error) {
        }
      }
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function removeSharedLockFromSubCompany(lockId) {
  return async (dispatch, getState) => {
    try {
      const response = await LocksAPI.removeSharedSmartLockFromCompany(lockId);
      if (response.data && response.data) {
        return response.data;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function fetchSharedSubcompaniesForLock(lock) {
  return async (dispatch, getState) => {
    try {
      const params = {
        sharedFromLockId: lock.id,
        pageSize: 50,
      };
      const response = await LocksAPI.fetchSharedSmartLocks(params);
      if (response.data && response.data.content) {
        const { subcompanies } = getState().settings;
        const lockSubcompanies = _.map(response.data.content, lock => {
          const lockSubcompany = _.find(subcompanies, company => company.id === lock.companyId);
          if (lockSubcompany) return { ...lockSubcompany, lockIdInCompany: lock.id } ;
        })
        const updatedSmartlock = {
          ...lock,
          subcompanies: lockSubcompanies,
        };
        dispatch(updateLockInState(updatedSmartlock));
        return updatedSmartlock;
      }
      throw new Error();
    } catch (error) {
      throw new Error(error);
    }
  };
}

export function updateSmartLockConfigurations(lockId, configuration) {
  return async (dispatch, getState) => {
    try {
      const lockEditResponse = await RestService.updateLock(lockId, { configuration });
      if (lockEditResponse && lockEditResponse.data) {
        const customFields = getState().customFields.locks.content;
        const accessories = getState().accessories.data.content;
        const lockModified = formatter.formatInputData(formatter.LOCK, { ...lockEditResponse.data, accessories, customFields });
        dispatch(updateLockInState(lockModified));
        return lockModified;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function fetchSmartLockTroubleShootingArticle(lock) {
  return async (dispatch, getState) => {
    try {
      const languagePrefix = getHelpCenterLanguagePrefix();
      const content = await LuckeyHelpCenterAPI.fetchTroubleshootingArticle(lock.troubleShootingArticleId, languagePrefix);
      if (content && !_.isEmpty(content.data)) {
        const troubleshootingItemsContent = _.first(content.data);
        const previewImage = troubleshootingItemsContent && troubleshootingItemsContent['_embedded'] && troubleshootingItemsContent['_embedded']['wp:featuredmedia'] && troubleshootingItemsContent['_embedded']['wp:featuredmedia'][0] ? troubleshootingItemsContent['_embedded']['wp:featuredmedia'][0].source_url : null;
        const troubleshootingArticle = {
          ...troubleshootingItemsContent,
          preview: troubleshootingItemsContent && troubleshootingItemsContent.excerpt && troubleshootingItemsContent.excerpt.rendered ? troubleshootingItemsContent.excerpt.rendered : undefined,
          previewImage,
          renderedTitle: troubleshootingItemsContent && troubleshootingItemsContent.title && troubleshootingItemsContent.title.rendered ? decodeUnicode(troubleshootingItemsContent.title.rendered) : '',
          renderedContent: troubleshootingItemsContent && troubleshootingItemsContent.content && troubleshootingItemsContent.content.rendered ? troubleshootingItemsContent.content.rendered : '',
        };
        const lockUpdated = {
          ...lock,
          troubleshootingArticle,
        }
        dispatch(selectLock(lockUpdated));
        dispatch(updateLockInState(lockUpdated));
        return troubleshootingArticle;
      }
    } catch (error) {
    }
  }
}

export function fetchAndAppendLocksOnFloorPlan(page = 0, pageSize = 100) {
  return async (dispatch, getState) => {
    try {
      await dispatch(AccessoriesActions.fetchAccessories());
    } catch (error) {
    }
    try {
      const filters = getState().locks.data.filters;
      const params = {
        page,
        pageSize,
        ...filters,
      };
      const response = await RestService.fetchLocks(params);
      if (response.data && response.data.content) {
        const customFields = getState().customFields.locks.content;
        const accessories = getState().accessories.data.content;
        const formattedLocks = _.map(response.data.content, lock => {
          const formattedLock = formatter.formatInputData(formatter.LOCK, { ...lock, accessories, customFields });
          dispatch(saveOrUpdateLockInState(formattedLock));
          return formattedLock;
        });
        dispatch(setLocksPaginationData(_.omit(response.data, 'content')));
        return formattedLocks;
      }
    } catch (error) {
      throw new Error(error);
    }
  };
}


export function fetchLocksFromAccessCredentials() {
  return async (dispatch, getState) => {
    try {
      const now = moment();
      let params = {
        page: 0,
        pageSize: 100,
        validInDate: now.valueOf(),
        type: CREDENTIALS_TYPES.SMART,
      };
      let credentialsResponse = await CredentialsAPI.fetchUserCredentials(params);
      let credentials = [];
      if (credentialsResponse.data && credentialsResponse.data.content) {
        credentials = [...credentialsResponse.data.content];
        while (!credentialsResponse.data.last) {
          params = {
            ...params,
            page: credentialsResponse.data.number + 1,
          };
          credentialsResponse = await CredentialsAPI.fetchUserCredentials(params);
          credentials.push(...credentialsResponse.data.content);
        }
        //TODO Qui le locks che hanno apertura online devono essere enabled di default.
        credentials = _.map(credentials, (c) => {
          if (c.smartLock.availableOffline) {
            return {
              ...c,
              offlineValidityExpDate: moment(now).add(c.smartLock.availableOfflineMinutes, 'minutes').valueOf(),
              lastCredentialUpdateDate: now.valueOf(),
            };
          }
          return c;
        });
        const { number, totalPages } = credentialsResponse.data;
        dispatch(saveAllowedLocksFromCredentialsAndSetTimezone(credentials));
        // dispatch(saveUserCredentials(credentials, append));
        // dispatch(saveCredentialsPaginationData(number, totalPages));
        // Set user timezone
        return credentialsResponse.data;
      }
      throw new Error();
    } catch (error) {
      throw error;
    }
  };
}

export function saveAllowedLocksFromCredentialsAndSetTimezone(credentials) {
  return (dispatch, getState) => {
    _.each(credentials, async (credential) => {
      let { enabled } = credential.smartLock;
      let smartLock = {
        ...formatter.formatInputData(formatter.LOCK, credential.smartLock),
        enabled,
        daysOfWeek: getWeekdayListFromCredendial(credential),
        credentialTimeInSecondsFrom: credential.timeInSecondsFrom,
        credentialTimeInSecondsTo: credential.timeInSecondsTo,
        offlineValidityExpDate: credential.offlineValidityExpDate,
      };
      // if (credential.smartLock && credential.smartLock.configuration && credential.smartLock.configuration.openMode === LOCKS_OPEN_MODE.REMOTE_OPEN_HT) {
      //   dispatch(addRemoteOpenLockIfNotExists({ ...credential.smartLock, enabled: true }));
      // }
      // const canSendGatewayNotification = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.GATEWAY_NOTIFICATION);
      // if (credential.smartLock && credential.smartLock.gatewayId && canSendGatewayNotification) {
      //   smartLock = { ...smartLock, remoteOpenHyperGateEnabled: true };
      //   dispatch(addRemoteOpenLockIfNotExists(smartLock));
      // }
      if (credential.smartLock && credential.smartLock.configuration && credential.smartLock.configuration.guestRemoteOpenEnabled && credential.smartLock.gatewayId) {
        let lockAccessory;
        try {
          lockAccessory = await dispatch(AccessoriesActions.fetchAccessoryDetailsByDeviceId(credential.smartLock.serialNumber));
        } catch (error) {
          // Do nothing
        }
        smartLock = { ...smartLock, remoteOpenHyperGateHighFlexEnabled: true, accessories: lockAccessory ? [lockAccessory] : [] };
        dispatch(addRemoteOpenLockIfNotExists(smartLock));
      }
      return smartLock;
    });
    // const existingLocks = getState().locks.remoteOpenSmartLocks;
    // const uniqueLocks = _.uniqBy(locks, (lock) => lock.id);
    // // Check lock groups
    // const locksWithGroup = _.filter(uniqueLocks, (lock) => lock.configuration.group);
    // if (locksWithGroup && !_.isEmpty(locksWithGroup)) {
    //   const groups = _.groupBy(locksWithGroup, (lock) => lock.configuration.group);
    //   const groupsToSave = _.map(_.keys(groups), (groupName) => {
    //     const groupDTO = formatter.formatInputData(formatter.LOCK_GROUP, { groupName, smartLocks: groups[groupName], groupMasterLockId: groups[groupName][0].configuration.groupMasterLockId })
    //     return groupDTO;
    //   });
    //   // ONLY SHOW V364 LOCKS WITH GROUP
    //   uniqueLocks.push(...locksWithGroup);
    //   uniqueLocks.push(...groupsToSave);
    // }
    // const locksToSave = _.map(uniqueLocks, (newLock) => {
    //   const oldLock = _.find(existingLocks, (existingLock) => existingLock.serialNumber === newLock.serialNumber);
    //   if (oldLock) {
    //     return _.merge(oldLock, newLock);
    //   }
    //   return newLock;
    // });
    // dispatch(saveRemoteOpenSmartLocks(locksToSave));
  }
}

export function canLockBeOpenedRemotely(lockGeoHash, lockGeoHashPrecision) {
  return async (dispatch, getState) => {
    if (!lockGeoHash) {
      return true;
    }
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const currentPosition = await new Promise((resolve, reject) => {
        try {
          navigator.geolocation.getCurrentPosition((info) => resolve(info), (error) => {
            reject(error);
          }, { enableHighAccuracy: true, timeout: 5000, maximumAge: 60000 });
        } catch (error) {
          reject(error);
        }
      });
      const currentPositionMatchLockPosition = await currentPositionMatchesGeoHash(currentPosition && currentPosition.coords, lockGeoHash, lockGeoHashPrecision);
      dispatch(UtilsActions.setSpinnerVisibile(false));
      return currentPositionMatchLockPosition;
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      return false
    }
  };
}

export function addTagsBatchLocks(locks, tags) {
  return async (dispatch, getState) => {
    const errorLocks = [];
    for (const lock of locks) {
      try {
        const tagIds = _.map([...tags, ...lock.tags], val => val.id)
        const data = {
          lockTagIds:  _.uniq(tagIds),
        }
        const response = await RestService.updateLock(lock.id, data);
        if (!(response && response.data))
          errorLocks.push(lock);
      } catch (error) {
        errorLocks.push(lock);
      }
    }
    if (!_.isEmpty(errorLocks)) {
      throw errorLocks;
    }
  };
}

export function removeTagsBatchLocks(locks, tags) {
  return async (dispatch, getState) => {
    const errorLocks = [];
    for (const lock of locks) {
      try {
        const tagIds = _.map(_.filter(lock.tags,e=>!_.some(tags,f=>e.id===f.id)), val => val.id)
        const data = {
          lockTagIds:  _.uniq(tagIds),
        }
        const response = await RestService.updateLock(lock.id, data);
        if (!(response && response.data))
          errorLocks.push(lock);
      } catch (error) {
        errorLocks.push(lock);
      }
    }
    if (!_.isEmpty(errorLocks)) {
      throw errorLocks;
    }
  };
}

export function fetchAllLocksCustomFilters(filters) {
  return async (dispatch, getState) => {
    try {
      let pageCounter = 0;
      const pageSize = 100;
      let formattedLocks = []
      while(pageCounter<10) {
        const params = {
          page: pageCounter,
          pageSize,
          ...(filters || {}),
        };
        const response = await RestService.fetchLocks(params);
        if (response.data && response.data.content) {
          const formattedLocksTemp = _.map(response.data.content, lock => formatter.formatInputData(formatter.LOCK, { ...lock }));
          formattedLocks = [...formattedLocks,...formattedLocksTemp]
          if (response.data.last)
            break;
          pageCounter++;
        }
        else 
          break;
      }
      dispatch(saveAllLocks(formattedLocks));
      return formattedLocks;
    } catch (error) {
      throw new Error(error);
    }
  };
}