import React from 'react';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';

export default class DatePickerView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
  }


  onDateChange(date) {
    const { onChange } = this.props;
    if (date) {
      onChange(date.valueOf());
    } else onChange(date);
  }

  onFocusChange({ focused }) {
    this.setState({ focused });
  }

  render() {
    const { focused } = this.state;
    const { date, id, useCursorStyleNotAllowed } = this.props;
    const wrapperClassName = useCursorStyleNotAllowed ? 'date-picker-not-allowed' : '';
    
    return (
      <div className={wrapperClassName}>
        {useCursorStyleNotAllowed && (
          <style>{`
            .date-picker-not-allowed .SingleDatePicker, 
            .date-picker-not-allowed .SingleDatePickerInput, 
            .date-picker-not-allowed .DateInput, 
            .date-picker-not-allowed .DateInput_input,
            .date-picker-not-allowed .SingleDatePicker_picker {
              cursor: not-allowed !important;
            }
            .date-picker-not-allowed .SingleDatePickerInput_calendarIcon {
              cursor: not-allowed !important;
            }
            .date-picker-not-allowed .DateInput_input {
              pointer-events: none;
            }
          `}</style>
        )}
        <SingleDatePicker
          numberOfMonths={1}
          focused={focused}
          hideKeyboardShortcutsPanel
          onDateChange={this.onDateChange}
          onFocusChange={this.onFocusChange}
          date={moment(date)}
          id={id}
          {...this.props}
        />
      </div>
    );
  }
}
