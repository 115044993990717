
import { IconButton } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Share, ShareOutlined } from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Autorenew';
import Cancel from '@material-ui/icons/Cancel';
import OfflineIcon from '@material-ui/icons/CloudOff';
import Edit from '@material-ui/icons/Edit.js';
import LockGroupIcon from '@material-ui/icons/Layers';
import LockOpen from '@material-ui/icons/LockOpen';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { change, initialize, submit } from 'redux-form';
import { ACCESSORIES_TYPES, BLUE, GREEN, LANGUAGE_DETAILS, LANGUAGES, LICENSE_TYPES, MAX_LOCKS_TAGS_LITE_LIMIT, OFFICE_MODE_PROGRAM_TYPES, PERMISSION_ENTITIES, PERMISSIONS, SMARTLOCK_DOOR_STATUS, SMARTLOCK_DOOR_STATUS_MAP, SMARTLOCKS_PROGRAM_TYPES, VENDORS_LIST } from '../../_config/consts';
import { canConfigureOfficeMode, convertValueFromUnitToMinutes, formatDomainHostNameToFriendlyName, getLockImage, hasFormWritePermission, saveDataToLocalStorage } from '../../_config/utils.js';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import * as AccessoriesActions from '../../redux/actions/accessories.actions';
import * as LockActions from '../../redux/actions/lock.actions';
import * as LogEventsActions from '../../redux/actions/logEvents.actions';
import * as ModalActions from '../../redux/actions/modal.actions';
import * as TagActions from '../../redux/actions/tag.actions';
import * as TagsManagementActions from '../../redux/actions/tagsManagement.actions';
import * as UtilsActions from '../../redux/actions/utils.actions';
import * as SettingsActions from '../../redux/actions/setting.actions.js'
import SmartLocksEvents from '../../routes/LogEvents/SmartLocksEvents.jsx';
import CustomFieldsDisplayView from '../CustomFields/CustomFieldsDisplayView.jsx';
import CameraIconCustom from '../CustomIcons/CameraIconCustom.jsx';
import ChartMultilineIconCustom from '../CustomIcons/ChartMultilineIconCustom.jsx';
import ClipboardIconCustom from '../CustomIcons/ClipboardIconCustom.jsx';
import OfficeModeIconCustom from '../CustomIcons/OfficeModeIconCustom.jsx';
import SmartLocksEventsIconCustom from '../CustomIcons/SmartLocksEventsIconCustom.jsx';
import LockCustomFieldsForm from '../forms/LockForms/LockCustomFieldsForm.jsx';
import LockNameInlineForm from '../forms/LockForms/LockNameInlineForm.jsx';
import LockOfficeModeForm from '../forms/LockForms/LockOfficeModeForm.jsx';
import LockOfflineAvailabilityForm from '../forms/LockForms/LockOfflineAvailabilityForm.jsx';
import LockTagsForm from '../forms/LockForms/LockTagsForm.jsx';
import TagForm from '../forms/TagForm.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import BasicModal from '../Modals/BasicModal.jsx';
import OperationalView from '../OperationalView/OperationalView.jsx';
import Tag from '../Tag.jsx';
import LockOfficeModeExplanationView from './LockOfficeModeExplanationView.jsx';
import LockSensorHistory from './LockSensorHistory.jsx';
import LockTechnicalDetailsView from './LockTechnicalDetailsView.jsx';
import MDSwitchView from '../forms/Fields/MDSwitch/MDSwitchView.jsx';
import LockTroubleShootingView from './LockTroubleShootingView.jsx';
import MessageAlertIconCustom from '../CustomIcons/MessageAlertIconCustom.jsx';
import ShareIconCustom from '../CustomIcons/ShareIconCustom.jsx';
import MapIconCustom from '../CustomIcons/MapIconCustom.jsx';

const theme = createTheme({
  palette: {
    primary: { 500: BLUE },
  },
});

const DefaultTagSection = ({ tag }) =>
  (
    <div style={{ marginTop: 5 }}>
      <Tag tag={tag} />
      <hr style={{ marginTop: 20, marginBottom: 10 }} />
    </div>
  );
// @ts-ignore
@connect((state) => ({
  subcompanies: state.settings.subcompanies,
  locksTagsNumber: state.tagsManagement.lockTags.number,
  lockTags: state.tags.lock,
  availabilityForm: state.form.LockOfflineAvailabilityForm,
  officeModeForm: state.form.LockOfficeModeForm,
  language: state.settings.language,
  companyConfigurations: state.user.companyConfigurations,
  lock: state.locks.selectedLock,
  troubleshootingArticles: state.settings.troubleshooting,
  themeName: state.settings.items.theme.data.themeName
}))
class LocksOperationalSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      showCameraLiveFeed: false,
      isCustomFieldFormOpen: false,
      isGuestRemoteOpenEnabled: props && props.lock && props.lock.configuration && props.lock.configuration.guestRemoteOpenEnabled,
    };
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(TagActions.fetchLockTags());
    dispatch(LogEventsActions.resetSmartLocksEventsData());
    dispatch(LogEventsActions.resetSmartLocksEventsFilters());
    dispatch(TagsManagementActions.fetchLocksSpecialTagsNumber());
  }

  onSelectTab(tabIndex) {
    const { dispatch, onInitializeLockOfflineForm, lock, companyConfigurations } = this.props;
    this.setState({ selectedTab: tabIndex });
    const canReadLogs = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.LOG);
    const canReadLocks = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.SMART_LOCK);
    const canSeeLogsTab = canReadLogs && canReadLocks;
    if (tabIndex === 1 && canSeeLogsTab) {
      dispatch(LogEventsActions.setSmartLocksEventsFilter('smartLockId', lock.id));
    }
    const isArgoOfflineLocksFeaturesEnabled = companyConfigurations && companyConfigurations.argoOfflineEnabled;
    if (isArgoOfflineLocksFeaturesEnabled && (tabIndex === 2 || (!canSeeLogsTab && tabIndex === 1))) {
      onInitializeLockOfflineForm(lock);
    }
  }


  async onUpdateLockTags(values) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(LockActions.updateLock(values));
      dispatch(LockActions.setOperationalMode(false));
      dispatch(LockActions.selectLock({}));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'lockUpdated' }} /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="errorEditingSmartLock" /></h6>),
        },
      }));
    }
  }

  onShowTagModal(values, mode) {
    const { dispatch } = this.props;
    dispatch(TagActions.fetchLockTagsCategories());
    if (mode === 'NEW') {
      dispatch(initialize('TagForm', { name: values.name }));
      this.newTagModal.open();
    } else {
      dispatch(initialize('TagForm', { ...values, type: { name: values.type }, color: { hex: values.color } }));
      this.editTagModal.open();
    }
  }

  async onCreateTag(values) {
    const { dispatch, locksTagsNumber } = this.props;
    const addTagToForm = true;
    await dispatch(TagActions.createLockTag(values, addTagToForm));
    dispatch(TagsManagementActions.fetchLocksSpecialTagsNumber());
    this.newTagModal.close();
  }

  async onEditTag(values) {
    const { dispatch } = this.props;
    try {
      const updatedTag = await dispatch(TagActions.updateLockTag(values));
      const updateTagInForm = true;
      dispatch(LockActions.updateSelectedLockTag(updatedTag, updateTagInForm));
      this.editTagModal.close();
      dispatch(LockActions.fetchLocks());
    } catch (error) {} 
  }


  onFilterLockTags(value) {
    const { dispatch } = this.props;
    dispatch(TagActions.cancelFetchLockTagsByFilter());
    dispatch(TagActions.fetchLockTagsByFilter(value, 50));
  }

  onOpenLock(event) {
    const { onOpenLock, lock } = this.props;
    event.stopPropagation();
    if (onOpenLock) onOpenLock(lock);
  }

  async onUpdateLockOfflineAvailability(availabilityData) {
    const { dispatch, lock } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      let availableOfflineMinutes = availabilityData.availableOfflineMinutes;
      try {
        availableOfflineMinutes = convertValueFromUnitToMinutes(availabilityData.availableOfflineMinutes, availabilityData.timeUnit);
      } catch (error) {
      }
      saveDataToLocalStorage('offlineTimeUnitSelected', availabilityData.timeUnit);
      await dispatch(LockActions.updateSmartLockOfflineAvailability(lock.id, availabilityData.availableOffline, availableOfflineMinutes));
      dispatch(LockActions.setOperationalMode(false));
      dispatch(LockActions.selectLock({}));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'lockUpdated' }} /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="errorEditingSmartLock" /></h6>),
        },
      }));
    }
  }
  // programsData = programs[{}]
  onSaveProgramDataRequest(programsData) {
    const { dispatch } = this.props;
    if (programsData.isEditing && programsData.programs && _.isEmpty(programsData.programs)) {
      const params = {
        modalType: 'CONFIRM_TO_CONTINUE_MODAL',
        modalProps: {
          title: 'warning',
          message: 'warningDeletingAllPrograms',
          onConfirmText: <Entity entity="confirm" />,
          onConfirm: () => this.onSaveProgramData(programsData),
          onCancelText: <Entity entity="cancel" />,
          onCancel: () => dispatch(ModalActions.hideModal()),
        },
      };
      dispatch(ModalActions.showModal(params));
    } else {
      this.onSaveProgramData(programsData);
    }
  }

  // programsData = programs[{}]
  async onSaveProgramData(programsData) {
    const { dispatch, lock } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      let lockUpdated = {};
      if (programsData && programsData.id && programsData.isEditing) {
        lockUpdated = await dispatch(LockActions.editOfficeModeSmartLockProgram(lock, programsData, programsData.id));
      } else if (programsData && !_.isEmpty(programsData.programs)) {
        lockUpdated = await dispatch(LockActions.createOfficeModeSmartLockSubPrograms(lock, programsData));
      }
      let option;
      if (programsData && programsData.allDeviceOptionActive) {
        option = await dispatch(LockActions.createSmartLockOptions(lock, 'TOGGLE_PASSAGE_MODE', 'ALL_DEVICES'));
      } else if (programsData.allDeviceOptionActive === false) {
        const allDeviceOption = lock.options && _.find(lock.options, existingOption => existingOption.deviceId === 'ALL_DEVICES' && existingOption.feature === 'TOGGLE_PASSAGE_MODE');
        if (allDeviceOption) {
          await dispatch(LockActions.deleteSmartLockOptions(lock, allDeviceOption.id));
          option = {};
        }
      }
      lockUpdated = {
        ...lockUpdated,
        options: [option],
      };
      this.onInitializeOfficeModeFormFromLockPrograms(lockUpdated, true);
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="programSendSuccessfully" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="officeModeSaveProgramError" /></h6>),
        },
      }));
    }
  }

  async onRefreshOfficeModeProgramStatus() {
    const { dispatch, lock } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      const lockUpdated = await dispatch(LockActions.fetchSmartLockPrograms(lock));
      this.onInitializeOfficeModeFormFromLockPrograms(lockUpdated, true);
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onInitializeOfficeModeFormFromLockPrograms(lock, isEditing = true) {
    const { dispatch } = this.props;
    const officeModePrograms = lock.programs && _.filter(lock.programs, program => program.type === SMARTLOCKS_PROGRAM_TYPES.OFFICE_MODE);
    const allDeviceOption = lock.options && _.filter(lock.options, option => option.deviceId === 'ALL_DEVICES' && option.feature === 'TOGGLE_PASSAGE_MODE');
    if (officeModePrograms && !_.isEmpty(officeModePrograms)) {
      const officeModeFirstProgram = officeModePrograms && _.first(officeModePrograms);
      dispatch(initialize('LockOfficeModeForm', {
        programs: officeModeFirstProgram.data,
        isEditing,
        allDeviceOptionActive: !_.isEmpty(allDeviceOption),
        ...officeModeFirstProgram,
      }));
    }
  }

  onInitializeNewOfficeModeSubProgram(programId) {
    const { dispatch } = this.props;
    dispatch(change('LockOfficeModeForm', `programs[${programId}].programType`, OFFICE_MODE_PROGRAM_TYPES.AUTOMATIC));
    dispatch(change('LockOfficeModeForm', `programs[${programId}].programStartTime`, moment().startOf('day').set('hours', 9).valueOf()));
    dispatch(change('LockOfficeModeForm', `programs[${programId}].programEndTime`, moment().startOf('day').set('hours', 18).valueOf()));
    dispatch(change('LockOfficeModeForm', `programs[${programId}].enableOnFirstAccess`, false));
    dispatch(change('LockOfficeModeForm', `programs[${programId}].daysOfTheWeek`, [1, 2, 3, 4, 5]));
  }

  onDeleteSubProgramRequest(programId) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'deleteOfficeModeProgram',
        message: 'deleteOfficeModeProgramDescription',
        onConfirmText: <Entity entity="confirm" />,
        onConfirm: () => this.onDeleteSubProgramConfirmed(programId),
        onCancelText: <Entity entity="cancel" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  onDeleteSubProgramConfirmed(programId) {
    const { officeModeForm, dispatch } = this.props;
    dispatch(ModalActions.hideModal());
    const officeModeFormValues = officeModeForm && officeModeForm.values;
    const officeModeProgramsValue = officeModeFormValues && officeModeFormValues.programs;
    _.pullAt(officeModeProgramsValue, [programId]);
    dispatch(change('LockOfficeModeForm', 'programs', officeModeProgramsValue));
  }

  onOpenOfficeModeInfoModal() {
    const { dispatch, language } = this.props;
    const languageImagePath = language && LANGUAGE_DETAILS[language] && LANGUAGE_DETAILS[language].storeLanguagePrefix ? `/imgs/common/officeModeImages/${LANGUAGE_DETAILS[language].storeLanguagePrefix}` : '/imgs/common/officeModeImages/en';
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        title: <Entity entity="officeMode" />,
        content: <LockOfficeModeExplanationView languageImagePath={languageImagePath} />,
        onConfirmText: <Entity entity="gotcha" />,
        modalStyle: { height: '90% !important' },
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
        onConfirm: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async onModifyLockName(values) {
    const { dispatch, lock } = this.props;
    try {
      const updatedLock = await dispatch(LockActions.updateLockName(lock.id, values.name));
      dispatch(LockActions.selectLock(updatedLock));
    } catch (error) {
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_MODAL',
        modalProps: { type: 'DEFAULT_ERROR', defaultMessage: <Entity entity="errorSavingLockName" /> },
      }));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  onOpenLockCustomFieldForm(lock) {
    const { dispatch } = this.props;
    dispatch(initialize('LockCustomFieldsForm',
      {
        id: lock.id,
        name: lock.name,
        ...lock.customAttributes,
        ...lock,
      }));
    this.setState({ isCustomFieldFormOpen: true });
  }

  async onUpdateLockCustomFields(values) {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      let newLock = await dispatch(LockActions.updateLock(values));
      newLock = {
        ...newLock,
        ...newLock.customAttributes,
      };
      dispatch(LockActions.selectLock(newLock));
      dispatch(initialize('LockNameInlineForm', { name: newLock.name }));
      this.setState({ isCustomFieldFormOpen: false });
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="modalMessage" data={{ modal: 'lockUpdated' }} /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="errorEditingSmartLock" /></h6>),
        },
      }));
    }
  }

  onGatewayClick(gatewayId) {
    const { dispatch } = this.props;
    dispatch(push(`/gateways?gatewayId=${gatewayId}`));
  }

  async onRefreshStatusSensor(smartLock, lockSensor) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const updatedLock = await dispatch(AccessoriesActions.refreshSmartLockStatusSensorValue(smartLock, lockSensor.id));
      dispatch(LockActions.selectLock(updatedLock));
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
  }

  async onToggleGuestFlexRemoteOpeningValue(value) {
    const { dispatch, lock } = this.props;
    const configuration = {
      ...lock.configuration,
      guestRemoteOpenEnabled: value
    };
    dispatch(UtilsActions.setSpinnerVisibile(true));
    try {
      await dispatch(LockActions.updateSmartLockConfigurations(lock.id, configuration));
      this.setState({ isGuestRemoteOpenEnabled: value });
      dispatch(UtilsActions.setSpinnerVisibile(false));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
    }
    
  }

  render() {
    const { selectedTab, isCustomFieldFormOpen, isGuestRemoteOpenEnabled } = this.state;
    const { dispatch, language, lock, onShowCameraFeed, officeModeForm, onShowLockMap, troubleshootingArticles, onOpenLockSharingSettings, lockTags, availabilityForm, themeName, subcompanies } = this.props;
    const isLockAvailableOffline = availabilityForm && availabilityForm.values && availabilityForm.values.availableOffline;
    const selectedMinutes = availabilityForm && availabilityForm.values && availabilityForm.values.availableOfflineMinutes;
    const selectedTimeUnit = availabilityForm && availabilityForm.values && availabilityForm.values.timeUnit;
    const isLockSharedFromOtherDomain = lock && lock.sharedFromHostname;
    const canEditLock = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], PERMISSION_ENTITIES.SMART_LOCK);
    const canReadLogs = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.LOG);
    const canReadLocks = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.SMART_LOCK);
    const canSendGatewayNotification = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.ALL], PERMISSION_ENTITIES.GATEWAY_NOTIFICATION);
    const canEnableOfflineFeature = canEditLock && lock.vendor && lock.vendor === VENDORS_LIST.ISEO_ARGO;
    const canSeeLogsTab = canReadLogs && canReadLocks;
    const isFullOnlineGate = lock && lock.configuration && lock.configuration.remoteOpenEnabled;
    const lockGroup = lock && lock.configuration && lock.configuration.group ? lock.configuration.group : '';
    const canBeOpenRemotely = (lock && lock.gatewayId && canSendGatewayNotification) || isFullOnlineGate;
    const canEnableOfficeMode = canConfigureOfficeMode(lock) && !isLockSharedFromOtherDomain;
    const officeModeFormValues = officeModeForm && officeModeForm.values;
    const is12HoursFormat = language === LANGUAGES.ENGLISH;
    const lockSensor = lock.accessories ? _.find(lock.accessories, accessory => accessory.type === ACCESSORIES_TYPES.DOOR_SENSOR) : null;
    const doorStatusVariables = lockSensor && lockSensor.value && SMARTLOCK_DOOR_STATUS_MAP[lockSensor.value] ? SMARTLOCK_DOOR_STATUS_MAP[lockSensor.value] : SMARTLOCK_DOOR_STATUS_MAP[SMARTLOCK_DOOR_STATUS.UNKNOWN];
    const showDoorStatus = lockSensor && doorStatusVariables;
    const areLocksCustomFieldActive = dispatch(UtilsActions.areLocksCustomFieldsPresent());
    const isHyperGateFlex = lock && lock.gateway && lock.gateway.highFlex;

    return (
      <MuiThemeProvider theme={theme}>
        <div>
          <div className="card card-noHover" style={{ backgroundColor: '#F8F9FA', position: 'relative', marginLeft: 20, marginRight: 20, padding: 15, marginTop: 10, marginBottom: 20 }}>
            <div style={{ marginBottom: 10 }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  {lock.model ? (
                    <img alt="smartlock" className="lock-icon" style={{ marginTop: 2, width: 50, height: 50, marginRight: 15 }} src={getLockImage(lock.model)} />
                  ) : null}
                  <div>
                    <h2 style={{ color: '#3f3f3f', fontWeight: 'bold', marginTop: 5, marginBottom: 5 }}>
                      <LockNameInlineForm
                        onValidateForm={() => dispatch(submit('LockNameInlineForm'))}
                        onSubmit={values => this.onModifyLockName(values)}
                        disabled={!canEditLock || isLockSharedFromOtherDomain}
                        hideEditIcon={areLocksCustomFieldActive}
                      />
                    </h2>
                    <CustomFieldsDisplayView
                      containerStyle={{ marginTop: 10, marginBottom: 10, marginLeft: -5 }}
                      customFields={lock.customFields}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {subcompanies && !_.isEmpty(subcompanies) ? (
                  <MDButton
                    title={<Entity entity="shareSmartLock" />}
                    containerStyle={{ margin: 0, marginRight: 20 }}
                    icon={<ShareIconCustom style={{ width: 15, color: 'white', marginLeft: 10, marginTop: 0 }} />}
                    style={{ minHeight: 30 }}
                    backgroundColor={BLUE}
                    onClick={() => onOpenLockSharingSettings(lock)}
                  />
                 ) : null}
                  {showDoorStatus ? (
                    <div style={{ margin: 0, display: 'flex', marginBottom: 5, marginRight: 20 }}>
                      <IconButton onClick={() => this.onRefreshStatusSensor(lock, lockSensor)}>
                        <RefreshIcon style={{ fontSize: 21 }} />
                      </IconButton>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {doorStatusVariables.icon}
                        <Entity key={doorStatusVariables.title} style={{ fontSize: 17, marginLeft: 5, fontWeight: 'bold', color: doorStatusVariables.color }} entity={doorStatusVariables.title} />
                      </div>
                    </div>
                  ) : null}
                  {areLocksCustomFieldActive && canEditLock && !isLockSharedFromOtherDomain ? (
                    <IconButton onClick={() => this.onOpenLockCustomFieldForm(lock)}>
                      <Edit style={{ fontSize: 22 }} />
                    </IconButton>
                  ) : null}
                </div>
              </div>
              {/* {lock && lock.id && lock.lockTag ? <DefaultTagSection tag={lock.lockTag} /> : null} */}
              {lockGroup ? (
                <div style={{ display: 'flex', marginTop: 5, flexDirection: 'row', alignItems: 'center' }}>
                  <LockGroupIcon style={{ width: 19, color: '#3f3f3f', marginRight: 5 }} />
                  <h5 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}>{<Entity entity="lockBelongToGroup" data={{ lockGroup }} />}</h5>
                </div>
              ) : null}
              {lock.subcompanies && !_.isEmpty(lock.subcompanies) ? (
                _.map(lock.subcompanies, subcompany => (
                  <div style={{ display: 'flex', marginTop: 15, flexDirection: 'row', alignItems: 'center' }}>
                    <ShareOutlined style={{ width: 19, color: '#3f3f3f', marginRight: 5 }} />
                    <h5 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}>{<Entity entity="lockSharedWithSpecificDomain" data={{ subcompany: subcompany.plantName }} />}</h5>
                  </div>
                ))
              ) : null}
              {lock.sharedFromHostname ? (
                <div style={{ display: 'flex', marginTop: 6, flexDirection: 'row', alignItems: 'center' }}>
                  <ShareOutlined style={{ width: 19, color: '#3f3f3f', marginRight: 5 }} />
                  <h5 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}>{<Entity entity="lockSharedFromSpecificDomain" data={{ subcompany: formatDomainHostNameToFriendlyName(lock.sharedFromHostname) }} />}</h5>
                </div>
              ) : null}
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
              {lock.lockHasCamera && lock.cameraId ? (
                <div style={{ display: 'flex', marginTop: 5, flexDirection: 'row', alignItems: 'center' }}>
                  <MDButton
                    title="LIVE"
                    containerStyle={{ margin: 0 }}
                    icon={<CameraIconCustom style={{ width: 15, color: 'white', marginLeft: 10 }} />}
                    style={{ minHeight: 22, borderRadius: 5 }}
                    onClick={() => onShowCameraFeed(lock.cameraId)}
                  />
                </div>
              ) : null}
              {lock && lock.locationMedia ? (
                <div style={{ display: 'flex', marginTop: 5, marginLeft: 10, flexDirection: 'row', alignItems: 'center' }}>
                  <MDButton
                    title={<Entity entity="map" />}
                    containerStyle={{ margin: 0 }}
                    backgroundColor={BLUE}
                    icon={<MapIconCustom style={{ width: 15, color: 'white', marginLeft: 10 }} />}
                    style={{ minHeight: 22, borderRadius: 5 }}
                    onClick={() => onShowLockMap(lock)}
                  />
                </div>
              ) : null}
              </div>
            </div>
            <LockTagsForm
              onLockInputChange={value => this.onFilterLockTags(value)}
              onCreateTag={(values) => this.onCreateTag(values)}
              onEditTag={(values) => this.onEditTag(values)}
              onShowTagModal={(values, mode) => this.onShowTagModal(values, mode)}
              onSubmit={values => this.onUpdateLockTags(values)}
            />
            {isHyperGateFlex ? (
              <div style={{ marginTop: 20 }}>
                <MDSwitchView
                  label={<Entity key={isGuestRemoteOpenEnabled} entity="enableGuestFlexRemoteOpening" />}
                  subtitle={<Entity entity="enableGuestFlexRemoteOpeningDescription" />}
                  checked={isGuestRemoteOpenEnabled}
                  titleStyle={{ fontWeight: isGuestRemoteOpenEnabled ? 'bold' : 'normal', color: isGuestRemoteOpenEnabled ? GREEN : '#3f3f3f' }}
                  subtitleStyle={{ marginLeft: 46, marginTop: -10 }}
                  onSelect={selected => this.onToggleGuestFlexRemoteOpeningValue(selected)}
                />
              </div>
            ) : null}
            {canBeOpenRemotely ? (
              <MDButton
                containerStyle={{ margin: 0, marginTop: 20 }}
                onClick={e => this.onOpenLock(e)}
                icon={<LockOpen style={{ color: 'white', marginLeft: 10, fontSize: 15 }} />}
                title={<Entity entity="openLockRemote" />}
              />) : null}
          </div>
          <AppBar position="sticky" color="default">
            <Tabs
              value={selectedTab}
              textColor="primary"
              indicatorColor="primary"
              scrollable
              style={{ paddingLeft: 30 }}
              onChange={(e, index) => this.onSelectTab(index)}
              TabIndicatorProps={{
                style: { display: 'none' }
              }}
            >
              <Tab icon={<ClipboardIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="locksInformation" /></h5>} />
              {canSeeLogsTab ? <Tab icon={<SmartLocksEventsIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="guestSmartLocksLogs" /></h5>} /> : null}
              {canEnableOfflineFeature ? <Tab icon={<OfflineIcon style={{ fontSize: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="offlineAvailability" /></h5>} /> : null}
              {canEnableOfficeMode ? <Tab icon={<OfficeModeIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="officeMode" /></h5>} /> : null}
              {lockSensor ? <Tab icon={<ChartMultilineIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="sensorHistory" /></h5>} /> : null}
              {/* <Tab icon={<MessageAlertIconCustom style={{ width: 26 }} />} label={<h5 style={{ margin: 0, fontWeight: 'bold' }}><Entity entity="troubleshooting" /></h5>} /> */}
            </Tabs>
          </AppBar>
          <div style={{ paddingLeft: 15, paddingRight: 15, marginTop: 0 }}>
            {selectedTab === 0 ? (
              <div style={{ marginTop: 0, marginBottom: 100, padding: 15 }}>
                <LockTechnicalDetailsView
                  lock={lock}
                  onGatewayClick={gatewayId => this.onGatewayClick(gatewayId)}
                />
              </div>
            ) : null}
            {canSeeLogsTab && selectedTab === 1 && (
              <div style={{ padding: 20 }}>
                <SmartLocksEvents
                  hideFilters
                  avoidReloading
                  containerStyle={{ marginTop: -20 }}
                  listContainerStyle={{ marginTop: 0, marginLeft: -20, marginBottom: 0, paddingLeft: 0 }}
                />
              </div>
            )}
            {canEnableOfflineFeature && (selectedTab === 2 || (!canSeeLogsTab && selectedTab === 1)) ? (
              <div style={{ marginTop: 0, marginBottom: 100 }}>
                <LockOfflineAvailabilityForm
                  selectedTimeUnit={selectedTimeUnit}
                  selectedMinutes={selectedMinutes}
                  isAvailableOffline={isLockAvailableOffline}
                  onSubmit={values => this.onUpdateLockOfflineAvailability(values)}
                  onOfflineAvailabilityFormSubmit={() => dispatch(submit('LockOfflineAvailabilityForm'))}
                />
              </div>
            ) : null}
            {canEnableOfficeMode && (selectedTab === 3 || (canSeeLogsTab && !canEnableOfflineFeature && selectedTab === 2) || (!canSeeLogsTab && selectedTab === 2) || (!canSeeLogsTab && !canEnableOfflineFeature && selectedTab === 1)) ? (
              <div style={{ marginTop: 0, marginBottom: 100, paddingRight: 5 }}>
                <LockOfficeModeForm
                  onSubmit={data => this.onSaveProgramDataRequest(data)}
                  formData={officeModeFormValues}
                  onInitializeNewOfficeModeSubProgram={subProgramId => this.onInitializeNewOfficeModeSubProgram(subProgramId)}
                  onDeleteSubProgramRequest={subProgramId => this.onDeleteSubProgramRequest(subProgramId)}
                  onRefreshOfficeModeProgramStatus={() => this.onRefreshOfficeModeProgramStatus()}
                  onOpenOfficeModeInfoModal={() => this.onOpenOfficeModeInfoModal()}
                  is12HoursFormat={is12HoursFormat}
                />
                {canEditLock ? (
                  <div style={{ backgroundColor: 'white', position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}>
                    <MDButton
                      title={<Entity entity="saveOfficeModePrograms" />}
                      disabled={!canEditLock}
                      style={{ height: 45, borderRadius: 0 }}
                      containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
                      onClick={() => dispatch(submit('LockOfficeModeForm'))}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            {lockSensor && (selectedTab === 4 || (canSeeLogsTab && !canEnableOfflineFeature && canEnableOfficeMode && selectedTab === 3) || (canSeeLogsTab && canEnableOfflineFeature && !canEnableOfficeMode && selectedTab === 3) || (canSeeLogsTab && !canEnableOfflineFeature && !canEnableOfficeMode && selectedTab === 2) || (!canSeeLogsTab && !canEnableOfflineFeature && !canEnableOfficeMode && selectedTab === 1)) ? (
              <div style={{ marginTop: 0, marginBottom: 100, paddingRight: 5 }}>
                <LockSensorHistory
                  doorSensor={lockSensor}
                />
              </div>
            ) : null}
            {/* {selectedTab === 5 || (canSeeLogsTab && canEnableOfflineFeature && canEnableOfficeMode && !lockSensor && selectedTab === 4)|| (canSeeLogsTab && !canEnableOfflineFeature && canEnableOfficeMode && !lockSensor && selectedTab === 4) || (canSeeLogsTab && canEnableOfflineFeature && !canEnableOfficeMode && !lockSensor && selectedTab === 3) || (canSeeLogsTab && !canEnableOfflineFeature && canEnableOfficeMode && !lockSensor && selectedTab === 3) || (canSeeLogsTab && !canEnableOfflineFeature && !canEnableOfficeMode && lockSensor && selectedTab === 3) || (canSeeLogsTab && !canEnableOfflineFeature && !canEnableOfficeMode && !lockSensor && selectedTab === 2) || (!canSeeLogsTab && !canEnableOfflineFeature && !canEnableOfficeMode && !lockSensor && selectedTab === 0) ? (
              <div style={{ marginTop: 0, marginBottom: 100, paddingRight: 5 }}>
                <LockTroubleShootingView
                  article={lock && lock.troubleshootingArticle}
                />
              </div>
            ) : null} */}
          </div>
          <BasicModal
            ref={(m) => { this.newTagModal = m; }}
            hideCloseButton
            body={
              <div>
                <div style={{ display: 'flex', marginBottom: 20, alignItems: 'center', justifyContent: 'space-between' }}>
                  <h3 style={{ fontWeight: 'bold', margin: 0, color: '#3f3f3f' }} ><Entity entity="newTag" /></h3>
                  <IconButton onClick={() => this.newTagModal.close()}>
                    <Cancel style={{ color: '#e95841', fontSize: 35 }} />
                  </IconButton>
                </div>
                <TagForm
                  tagType="lock"
                  doAsyncValidate
                  categories={lockTags.categories}
                  onSubmit={values => this.onCreateTag(values)}
                />
                <MDButton
                  containerStyle={{ marginTop: 30 }}
                  title={<Entity entity="save" />}
                  onClick={() => dispatch(submit('TagForm'))}
                />
              </div>
            }
          />
          <BasicModal
            ref={(m) => { this.editTagModal = m; }}
            hideCloseButton
            body={
              <div>
                <div style={{ display: 'flex', marginBottom: 20, alignItems: 'center', justifyContent: 'space-between' }}>
                  <h3 style={{ fontWeight: 'bold', margin: 0, color: '#3f3f3f' }} ><Entity entity="editTag" /></h3>
                  <IconButton onClick={() => this.editTagModal.close()}>
                    <Cancel style={{ color: '#e95841', fontSize: 35 }} />
                  </IconButton>
                </div>
                <TagForm
                  tagType="lock"
                  doAsyncValidate={false}
                  categories={lockTags.categories}
                  onSubmit={values => this.onEditTag(values)}
                />
                <MDButton
                  containerStyle={{ marginTop: 30 }}
                  title={<Entity entity="save" />}
                  onClick={() => dispatch(submit('TagForm'))}
                />
              </div>
            }
          />
          <OperationalView
            themeName={themeName}
            isVisible={isCustomFieldFormOpen}
            onClose={() => this.setState({ isCustomFieldFormOpen: false })}
            operationaViewStyle={{ zIndex: 10000 }}
            style={{ margin: 0, padding: 0 }}
            title={<Entity entity="sectionTitle" data={{ name: 'modifyLock' }} />}
          >
            <LockCustomFieldsForm
              onSubmit={values => this.onUpdateLockCustomFields(values)}
            />
            {hasFormWritePermission(PERMISSION_ENTITIES.SMART_LOCK, true) ? (
              <MDButton
                title={<Entity entity="save" />}
                containerStyle={{ position: 'absolute', left: 0, right: 0, bottom: 0, width: '100%', margin: 0 }}
                style={{ height: 45, borderRadius: 0 }}
                onClick={() => dispatch(submit('LockCustomFieldsForm'))}
              />
            ) : null}
          </OperationalView>
        </div>
      </MuiThemeProvider>
    );
  }
} 

export default LocksOperationalSection;
