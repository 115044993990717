module.exports = {
  SAVE_COMMUNICATIONS: 'SAVE_COMMUNICATIONS',
  APPEND_COMMUNICATIONS: 'APPEND_COMMUNICATIONS',
  SAVE_COMMUNICATION: 'SAVE_COMMUNICATION',
  SET_COMMUNICATIONS_PAGINATION_DATA: 'SET_COMMUNICATIONS_PAGINATION_DATA',
  SELECT_COMMUNICATION: 'SELECT_COMMUNICATION',
  SET_COMMUNICATIONS_FILTER: 'SET_COMMUNICATIONS_FILTER',
  UPDATE_COMMUNICATION: 'UPDATE_COMMUNICATION',
  RESET_COMMUNICATIONS_FILTERS: 'RESET_COMMUNICATIONS_FILTERS',
  RESET_COMMUNICATIONS_DATA: 'RESET_COMMUNICATIONS_DATA',
  RESET_COMMUNICATION_PAGINATION_DATA: 'RESET_COMMUNICATION_PAGINATION_DATA',
  SET_COMMUNICATIONS_OPERATIONAL_MODE: 'SET_COMMUNICATIONS_OPERATIONAL_MODE',
  SAVE_COMMUNICATION_USERS: 'SAVE_COMMUNICATION_USERS',
  APPEND_COMMUNICATION_USERS: 'APPEND_COMMUNICATION_USERS',
  SAVE_COMMUNICATION_USERS_PAGINATION_DATA: 'SAVE_COMMUNICATION_USERS_PAGINATION_DATA',
  SAVE_COMMUNICATION_USERS_TOTAL: 'SAVE_COMMUNICATION_USERS_TOTAL',
  RESET_COMMUNICATION_USERS_FILTERS: 'RESET_COMMUNICATION_USERS_FILTERS',
  SET_COMMUNICATION_USERS_FILTER: 'SET_COMMUNICATION_USERS_FILTER',
};
