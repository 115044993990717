// @ts-nocheck
import UserIcon from '@material-ui/icons/AccountBox';
import Alarm from '@material-ui/icons/Alarm';
import RefreshIcon from '@material-ui/icons/Autorenew';
import BanIcon from '@material-ui/icons/Backspace';
import BatteryLow from '@material-ui/icons/Battery20';
import BluetoothIcon from '@material-ui/icons/BluetoothConnected';
import UserEditIcon from '@material-ui/icons/BorderColor';
import InstallationIcon from '@material-ui/icons/Build';
import CancelIcon from '@material-ui/icons/Cancel';
import GatewayIcon from '@material-ui/icons/CastOutlined.js';
import CheckIcon from '@material-ui/icons/CheckCircle';
import CloudDone from '@material-ui/icons/CloudDone';
import CloudDownload from '@material-ui/icons/CloudDownload';
import CloudDeny from '@material-ui/icons/CloudQueue';
import CardIcon from '@material-ui/icons/CreditCard';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import ExitIcon from '@material-ui/icons/ExitToApp';
import FingerPrint from '@material-ui/icons/Fingerprint';
import CloseIcon from '@material-ui/icons/HighlightOff';
import LockGroupIcon from '@material-ui/icons/Layers';
import LinkIcon from '@material-ui/icons/Link';
import LockIcon from '@material-ui/icons/LockOpen';
import LockCloseIcon from '@material-ui/icons/NoEncryption';
import BlockIcon from '@material-ui/icons/NotInterested';
import UserAddIcon from '@material-ui/icons/PersonAdd';
import SmartphoneIcon from '@material-ui/icons/PhoneIphone';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import SettingsIcon from '@material-ui/icons/Settings';
import ChartIcon from '@material-ui/icons/ShowChart';
import StarIcon from '@material-ui/icons/Stars';
import SecureIcon from '@material-ui/icons/VerifiedUser';
import KeyIcon from '@material-ui/icons/VpnKey';
import WarningIcon from '@material-ui/icons/Warning';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import AccessCloudIconCustom from '../components/CustomIcons/AccessCloudIconCustom.jsx';
import AddonIconCustom from '../components/CustomIcons/AddonIconCustom.jsx';
import AuthTechnologiesIconCustom from '../components/CustomIcons/AuthTechnologiesIconCustom.jsx';
import BimTechnologiesIconCustom from '../components/CustomIcons/BimTechnologiesIconCustom.jsx';
import BookingIconCustom from '../components/CustomIcons/BookingIconCustom.jsx';
import BuildingTechnologiesIconCustom from '../components/CustomIcons/BuildingTechnologiesIconCustom.jsx';
import CalendarEditIconCustom from '../components/CustomIcons/CalendarEditIconCustom.jsx';
import CounterIconCustom from '../components/CustomIcons/CounterIconCustom.jsx';
import CustomDesignIconCustom from '../components/CustomIcons/CustomDesignIconCustom.jsx';
import DoorIconCustom from '../components/CustomIcons/DoorIconCustom.jsx';
import DoorOpenIconCustom from '../components/CustomIcons/DoorOpenIconCustom.jsx';
import IntegrationIconCustom from '../components/CustomIcons/IntegrationIconCustom.jsx';
import KeyOutlinedIconCustom from '../components/CustomIcons/KeyOutlinedIconCustom.jsx';
import OfficeModeIconCustom from '../components/CustomIcons/OfficeModeIconCustom.jsx';
import PMSIconCustom from '../components/CustomIcons/PMSIconCustom.jsx';
import PluginActiveIconCustom from '../components/CustomIcons/PluginActiveIconCustom.jsx';
import PropertyManagementIconCustom from '../components/CustomIcons/PropertyManagementIconCustom.jsx';
import RfidBluetoothIconCustom from '../components/CustomIcons/RfidBluetoothIconCustom.jsx';
import SensorAccessoryIconCustom from '../components/CustomIcons/SensorAccessoryIconCustom.jsx';
import ServicesIconCustom from '../components/CustomIcons/ServicesIconCustom.jsx';
import SmartLockAddIconCustom from '../components/CustomIcons/SmartLockAddIconCustom.jsx';
import SmartLocksEventsIconCustom from '../components/CustomIcons/SmartLocksEventsIconCustom.jsx';
import TenantAppIconCustom from '../components/CustomIcons/TenantAppIconCustom.jsx';
import TextEditIconCustom from '../components/CustomIcons/TextEditIconCustom.jsx';
import { isLicenseBasic, isLicenseEngine, isLicenseRFID, isV364BaseSubscription } from './utils.js';
import TimeAttendanceIconCustom from '../components/CustomIcons/TimeAttendanceIconCustom.jsx';
import EmailSendIconCustom from '../components/CustomIcons/EmailSendIconCustom.jsx';
import ParcelPickedupIconCustom from '../components/CustomIcons/ParcelPickedupIconCustom.jsx';
import ParcelDeliveredIconCustom from '../components/CustomIcons/ParcelDeliveredIconCustom.jsx';
import CloseCircleIconCustom from '../components/CustomIcons/CloseCircleIconCustom.jsx';
import DeleteOutlineIconCustom from '../components/CustomIcons/DeleteOutlineIconCustom.jsx';
import CalendarClockIconCustom from '../components/CustomIcons/CalendarClockIconCustom.jsx';
import AddCircleOutlineIconCustom from '../components/CustomIcons/AddCircleOutlineIconCustom.jsx';
import SupportAgentIconCustom from '../components/CustomIcons/SupportAgentIconCustom.jsx';

export const BLUE = '#1B97C2';
export const DARK_BLUE = '#157495';
export const RED = '#D32F2F';
export const ORANGE = '#FFA726';
export const GREEN = '#4DB6AC';

export const ROLES = {
  MANAGER: 'ROLE_MANAGER',
  GUEST: 'ROLE_GUEST',
  INSTALLER: 'ROLE_INSTALLER',
};

export const VIEW_MODES = {
  CARDS: 'CARDS',
  TABLE: 'TABLE',
  CALENDAR: 'CALENDAR',
};

export const SOFIA_CONTACTS = {
  INFORMATION: 'info@sofialocks.com',
  SUPPORT: 'support@sofialocks.com',
};

export const SOFIA_SUPPORT_MANAGERS_EMAILS = [
  'support@sofialocks.com',
  'support+readonly@sofialocks.com',
];

export const LUCKEY_MANUALS_LINK = 'https://help-center.sofialocks.com/luckey-manuals/';
export const LUCKEY_ONLINE_KB_LINK = 'https://help-center.sofialocks.com/';
export const LUCKEY_SERVICE_STATUS_LINK = 'https://sofialocks-luckey.freshstatus.io/';

export const ISEO_ZENDESK_GROUP_ID = {
  production: '12493713416093',
  staging: '',
}

export const DEVELOPERS_URL = {
  production: 'https://api-developers.jago.cloud',
  staging: 'https://api-developers.staging.jago.cloud'
}

export const MATCH_TAG_MODE = {
  EVERY_TAG: 'EVERY_TAG',
  AT_LEAST_ONE_TAG: 'AT_LEAST_ONE_TAG',
};

export const RESOURCE_CAPACITY_TYPE = {
  ONE: 'ONE',
  MULTI: 'MULTI',
};

export const RESOURCE_CAPACITY_SLOTS = {
  MINUTES_15: {
    label: 'minutes15',
    value: 15,
  },
  MINUTES_30: {
    label: 'minutes30',
    value: 30,
  },
  MINUTES_60: {
    label: 'minutes60',
    value: 60,
  },
};

export const AGREEMENT_TYPES = {
  TERM: 'TERM',
  PRIVACY: 'PRIVACY',
  PRIVACY_THIRD_PARTY: 'PRIVACY_THIRD_PARTY',
};

export const AGREEMENT_DEFAULT_LINKS = {
  TERM: 'https://jago.cloud/legal/luckey/host/terms/v2',
  PRIVACY: 'https://jago.cloud/legal/luckey/host/privacy/v2',
};

export const AMPLITUDE_API_KEY = "15c98027cee7289df7f2aab11681b032";

export const LOCKS_MODELS = {
  'IMArgoLib.Stylos': 'HyperLock Stylos',
  'IMArgoLib.Libra': 'HyperLock Libra',
  'IMArgoLib.X1revo': 'HyperLock X1R',
  'IMArgoLib.Aries': 'HyperLock Aries',
  STYLOS_DISPLAY: 'HyperLock Stylos Smart',
  STYLOS: 'HyperLock Stylos',
  LIBRA: 'HyperLock Libra',
  X1REVO: 'HyperLock X1R',
  F9000: 'F9000',
  ARIES: 'HyperLock Aries',
  MAYA: 'HyperLock MA1A',
  LOCKER: 'HyperLock Smart Locker',
  SMART_RELAY: 'HyperLock Smart Relay',
  MULLION: 'HyperLock 1NCA',
  MULLION_KB: 'HyperLock 1NCA Keyboard',
  MULLION_RELAY: 'HyperLock 1NCA Relay',
  MULLION_RELAY_KB: 'HyperLock 1NCA Relay Keyboard',
  EMOTION_SMART: 'HyperLock Emotion Smart'
};

export const VENDORS_LIST = {
  ISEO_ARGO: 'ISEO_ARGO',
  ISEO_VEGA: 'ISEO_VEGA',
};

export const HYPERGATE_LANDING_PAGE = {
  it: 'https://www.sofialocks.com/it/tecnologia/hypergate/',
  en: 'https://www.sofialocks.com/en/technology/hypergate/',
};

export const THEMES = [
  'default',
  'red',
  'green',
  'blue',
  'purple',
  'orange',
  'dark',
];

export const LOCKER_RESERVATION_HISTORY_ACTIONS = {
  CREATED: 'CREATED',
  CREATED_DELIVERED: 'CREATED_DELIVERED',
  DATE_UPDATED: 'DATE_UPDATED',
  DELIVERING: 'DELIVERING',
  CANCELLED: 'CANCELLED',
  DELETED_FROM_MANAGER: 'DELETED_FROM_MANAGER',
  EXPIRED: 'EXPIRED',
  DELIVERED: 'DELIVERED',
  PICKED_UP: 'PICKED_UP',
  REMIND: 'REMIND',
};

export const LOCKER_RESERVATION_HISTORY_ACTIONS_MAP = {
  [LOCKER_RESERVATION_HISTORY_ACTIONS.CREATED]: {
    label: 'lockerReservationHistoryActionCREATED',
    icon: <AddCircleOutlineIconCustom style={{ width: 24, color: BLUE, marginLeft: 10, marginRight:10 }} />,
  },
  [LOCKER_RESERVATION_HISTORY_ACTIONS.CREATED_DELIVERED]: {
    label: 'lockerReservationHistoryActionCREATED_DELIVERED',
    icon: <ParcelDeliveredIconCustom style={{ width: 24, color: BLUE, marginLeft: 10, marginRight:10 }} />,
  },
  [LOCKER_RESERVATION_HISTORY_ACTIONS.DATE_UPDATED]: {
    label: 'lockerReservationHistoryActionDATE_UPDATED',
    icon: <CalendarClockIconCustom style={{ width: 24, color: BLUE, marginLeft: 10, marginRight:10 }} />,
  },
  [LOCKER_RESERVATION_HISTORY_ACTIONS.DELIVERING]: {
    label: 'lockerReservationHistoryActionDELIVERING',
    icon: <ParcelDeliveredIconCustom style={{ width: 24, color: BLUE, marginLeft: 10, marginRight:10 }} />,
  },
  [LOCKER_RESERVATION_HISTORY_ACTIONS.CANCELLED]: {
    label: 'lockerReservationHistoryActionCANCELLED',
    icon: <DeleteOutlineIconCustom style={{ width: 24, color: RED, marginLeft: 10, marginRight:10 }} />,
  },
  [LOCKER_RESERVATION_HISTORY_ACTIONS.DELETED_FROM_MANAGER]: {
    label: 'lockerReservationHistoryActionDELETED_FROM_MANAGER',
    icon: <DeleteOutlineIconCustom style={{ width: 24, color: RED, marginLeft: 10, marginRight:10 }} />,
  },
  [LOCKER_RESERVATION_HISTORY_ACTIONS.EXPIRED]: {
    label: 'lockerReservationHistoryActionEXPIRED',
    icon: <CloseCircleIconCustom style={{ width: 24, color: RED, marginLeft: 10, marginRight:10 }} />,
  },
  [LOCKER_RESERVATION_HISTORY_ACTIONS.DELIVERED]: {
    label: 'lockerReservationHistoryActionDELIVERED',
    icon: <ParcelDeliveredIconCustom style={{ width: 24, color: BLUE, marginLeft: 10, marginRight:10 }} />,
  },
  [LOCKER_RESERVATION_HISTORY_ACTIONS.PICKED_UP]: {
    label: 'lockerReservationHistoryActionPICKED_UP',
    icon: <ParcelPickedupIconCustom style={{ width: 24, color: BLUE, marginLeft: 10, marginRight:10 }} />,
  },
  [LOCKER_RESERVATION_HISTORY_ACTIONS.REMIND]: {
    label: 'lockerReservationHistoryActionREMIND',
    icon: <EmailSendIconCustom style={{ width: 24, color: BLUE, marginLeft: 10, marginRight:10 }} />,
  },
};

export const LOCKER_RESERVATION_STATUSES = {
  BOOKED: 'BOOKED',
  DELIVERING: 'DELIVERING',
  DELIVERED: 'DELIVERED',
  DELETED_FROM_MANAGER: 'DELETED_FROM_MANAGER',
  PICKED_UP: 'PICKED_UP',
  CANCELLED: 'CANCELLED',
  EXPIRED: 'EXPIRED',
};

export const LOCKER_RESERVATION_ERRORS = {
  '12102': 'USER_NOT_ALLOWED',
  '12805': 'LOCKER_FULL',
}

export const LOCKER_RESERVATION_STATUSES_PARAMETERS = {
  [LOCKER_RESERVATION_STATUSES.BOOKED]: {
    value: LOCKER_RESERVATION_STATUSES.BOOKED,
    color: 'green',
    string: 'lockerReservationBooked',
  },
  [LOCKER_RESERVATION_STATUSES.DELIVERING]: {
    value: LOCKER_RESERVATION_STATUSES.DELIVERING,
    color: '#ef6c00',
    string: 'lockerReservationDelivering',
  },
  [LOCKER_RESERVATION_STATUSES.DELIVERED]: {
    value: LOCKER_RESERVATION_STATUSES.DELIVERED,
    color: '#ef6c00',
    string: 'lockerReservationDelivered',
  },
  [LOCKER_RESERVATION_STATUSES.DELETED_FROM_MANAGER]: {
    value: LOCKER_RESERVATION_STATUSES.DELETED_FROM_MANAGER,
    color: 'red',
    string: 'lockerReservationDeletedFromManager',
  },
  [LOCKER_RESERVATION_STATUSES.PICKED_UP]: {
    value: LOCKER_RESERVATION_STATUSES.PICKED_UP,
    color: 'grey',
    string: 'lockerReservationPickedUp',
  },
  [LOCKER_RESERVATION_STATUSES.CANCELLED]: {
    value: LOCKER_RESERVATION_STATUSES.CANCELLED,
    color: 'red',
    string: 'lockerReservationCancelled',
  },
  [LOCKER_RESERVATION_STATUSES.EXPIRED]: {
    value: LOCKER_RESERVATION_STATUSES.EXPIRED,
    color: 'red',
    string: 'lockerReservationExpired',
  },
};

export const RESERVATION_STATUSES = {
  ACTIVE: 'ACTIVE',
  NOT_YET_ACTIVE: 'NOT_YET_ACTIVE',
  EXPIRED: 'EXPIRED',
  NO_TAGS: 'NO_TAGS',
  FAILED: 'FAILED',
};

export const INVITATION_STATUSES = {
  ACTIVE: 'ACTIVE',
  NOT_YET_ACTIVE: 'NOT_YET_ACTIVE',
  EXPIRED: 'EXPIRED',
  NO_TAGS: 'NO_TAGS',
};

export const RESERVATION_STATUSES_PARAMETERS = {
  [RESERVATION_STATUSES.ACTIVE]: {
    color: 'green',
    string: 'reservationActive',
  },
  [RESERVATION_STATUSES.NOT_YET_ACTIVE]: {
    color: '#ef6c00',
    string: 'reservationEarly',
  },
  [RESERVATION_STATUSES.EXPIRED]: {
    color: 'red',
    string: 'reservationExpired',
  },
  [RESERVATION_STATUSES.NO_TAGS]: {
    color: 'red',
    string: 'credentialRuleInvalid',
  },
  [RESERVATION_STATUSES.NO_TAGS]: {
    color: 'red',
    string: 'credentialRuleFailed',
  },
};

export const INVITATION_STATUS_PARAMETERS = {
  [INVITATION_STATUSES.ACTIVE]: {
    color: 'green',
    string: 'invitationActive',
  },
  [INVITATION_STATUSES.NOT_YET_ACTIVE]: {
    color: '#ef6c00',
    string: 'invitationEarly',
  },
  [INVITATION_STATUSES.EXPIRED]: {
    color: 'red',
    string: 'invitationExpired',
  },
  [INVITATION_STATUSES.NO_TAGS]: {
    color: 'red',
    string: 'credentialRuleInvalid',
  },
};

export const CREDENTIAL_TYPES = {
  SMART: 'SMART',
  STANDARD: 'STANDARD',
};

export const APP_STORE_LINKS = {
  ios: 'https://itunes.apple.com/it/app/luckey/id1345062313?mt=8',
  android: 'https://play.google.com/store/apps/details?id=com.sofialocks.luckey',
};

export const JAGO_TOOLS_APP_STORE_LINKS = {
  ios: 'https://apps.apple.com/it/app/jago-tools/id1220045778',
  android: 'https://play.google.com/store/apps/details?id=it.sofia.jago_tools&hl=it',
};

export const COBOT_ACTIVATION_HELP_LINK = 'https://www.cobot.me/oauth2_clients/new';

export const LOGS = {
  USER_CREATION: 'USER_CREATION',
  USER_UPDATE: 'USER_UPDATE',
  CREDENTIAL_RULE_CREATION: 'CREDENTIAL_RULE_CREATION',
  CREDENTIAL_RULE_DELETION: 'CREDENTIAL_RULE_DELETION',
  ACS_OPEN_OK: 'ACS_OPEN_OK',
  ACS_OPEN_NOT_AUTHORIZED: 'ACS_OPEN_NOT_AUTHORIZED',
};

export const LOGS_ENTITY_MESSAGE = {
  USER_CREATION: 'userCreatedLog',
  USER_UPDATE: 'userUpdatedLog',
  CREDENTIAL_RULE_CREATION: 'ruleCreatedLog',
  CREDENTIAL_RULE_DELETION: 'ruleDeletedLog',
  ACS_OPEN_OK: 'acsOpenedLog',
  ACS_OPEN_NOT_AUTHORIZED: 'acsOpenedNotAuthorizedLog',
};

export const BATTERY_IMAGES = {
  0: '/imgs/common/batteryIcons/Batteria_Empty.png',
  25: '/imgs/common/batteryIcons/Batteria_Very_low.png',
  50: '/imgs/common/batteryIcons/Batteria_low.png',
  75: '/imgs/common/batteryIcons/Batteria_Mid_Full.png',
  100: '/imgs/common/batteryIcons/Batteria_Full.png',
};

export const RESOURCE_IMAGE_MAP = {
  resource1: '/imgs/common/resources/resource_0.png',
  resource2: '/imgs/common/resources/resource_1.png',
  resource3: '/imgs/common/resources/resource_2.png',
  resource4: '/imgs/common/resources/resource_3.png',
  resource5: '/imgs/common/resources/resource_4.png',
  resource6: '/imgs/common/resources/resource_5.png',
  resource7: '/imgs/common/resources/resource_6.png',
  resource8: '/imgs/common/resources/resource_7.png',
};

export const LOGS_ICONS = {
  [LOGS.USER_CREATION]: <UserAddIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  [LOGS.USER_UPDATE]: <UserEditIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  [LOGS.CREDENTIAL_RULE_CREATION]: <SecureIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  [LOGS.CREDENTIAL_RULE_DELETION]: <DeleteIcon style={{ fontSize: 30, color: '#3f3f3f' }} />,
  [LOGS.ACS_OPEN_OK]: <CheckIcon style={{ fontSize: 30, color: '#3f3f3f' }} />,
  [LOGS.ACS_OPEN_NOT_AUTHORIZED]: <BlockIcon style={{ fontSize: 30, color: '#3f3f3f' }} />,
};

export const LANGUAGES = {
  ENGLISH: 'ENGLISH',
  ITALIAN: 'ITALIAN',
  SPANISH: 'SPANISH',
  GERMAN: 'GERMAN',
  FRENCH: 'FRENCH',
  POLISH: 'POLISH',
  DUTCH: 'DUTCH',
};

export const LANGUAGE_DETAILS = {
  [LANGUAGES.ENGLISH]: { flag: 'United-Kingdom', lang: 'enUS', l20n: 'en-US', moment: 'en-gb', backend: LANGUAGES.ENGLISH, helpCenterLanguage: '', storeLanguagePrefix: 'en' },
  [LANGUAGES.ITALIAN]: { flag: 'Italy', lang: 'it', l20n: 'it', moment: 'it', backend: LANGUAGES.ITALIAN, helpCenterLanguage: 'it', storeLanguagePrefix: 'it' },
  [LANGUAGES.SPANISH]: { flag: 'Spain', lang: 'es', l20n: 'es', moment: 'es', backend: LANGUAGES.SPANISH, helpCenterLanguage: 'es', storeLanguagePrefix: 'es' },
  [LANGUAGES.FRENCH]: { flag: 'France', lang: 'fr', l20n: 'fr', moment: 'fr', backend: LANGUAGES.FRENCH, helpCenterLanguage: 'fr', storeLanguagePrefix: 'fr' },
  [LANGUAGES.POLISH]: { flag: 'Polish', lang: 'pl', l20n: 'pl', moment: 'pl', backend: LANGUAGES.POLISH, helpCenterLanguage: '', storeLanguagePrefix: 'pl' },
  [LANGUAGES.GERMAN]: { flag: 'Germany', lang: 'de', l20n: 'de', moment: 'de', backend: LANGUAGES.GERMAN, helpCenterLanguage: 'de', storeLanguagePrefix: 'de' },
  [LANGUAGES.DUTCH]: { flag: 'Netherland', lang: 'nl', l20n: 'nl', moment: 'nl', backend: LANGUAGES.DUTCH, helpCenterLanguage: 'nl', storeLanguagePrefix: 'nl' },
};

export const LANGUAGE_LOCALES = ['it', 'en-US', 'es', 'fr', 'pl', 'de', 'nl'];


export const SOURCE_TYPE = {
  PLUGGED: 'PLUGGED',
  BATTERY: 'BATTERY',
};

export const MOBILE_UDID_STATES = {
  SET: 'SET',
  NEVER_SET: 'NEVER_SET',
  RESET: 'RESET',
  ALWAYS_VALID: 'ALWAYS_VALID',
};

export const COMPANY_NOTIFICATIONS_GATEWAY_STATES = {
  ON: 'ON',
  OFF: 'OFF',
};

export const COMPANY_NOTIFICATIONS_BATTERY_STATES = {
  ON: 'ON',
  OFF: 'OFF',
};

export const COMPANY_NOTIFICATIONS_BATTERY_LEVELS = {
  L75: '75%',
  L50: '50%',
  L25: '25%',
};

export const COMPANY_NOTIFICATIONS_ATLAS_STATES = {
  ON: 'ON',
  OFF: 'OFF',
};

export const COMPANY_NOTIFICATIONS_REPEAT_STATES = {
  ON: 'ON',
  OFF: 'OFF',
};

export const LICENSES = {
  STANDARD: 'STANDARD',
  PROFESSIONAL: 'PROFESSIONAL',
  UNKNOWN: 'UNKNOWN',
  ENTERPRISE: 'ENTERPRISE',
  LITE: 'BASIC',
};

export const LICENSE_TYPES = {
  LITE: 'BASIC',
  ENTERPRISE: 'ENTERPRISE',
  ENGINE: 'ENGINE',
  RFID: 'RFID',
};

export const TIMED_TAG_STATUSES = {
  VALID: 'VALID',
  NOT_VALID: 'NOT_VALID',
  EXPIRED: 'EXPIRED',
};

export const CREDENTIAL_RULE_STATUSES = {
  UPDATED: 'UPDATED',
  NOT_UPDATED: 'NOT_UPDATED',
};

export const CARDS_CREDENDIAL_RULES_STATUSES = {
  SYNCED: 'SYNCED',
  TO_BE_INSERTED: 'TO_BE_INSERTED',
  FAILED: 'FAILED',
  NOT_COMPATIBLE: 'NOT_COMPATIBLE'
};

export const CARD_TYPES = {
  GENERIC_CARD: 'GENERIC_CARD',
  GENERIC_CARD_DESFIRE: 'GENERIC_CARD_DESFIRE',
  ISEO_CARD: 'ISEO_CARD',
  ISEO_CARD_DESFIRE: 'ISEO_CARD_DESFIRE',
  ISEO_PIN: 'ISEO_PIN',
};

export const GATEWAYS_NOTIFICATION_PAYLOAD_TYPES = {
  SMARTLOCK_OPEN: 'SMARTLOCK_OPEN',
  GATEWAY_PAIRED: 'GATEWAY_PAIRED',
  SMARTLOCKS_UPDATED: 'SMARTLOCKS_UPDATED',
  GATEWAY_RESTART: 'GATEWAY_RESTART',
  CREDENTIALS_UPDATED: 'CREDENTIALS_UPDATED',
  TOGGLE_PASSAGE_MODE: 'TOGGLE_PASSAGE_MODE',
};

export const GATEWAYS_NOTIFICATION_STATUSES = {
  WAITING: 'WAITING',
  IN_TRANSIT: 'IN_TRANSIT',
  RECEIVED: 'RECEIVED',
  APPLIED: 'APPLIED',
  FAILED: 'FAILED',
};

export const GATEWAYS_NOTIFICATION_TYPES = {
  ACTION: 'ACTION',
  CONFIGURATION: 'CONFIGURATION',
  COMMAND: 'COMMAND',
};

export const PRIVATE_UNITS_STATUS = {
  TO_BE_ACTIVATED: 'TO_BE_ACTIVATED',
  ACTIVE: 'ACTIVE',
};

export const ADMIN_EVENTS = {
  USER_CREATION: 'USER_CREATION',
  USER_UPDATE: 'USER_UPDATE',
  USER_DELETION: 'USER_DELETION',
  CREDENTIAL_RULE_CREATION: 'CREDENTIAL_RULE_CREATION',
  CREDENTIAL_RULE_DELETION: 'CREDENTIAL_RULE_DELETION',
  STANDARD_DEVICE_UPDATE: 'STANDARD_DEVICE_UPDATE',
  STANDARD_DEVICE_CREATION: 'STANDARD_DEVICE_CREATION',
  STANDARD_DEVICE_DELETION: 'STANDARD_DEVICE_DELETION',
  USER_IMPERSONATE: 'USER_IMPERSONATE',
  INVITATION_CREATION: 'INVITATION_CREATION',
  INVITATION_DELETION: 'INVITATION_DELETION',
  INSTALLATION_MODE_ENABLED: 'INSTALLATION_MODE_ENABLED',
  INSTALLATION_MODE_DISABLED: 'INSTALLATION_MODE_DISABLED',
  ITEM_DELETION: 'ITEM_DELETION',
  ITEM_UPDATE: 'ITEM_UPDATE',
  ITEM_CREATION: 'ITEM_CREATION',
  COMMUNICATION_UPDATE: 'COMMUNICATION_UPDATE',
  COMMUNICATION_DELETION: 'COMMUNICATION_DELETION',
  COMMUNICATION_CREATION: 'COMMUNICATION_CREATION',
  TEMPORARY_ACCESS_CREATION: 'TEMPORARY_ACCESS_CREATION',
  TEMPORARY_ACCESS_DELETION: 'TEMPORARY_ACCESS_DELETION',
  SMART_LOCK_CREATION: 'SMART_LOCK_CREATION',
  SMART_LOCK_DELETION: 'SMART_LOCK_DELETION',
  GATEWAY_CREATION: 'GATEWAY_CREATION',
  GATEWAY_DELETION: 'GATEWAY_DELETION',
  VISITOR_CREATION: 'VISITOR_CREATION',
  VISITOR_DELETION: 'VISITOR_DELETION',
  TICKET_DELETION: 'TICKET_DELETION',
  TICKET_CREATION: 'TICKET_CREATION',
  LOCKER_CREATION: 'LOCKER_CREATION',
  LOCKER_DELETION: 'LOCKER_DELETION',
  LOCKER_RESERVATION_CREATION: 'LOCKER_RESERVATION_CREATION',
  LOCKER_RESERVATION_DELETION: 'LOCKER_RESERVATION_DELETION',
};

export const ADMIN_EVENTS_MAP = {
  USER_CREATION: {
    title: 'userCreation',
    body: 'userCreatedDescription',
    icon: 'icon-simple-line-icons-user-follow',
  },
  USER_UPDATE: {
    title: 'userUpdate',
    body: 'userUpdatedDescription',
    actorFields: ['firstname', 'lastname', 'email'],
    icon: 'icon-simple-line-icons-note',
  },
  USER_DELETION: {
    title: 'userDeleted',
    body: 'userDeleteDescription',
    actorFields: ['firstname', 'lastname', 'email'],
    icon: 'icon-simple-line-icons-note',
  },
  CREDENTIAL_RULE_CREATION: {
    title: 'credentialRuleCreation',
    body: 'credentialRuleCreateDescription',
    icon: 'icon-simple-line-icons-badge',
  },
  CREDENTIAL_RULE_DELETION: {
    title: 'credentialRuleDelete',
    body: 'credentialRuleDeleteDescription',
    icon: 'icon-simple-line-icons-trash',
  },
  STANDARD_DEVICE_UPDATE: {
    title: 'standardDevicesUpdateEventGenericTitle',
    body: 'standardDeviceUpdateDescription',
  },
  STANDARD_DEVICE_CREATION: {
    title: 'standardDevicesCreateEventGenericTitle',
    body: 'standardDeviceCreationDescription',
  },
  STANDARD_DEVICE_DELETION: {
    title: 'standardDevicesDeleteEventGenericTitle',
    body: 'standardDeviceDeleteDescription',
  },
  USER_IMPERSONATE: {
    title: 'userImpersonate',
    body: 'userImpersonateDescription',
  },
  INVITATION_DELETION: {
    title: 'invitationDeletion',
    body: 'invitationDeletionDescription',
  },
  INVITATION_CREATION: {
    title: 'invitationCreation',
    body: 'invitationCreationDescription',
  },
  INSTALLATION_MODE_ENABLED: {
    title: 'installationModeEnabledTitle',
    body: 'installationModeEnabledDescription',
  },
  INSTALLATION_MODE_DISABLED: {
    title: 'installationModeDisableTitle',
    body: 'installationModeDisableDescription',
  },
  ITEM_DELETION: {
    title: 'itemDeleteEventTitle',
    body: 'itemDeleteEventDescription',
  },
  ITEM_UPDATE: {
    title: 'itemUpdateEventTitle',
    body: 'itemUpdateEventDescription',
  },
  ITEM_CREATION: {
    title: 'itemCreateEventTitle',
    body: 'itemCreateEventDescription',
  },
  COMMUNICATION_UPDATE: {
    title: 'communicationUpdateEventTitle',
    body: 'communicationUpdateEventDescription',
  },
  COMMUNICATION_DELETION: {
    title: 'communicationDeleteEventTitle',
    body: 'communicationDeleteEventDescription',
  },
  COMMUNICATION_CREATION: {
    title: 'communicationCreateEventTitle',
    body: 'communicationCreateEventDescription',
  },
  TEMPORARY_ACCESS_CREATION: {
    title: 'temporaryAccessCreateEventTitle',
    body: 'temporaryAccessCreateEventDescription',
  },
  TEMPORARY_ACCESS_DELETION: {
    title: 'temporaryAccessDeleteEventTitle',
    body: 'temporaryAccessDeleteEventDescription',
  },
  SMART_LOCK_CREATION: {
    title: 'smartlockCreateEventTitle',
    body: 'smartlockCreateEventDescription',
  },
  SMART_LOCK_DELETION: {
    title: 'smartlockDeleteEventTitle',
    body: 'smartlockDeleteEventDescription',
  },
  GATEWAY_CREATION: {
    title: 'gatewayCreateEventTitle',
    body: 'gatewayDeleteEventDescription',
  },
  GATEWAY_DELETION: {
    title: 'gatewayDeleteEventTitle',
    body: 'gatewayDeleteEventDescription',
  },
  VISITOR_CREATION: {
    title: 'visitorCreateEventTitle',
    body: 'visitorCreateEventDescription',
  },
  VISITOR_DELETION: {
    title: 'visitorDeleteEventTitle',
    body: 'visitorDeleteEventDescription',
  },
  TICKET_DELETION: {
    title: 'ticketDeleteEventTitle',
    body: 'ticketDeleteEventDescription'
  },
  TICKET_CREATION: {
    title: 'ticketCreateEventTitle',
    body: 'ticketCreateEventDescription'
  },
  LOCKER_CREATION: {
    title: 'lockerCreateEventTitle',
    body: 'lockerCreateEventDescription'
  },
  LOCKER_DELETION: {
    title: 'lockerDeleteEventTitle',
    body: 'lockerDeleteEventDescription'
  },
  LOCKER_RESERVATION_CREATION: {
    title: 'lockerReservationCreateEventTitle',
    body: 'lockerReservationCreateEventDescription'
  },
  LOCKER_RESERVATION_DELETION: {
    title: 'lockerReservationDeleteEventTitle',
    body: 'lockerReservationDeleteEventDescription'
  },
  ALL: {
    title: 'allEvents',
  },
};

export const ADMIN_EVENT_TYPE_FILTERS = {
  ALL: 'ALL',
  USER_CREATION: 'USER_CREATION',
  USER_UPDATE: 'USER_UPDATE',
  USER_DELETION: 'USER_DELETION',
  CREDENTIAL_RULE_CREATION: 'CREDENTIAL_RULE_CREATION',
  CREDENTIAL_RULE_DELETION: 'CREDENTIAL_RULE_DELETION',
  STANDARD_DEVICE_UPDATE: 'STANDARD_DEVICE_UPDATE',
  STANDARD_DEVICE_CREATION: 'STANDARD_DEVICE_CREATION',
  STANDARD_DEVICE_DELETION: 'STANDARD_DEVICE_DELETION',
  SMART_LOCK_CREATION: 'SMART_LOCK_CREATION',
  SMART_LOCK_DELETION: 'SMART_LOCK_DELETION',
  GATEWAY_CREATION: 'GATEWAY_CREATION',
  GATEWAY_DELETION: 'GATEWAY_DELETION',
  VISITOR_CREATION: 'VISITOR_CREATION',
  VISITOR_DELETION: 'VISITOR_DELETION',
  TICKET_DELETION: 'TICKET_DELETION',
  TICKET_CREATION: 'TICKET_CREATION',
  LOCKER_CREATION: 'LOCKER_CREATION',
  LOCKER_DELETION: 'LOCKER_DELETION',
  LOCKER_RESERVATION_CREATION: 'LOCKER_RESERVATION_CREATION',
  LOCKER_RESERVATION_DELETION: 'LOCKER_RESERVATION_DELETION',
};

export const DOOR_LOCK_EVENT_TYPE_FILTERS_NAME = {
  ALL: 'allEvents',
  OPEN_SUCCESS: 'openSuccess',
  SMARTPHONE_CARDS_EVENTS: 'smartphoneCardsEvents',
  OPEN_SMARTPHONE_FAIL: 'openDoorErrorFilterTitle',
  OPEN_UNAUTHORIZED: 'openRequestDeniedFilterTitle',
  OPEN_REMOTE_SUCCESS: 'openDoorRemoteFilterTitle',
};

export const DOOR_LOCK_EVENT_TYPE_FILTERS = {
  ALL: 'ALL',
  OPEN_SUCCESS: 'OPEN_SUCCESS',
  SMARTPHONE_CARDS_EVENTS: 'SMARTPHONE_CARDS_EVENTS',
  OPEN_SMARTPHONE_FAIL: 'OPEN_SMARTPHONE_FAIL',
  OPEN_UNAUTHORIZED: 'OPEN_UNAUTHORIZED',
  OPEN_REMOTE_SUCCESS: 'OPEN_REMOTE_SUCCESS',
};

export const DOOR_LOCK_EVENT_TYPE_FILTER_MAP = {
  SMARTPHONE_CARDS_EVENTS: {
    events: ['JAGO_0', 'JAGO_3', 'ISEO_ARGO_8', 'JAGO_7'],
  },
  OPEN_SMARTPHONE_FAIL: {
    events: ['JAGO_0', 'JAGO_MOBILE_1'],
    outcomes: ['JAGO_MOBILE_1_0', 'JAGO_MOBILE_2_0', 'JAGO_MOBILE_1_1', 'JAGO_MOBILE_1_2', 'JAGO_MOBILE_1_10', 'JAGO_MOBILE_1_1094'],
  },
  OPEN_UNAUTHORIZED: {
    events: ['JAGO_1', 'JAGO_4', 'ISEO_ARGO_4', 'JAGO_6'],
  },
  OPEN_REMOTE_SUCCESS: {
    events: ['JAGO_5', 'JAGO_11', 'JAGO_9'],
  },
};


export const DOOR_LOCK_EVENTS = {
  ISEO_ARGO_0: 'ISEO_ARGO_SOFTWARE_UPGRADE',
  ISEO_ARGO_1: 'ISEO_ARGO_NEW_MASTER_SET',
  ISEO_ARGO_2: 'ISEO_ARGO_NEW_MASTER_LEVEL',
  ISEO_ARGO_3: 'ISEO_ARGO_OPEN_DENIED_BT_ERROR',
  ISEO_ARGO_4: 'ISEO_ARGO_OPEN_DENIED_NOT_IN_MEMORY',
  ISEO_ARGO_5: 'ISEO_ARGO_OPEN_DENIED_WRONG_PIN',
  ISEO_ARGO_6: 'ISEO_ARGO_BATTERY_EXTRA_LOW',
  ISEO_ARGO_7: 'ISEO_ARGO_DELAYED_OPEN',
  ISEO_ARGO_8: 'ISEO_ARGO_LOCK_OPEN',
  ISEO_ARGO_9: 'ISEO_ARGO_PASSAGE_MODE_ON',
  ISEO_ARGO_10: 'ISEO_ARGO_PASSAGE_MODE_OFF',
  ISEO_ARGO_11: 'ISEO_ARGO_VIP_ONLY_ACCESS',
  ISEO_ARGO_12: 'ISEO_ARGO_VIP_ACCESS_OFF',
  ISEO_ARGO_13: 'ISEO_ARGO_BLOCKED_USER',
  ISEO_ARGO_14: 'ISEO_ARGO_USER_LIST_CLEAR',
  ISEO_ARGO_15: 'ISEO_ARGO_USER_ADDED',
  ISEO_ARGO_16: 'ISEO_ARGO_USER_DELETED',
  ISEO_ARGO_17: 'ISEO_ARGO_USER_UPDATED',
  ISEO_ARGO_18: 'ISEO_ARGO_UNDEFINED_LOG_EVENT',
  ISEO_ARGO_19: 'ISEO_ARGO_LOCK_CLOSE',
  ISEO_ARGO_20: 'ISEO_ARGO_DELAYED_LOCK_CLOSE',
  ISEO_ARGO_21: 'ISEO_ARGO_MEMORY_FULL',
  ISEO_ARGO_22: 'ISEO_ARGO_LOCK_OPEN_MOTOR_EXTRACURRENT_ERROR',
  ISEO_ARGO_23: 'ISEO_ARGO_LOCK_OPEN_SENSOR_TIMEOUT_ERROR',
  ISEO_ARGO_24: 'ISEO_ARGO_LOCK_OPEN_GENERIC_ERROR',
  ISEO_ARGO_25: 'ISEO_ARGO_LOCK_CLOSE_MOTOR_EXTRACURRENT_ERROR',
  ISEO_ARGO_26: 'ISEO_ARGO_LOCK_CLOSE_SENSOR_TIMEOUT_ERROR',
  ISEO_ARGO_27: 'ISEO_ARGO_LOCK_CLOSE_GENERIC_ERROR',
  ISEO_ARGO_28: 'ISEO_ARGO_ENTER_BT_CONFIG',
  ISEO_ARGO_29: 'ISEO_ARGO_EXIT_BT_CONFIG',
  ISEO_ARGO_30: 'ISEO_ARGO_POWER_ON_RESET',
  ISEO_ARGO_31: 'ISEO_ARGO_USER_BLOCKED_PRIVACY',
  ISEO_ARGO_32: 'ISEO_ARGO_EMERGENCY_LOCK_OPEN',
  ISEO_ARGO_33: 'ISEO_ARGO_HANDLE_LOCK_OPEN',
  ISEO_ARGO_34: 'ISEO_ARGO_LOCK_OPEN_BY_KEY',
  ISEO_ARGO_35: 'ISEO_ARGO_LOCK_BOLTS_HALFWAY_HANDLE',
  ISEO_ARGO_36: 'ISEO_ARGO_LOCK_BOLTS_HALFWAY_KEY',
  ISEO_ARGO_37: 'ISEO_ARGO_LOCK_CLOSED_BY_HANDLE',
  ISEO_ARGO_38: 'ISEO_ARGO_LOCK_CLOSED_BY_KEY',
  ISEO_ARGO_39: 'ISEO_ARGO_PRIVACY_ON',
  ISEO_ARGO_40: 'ISEO_ARGO_PRIVACY_OFF',
  ISEO_ARGO_41: 'ISEO_ARGO_WATCHDOG_RESET',
  ISEO_ARGO_42: 'ISEO_ARGO_FACTORY_RESET_DEFAULT_SETTINGS',
  ISEO_ARGO_43: 'ISEO_ARGO_FACTORY_RESET_COMPLETE',
  ISEO_ARGO_44: 'ISEO_ARGO_OPEN_DENIED_PRECONDITION',
  ISEO_ARGO_45: 'ISEO_ARGO_OPEN_SUCCESS_REMOTE_CONTACT_BUTTON',
  ISEO_ARGO_46: 'ISEO_ARGO_LOCKBUS_KEY_EXCHANGE_PERFORMED',
  ISEO_ARGO_47: 'ISEO_ARGO_BT_TX_POWER_SET_STANDARD',
  ISEO_ARGO_48: 'ISEO_ARGO_BT_TX_POWER_SET_HIGH',
  ISEO_ARGO_49: 'ISEO_ARGO_BT_ADVERTISING_RATE_SET_STANDARD',
  ISEO_ARGO_50: 'ISEO_ARGO_BT_ADVERTISING_RATE_SET_HIGH',
  ISEO_ARGO_51: 'ISEO_ARGO_OPEN_DENIED_START_VALIDITY_DATE_NOT_REACHED',
  ISEO_ARGO_52: 'ISEO_ARGO_OPEN_DENIED_END_VALIDITY_DATE_EXCEEDED',
  ISEO_ARGO_53: 'ISEO_ARGO_OPEN_DENIED_USER_TIMEPROFILE_NOT_FULLFILLED',
  ISEO_ARGO_54: 'ISEO_ARGO_CHANGED_DEVICE_MODE',
  ISEO_ARGO_55: 'ISEO_ARGO_ENTERED_BOOTLOADER',
  ISEO_ARGO_56: 'ISEO_ARGO_CHANGED_PASSAGE_MODE',
  ISEO_ARGO_57: 'ISEO_ARGO_OPEN_DENIED_PORT_STATUS_NOT_AVAILABLE',
  ISEO_ARGO_58: 'ISEO_ARGO_DOOR_SCHEDULE_CONFIGURATION_CHANGED',
  ISEO_ARGO_59: 'ISEO_ARGO_LOCK_CLOSED_ONLY_BY_LATCH',
  ISEO_ARGO_60: 'ISEO_ARGO_BACKUP_LITHIUM_OK',
  ISEO_ARGO_61: 'ISEO_ARGO_BACKUP_LITHIUM_KO',
  ISEO_ARGO_62: 'ISEO_ARGO_OEM_AUTHENTICATION_ERROR',
  ISEO_ARGO_63: 'ISEO_ARGO_MAINS_POWER_OK',
  ISEO_ARGO_64: 'ISEO_ARGO_MAINS_POWER_KO',
  ISEO_ARGO_65: 'ISEO_ARGO_SINGLE_ACTION_PARAMETER_CHANGED',
  ISEO_ARGO_66: 'ISEO_ARGO_BOOTLOADER_UPDATED',
  ISEO_ARGO_67: 'ISEO_ARGO_SENSOR_UNSTABLE',
  ISEO_ARGO_68: 'ISEO_ARGO_AUTHENTICATION_MISMATCH',
  ISEO_ARGO_69: 'ISEO_ARGO_LOG_VERBOSE_GENERIC',
  ISEO_ARGO_70: 'ISEO_ARGO_LATCH_ALERT',
  ISEO_ARGO_71: 'ISEO_ARGO_FINGERPRINT_STUCK_ALERT',
  ISEO_ARGO_72: 'ISEO_ARGO_RTC_SYNCHRONIZATION_REQUEST',
  ISEO_ARGO_73: 'ISEO_ARGO_RTC_SYNCHRONIZED',
  ISEO_ARGO_74: 'ISEO_ARGO_FINGERPRINT_FIRWARE_UPDATED',
  ISEO_ARGO_75: 'ISEO_ARGO_LOW_BATTERY_LOCK_OPEN',
  ISEO_ARGO_76: 'ISEO_ARGO_LOW_BATTERY_LOCK_CLOSE',
  ISEO_ARGO_77: 'ISEO_ARGO_FINGERPRINT_CRYPTO_MISMATCH',
  ISEO_ARGO_78: 'ISEO_ARGO_ENTER_MASTER_MODE',
  ISEO_ARGO_79: 'ISEO_ARGO_EXIT_MASTER_MODE',
  ISEO_ARGO_80: 'ISEO_ARGO_LOCK_OPEN_TIMEOUT',
  ISEO_ARGO_81: 'ISEO_ARGO_LOCK_OPEN_BY_LATCH',
  ISEO_ARGO_82: 'ISEO_ARGO_SYSTEM_BLOCKED',
  ISEO_ARGO_83: 'ISEO_ARGO_SYSTEM_UNBLOCKED',
  ISEO_ARGO_84: 'ISEO_ARGO_MANUAL_SENSOR_CALIBRATION_TRIGGERED',
  ISEO_ARGO_85: 'ISEO_ARGO_PROXY_OPEN_REQUEST',
  JAGO_0: 'JAGO_0',
  JAGO_1: 'JAGO_1',
  JAGO_2: 'JAGO_2',
  JAGO_3: 'JAGO_3', // remote open with device authorized
  JAGO_4: 'JAGO_4', // open with device NOT authorized
  JAGO_5: 'JAGO_5', // remote open not from device authorized (manager)
  JAGO_6: 'JAGO_6', // remote open generic error
  JAGO_7: 'JAGO_7', // lock group open
  JAGO_9: 'JAGO_9', // remote with hypergate from smartphone user,
  JAGO_10: 'JAGO_10', // remote with hypergate from smartphone user NOT authorized,
  JAGO_11: 'JAGO_11', // remote open with hypergate authorized from no specific device
  JAGO_MOBILE_0: 'JAGO_MOBILE_0', // open ok upload by user
  JAGO_MOBILE_1: 'JAGO_MOBILE_1',
  JAGO_0_PASSAGE_MODE_ON: 'JAGO_0_PASSAGE_MODE_ON',
  JAGO_0_PASSAGE_MODE_OFF: 'JAGO_0_PASSAGE_MODE_OFF',
  JAGO_0_READ_EVENTS: 'JAGO_0_READ_EVENTS',
  ISEO_ARGO_86: 'ISEO_ARGO_PERMISSION_DENIED',
  ISEO_ARGO_87: 'ISEO_ARGO_PROXY_LOGIN_REQUEST',
  ISEO_ARGO_88: 'ISEO_ARGO_OPEN_DENIED',
  ISEO_ARGO_89: 'ISEO_ARGO_WRONG_PASSWORD',
  ISEO_ARGO_90: 'ISEO_ARGO_HARDWARE_FAULT',
  ISEO_ARGO_91: 'ISEO_ARGO_INTERNAL_HANDLE_PRESSED',
  ISEO_ARGO_92: 'ISEO_ARGO_DOOR_CONTACT_NOT_TOUCHING_ERROR',
  ISEO_ARGO_93: 'ISEO_ARGO_MASTER_MODE',
  ISEO_ARGO_94: 'ISEO_ARGO_FINGERPRINT_SELF_RECOVERED',
  ISEO_ARGO_95: 'ISEO_ARGO_DEVICE_INITIALIZED',
  ISEO_ARGO_96: 'ISEO_ARGO_DEVICE_UNINITIALIZED',
  ISEO_ARGO_97: 'ISEO_ARGO_BT_CONNECTION_ERROR',
  ISEO_ARGO_98: 'ISEO_ARGO_FINGERPRINT_INIT_ERROR',
  ISEO_ARGO_99: 'ISEO_ARGO_MASTER_CARD_ERROR',
  ISEO_ARGO_102: 'ISEO_ARGO_PHYSICAL_KEY_USED',
  ISEO_ARGO_103: 'ISEO_ARGO_DOOR_OPEN_MECHANICAL',
  ISEO_ARGO_104: 'ISEO_ARGO_DOOR_CLOSED',
  ISEO_ARGO_105: 'ISEO_ARGO_DOOR_OPEN_OUT_OF_FRAME',
};

export const DOOR_LOCK_EVENTS_MAP = {
  ALL: {
    title: 'allEvents',
  },
  JAGO_MOBILE_0: { // richiesto accesso, accesso consentito
    title: 'openRequestGrantedTitle',
    body: 'openRequestGrantedDescription',
    icon: <CloudDone style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  JAGO_MOBILE_1: { // richiesto accesso, accesso negato
    title: 'openRequestDeniedTitle',
    body: 'openRequestDeniedDescription',
    icon: <LockCloseIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  JAGO_0_PASSAGE_MODE_ON: {
    title: 'openOfficeModeActivateTitle',
    body: 'openOfficeModeActivateDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <OfficeModeIconCustom style={{ width: 35, color: '#3f3f3f' }} />
        <CheckIcon style={{ marginTop: 27, marginLeft: -12, backgroundColor: 'white', borderRadius: 10, fontSize: 20, alignSelf: 'flex-end', color: '#4DB6AC' }} />
      </div>
    ),
  },
  JAGO_0_PASSAGE_MODE_OFF: {
    title: 'openOfficeModeDeactivateTitle',
    body: 'openOfficeModeDeactivateDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <OfficeModeIconCustom style={{ width: 35, color: '#3f3f3f' }} />
        <CancelIcon style={{ marginTop: 27, marginLeft: -12, backgroundColor: 'white', fontSize: 20, alignSelf: 'flex-end', color: 'gray' }} />
      </div>
    ),
  },
  JAGO_0_READ_EVENTS: {
    title: 'openReadEventsTitle',
    body: 'openReadEventsDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <SmartLocksEventsIconCustom style={{ width: 35, color: '#3f3f3f' }} />
        <CheckIcon style={{ marginTop: 17, marginLeft: -19, backgroundColor: 'white', borderRadius: 10, fontSize: 20, alignSelf: 'flex-end', color: '#4DB6AC' }} />
      </div>
    ),
  },
  JAGO_0: { // richiesto accesso, accesso consentito
    title: 'openRequestGrantedTitle',
    body: 'openRequestGrantedDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <DoorOpenIconCustom style={{ width: 35, color: '#3f3f3f' }} />
        <CloudDone style={{ marginTop: 20, marginLeft: -14, fontSize: 20, backgroundColor: 'white', borderRadius: 10, alignSelf: 'flex-end', color: '#4DB6AC' }} />
      </div>
    ),
  },
  JAGO_1: { // richiesto accesso, accesso negato
    title: 'openRequestDeniedTitle',
    body: 'openRequestDeniedDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 20 }}>
        <CloudDeny style={{ fontSize: 40, color: '#3f3f3f' }} />
        <CancelIcon style={{ marginTop: 20, marginLeft: -8, fontSize: 20, backgroundColor: 'white', borderRadius: 10, alignSelf: 'flex-end', color: '#D32F2F' }} />
      </div>
    ),
  },
  JAGO_2: {
    title: 'userRequestOfflineLocksTitle',
    body: 'userRequestOfflineLocksDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <DoorOpenIconCustom style={{ width: 35, color: '#3f3f3f' }} />
        <CloudDownload style={{ marginTop: 25, marginLeft: -10, marginRight: -5, fontSize: 20, backgroundColor: 'white', borderRadius: 10, alignSelf: 'flex-end', color: '#4DB6AC' }} />
      </div>
    ),
  },
  JAGO_3: { // remote open with device authorized
    title: 'openOnlineRequestSuccessTitle',
    body: 'openOnlineRequestSuccessDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <SmartphoneIcon style={{ fontSize: 35, color: '#3f3f3f' }} />
        <CheckIcon style={{ marginTop: 20, marginLeft: -14, fontSize: 20, backgroundColor: 'white', borderRadius: 10, alignSelf: 'flex-end', color: '#4DB6AC' }} />
      </div>
    ),
  },
  JAGO_4: { // open with device NOT authorized
    title: 'openOnlineRequestDeniedTitle',
    body: 'openOnlineRequestDeniedDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 20 }}>
        <CloudDeny style={{ fontSize: 40, color: '#3f3f3f' }} />
        <CancelIcon style={{ marginTop: 20, marginLeft: -8, fontSize: 20, backgroundColor: 'white', borderRadius: 10, alignSelf: 'flex-end', color: '#D32F2F' }} />
      </div>
    ),
  },
  JAGO_5: { // remote open not from device authorized (manager)
    title: 'openOnlineManagerRequestSuccessTitle',
    body: 'openOnlineManagerRequestSuccessDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <LockIcon style={{ fontSize: 35, color: '#3f3f3f' }} />
        <CloudDone style={{ marginTop: 25, marginLeft: -10, marginRight: -5, fontSize: 20, alignSelf: 'flex-end', color: '#4DB6AC' }} />
      </div>
    ),
  },
  JAGO_6: { // remote open generic error
    title: 'openOnlineRequestFailedTitle',
    body: 'openOnlineRequestFailedDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 20 }}>
        <CloudDeny style={{ fontSize: 40, color: '#3f3f3f' }} />
        <CancelIcon style={{ marginTop: 20, marginLeft: -8, fontSize: 20, backgroundColor: 'white', borderRadius: 10, alignSelf: 'flex-end', color: '#D32F2F' }} />
      </div>
    ),
  },
  JAGO_7: {
    title: 'openGroupGranted',
    body: 'openGroupGrantedDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <LockGroupIcon style={{ fontSize: 35, color: '#3f3f3f' }} />
        <CloudDone style={{ marginTop: 25, marginLeft: -10, marginRight: -5, fontSize: 20, alignSelf: 'flex-end', color: '#4DB6AC' }} />
      </div>
    ),
  },
  JAGO_10: {
    title: 'openOnlineManagerRequestNotAuthorizedTitle',
    body: 'openOnlineManagerRequestNotAuthorizedDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 20 }}>
        <CloudDeny style={{ fontSize: 40, color: '#3f3f3f' }} />
        <CancelIcon style={{ marginTop: 20, marginLeft: -8, fontSize: 20, backgroundColor: 'white', borderRadius: 10, alignSelf: 'flex-end', color: '#D32F2F' }} />
      </div>
    ),
  },
  JAGO_11: {
    title: 'openOnlineManagerRequestSuccessTitle',
    body: 'openOnlineManagerRequestSuccessDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <LockIcon style={{ fontSize: 35, color: '#3f3f3f' }} />
        <CloudDone style={{ marginTop: 25, marginLeft: -10, marginRight: -5, fontSize: 20, alignSelf: 'flex-end', color: 'gray' }} />
      </div>
    ),
  },
  JAGO_9: {
    title: 'openRequestGrantedTitle',
    body: 'openRequestRemoteGrantedDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <LockIcon style={{ fontSize: 35, color: '#3f3f3f' }} />
        <CloudDone style={{ marginTop: 25, marginLeft: -10, marginRight: -5, fontSize: 20, alignSelf: 'flex-end', color: '#4DB6AC' }} />
      </div>
    ),
  },
  ISEO_ARGO_SOFTWARE_UPGRADE: {
    title: 'iseoArgoSoftwareUpdateTitle',
    body: 'iseoArgoSoftwareUpdateDescription',
    icon: <InstallationIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_NEW_MASTER_SET: {
    title: 'iseoArgoNewMasterSetTitle',
    body: 'iseoArgoNewMasterSetDescription',
    icon: <CardIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_NEW_MASTER_LEVEL: {
    title: 'iseoArgoNewMasterLevelTitle',
    body: 'iseoArgoNewMasterLevelDescription',
    icon: <CardIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_OPEN_DENIED_BT_ERROR: {
    title: 'iseoArgoOpenBTDeniedTitle',
    body: 'iseoArgoOpenBTDeniedDescription',
    icon: <CloseIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_OPEN_DENIED_NOT_IN_MEMORY: {
    title: 'iseoArgoOpenDeniedNotInMemoryTitle',
    body: 'iseoArgoOpenDeniedNotInMemoryDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <DoorOpenIconCustom style={{ width: 35, color: '#3f3f3f' }} />
        <CancelIcon style={{ marginTop: 20, marginLeft: -14, fontSize: 20, backgroundColor: 'white', borderRadius: 10, alignSelf: 'flex-end', color: '#D32F2F' }} />
      </div>
    ),
  },
  ISEO_ARGO_OPEN_DENIED_WRONG_PIN: {
    title: 'iseoArgoOpenDeniedWrongPinTitle',
    body: 'iseoArgoOpenDeniedWrongPinDescription',
    icon: <CloseIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_BATTERY_EXTRA_LOW: {
    title: 'iseoArgoBatteryExtraLowTitle',
    body: 'iseoArgoBatteryExtraLowDescription',
    icon: <BatteryLow style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_DELAYED_OPEN: {
    title: 'iseoArgoDelayedOpenTitle',
    body: 'iseoArgoDelayedOpenDescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_LOCK_OPEN: {
    title: 'iseoArgoLockOpenTitle',
    body: 'iseoArgoLockOpenDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <DoorOpenIconCustom style={{ width: 35, color: '#3f3f3f' }} />
        <CheckIcon style={{ marginTop: 20, marginLeft: -14, fontSize: 20, backgroundColor: 'white', borderRadius: 10, alignSelf: 'flex-end', color: '#4DB6AC' }} />
      </div>
    ),
  },
  ISEO_ARGO_PASSAGE_MODE_ON: {
    title: 'iseoArgoPassageModeOnTitle',
    body: 'iseoArgoPassageModeOnDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <OfficeModeIconCustom style={{ width: 35, color: '#3f3f3f' }} />
        <CheckIcon style={{ marginTop: 27, marginLeft: -12, backgroundColor: 'white', borderRadius: 10, fontSize: 20, alignSelf: 'flex-end', color: '#4DB6AC' }} />
      </div>
    ),
  },
  ISEO_ARGO_PASSAGE_MODE_OFF: {
    title: 'iseoArgoPassageModeOffTitle',
    body: 'iseoArgoPassageModeOffDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <OfficeModeIconCustom style={{ width: 35, color: '#3f3f3f' }} />
        <CancelIcon style={{ marginTop: 27, marginLeft: -12, backgroundColor: 'white', fontSize: 20, alignSelf: 'flex-end', color: 'gray' }} />
      </div>
    ),
  },
  ISEO_ARGO_VIP_ONLY_ACCESS: {
    title: 'iseoArgoVipOnlyAccessTitle',
    body: 'iseoArgoVipOnlyAccessDescription',
    icon: <StarIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_VIP_ACCESS_OFF: {
    title: 'iseoArgoVipAccessOffTitle',
    body: 'iseoArgoVipAccessOffDescription',
    icon: <PowerIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_BLOCKED_USER: {
    title: 'iseoArgoBlockedUserTitle',
    body: 'iseoArgoBlockedUserDescription',
    icon: <CloseIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_USER_LIST_CLEAR: {
    title: 'iseoArgoUserListClearTitle',
    body: 'iseoArgoUserListClearDescription',
    icon: <DeleteIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_USER_ADDED: {
    title: 'iseoArgoUserAddedTitle',
    body: 'iseoArgoUserAddedDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <DoorIconCustom style={{ width: 35, color: '#3f3f3f' }} />
        <UserIcon style={{ marginTop: 20, marginLeft: -14, fontSize: 20, backgroundColor: 'white', borderRadius: 10, alignSelf: 'flex-end', color: GREEN }} />
      </div>
    ),
  },
  ISEO_ARGO_USER_DELETED: {
    title: 'iseoArgoUserDeletedTitle',
    body: 'iseoArgoUserDeletedDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <DoorIconCustom style={{ width: 35, color: '#3f3f3f' }} />
        <UserIcon style={{ marginTop: 20, marginLeft: -14, fontSize: 20, backgroundColor: 'white', borderRadius: 10, alignSelf: 'flex-end', color: RED }} />
      </div>
    ),
  },
  ISEO_ARGO_USER_UPDATED: {
    title: 'iseoArgoUserUpdatedTitle',
    body: 'iseoArgoUserUpdatedDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <DoorIconCustom style={{ width: 35, color: '#3f3f3f' }} />
        <UserIcon style={{ marginTop: 20, marginLeft: -14, fontSize: 20, backgroundColor: 'white', borderRadius: 10, alignSelf: 'flex-end', color: ORANGE }} />
      </div>
    ),
  },
  ISEO_ARGO_UNDEFINED_LOG_EVENT: {
    title: 'iseoArgoUndefinedLogEventTitle',
    body: 'iseoArgoUndefinedLogEventDescription',
    icon: <CloseIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_LOCK_CLOSE: {
    title: 'iseoArgoLockCloseTitle',
    body: 'iseoArgoLockCloseDescription',
    icon: <CheckIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_DELAYED_LOCK_CLOSE: {
    title: 'iseoArgoDelayedLockCloseTitle',
    body: 'iseoArgoDelayedLockCloseDescription',
    icon: <BatteryLow style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_MEMORY_FULL: {
    title: 'iseoArgoMemoryFullTitle',
    body: 'iseoArgoMemoryFullDescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_LOCK_OPEN_MOTOR_EXTRACURRENT_ERROR: {
    title: 'iseoArgoLockOpenMotorExtracurrentErrorTitle',
    body: 'iseoArgoLockOpenMotorExtracurrentErrorDescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_LOCK_OPEN_SENSOR_TIMEOUT_ERROR: {
    title: 'iseoArgoLockOpenSensorTimeoutErrorTitle',
    body: 'iseoArgoLockOpenSensorTimeoutErrorDescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_LOCK_OPEN_GENERIC_ERROR: {
    title: 'iseoArgoLockOpenGenericErrorTitle',
    body: 'iseoArgoLockOpenGenericErrorDescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_LOCK_CLOSE_MOTOR_EXTRACURRENT_ERROR: {
    title: 'iseoArgoLockCloseMotorExtracurrentErrorTitle',
    body: 'iseoArgoLockCloseMotorExtracurrentErrorDescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_LOCK_CLOSE_SENSOR_TIMEOUT_ERROR: {
    title: 'iseoArgoLockCloseSensorTimeoutErrorTitle',
    body: 'iseoArgoLockCloseSensorTimeoutErrorDescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_LOCK_CLOSE_GENERIC_ERROR: {
    title: 'iseoArgoLockCloseGenericErrorTitle',
    body: 'iseoArgoLockCloseGenericErrorDescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_ENTER_BT_CONFIG: {
    title: 'iseoArgoEnterBTConfigTitle',
    body: 'iseoArgoEnterBTConfigDescription',
    icon: <InstallationIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_EXIT_BT_CONFIG: {
    title: 'iseoArgoExitBTConfigTitle',
    body: 'iseoArgoExitBTConfigDescription',
    icon: <ExitIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_POWER_ON_RESET: {
    title: 'iseoArgoPowerOnResetTitle',
    body: 'iseoArgoPowerOnResetDescription',
    icon: <PowerIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_USER_BLOCKED_PRIVACY: {
    title: 'iseoArgoUserBlockedPrivacyTitle',
    body: 'iseoArgoUserBlockedPrivacyDescription',
    icon: <CloseIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_EMERGENCY_LOCK_OPEN: {
    title: 'iseoArgoEmergencyLockOpenTitle',
    body: 'iseoArgoEmergencyLockOpenDescription',
    icon: <WarningIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_HANDLE_LOCK_OPEN: {
    title: 'iseoArgoHandleLockOpenTitle',
    body: 'iseoArgoHandleLockOpenDescription',
    icon: <CheckIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_LOCK_OPEN_BY_KEY: {
    title: 'iseoArgoLockOpenByKeyTitle',
    body: 'iseoArgoLockOpenByKeyDescription',
    icon: <KeyIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_LOCK_BOLTS_HALFWAY_HANDLE: {
    title: 'iseoArgoLockBoltsHalfwayHandleTitle',
    body: 'iseoArgoLockBoltsHalfwayHandleDescription',
    icon: <CloseIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_LOCK_BOLTS_HALFWAY_KEY: {
    title: 'iseoArgoLockBoltsHalfwayKeyTitle',
    body: 'iseoArgoLockBoltsHalfwayKeyDescription',
    icon: <CloseIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_LOCK_CLOSED_BY_HANDLE: {
    title: 'iseoArgoLockClosedByHandleTitle',
    body: 'iseoArgoLockClosedByHandleDescription',
    icon: <LockIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_LOCK_CLOSED_BY_KEY: {
    title: 'iseoArgoLockClosedByKeyTitle',
    body: 'iseoArgoLockClosedByKeyDescription',
    icon: <KeyIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_PRIVACY_ON: {
    title: 'iseoArgoPrivacyOnTitle',
    body: 'iseoArgoPrivacyOnDescription',
    icon: <LockIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_PRIVACY_OFF: {
    title: 'iseoArgoPrivacyOffTitle',
    body: 'iseoArgoPrivacyOffDescription',
    icon: <PowerIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_WATCHDOG_RESET: {
    title: 'iseoArgoWatchdogResetTitle',
    body: 'iseoArgoWatchdogResetDescription',
    icon: <InstallationIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_FACTORY_RESET_DEFAULT_SETTINGS: {
    title: 'iseoArgoFactoryResetDefaultSettingsTitle',
    body: 'iseoArgoFactoryResetDefaultSettingsDescription',
    icon: <PowerIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_FACTORY_RESET_COMPLETE: {
    title: 'iseoArgoFactoryResetCompleteTitle',
    body: 'iseoArgoFactoryResetCompleteDescription',
    icon: <PowerIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_OPEN_DENIED_PRECONDITION: {
    title: 'isoeArgoOpenDeniedPreconditionTitle',
    body: 'isoeArgoOpenDeniedPreconditionDescription',
    icon: <CloseIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_OPEN_SUCCESS_REMOTE_CONTACT_BUTTON: {
    title: 'iseoArgoOpenSuccessRemoteContactButtonTitle',
    body: 'iseoArgoOpenSuccessRemoteContactButtonDescription',
    icon: <CheckIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_LOCKBUS_KEY_EXCHANGE_PERFORMED: {
    title: 'iseoArgoLockbusKeyExchangePerformedTitle',
    body: 'iseoArgoLockbusKeyExchangePerformedDescription',
    icon: <RefreshIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_BT_TX_POWER_SET_STANDARD: {
    title: 'iseoArgoBTTXPowerSetStandardTitle',
    body: 'iseoArgoBTTXPowerSetStandardDescription',
    icon: <BluetoothIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_BT_TX_POWER_SET_HIGH: {
    title: 'iseoArgoBTTXPowerSetHighTitle',
    body: 'iseoArgoBTTXPowerSetHighDecription',
    icon: <BluetoothIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_BT_ADVERTISING_RATE_SET_STANDARD: {
    title: 'iseoArgoBTAdvertisingRateSetStandardTitle',
    body: 'iseoArgoBTAdvertisingRateSetStandardDescription',
    icon: <BluetoothIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_BT_ADVERTISING_RATE_SET_HIGH: {
    title: 'iseoArgoBTAdvertisingRateSetHighTitle',
    body: 'iseoArgoBTAdvertisingRateSetHighDescription',
    icon: <BluetoothIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_OPEN_DENIED_START_VALIDITY_DATE_NOT_REACHED: {
    title: 'iseoArgoOpenDeniedStartDateNotYetValidTitle',
    body: 'iseoArgoOpenDeniedStartDateNotYetValidDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <DoorOpenIconCustom style={{ width: 35, color: '#3f3f3f' }} />
        <Alarm style={{ marginTop: 20, marginLeft: -14, fontSize: 20, backgroundColor: 'white', borderRadius: 10, alignSelf: 'flex-end', color: RED }} />
      </div>
    ),
  },
  ISEO_ARGO_OPEN_DENIED_END_VALIDITY_DATE_EXCEEDED: {
    title: 'iseoArgoOpenDeniedEndDateExceedTitle',
    body: 'iseoArgoOpenDeniedEndDateExceedDescrition',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <DoorOpenIconCustom style={{ width: 35, color: '#3f3f3f' }} />
        <Alarm style={{ marginTop: 20, marginLeft: -14, fontSize: 20, backgroundColor: 'white', borderRadius: 10, alignSelf: 'flex-end', color: RED }} />
      </div>
    ),
  },
  ISEO_ARGO_OPEN_DENIED_USER_TIMEPROFILE_NOT_FULLFILLED: {
    title: 'iseoArgoOpenDeniedTimeprofileNotFullfilledTitle',
    body: 'iseoArgoOpenDeniedTimeprofileNotFullfilledDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <DoorOpenIconCustom style={{ width: 35, color: '#3f3f3f' }} />
        <Alarm style={{ marginTop: 20, marginLeft: -14, fontSize: 20, backgroundColor: 'white', borderRadius: 10, alignSelf: 'flex-end', color: RED }} />
      </div>
    ),
  },
  ISEO_ARGO_CHANGED_DEVICE_MODE: {
    title: 'iseoArgoChangedDeviceModeTitle',
    body: 'iseoArgoChangedDeviceModeDescription',
    icon: <SettingsIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_ENTERED_BOOTLOADER: {
    title: 'iseoArgoEnteredBootloaderTitle',
    body: 'iseoArgoEnteredBootloaderDescription',
    icon: <InstallationIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_CHANGED_PASSAGE_MODE: {
    title: 'iseoArgoChangedPassageModeTitle',
    body: 'iseoArgoChangedPassageModeDescription',
    icon: <SettingsIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_OPEN_DENIED_PORT_STATUS_NOT_AVAILABLE: {
    title: 'iseoArgoOpenDeniedPortStatusUnavailableTitle',
    body: 'iseoArgoOpenDeniedPortStatusUnavailableDescription',
    icon: <CloseIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_DOOR_SCHEDULE_CONFIGURATION_CHANGED: {
    title: 'iseoArgoDoorScheduleConfigurationChangedTitle',
    body: 'iseoArgoDoorScheduleConfigurationChangedDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <OfficeModeIconCustom style={{ width: 35, color: '#3f3f3f' }} />
        <SettingsIcon style={{ marginTop: 27, marginLeft: -12, backgroundColor: 'white', borderRadius: 10, fontSize: 20, alignSelf: 'flex-end', color: '#3f3f3f' }} />
      </div>
    ),
  },
  ISEO_ARGO_LOCK_CLOSED_ONLY_BY_LATCH: {
    title: 'iseoArgoLockClosedByLatchTitle',
    body: 'iseoArgoLockClosedByLatchDescription',
    icon: <LinkIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_BACKUP_LITHIUM_OK: {
    title: 'iseoArgoBackupLithiumOKTitle',
    body: 'iseoArgoBackupLithiumOKDescription',
    icon: <ChartIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_BACKUP_LITHIUM_KO: {
    title: 'iseoArgoBackupLithiumKOTitle',
    body: 'iseoArgoBackupLithiumKODescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_OEM_AUTHENTICATION_ERROR: {
    title: 'iseoArgoOEMAuthenticationErrorTitle',
    body: 'iseoArgoOEMAuthenticationErrorDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <DoorIconCustom style={{ width: 35, color: '#3f3f3f' }} />
        <BanIcon style={{ marginTop: 20, marginLeft: -14, fontSize: 20, backgroundColor: 'white', borderRadius: 10, alignSelf: 'flex-end', color: RED }} />
      </div>
    ),
  },
  ISEO_ARGO_MAINS_POWER_OK: {
    title: 'iseoArgoMainPowerOKTitle',
    body: 'iseoArgoMainPowerOKDescription',
    icon: <ChartIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_MAINS_POWER_KO: {
    title: 'iseoArgoMainPowerKOTitle',
    body: 'iseoArgoMainPowerKODescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_SINGLE_ACTION_PARAMETER_CHANGED: {
    title: 'iseoArgoSingleActionParamChangedTitle',
    body: 'iseoArgoSingleActionParamChangedDescription',
    icon: <SettingsIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_BOOTLOADER_UPDATED: {
    title: 'iseoArgoBootloaderUpdatedTitle',
    body: 'iseoArgoBootloaderUpdatedDescription',
    icon: <InstallationIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_SENSOR_UNSTABLE: {
    title: 'iseoArgoSensorUnstableTitle',
    body: 'iseoArgoSensorUnstableDescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_AUTHENTICATION_MISMATCH: {
    title: 'iseoArgoAuthMismatchTitle',
    body: 'iseoArgoAuthMismatchDescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_LOG_VERBOSE_GENERIC: {
    title: 'iseoArgoLogVerboseGenericTitle',
    body: 'iseoArgoLogVerboseGenericDescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_LATCH_ALERT: {
    title: 'iseoArgoLatchAlertTitle',
    body: 'iseoArgoLatchAlertDescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_FINGERPRINT_STUCK_ALERT: {
    title: 'iseoArgoFingerprintStuckAlertTitle',
    body: 'iseoArgoFingerprintStuckAlertDescription',
    icon: <FingerPrint style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_RTC_SYNCHRONIZATION_REQUEST: {
    title: 'iseoArgoRTCSynchRequestTitle',
    body: 'iseoArgoRTCSynchRequestDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <DoorOpenIconCustom style={{ width: 35, color: '#3f3f3f' }} />
        <Alarm style={{ marginTop: 20, marginLeft: -14, fontSize: 20, backgroundColor: 'white', borderRadius: 10, alignSelf: 'flex-end', color: ORANGE }} />
      </div>
    ),
  },
  ISEO_ARGO_RTC_SYNCHRONIZED: {
    title: 'iseoArgoRTCSynchSuccessTitle',
    body: 'iseoArgoRTCSynchSuccessDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <DoorOpenIconCustom style={{ width: 35, color: '#3f3f3f' }} />
        <Alarm style={{ marginTop: 20, marginLeft: -14, fontSize: 20, backgroundColor: 'white', borderRadius: 10, alignSelf: 'flex-end', color: GREEN }} />
      </div>
    ),
  },
  ISEO_ARGO_FINGERPRINT_FIRWARE_UPDATED: {
    title: 'iseoArgoFingerprintFirwmareUpdatedTitle',
    body: 'iseoArgoFingerprintFirwmareUpdatedDescription',
    icon: <FingerPrint style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_LOW_BATTERY_LOCK_OPEN: {
    title: 'iseoArgoLowBatteryLockOpenTitle',
    body: 'iseoArgoLowBatteryLockOpenDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <DoorOpenIconCustom style={{ width: 35, color: '#3f3f3f' }} />
        <BatteryLow style={{ marginTop: 20, marginLeft: -14, fontSize: 20, backgroundColor: 'white', borderRadius: 10, alignSelf: 'flex-end', color: ORANGE }} />
      </div>
    ),
  },
  ISEO_ARGO_LOW_BATTERY_LOCK_CLOSE: {
    title: 'iseoArgoLowBatteryLockCloseTitle',
    body: 'iseoArgoLowBatteryLockCloseDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <DoorOpenIconCustom style={{ width: 35, color: '#3f3f3f' }} />
        <BatteryLow style={{ marginTop: 20, marginLeft: -14, fontSize: 20, backgroundColor: 'white', borderRadius: 10, alignSelf: 'flex-end', color: ORANGE }} />
      </div>
    ),
  },
  ISEO_ARGO_FINGERPRINT_CRYPTO_MISMATCH: {
    title: 'iseoArgoFingerprintCryptoMismatchTitle',
    body: 'iseoArgoFingerprintCryptoMismatchDescription',
    icon: <FingerPrint style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_ENTER_MASTER_MODE: {
    title: 'iseoArgoEnterMasterModeTitle',
    body: 'iseoArgoEnterMasterModeDescription',
    icon: <InstallationIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_EXIT_MASTER_MODE: {
    title: 'iseoArgoExitMasterModeTitle',
    body: 'iseoArgoExitMasterModeDescription',
    icon: <InstallationIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_LOCK_OPEN_TIMEOUT: {
    title: 'iseoArgoLockOpenTimeoutTitle',
    body: 'iseoArgoLockOpenTimeoutDescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_LOCK_OPEN_BY_LATCH: {
    title: 'iseoArgoLockOpenByLatchTitle',
    body: 'iseoArgoLockOpenByLatchDescription',
    icon: <CheckIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_SYSTEM_BLOCKED: {
    title: 'iseoArgoSystemBlockedTitle',
    body: 'iseoArgoSystemBlockedDescription',
    icon: <InstallationIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_SYSTEM_UNBLOCKED: {
    title: 'iseoArgoSystemUnblockedTitle',
    body: 'iseoArgoSystemUnblockedDescription',
    icon: <InstallationIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_MANUAL_SENSOR_CALIBRATION_TRIGGERED: {
    title: 'iseoArgoManualSensorCalibrationTriggeredTitle',
    body: 'iseoArgoManualSensorCalibrationTriggeredDescription',
    icon: <InstallationIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_PROXY_OPEN_REQUEST: {
    title: 'iseoArgoProxyOpenRequestTitle',
    body: 'iseoArgoProxyOpenRequestDescription',
    icon: <CheckIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_PERMISSION_DENIED: {
    title: 'iseoArgoPermissionDeniedTitle',
    body: 'iseoArgoPermissionDeniedDescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_PROXY_LOGIN_REQUEST: {
    title: 'iseoArgoProxyLoginRequestTitle',
    body: 'iseoArgoProxyLoginRequestDescription',
    icon: <InstallationIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_OPEN_DENIED: {
    title: 'iseoArgoOpenDeniedTitle',
    body: 'iseoArgoOpenDeniedDescription',
    icon: (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 30 }}>
        <DoorOpenIconCustom style={{ width: 35, color: '#3f3f3f' }} />
        <CancelIcon style={{ marginTop: 20, marginLeft: -8, fontSize: 20, backgroundColor: 'white', borderRadius: 10, alignSelf: 'flex-end', color: '#D32F2F' }} />
      </div>
    ),
  },
  ISEO_ARGO_WRONG_PASSWORD: {
    title: 'iseoArgoWrongPasswordTitle',
    body: 'iseoArgoWrongPasswordDescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_HARDWARE_FAULT: {
    title: 'iseoArgoHardwareFaultTitle',
    body: 'iseoArgoHardwareFaultDescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_INTERNAL_HANDLE_PRESSED: {
    title: 'iseoArgoInternalHandlePressedTitle',
    body: 'iseoArgoInternalHandlePressedDescription',
    icon: <InstallationIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_DOOR_CONTACT_NOT_TOUCHING_ERROR: {
    title: 'iseoArgoDoorContactNotTouchingTitle',
    body: 'iseoArgoDoorContactNotTouchingDescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_MASTER_MODE: {
    title: 'iseoArgoMasterModeTitle',
    body: 'iseoArgoMasterModeDescription',
    icon: <InstallationIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_FINGERPRINT_SELF_RECOVERED: {
    title: 'iseoArgoFingerprintSelfRecoveredTitle',
    body: 'isoeArgoFingerprintSelfRecoveredDescription',
    icon: <InstallationIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_DEVICE_INITIALIZED: {
    title: 'iseoArgoDeviceInitializedTitle',
    body: 'iseoArgoDeviceInitializedDescription',
    icon: <InstallationIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_DEVICE_UNINITIALIZED: {
    title: 'iseoArgoDeviceUninitializedTitle',
    body: 'iseoArgoDeviceUninitializedDescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_BT_CONNECTION_ERROR: {
    title: 'iseoArgoBlutoothConnectionErrorTitle',
    body: 'iseoArgoBluetoothConnectionErrorDescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_FINGERPRINT_INIT_ERROR: {
    title: 'iseoArgoFingerprintInitErrorTitle',
    body: 'iseoArgoFingerprintInitErrorDescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_MASTER_CARD_ERROR: {
    title: 'iseoArgoMasterCardErrorTitle',
    body: 'iseoArgoMasterCardErrorDescription',
    icon: <BanIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_PHYSICAL_KEY_USED: {
    title: 'iseoArgoPhysicalKeyUsedTitle',
    body: 'iseoArgoPhysicalKeyUsedDescription',
    icon: <KeyIcon style={{ fontSize: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_DOOR_OPEN_MECHANICAL: {
    title: 'iseoArgoDoorOpenMechanicalTitle',
    body: 'iseoArgoDoorOpenMechanicalDescription',
    icon: <DoorOpenIconCustom style={{ width: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_DOOR_CLOSED: {
    title: 'iseoArgoDoorClosedTitle',
    body: 'iseoArgoDoorClosedDescription',
    icon: <DoorIconCustom style={{ width: 30, marginRight: 40, color: '#3f3f3f' }} />,
  },
  ISEO_ARGO_DOOR_OPEN_OUT_OF_FRAME: {
    title: 'iseoArgoDoorOpenOutOfFrameTitle',
    body: 'iseoArgoDoorOpenOutOfFrameDescription',
    icon: <DoorOpenIconCustom style={{ width: 30, marginRight: 40, color: RED }} />,
  }

};

export const BACKGROUND_TASKS_MAX_QUEUE = 2;

export const BACKGROUND_TASK_TYPES = {
  DOWNLOAD_GUESTS_CSV: 'DOWNLOAD_GUESTS_CSV',
  DOWNLOAD_TAG_GUESTS_CSV: 'DOWNLOAD_TAG_GUESTS_CSV',
  DOWNLOAD_TAG_LOCK_CSV: 'DOWNLOAD_TAG_LOCKS_CSV',
  DOWNLOAD_LOCKS_CSV: 'DOWNLOAD_LOCKS_CSV',
  DOWNLOAD_USERS_RESERVATIONS_CSV: 'DOWNLOAD_USERS_RESERVATIONS_CSV',
  DOWNLOAD_RESOURCE_RESERVATIONS_CSV: 'DOWNLOAD_RESOURCE_RESERVATIONS_CSV',
  DOWNLOAD_RESERVATIONS_CSV: 'DOWNLOAD_RESERVATIONS_CSV',
  DOWNLOAD_SMARTLOCKS_EVENTS: 'DOWNLOAD_SMARTLOCKS_EVENTS',
  DOWNLOAD_STANDARD_DEVICES_CSV: 'DOWNLOAD_STANDARD_DEVICES_CSV',
  DOWNLOAD_CREDENTIALS_CSV: 'DOWNLOAD_CREDENTIALS_CSV',
  DELETE_USERS_BATCH: 'DELETE_USERS_BATCH',
  DOWNLOAD_TICKETS_CSV: 'DOWNLOAD_TICKETS_CSV',
};

export const OFFLINE_TIME_UNIT = {
  hours: 'hours',
  minutes: 'minutes',
  days: 'days',
};

export const AREA_TIMEOUT_TIME_UNIT = {
  hours: 'hours',
  minutes: 'minutes',
  days: 'days',
};

export const EXPORT_FORMATS = {
  CSV: 'CSV',
  XLSX: 'XLSX',
};

export const PERMISSIONS = {
  READ: 'READ',
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
  ALL: 'ALL',
  ME: 'ME',
};

export const PERMISSION_ENTITIES = {
  CREDENTIAL: 'CREDENTIAL',
  CREDENTIAL_RULE: 'CREDENTIAL_RULE',
  STANDARD_DEVICE: 'STANDARD_DEVICE',
  USER: 'USER',
  ROLE: 'ROLE',
  USER_TAG: 'USER_TAG',
  LOG: 'LOG',
  SMART_LOCK: 'SMART_LOCK',
  SMART_LOCK_TAG: 'SMART_LOCK_TAG',
  GATEWAY: 'GATEWAY',
  COMPANY: 'COMPANY',
  GATEWAY_NOTIFICATION: 'GATEWAY_NOTIFICATION',
  SUBSCRIPTION: 'SUBSCRIPTION',
  OPEN: 'OPEN',
  ITEM: 'ITEM',
  COMMUNICATION: 'COMMUNICATION',
  INVITATION: 'INVITATION',
  DOMAIN_CLOUD_CREDITS: 'DOMAIN_CLOUD_CREDITS',
  CAMERA: 'CAMERA',
  ACCESS_PROFILES: 'ACCESS_PROFILE',
  VISITORS: 'VISITORS',
  TICKET: 'TICKET',
  AREA: 'AREA',
  LOCKER: 'LOCKER',
};

export const HIDDEN_PERMISSIONS = [
  PERMISSION_ENTITIES.SUBSCRIPTION,
  PERMISSION_ENTITIES.OPEN,
];

export const SPECIAL_ALL_ENTITIES = [
  PERMISSION_ENTITIES.GATEWAY_NOTIFICATION,
  PERMISSION_ENTITIES.ROLE,
  PERMISSION_ENTITIES.ITEM,
  PERMISSION_ENTITIES.COMMUNICATION,
  PERMISSION_ENTITIES.DOMAIN_CLOUD_CREDITS,
  PERMISSION_ENTITIES.INVITATION,
  PERMISSION_ENTITIES.VISITORS,
  PERMISSION_ENTITIES.TICKET,
  PERMISSION_ENTITIES.AREA,
];

export const DEFAULT_PERMISSIONS = {
  USER_ME: 'USER_ME',
  USER_TAG_READ: 'USER_TAG_READ',
  SMART_LOCK_TAG_READ: 'SMART_LOCK_TAG_READ',
  CREDENTIAL_READ: 'CREDENTIAL_READ',
  OPEN: 'OPEN',
  COMPANY_READ: 'COMPANY_READ',
  COMMUNICATION_READ: 'COMMUNICATION_READ',
  ITEM_READ: 'ITEM_READ',
  DOMAIN_CLOUD_CREDITS_READ: 'DOMAIN_CLOUD_CREDITS_READ',
  INVITATION_READ: 'INVITATION_READ',
  SUBSCRIPTION_READ: 'SUBSCRIPTION_READ',
  CUSTOM_FIELDS_ALL: 'CUSTOM_FIELDS_ALL',
  ACCESSORY_ALL: 'ACCESSORY_ALL',
  VISITORS_ALL: 'VISITORS_ALL',
  TICKET_ALL: 'TICKET_ALL',
  AREA_ALL: 'AREA_ALL',
};

export const forbiddenManagerEmailAddresses = ['demo-manager@sofialocks.com'];

export const EVENT_OUTCOME = {
  OPEN_SUCCESS: 'JAGO_MOBILE_0_0',
  OPEN_RFID_SUCCESS: 'JAGO_RFID_0_0',
  CONNECTION_ERROR: 'JAGO_MOBILE_1_0',
  NOT_CONNECTED: 'JAGO_MOBILE_1_1',
  MASTER_MODE_REQUIRED: 'JAGO_MOBILE_1_2',
  IO_GENERIC: 'JAGO_MOBILE_1_10',
  IO_INTERRUPTED: 'JAGO_MOBILE_1_11',
  FIRMWARE_UPD_GENERIC: 'JAGO_MOBILE_1_20',
  FIRMWARE_UPD_DENIED_LOW_BATTERY: 'JAGO_MOBILE_1_21',
  FIRMWARE_UPD_DENIED_UNSUPPORTED: 'JAGO_MOBILE_1_22',
  FIRMWARE_UPD_INIT_ERROR: 'JAGO_MOBILE_1_23',
  FIRMWARE_UPD_IO_ERROR: 'JAGO_MOBILE_1_24',
  CMD_UNSUPPORTED: 'JAGO_MOBILE_1_50',
  CMD_INVALID_REQUEST: 'JAGO_MOBILE_1_51',
  CMD_INVALID_RESPONSE: 'JAGO_MOBILE_1_52',
  CMD_TIMEOUT: 'JAGO_MOBILE_1_53',
  CMD_EC_GENERIC: 'JAGO_MOBILE_1_1000',
  CMD_EC_ALREADY_OPEN: 'JAGO_MOBILE_1_1077',
  CMD_EC_ALREADY_OPEN_PASSAGE_MODE: 'JAGO_MOBILE_1_1082',
  CMD_EC_INDEX: 'JAGO_MOBILE_1_1007',
  CMD_EC_DENIED: 'JAGO_MOBILE_1_1010',
  CMD_EC_DENIED_INVITATION_CODE_NOT_VALID: 'JAGO_MOBILE_1_1098',
  CMD_EC_DENIED_INVITATION_TIMEPROFILE_NOT_FULFILLED: 'JAGO_MOBILE_1_1097',
  CMD_EC_DENIED_INVITATION_VALIDITY_END_EXCEEDED: 'JAGO_MOBILE_1_1096',
  CMD_EC_DENIED_INVITATION_VALIDITY_START_NOT_REACHED: 'JAGO_MOBILE_1_1095',
  CMD_EC_DENIED_INVITATION_PERMISSIONS_INSUFFICIENT: 'JAGO_MOBILE_1_1101',
  CMD_EC_DENIED_PASSAGE_MODE_DISABLED: 'JAGO_MOBILE_1_1099',
  CMD_EC_DENIED_PIN_MISMATCH: 'JAGO_MOBILE_1_1065',
  CMD_EC_DENIED_PIN_NOT_CHANGEABLE: 'JAGO_MOBILE_1_1079',
  CMD_EC_DENIED_PIN_NOT_DISABLEABLE: 'JAGO_MOBILE_1_1078',
  CMD_EC_DENIED_PIN_REQUIRED: 'JAGO_MOBILE_1_1074',
  CMD_EC_DENIED_PRIVACY: 'JAGO_MOBILE_1_1086',
  CMD_EC_DENIED_VIP: 'JAGO_MOBILE_1_1081',
  CMD_EC_DENIED_USER_TOGGLE_PM_NOT_ENABLED: 'JAGO_MOBILE_1_1073',
  CMD_EC_DENIED_USER_AUTH_MISMATCH: 'JAGO_MOBILE_1_1100',
  CMD_EC_DENIED_USER_TIMEPROFILE_NOT_FULFILLED: 'JAGO_MOBILE_1_1093',
  CMD_EC_DENIED_USER_ID_NOT_FOUND: 'JAGO_MOBILE_1_1064',
  CMD_EC_DENIED_USER_VALIDITY_END_EXCEEDED: 'JAGO_MOBILE_1_1092',
  CMD_EC_DENIED_USER_VALIDITY_START_NOT_REACHED: 'JAGO_MOBILE_1_1091',
  CMD_EC_DENIED_USER_VIP_PLUS_NOT_ENABLED: 'JAGO_MOBILE_1_1072',
  CMD_EC_DENIED_USER_PASSWORD_MISMATCH: 'JAGO_MOBILE_1_1102',
  CMD_EC_DENIED_USER_OPEN_INHIBITED: 'JAGO_MOBILE_1_1103',
  CMD_EC_OPEN_FAULT_EXTRA_LOW_BATTERY: 'JAGO_MOBILE_1_1085',
  CMD_EC_OPEN_FAULT_PRECONDITION: 'JAGO_MOBILE_1_1090',
  CMD_EC_PASSWORD_AUTHENTICATION: 'JAGO_MOBILE_1_1094',
  CMD_EC_USER_LIST_IO: 'JAGO_MOBILE_1_1068',
  CMD_EC_USER_LIST_FULL: 'JAGO_MOBILE_1_1067',
  CMD_EC_SYSTEM_BLOCKED: 'JAGO_MOBILE_1_1071',
  CMD_EC_USER_FP_TEMPLATE_NOT_FOUND: 'JAGO_MOBILE_1_2000',
  CMD_EC_USER_FP_TEMPLATE_READ_FAILED: 'JAGO_MOBILE_1_2001',
  CMD_EC_USER_FP_ID_ALREADY_REGISTERED: 'JAGO_MOBILE_1_2010',
  CMD_EC_USER_FP_TEMPLATE_SIMILAR: 'JAGO_MOBILE_1_2083',
  CMD_EC_CFG_GENERIC: 'JAGO_MOBILE_1_3000',
  CMD_EC_CFG_PARAM_KEY_NOT_FOUND: 'JAGO_MOBILE_1_3001',
  CMD_EC_CFG_PARAM_VALUE_INVALID: 'JAGO_MOBILE_1_3002',
  CMD_EC_CFG_PARAM_TYPE_MISMATCH: 'JAGO_MOBILE_1_3009',
  CMD_EC_CFG_PARAM_WRITE_NOT_PERMITTED: 'JAGO_MOBILE_1_3089',
  CMD_EC_LNS_NO_FREE_SLOTS: 'JAGO_MOBILE_1_4001',
  CMD_EC_LNS_DENIED: 'JAGO_MOBILE_1_4002',
  INTERNAL_ERROR: 'JAGO_MOBILE_1_9999',
  OFFLINE_OPEN_ERROR: 'JAGO_MOBILE_2_0',
  GATEWAY_REMOTE_OPEN_SUCCESS: 'JAGO_GATEWAY_0_0',
  GATEWAY_REMOTE_OPEN_ERROR: 'JAGO_GATEWAY_1_0',
  OPEN_PIN_SUCCESS: 'JAGO_PIN_0_0',
};

export const VEGA_OPERATION_CODES = {
  NO_OPERATION: '0',
  DOOR_OPEN: '1',
  OFFICE_MODE_ENABLED: '2',
  OFFICE_MODE_DISABLED: '3',
  EMERGENCY_OPEN_STATUS_ENABLED: '4',
  EMERGENCY_OPEN_STATUS_DISABLED: '5',
  EMERGENCY_OPEN_STATUS_ENABLED_DATE_INVALID: '6',
  DOOR_OPEN_WITH_EMERGENCY_KEY: '7',
  DOOR_OPEN_WITH_EMERGENCY_KEY_DATE_INVALID: '8',
  DOOR_OPEN_WITH_FIRE_RESCUE: '10',
  LOCKOUT_ENABLED: '11', // TODO understand
  LOCKOUT_REMOVED: '12',
  KEY_INSERTED_IN_BLACK_LIST: '16',
  KEY_ACTIVATED_BY_PIN_CODE: '29',
  KEY_EXTRACTED_FROM_CYLINDER: '30',
  OFFICE_MODE_ENABLED_USING_TIME_PROFILE: '32',
  OFFICE_MODE_DISABLED_USING_TIME_PROFILE: '33',
  PRIVACY_MODE_ENABLED: '34',
  PRIVACY_MODE_DISABLED: '35',
  OWNERSHIP_STATUS_CLEARED: '36',
  VALIDATION_INHIBIT_MODE_ENABLED: '37',
  VALIDATION_INHIBIT_MODE_DISABLED: '38',
  VALIDATION_INHIBIT_MODE_DISABLED_TIMEOUT_EXPIRED: '39',
  MAINTENANCE_CARD: '40',
  SETUP_MODE_ENABLED: '41',
  MANUAL_OFFICE_MODE_DISABLED_WITH_TIME_PROFILE: '42',
  MAINTENANCE_CARD_INVALID_DATE: '43',
  DOOR_OPEN_WITH_MECHANICAL_KEY: '48',
  KEY_REMOVED_FROM_CYLINDER: '49',
  REMOTE_OPEN: '50',
  DOOR_INIT: '52',
  DOOR_FACTORY_RESET: '53',
  DOOR_UPDATED: '54',
  DOOR_POSITION_REPORTING: '60',
  CSF_SERVICE_KEY: '61',
  KEY_UNKNOWN_BY_THE_SYSTEM: '63',
};

export const VEGA_RESULTS_CODES = {
  OPERATION_COMPLETED_SUCCESSFULLY: '0',
  WRONG_CUSTOMER_CODE: '1',
  WRONG_SITE_CODE: '2',
  CREDENTIAL_NOT_ENABLED_FOR_GATE: '3',
  PIN_CODE_NOT_VALID_FOR_OPERATION: '4',
  CREDENTIAL_OUT_OF_VALIDITY_PERIOD: '5',
  VALIDATION_PERIOD_EXPIRED: '6',
  VALIDATION_PERIOD_SUSPENDED: '7',
  VALIDATION_MODE_NOT_SUPPORTED: '8',
  CREDENTIAL_INVALID_TIME_RANGE: '9',
  PRIVACY_STATUS_IS_ON: '10',
  CREDENTIAL_OUT_OF_VALIDITY_PERIOD_PROFILE_ENABLED: '11',
  KEY_IS_LOCKED_OUT_MODE: '12',
  FUNCTION_NOT_SUPPORTED_FOR_THIS_GATE: '13',
  FUNCTION_IS_INHIBITED_ON_THIS_GATE: '14',
  FUNCTION_IS_USELESS_FOR_THIS_GATE: '15',
  KEY_IS_IN_BLACK_LIST: '16',
  BLACKLIST_IS_FULL: '17',
  KEY_NO_MORE_OWNERSHIP_OF_THE_GATE: '18',
  KEY_ARE_NOT_ACTIVATED: '19',
  CARD_INVALIDATED_DURING_WRITE_OPERATION: '20',
  ERROR_WRITING_LOG: '100',
  KEY_LOGS_ARE_FULL: '101',
  INVALID_KEY_CREDENTIAL_ID_NULL: '129',
  CREDENTIAL_NOT_FOUND_IN_ATLAS: '130',
  UNSUPPORTED_FUNCTION: '254',
  GENERIC_ERROR: '255',
};

export const OFFICE_MODE_PROGRAM_TYPES = {
  AUTOMATIC: 'AUTOMATIC',
  SEMIAUTOMATIC: 'SEMIAUTOMATIC',
};


export const SMARTLOCKS_PROGRAM_TYPES = {
  OFFICE_MODE: 'OFFICE_MODE',
};

export const SMARTLOCK_PROGRAM_STATUSES = {
  SYNCED: 'SYNCED',
  TO_BE_SYNCED: 'TO_BE_SYNCED',
  FAILED: 'FAILED',
};


export const OFFICE_MODE_SMARTLOCK_MODELS_ENABLED = [
  'LIBRA',
  'ARIES',
  'LOCKER',
  'STYLOS',
  'MAYA',
  'STYLOS_DISPLAY',
  'IMArgoLib.X1revo',
  'X1REVO',
  'MULLION',
  'MULLION_KB',
  'MULLION_RELAY',
  'MULLION_RELAY_KB',
  'EMOTION_SMART',
];


export const ITEM_TYPES = {
  MASTER_CARD: 'MASTER_CARD',
  PHYSICAL_KEY: 'PHYSICAL_KEY',
  PHYSICAL_KEY_MK: 'PHYSICAL_KEY_MK',
  PHYSICAL: 'PHYSICAL',
  DIGITAL: 'DIGITAL',
  OTHER: 'OTHER',
};


export const ITEM_TYPES_NAMES = {
  PHYSICAL: 'itemTypePhysical',
  DIGITAL: 'itemTypeDigital',
  OTHER: 'itemTypeGeneric',
  MASTER_CARD: 'itemTypeMasterCard',
  PHYSICAL_KEY: 'itemTypePhysicalKey',
  PHYSICAL_KEY_MK: 'itemTypePhysicalMasterKey',
};

export const ITEM_DELIVERY_TYPES = {
  COLLECTION_ON_SITE: 'COLLECTION_ON_SITE',
  STANDARD_COURIER: 'STANDARD_COURIER',
  MAIL: 'MAIL',
  OTHER_CHANNELS: 'OTHER_CHANNELS',
};


export const ITEM_DELIVERY_TYPES_NAMES = {
  COLLECTION_ON_SITE: 'itemDeliveryModeCollectionOnSite',
  EXPRESS_COURIER: 'itemDeliveryModeExpressCourier',
  STANDARD_COURIER: 'itemDeliveryModeStandardCourier',
  MAIL: 'itemDeliveryModeMail',
  OTHER_CHANNELS: 'itemDeliveryModeOther',
};

export const ACCESS_METHODS = {
  STANDARD: 'STANDARD',
  PIN: 'PIN',
  SMART: 'SMART',
  F9000: 'F9000',
  ITEM: 'ITEM',
};


export const COMMUNICATION_STATUSES = {
  VISIBLE: 'VISIBLE',
  NOT_YET_VISIBLE: 'NOT_YET_VISIBLE',
  NOT_VISIBLE: 'NOT_VISIBLE',
};

export const COMMUNICATION_STATUSES_PARAMETERS = {
  [COMMUNICATION_STATUSES.VISIBLE]: {
    color: 'green',
    string: 'communicationVisible',
  },
  [COMMUNICATION_STATUSES.NOT_YET_VISIBLE]: {
    color: '#ef6c00',
    string: 'communicationEarly',
  },
  [COMMUNICATION_STATUSES.NOT_VISIBLE]: {
    color: 'grey', // 'red'
    string: 'communicationNotVisible',
  },
};

export const USER_COMMUNICATION_EMAIL_STATUSES = {
  NOT_SENT: 'NOT_SENT',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
};

export const USER_COMMUNICATION_EMAIL_STATUSES_PARAMETERS = {
  [USER_COMMUNICATION_EMAIL_STATUSES.NOT_SENT]: {
    value: USER_COMMUNICATION_EMAIL_STATUSES.NOT_SENT,
    color: 'grey',
    string: 'userCommunicationEmailStatusNotSent',
  },
  [USER_COMMUNICATION_EMAIL_STATUSES.SUCCESS]: {
    value: USER_COMMUNICATION_EMAIL_STATUSES.SUCCESS,
    color: 'green',
    string: 'userCommunicationEmailStatusSuccess',
  },
  [USER_COMMUNICATION_EMAIL_STATUSES.FAIL]: {
    value: USER_COMMUNICATION_EMAIL_STATUSES.FAIL,
    color: 'red',
    string: 'userCommunicationEmailStatusFail',
  },
};

export const ATLAS_NETWORK_STATUS = {
  OFF_LINE: 'OFF_LINE',
  ON_LINE: 'ON_LINE',
  UNKNOWN: 'UNKNOWN',
};

export const ATLAS_NETWORK_STATUS_PARAMETERS = {
  [ATLAS_NETWORK_STATUS.OFF_LINE]: {
    color: 'red',
    title: 'atlasOfflineTitle',
    description: 'atlasOfflineDescription',
  },
  [ATLAS_NETWORK_STATUS.ON_LINE]: {
    color: 'green',
    title: 'atlasOnlineTitle',
    description: 'atlasOnlineDescription',
  },
  [ATLAS_NETWORK_STATUS.UNKNOWN]: {
    color: 'gray',
    title: 'atlasNetworkUnknownTitle',
    description: 'atlasNetworkUnknownDescription',
  },
};

export const ATLAS_SYNCHRONIZATION_STATUS = {
  BAD_SYNCRO: 'BAD_SYNCRO',
  SYNCRONIZED: 'SYNCRONIZED',
  NOT_SYNCRONIZED: 'NOT_SYNCRONIZED',
  UNKNOWN: 'UNKNOWN',
};


export const ATLAS_SYNCHRONIZATION_STATUS_PARAMETERS = {
  [ATLAS_SYNCHRONIZATION_STATUS.BAD_SYNCRO]: {
    color: 'red',
    title: 'atlasBadSyncroTitle',
    description: 'atlasBadSyncroDescription',
  },
  [ATLAS_SYNCHRONIZATION_STATUS.SYNCRONIZED]: {
    color: 'green',
    title: 'atlasSyncronizedTitle',
    description: 'atlasSyncronizedDescription',
  },
  [ATLAS_SYNCHRONIZATION_STATUS.NOT_SYNCRONIZED]: {
    color: '#ef6c00',
    title: 'atlasNotSyncronizedTitle',
    description: 'atlasNotSyncronizedDescription',
  },
  [ATLAS_SYNCHRONIZATION_STATUS.UNKNOWN]: {
    color: 'gray',
    title: 'atlasSyncronizationUnknownTitle',
    description: 'atlasSyncronizationUnknownDescription',
  },
};

export const FRESHDESK_DEMO_REQUEST_GROUP_ID = 2043001823679;

export const CREDENTIAL_CATEGORIES = {
  MOBILE: 'MOBILE',
  RFID: 'RFID',
  F9000: 'F9000',
  PIN: 'PIN',
};

export const CREDENTIALS_FILTERS_MAP = {
  [CREDENTIAL_CATEGORIES.MOBILE]: {
    type: CREDENTIAL_TYPES.SMART,
  },
  [CREDENTIAL_CATEGORIES.RFID]: {
    type: CREDENTIAL_TYPES.STANDARD,
    deviceTypes: [CARD_TYPES.GENERIC_CARD, CARD_TYPES.GENERIC_CARD_DESFIRE, CARD_TYPES.ISEO_CARD, CARD_TYPES.ISEO_CARD_DESFIRE],
  },
  [CREDENTIAL_CATEGORIES.F9000]: {
    type: CREDENTIAL_TYPES.STANDARD,
    deviceTypes: ['F9000'],
  },
  [CREDENTIAL_CATEGORIES.PIN]: {
    type: CREDENTIAL_TYPES.STANDARD,
    deviceTypes: [CARD_TYPES.ISEO_PIN],
  },
};

export const TWO_FACTOR_TYPES = {
  MAIL_OTP: 'MAIL_OTP',
};

export const RESOURCE_UNAVAILABILITY_REASON = {
  BLOCK: 'BLOCK',
};

export const RESOURCE_UNAVAILABILITY_REASON_PROPERTIES_MAP = {
  [RESOURCE_UNAVAILABILITY_REASON.BLOCK]: {
    title: 'unavailabilityTypeBlockTitle',
    description: 'unavailabilityTypeBlockDescription',
  },
};

export const SMARTLOCK_DOOR_STATUS = {
  UNKNOWN: 'UNKNOWN',
  OPENED: 'OPENED',
  CLOSED: 'CLOSED',
};

export const SMARTLOCK_DOOR_STATUS_MAP = {
  [SMARTLOCK_DOOR_STATUS.UNKNOWN]: {
    color: 'gray',
    title: 'doorStatusUnknown',
    icon: <WarningIcon style={{ fontSize: 18, color: 'gray' }} />,
  },
  [SMARTLOCK_DOOR_STATUS.OPENED]: {
    color: RED,
    title: 'doorStatusOpened',
    icon: <DoorOpenIconCustom style={{ width: 18, color: RED }} />,
  },
  [SMARTLOCK_DOOR_STATUS.CLOSED]: {
    color: GREEN,
    title: 'doorStatusClosed',
    icon: <DoorIconCustom style={{ width: 18, color: GREEN }} />,
  },
};


export const WALLET_EVENT_TYPE = {
  DELETION_EVENT: 'DELETION_EVENT',
  ACTIVATION_EVENT: 'ACTIVATION_EVENT',
};

export const CUSTOM_FIELDS_ENTITY_TYPES = {
  USER: 'USER',
  LOCK: 'LOCK',
};

export const CUSTOM_FIELDS_TYPES = {
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
  INTEGRATION_ID: 'INTEGRATION_ID',
};

export const CUSTOM_FIELDS_TYPES_ICONS = {
  TEXT: <TextEditIconCustom style={{ width: 20, color: '#3f3f3f' }} />,
  NUMBER: <CounterIconCustom style={{ width: 20, color: '#3f3f3f' }} />,
  DATE: <CalendarEditIconCustom style={{ width: 20, color: '#3f3f3f' }} />,
};

export const CUSTOM_FIELDS_FORM_TYPE = {
  [CUSTOM_FIELDS_TYPES.TEXT]: 'text',
  [CUSTOM_FIELDS_TYPES.DATE]: 'airbnbDate',
  [CUSTOM_FIELDS_TYPES.NUMBER]: 'number',
};

export const MAX_GUEST_TAGS_LITE_LIMIT = 2;
export const MAX_LOCKS_TAGS_LITE_LIMIT = 2;
export const CAMERAS_PAGE_SIZE = 6;

export const ACCESSORIES_TYPES = {
  DOOR_SENSOR: 'DOOR_SENSOR',
  QR_CODE: 'QR_CODE',
  HYPER_TAG: 'HYPER_TAG',
  BT_READER: 'BT_READER'
};

export const EAGLE_EYE_CUSTOM_FIELD_DTO = {
  name: 'Eagle Eye Camera',
  type: CUSTOM_FIELDS_TYPES.INTEGRATION_ID,
  entity: 'LOCK',
};

export const STORE_ITEMS_TYPES = {
  INTEGRATION: 'INTEGRATION',
  ADDON: 'ADDON',
  PROFESSIONAL_SERVICES: 'PROFESSIONAL_SERVICES',
  HARDWARE: 'HARDWARE',
};

export const STORE_ITEMS_TYPES_MAP = {
  INTEGRATION: {
    name: 'luckeyStoreItemTypeIntegration',
    nameSingle: 'luckeyStoreItemTypeIntegrationSingle',
    icon: <IntegrationIconCustom style={{ width: 20 }} />,
  },
  ADDON: {
    name: 'luckeyStoreItemTypeAddon',
    nameSingle: 'luckeyStoreItemTypeAddonSingle',
    icon: <AddonIconCustom style={{ width: 20 }} />,
  },
  PROFESSIONAL_SERVICES: {
    name: 'luckeyStoreItemTypeService',
    nameSingle: 'luckeyStoreItemTypeServiceSingle',
    icon: <ServicesIconCustom style={{ width: 20 }} />,
  },
  HARDWARE: {
    name: 'luckeyStoreItemTypeHardware',
    nameSingle: 'luckeyStoreItemTypeHardwareSingle',
    icon: <SmartLockAddIconCustom style={{ width: 20 }} />,
  }
};

export const STORE_ITEMS_STATUS_MAP = {
  AVAILABLE: {
    name: 'luckeyStoreItemAvailable',
    icon: <PluginActiveIconCustom style={{ width: 19 }} />,
    color: GREEN,
  },
  IN_DEVELOPMENT: {
    name: 'luckeyStoreItemInDevelopment',
    icon: <SettingsIcon style={{ fontSize: 18 }} />,
    color: ORANGE
  },
};

export const STORE_ITEMS_CATEGORIES = {
  PMS: 'PMS',
  TENANT_APP: 'TENANT_APP',
  AUTHENTICATION_TECHNOLOGIES: 'AUTHENTICATION_TECHNOLOGIES',
  BOOKING_SYSTEMS: 'BOOKING_SYSTEMS',
  TIME_ATTENDANCE: 'TIME_ATTENDANCE',
  BIM_TECHNOLOGIES: 'BIM_TECHNOLOGIES',
  BUILDING_TECHNOLOGIES_IOT: 'BUILDING_TECHNOLOGIES_IOT',
  PROPERTY_MANAGEMENT: 'PROPERTY_MANAGEMENT',
  ACCESS_MANAGEMENT: 'ACCESS_MANAGEMENT',
  CUSTOMIZATIONS: 'CUSTOMIZATIONS',
  BLUETOOTH_RFID_DEVICES: 'BLUETOOTH_RFID_DEVICES',
  MECHATRONIC: 'MECHATRONIC',
  NETWORK_DEVICES: 'NETWORK_DEVICES',
  SENSORS_AND_ACCESSORIES: 'SENSORS_AND_ACCESSORIES',
  CUSTOMER_SUPPORT: 'CUSTOMER_SUPPORT',
};

export const STORE_ITEMS_CATEGORIES_MAP = {
  PMS: {
    name: 'luckeyStoreItemCategoryPMS',
    nameShort: 'luckeyStoreItemCategoryPMSShort',
    icon: <PMSIconCustom style={{ width: 20 }} />,
  },
  TENANT_APP: {
    name: 'luckeyStoreItemCategoryTenant',
    nameShort: 'luckeyStoreItemCategoryTenantShort',
    icon: <TenantAppIconCustom style={{ width: 20 }} />,
  },
  AUTHENTICATION_TECHNOLOGIES: {
    name: 'luckeyStoreItemCategoryAuthTech',
    nameShort: 'luckeyStoreItemCategoryAuthTechShort',
    icon: <AuthTechnologiesIconCustom style={{ width: 20 }} />,
  },
  BOOKING_SYSTEMS: {
    name: 'luckeyStoreItemCategoryBookingSystems',
    nameShort: 'luckeyStoreItemCategoryBookingSystemsShort',
    icon: <BookingIconCustom style={{ width: 20 }} />,
  },
  TIME_ATTENDANCE: {
    name: 'luckeyStoreItemCategoryTimeAttendance',
    nameShort: 'luckeyStoreItemCategoryTimeAttendanceShort',
    icon: <TimeAttendanceIconCustom style={{ width: 20 }} />,
  },
  BIM_TECHNOLOGIES: {
    name: 'luckeyStoreItemCategoryBimTechnologies',
    nameShort: 'luckeyStoreItemCategoryBimTechnologiesShort',
    icon: <BimTechnologiesIconCustom style={{ width: 20 }} />,
  },
  BUILDING_TECHNOLOGIES_IOT: {
    name: 'luckeyStoreItemCategoryBuildingTechnologiesIot',
    nameShort: 'luckeyStoreItemCategoryBuildingTechnologiesIotShort',
    icon: <BuildingTechnologiesIconCustom style={{ width: 20 }} />,
  },
  PROPERTY_MANAGEMENT: {
    name: 'luckeyStoreItemCategoryBuildingManagement',
    nameShort: 'luckeyStoreItemCategoryBuildingManagementShort',
    icon: <PropertyManagementIconCustom style={{ width: 20 }} />,
  },
  ACCESS_MANAGEMENT: {
    name: 'luckeyStoreItemCategoryAccessManagement',
    nameShort: 'luckeyStoreItemCategoryAccessManagementShort',
    icon: <AccessCloudIconCustom style={{ width: 22 }} />,
  },
  CUSTOMIZATIONS: {
    name: 'luckeyStoreItemCategoryCustomizations',
    nameShort: 'luckeyStoreItemCategoryCustomizationsShort',
    icon: <CustomDesignIconCustom style={{ width: 20 }} />,
  },
  BLUETOOTH_RFID_DEVICES: {
    name: 'luckeyStoreItemCategoryBluetoothRFID',
    nameShort: 'luckeyStoreItemCategoryBluetoothRFIDShort',
    icon: <RfidBluetoothIconCustom style={{ width: 22 }} />,
  },
  MECHATRONIC: {
    name: 'luckeyStoreItemCategoryMechatronic',
    nameShort: 'luckeyStoreItemCategoryMechatronicShort',
    icon: <KeyOutlinedIconCustom style={{ width: 20 }} />,
  },
  NETWORK_DEVICES: {
    name: 'luckeyStoreItemCategoryNetworkDevices',
    nameShort: 'luckeyStoreItemCategoryNetworkDevicesShort',
    icon: <GatewayIcon style={{ fontSize: 20 }} />,
  },
  SENSORS_AND_ACCESSORIES: {
    name: 'luckeyStoreItemCategorySensorsAndAccessories',
    nameShort: 'luckeyStoreItemCategorySensorsAndAccessories',
    icon: <SensorAccessoryIconCustom style={{ width: 20 }} />,
  },
  CUSTOMER_SUPPORT: {
    name: 'luckeyStoreItemCategoryCustomerSupport',
    nameShort: 'luckeyStoreItemCategoryCustomerSupportShort',
    icon: <SupportAgentIconCustom style={{ width: 20 }} />,
  }
};


export const SUBSCRIPTION_TYPES = {
  BOOKEY: 'BOOKEY',
  DOMAIN: 'DOMAIN',
  USERS: 'USERS',
  LOCKS: 'LOCKS',
  INVITATIONS: 'INVITATIONS',
  REGISTRY: 'REGISTRY',
  'USERS+LOCKS': 'USERS+LOCKS',
  COMMUNICATIONS: 'COMMUNICATIONS',
  F9000: 'F9000',
  ACL: 'ACL',
  BATCH_OPERATIONS: 'BATCH_OPERATIONS',
  CUSTOM_FIELDS: 'CUSTOM_FIELDS',
  EAGLE_EYE_CAMERAS_PLUGIN: 'EAGLE_EYE_CAMERAS_PLUGIN',
  NEXUDUS_INTEGRATION: 'NEXUDUS_INTEGRATION',
  COBOT_INTEGRATION: 'COBOT_INTEGRATION',
  V364: 'V364',
  ANONYMOUS_USERS: 'ANONYMOUS_USERS',
  RFID_ADVANCED_ADDON: 'RFID_ADVANCED_ADDON',
  OFFICERND_INTEGRATION: 'OFFICERND_INTEGRATION',
  ACCESS_PROFILES_ADDON: 'ACCESS_PROFILES_ADDON',
  CHAINELS_INTEGRATION: 'CHAINELS_INTEGRATION',
  ANDCARDS_INTEGRATION: 'ANDCARDS_INTEGRATION',
  OFFICE365_INTEGRATION: 'OFFICE365_INTEGRATION',
  VISITORS_MANAGEMENT: 'VISITORS_MANAGEMENT',
  CLOUDBEDS_INTEGRATION: 'CLOUDBEDS_INTEGRATION',
  MEWS_INTEGRATION: 'MEWS_INTEGRATION',
  OPTIX_INTEGRATION: 'OPTIX_INTEGRATION',
  TENUP_INTEGRATION: 'TENUP_INTEGRATION',
  TICKETING_MANAGEMENT: 'TICKETING_MANAGEMENT',
  ZAPFLOOR_INTEGRATION: 'ZAPFLOOR_INTEGRATION',
  OCTORATE_INTEGRATION: 'OCTORATE_INTEGRATION',
  CAPACITY_MANAGEMENT: 'CAPACITY_MANAGEMENT',
  PARCEL_MANAGEMENT: 'PARCEL_MANAGEMENT',
  RES_HARMONICS_INTEGRATION: 'RES_HARMONICS_INTEGRATION',
  PRIVATE_UNITS_MANAGEMENT: 'PRIVATE_UNITS_MANAGEMENT',
  FLUIDA_INTEGRATION: 'FLUIDA_INTEGRATION',
};

export const INTEGRATIONS_VALUES_MAP = {
  [SUBSCRIPTION_TYPES.NEXUDUS_INTEGRATION]: {
    serviceName: 'nexudus-connector',
  },
  [SUBSCRIPTION_TYPES.COBOT_INTEGRATION]: {
    serviceName: 'cobot-connector',
  },
  [SUBSCRIPTION_TYPES.EAGLE_EYE_CAMERAS_PLUGIN]: {
    serviceName: 'EAGLE_EYE',
  },
  [SUBSCRIPTION_TYPES.OFFICERND_INTEGRATION]: {
    serviceName: 'officernd-connector',
  },
  [SUBSCRIPTION_TYPES.CHAINELS_INTEGRATION]: {
    serviceName: 'chainels-connector',
  },
  [SUBSCRIPTION_TYPES.ANDCARDS_INTEGRATION]: {
    serviceName: 'andcards-connector',
  },
  [SUBSCRIPTION_TYPES.OFFICE365_INTEGRATION]: {
    serviceName: 'office365-connector',
  },
  [SUBSCRIPTION_TYPES.CLOUDBEDS_INTEGRATION]: {
    serviceName: 'cloudbeds-connector',
  },
  [SUBSCRIPTION_TYPES.MEWS_INTEGRATION]: {
    serviceName: 'mews-connector',
  },
  [SUBSCRIPTION_TYPES.OPTIX_INTEGRATION]: {
    serviceName: 'optixapp-connector',
  },
  [SUBSCRIPTION_TYPES.TENUP_INTEGRATION]: {
    serviceName: 'tenup-connector',
  },
  [SUBSCRIPTION_TYPES.ZAPFLOOR_INTEGRATION]: {
    serviceName: 'zapfloor-connector',
  },
  [SUBSCRIPTION_TYPES.OCTORATE_INTEGRATION]: {
    serviceName: 'octorate-connector',
  },
  [SUBSCRIPTION_TYPES.FLUIDA_INTEGRATION]: {
    serviceName: 'fluida-connector',
  }
};

export const PLUGIN_STATUS = {
  AVAILABLE: 'AVAILABLE',
  IN_DEVELOPMENT: 'IN_DEVELOPMENT',
}

export const BANNER_ITEMS = {
  MAIN_BANNER: {
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 3233,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 3227,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 3228,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 3229,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 3231,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 3230,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3232,
    },
  },
  WORKING_SPACES: {
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 755,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 14,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 756,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 757,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 759,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 758,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3226,
    },
  },
  LIVING: {
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 864,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 863,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 865,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 866,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 868,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 867,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3225,
    },
  },
  RETAIL: {
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 869,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 871,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 872,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 874,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 876,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 875,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3224,
    },
  },
  HOSPITAL: {
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 3403,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 3401,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 3402,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 3407,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 3405,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 3406,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3404,
    },
  },
};

export const LOGIN_STORE_CONTENT_ITEMS = {
  LOGIN_CONTENT: {
    postIds: {
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 5300,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 5295,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 5302,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 5304,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 5306,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 5308,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 5310,
    },
  },
  LOGIN_CONTENT_STAGING: {
    postIds: {
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 5318,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 5297,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 5320,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 5322,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 5312,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 5314,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 5316,
    },
  },
};
// it, en, fr, de, es, pl, nl
export const STORE_ITEMS = {
  BOOKEY: {
    licenseName: 'BOOKEY',
    type: STORE_ITEMS_TYPES.ADDON,
    category: STORE_ITEMS_CATEGORIES.PROPERTY_MANAGEMENT,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 7,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 387,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 445,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 579,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 678,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 722,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3065,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isRFIDDomain = isLicenseRFID(subscriptions);
      return !isRFIDDomain;
    },
  },
  INVITATIONS: {
    licenseName: 'INVITATIONS',
    type: STORE_ITEMS_TYPES.ADDON,
    category: STORE_ITEMS_CATEGORIES.ACCESS_MANAGEMENT,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 57,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 405,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 478,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 569,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 668,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 712,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3060,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const { numOfArgoLocks, numOfLocks } = companyConfig;
      const isRFIDDomain = isLicenseRFID(subscriptions);
      return (numOfLocks === 0 || numOfArgoLocks > 0) && !isRFIDDomain;
    },
  },
  COMMUNICATIONS: {
    licenseName: 'COMMUNICATIONS',
    type: STORE_ITEMS_TYPES.ADDON,
    category: STORE_ITEMS_CATEGORIES.PROPERTY_MANAGEMENT,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 20,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 391,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 449,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 575,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 672,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 718,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3116,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      return !isLiteDomain;
    },
  },
  TICKETING_MANAGEMENT: {
    licenseName: 'TICKETING_MANAGEMENT',
    type: STORE_ITEMS_TYPES.ADDON,
    category: STORE_ITEMS_CATEGORIES.PROPERTY_MANAGEMENT,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 4352,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 4315,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 4344,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 4348,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 4360,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 4356,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 4341,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      const isRFIDDomain = isLicenseRFID(subscriptions);
      return !isLiteDomain && !isRFIDDomain;
    },
  },
  VISITORS_MANAGEMENT: {
    licenseName: 'VISITORS_MANAGEMENT',
    type: STORE_ITEMS_TYPES.ADDON,
    category: STORE_ITEMS_CATEGORIES.ACCESS_MANAGEMENT,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 3329,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 3365,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 3369,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 3373,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 3377,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 3381,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3361,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      const isRFIDDomain = isLicenseRFID(subscriptions);
      return !isLiteDomain && !isRFIDDomain;
    },
  },
  CAPACITY_MANAGEMENT: {
    licenseName: 'CAPACITY_MANAGEMENT',
    type: STORE_ITEMS_TYPES.ADDON,
    category: STORE_ITEMS_CATEGORIES.PROPERTY_MANAGEMENT,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 4422,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 4442,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 4448,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 4440,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 4450,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 4452,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 4446,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      const isRFIDDomain = isLicenseRFID(subscriptions);
      return !isLiteDomain && !isRFIDDomain;
    },
  },
  PARCEL_MANAGEMENT: {
    licenseName: 'PARCEL_MANAGEMENT',
    type: STORE_ITEMS_TYPES.ADDON,
    category: STORE_ITEMS_CATEGORIES.PROPERTY_MANAGEMENT,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 4876,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 4898,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 4900,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 4894,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 4906,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 4896,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 4904,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      return !isLiteDomain;
    },
  },
  REGISTRY: {
    licenseName: 'REGISTRY',
    type: STORE_ITEMS_TYPES.ADDON,
    category: STORE_ITEMS_CATEGORIES.PROPERTY_MANAGEMENT,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 22,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 403,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 447,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 577,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 676,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 720,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3107,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      return !isLiteDomain;
    },
  },
  CUSTOM_FIELDS: {
    licenseName: 'CUSTOM_FIELDS',
    type: STORE_ITEMS_TYPES.ADDON,
    category: STORE_ITEMS_CATEGORIES.PROPERTY_MANAGEMENT,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 963,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 949,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 957,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 961,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 967,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 965,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3152,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      return !isLiteDomain;
    },
  },
  PIN_SHARING_DELIVERY: {
    licenseName: 'PIN_SHARING_DELIVERY',
    type: STORE_ITEMS_TYPES.ADDON,
    category: STORE_ITEMS_CATEGORIES.ACCESS_MANAGEMENT,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 813,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 821,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 823,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 825,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 829,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 827,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3080,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      const isRFIDDomain = isLicenseRFID(subscriptions);
      return !isLiteDomain && !isRFIDDomain;
    },
  },
  CUSTOM_PRIVACY_SETTINGS: {
    licenseName: 'CUSTOM_PRIVACY_SETTINGS',
    type: STORE_ITEMS_TYPES.PROFESSIONAL_SERVICES,
    category: STORE_ITEMS_CATEGORIES.CUSTOMIZATIONS,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 2293,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 2304,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 2307,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 2311,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 2319,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 2315,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3088,
    },
    isCompatible: (companyConfig, subscriptions) => {
      return true;
    },
  },
  BIG_DATA_ANALYTICS: {
    licenseName: 'BIG_DATA_ANALYTICS',
    type: STORE_ITEMS_TYPES.ADDON,
    category: STORE_ITEMS_CATEGORIES.PROPERTY_MANAGEMENT,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 170,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 410,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 464,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 522,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 632,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 684,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3098,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      return !isLiteDomain;
    },
  },
  F9000: {
    licenseName: 'F9000',
    type: STORE_ITEMS_TYPES.ADDON,
    category: STORE_ITEMS_CATEGORIES.PROPERTY_MANAGEMENT,
    postIds: {
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 5445,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 5420,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 5447,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 5449,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 5443,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 5451,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 5453,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isV364BaseSubscriptionDomain = isV364BaseSubscription(subscriptions);
      return isV364BaseSubscriptionDomain;
    },
    incompatibleAdditionalNotes: 'incompatibleAdditionalNotesF9000',
  },
  HYPERSENSOR_DS_ADDON: {
    licenseName: 'HYPERSENSOR_DS_ADDON',
    type: STORE_ITEMS_TYPES.ADDON,
    category: STORE_ITEMS_CATEGORIES.PROPERTY_MANAGEMENT,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 1241,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 1256,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 1258,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 1260,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 1263,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 1265,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3070,
    },
    isCompatible: (companyConfig, subscriptions) => {
      return true;
    },
  },
  HYPERBRIDGE_MANAGEMENT: {
    licenseName: 'HYPERBRIDGE_MANAGEMENT',
    type: STORE_ITEMS_TYPES.ADDON,
    category: STORE_ITEMS_CATEGORIES.PROPERTY_MANAGEMENT,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 4058,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 4102,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 4106,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 4134,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 4138,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 4142,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 4145,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      return !isLiteDomain;
    },
  },
  ELEVATOR_CONTROL: {
    licenseName: 'ELEVATOR_CONTROL',
    type: STORE_ITEMS_TYPES.ADDON,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 159,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 412,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 468,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 526,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 634,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 686,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3111,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const vegaSubscription = _.find(subscriptions, sub => sub && sub.resource === 'V364');
      const isLiteDomain = isLicenseBasic(subscriptions);
      return vegaSubscription && !isLiteDomain;
    },
  },
  MAIL_DNS: {
    licenseName: 'MAIL_DNS',
    type: STORE_ITEMS_TYPES.PROFESSIONAL_SERVICES,
    category: STORE_ITEMS_CATEGORIES.CUSTOMIZATIONS,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 201,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 383,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 456,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 514,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 626,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 680,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3093,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      const isRFIDDomain = isLicenseRFID(subscriptions);
      return !isLiteDomain && !isRFIDDomain;
    },
  },
  EXTRA_DOMAIN: {
    licenseName: 'EXTRA_DOMAIN',
    type: STORE_ITEMS_TYPES.ADDON,
    category: STORE_ITEMS_CATEGORIES.PROPERTY_MANAGEMENT,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 1215,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 1223,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 1227,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 1233,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 1235,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 1239,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3077,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      return !isLiteDomain;
    },
  },
  CUSTOM_MOBILE_APP: {
    licenseName: 'CUSTOM_MOBILE_APP',
    category: STORE_ITEMS_CATEGORIES.CUSTOMIZATIONS,
    type: STORE_ITEMS_TYPES.PROFESSIONAL_SERVICES,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 1148,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 1156,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 1164,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 1160,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 1172,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 1168,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3101,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      const isRFIDDomain = isLicenseRFID(subscriptions);
      return !isLiteDomain && !isRFIDDomain;
    },
  },
  EXTENDED_SUPPORT: {
    licenseName: 'EXTENDED_SUPPORT',
    category: STORE_ITEMS_CATEGORIES.CUSTOMER_SUPPORT,
    postIds: {
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 5363,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 5340,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 5365,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 5367,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 5369,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 5361,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 5379,
    },
    type: STORE_ITEMS_TYPES.PROFESSIONAL_SERVICES,
    isCompatible: (companyConfig, subscriptions) => {
      return true;
    },
  },
  STANDARD_SUPPORT: {
    licenseName: 'STANDARD_SUPPORT',
    category: STORE_ITEMS_CATEGORIES.CUSTOMER_SUPPORT,
    postIds: {
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 5377,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 5338,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 5375,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 5373,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 5371,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 5381,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 5359,
    },
    type: STORE_ITEMS_TYPES.PROFESSIONAL_SERVICES,
    isCompatible: (companyConfig, subscriptions) => {
      return true;
    },
  },
  EAGLE_EYE_CAMERAS_PLUGIN: {
    licenseName: 'EAGLE_EYE_CAMERAS_PLUGIN',
    provider: 'Sofia',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.BUILDING_TECHNOLOGIES_IOT,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 45,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 440,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 482,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 571,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 670,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 716,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3210,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isLiteDomain && !isRFIDDomain && !isEngineDomain;
    },
  },
  COBOT_INTEGRATION: {
    licenseName: 'COBOT_INTEGRATION',
    provider: 'Sofia',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.PMS,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 115,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 420,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 496,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 547,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 652,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 698,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3204,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isRFIDDomain && !isEngineDomain;
    },
  },
  OCTORATE_INTEGRATION: {
    licenseName: 'OCTORATE_INTEGRATION',
    provider: 'Sofia',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.PMS,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 4402,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 4374,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 4394,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 4398,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 4410,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 4406,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 4391,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isRFIDDomain && !isEngineDomain;
    },
  },
  ANDCARDS_INTEGRATION: {
    licenseName: 'ANDCARDS_INTEGRATION',
    provider: 'Sofia',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.PMS,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 2715,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 2696,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 2704,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 2717,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 2724,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 2721,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3121,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isRFIDDomain && !isEngineDomain;
    },
  },
  ZAPFLOOR_INTEGRATION: {
    licenseName: 'ZAPFLOOR_INTEGRATION',
    provider: 'Sofia',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.PMS,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 4271,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 4249,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 4262,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 4266,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 4280,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 4278,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 4259,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isRFIDDomain && !isEngineDomain;
    },
  },
  CHAINELS_INTEGRATION: {
    licenseName: 'CHAINELS_INTEGRATION',
    provider: 'Sofia',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.TENANT_APP,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 2797,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 2819,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 2823,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 2827,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 2831,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 2835,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3134,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isLiteDomain && !isRFIDDomain && !isEngineDomain;
    },
  },
  OPTIX_INTEGRATION: {
    licenseName: 'OPTIX_INTEGRATION',
    provider: 'Sofia',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.PMS,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 3479,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 3501,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 3505,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 3509,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 3515,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 3511,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3498,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isRFIDDomain && !isEngineDomain;
    },
  },
  MEWS_INTEGRATION: {
    licenseName: 'MEWS_INTEGRATION',
    provider: 'Sofia',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.PMS,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 3639,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 3664,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 3667,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 3675,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 3679,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 3683,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3660,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isRFIDDomain && !isEngineDomain;
    },
  },
  FLUIDA_INTEGRATION: { // TODO CONFIGURATIONS
    licenseName: 'FLUIDA_INTEGRATION',
    provider: 'Sofia',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.TIME_ATTENDANCE,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 5263,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 5275,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 5277,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 5279,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 5283,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 5281,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 5273,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isRFIDDomain && !isEngineDomain;
    },
  },
  ROOMRACCOON_INTEGRATION: {
    licenseName: 'ROOMRACCOON_INTEGRATION',
    provider: 'Sofia',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.PMS,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 3687,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 3693,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 3696,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 3699,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 3705,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 3709,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3689,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isRFIDDomain && !isEngineDomain;
    },
  },
  TOPLIFE_INTEGRATION: {
    licenseName: 'TOPLIFE_INTEGRATION',
    provider: 'TopLife',
    status: PLUGIN_STATUS.IN_DEVELOPMENT,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.TENANT_APP,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 1018,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 999,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 1011,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 1014,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 1026,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 1022,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3144,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isLiteDomain && !isRFIDDomain && !isEngineDomain;
    },
  },
  COSOFT_FR_INTEGRATION: {
    licenseName: 'COSOFT_FR_INTEGRATION',
    provider: 'Cosoft France',
    status: PLUGIN_STATUS.AVAILABLE,
    category: STORE_ITEMS_CATEGORIES.PMS,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 3519,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 3579,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 3582,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 3586,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 3590,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 3594,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3576,
    },
    type: STORE_ITEMS_TYPES.INTEGRATION,
    isCompatible: (companyConfig, subscriptions) => {
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isRFIDDomain && !isEngineDomain;
    },
  },
  TENUP_INTEGRATION: {
    licenseName: 'TENUP_INTEGRATION',
    provider: 'Ten\'Up',
    status: PLUGIN_STATUS.AVAILABLE,
    category: STORE_ITEMS_CATEGORIES.BOOKING_SYSTEMS,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 3872,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 3892,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 3896,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 3900,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 3904,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 3908,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3889,
    },
    type: STORE_ITEMS_TYPES.INTEGRATION,
    isCompatible: (companyConfig, subscriptions) => {
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isRFIDDomain && !isEngineDomain;;
    },
  },
  NEXUDUS_INTEGRATION: {
    licenseName: 'NEXUDUS_INTEGRATION',
    provider: 'Sofia',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.PMS,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 84,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 436,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 490,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 565,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 664,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 708,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3207,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isRFIDDomain && !isEngineDomain;
    },
  },
  OFFICERND_INTEGRATION: {
    licenseName: 'OFFICERND_INTEGRATION',
    provider: 'Sofia',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.PMS,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 1097,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 1086,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 1088,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 1092,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 1104,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 1100,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3129,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isRFIDDomain && !isEngineDomain;
    },
  },
  RES_HARMONICS_INTEGRATION: {
    licenseName: 'RES_HARMONICS_INTEGRATION',
    provider: 'Sofia',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.PMS,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 4976,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 5003,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 5001,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 4995,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 4999,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 4997,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 5005,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      return !isLiteDomain;
    },
  },
  CLOUDBEDS_INTEGRATION: {
    licenseName: 'CLOUDBEDS_INTEGRATION',
    provider: 'Sofia',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.PMS,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 3286,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 3309,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 3313,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 3317,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 3321,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 3325,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3305,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isRFIDDomain && !isEngineDomain;
    },
  },
  WITCO_INTEGRATION: {
    licenseName: 'WITCO_INTEGRATION',
    provider: 'Witco',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.PMS,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 96,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 426,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 500,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 555,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 658,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 702,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3186,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isRFIDDomain && !isLiteDomain && !isEngineDomain;
    },
  },
  AUTH0_INTEGRATION: {
    licenseName: 'AUTH0_INTEGRATION',
    provider: 'Sofia',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.AUTHENTICATION_TECHNOLOGIES,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 111,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 430,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 504,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 559,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 660,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 704,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3191,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      return !isLiteDomain;
    },
  },
  SAML_SSO: {
    licenseName: 'SAML_SSO',
    provider: 'Sofia',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.AUTHENTICATION_TECHNOLOGIES,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 5022,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 5007,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 5028,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 5020,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 5024,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 5026,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 5018,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      return !isLiteDomain;
    },
  },
  NIBOL_INTEGRATION: {
    licenseName: 'NIBOL_INTEGRATION',
    provider: 'Nibol',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.TENANT_APP,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 87,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 432,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 506,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 563,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 662,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 706,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3196,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isRFIDDomain && !isLiteDomain && !isEngineDomain;
    },
  },
  TITIRO_INTEGRATION: {
    licenseName: 'TITIRO_INTEGRATION',
    provider: 'Titiro',
    status: PLUGIN_STATUS.IN_DEVELOPMENT,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.TENANT_APP,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 85,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 438,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 486,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 567,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 666,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 710,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3201,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isRFIDDomain && !isLiteDomain && !isEngineDomain;
    },
  },
  GOOGLE_SSO: {
    licenseName: 'GOOGLE_SSO',
    provider: 'Sofia',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.AUTHENTICATION_TECHNOLOGIES,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 146,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 414,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 472,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 530,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 642,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 690,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3147,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      return !isLiteDomain;
    },
  },
  OFFICE_SSO: {
    licenseName: 'OFFICE_SSO',
    provider: 'Sofia',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.AUTHENTICATION_TECHNOLOGIES,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 126,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 416,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 476,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 532,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 646,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 694,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3156,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      return !isLiteDomain;
    },
  },
  FARNETWORK_INTEGRATION: {
    licenseName: 'FARNETWORK_INTEGRATION',
    provider: 'FAR Networks',
    status: PLUGIN_STATUS.IN_DEVELOPMENT,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.BOOKING_SYSTEMS,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 133,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 418,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 492,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 536,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 650,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 696,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3171,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isRFIDDomain && !isLiteDomain && !isEngineDomain;
    },
  },
  PEOPLELINK_INTEGRATION: {
    licenseName: 'PEOPLELINK_INTEGRATION',
    provider: 'PeopleLink',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.TIME_ATTENDANCE,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 163,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 407,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 460,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 518,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 630,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 682,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3176,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isRFIDDomain && !isLiteDomain && !isEngineDomain;
    },
  },
  FLUIDA_INTEGRATION: {
    licenseName: 'FLUIDA_INTEGRATION',
    provider: 'Fluida',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.TIME_ATTENDANCE,
    postIds: {
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 5273,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 5263,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 5275,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 5277,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 5279,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 5283,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 5281,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isRFIDDomain && !isEngineDomain;
    },
  },
  PLANET_INTEGRATION: {
    licenseName: 'PLANET_INTEGRATION',
    provider: 'PlanetIdea',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.TENANT_APP,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 130,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 422,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 498,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 551,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 656,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 700,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3181,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isRFIDDomain && !isLiteDomain && !isEngineDomain;
    },
  },
  HP_RFIDEAS_INTEGRATION: {
    licenseName: 'HP_RFIDEAS_INTEGRATION',
    provider: 'Sofia part of ISEO & HP',
    status: PLUGIN_STATUS.AVAILABLE,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.BUILDING_TECHNOLOGIES_IOT,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 4780,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 4798,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 4800,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 4802,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 4807,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 4804,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 4796,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isRFIDDomain && !isLiteDomain && !isEngineDomain;
    },
  },
  STRATEGIC_BIM_INTEGRATION: {
    licenseName: 'STRATEGIC_BIM_INTEGRATION',
    provider: 'UTwin',
    status: PLUGIN_STATUS.IN_DEVELOPMENT,
    type: STORE_ITEMS_TYPES.INTEGRATION,
    category: STORE_ITEMS_CATEGORIES.BIM_TECHNOLOGIES,
    postIds: {
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 743,
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 726,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 735,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 739,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 751,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 747,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3159,
    },
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      const isRFIDDomain = isLicenseRFID(subscriptions);
      const isEngineDomain = isLicenseEngine(subscriptions);
      return !isLiteDomain && !isRFIDDomain && !isEngineDomain;
    },
  },
};

// DON'T PUT PRICES HEREEE
export const STORE_ADDITIONAL_ITEMS = {
  HYPERTOUCH: {
    id: 'HYPERTOUCH',
    name: 'HYPERTOUCH',
    type: STORE_ITEMS_TYPES.HARDWARE,
    category: STORE_ITEMS_CATEGORIES.SENSORS_AND_ACCESSORIES,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 1476,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 1491,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 1495,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 1498,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 1500,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 1502,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3054,
    },
    visible: true,
    hidePrice: true, // DON'T PUT PRICES HERE
    isCompatible: (companyConfig, subscriptions) => {
      return true;
    },
  },
  HYPERBRIDGE: {
    id: 'HYPERBRIDGE',
    name: 'HYPERBRIDGE',
    type: STORE_ITEMS_TYPES.HARDWARE,
    category: STORE_ITEMS_CATEGORIES.NETWORK_DEVICES,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 4110,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 4150,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 4153,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 4157,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 4161,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 4165,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 4145,
    },
    visible: true,
    hidePrice: true, // DON'T PUT PRICES HERE
    isCompatible: (companyConfig, subscriptions) => {
      const isLiteDomain = isLicenseBasic(subscriptions);
      return !isLiteDomain;
    },
  },
  HYPERGATEPRO: {
    id: 'HYPERGATEPRO',
    name: 'HYPERGATEPRO',
    type: STORE_ITEMS_TYPES.HARDWARE,
    category: STORE_ITEMS_CATEGORIES.NETWORK_DEVICES,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 1514,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 1531,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 1535,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 1539,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 1543,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 1547,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3046,
    },
    visible: true,
    hidePrice: true, // DON'T PUT PRICES HERE
    isCompatible: (companyConfig, subscriptions) => {
      return true;
    },
  },
  HYPERGATELITE: {
    id: 'HYPERGATELITE',
    name: 'HYPERGATELITE',
    type: STORE_ITEMS_TYPES.HARDWARE,
    category: STORE_ITEMS_CATEGORIES.NETWORK_DEVICES,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 1559,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 2323,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 2327,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 2331,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 2335,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 2339,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 2939,
    },
    visible: true,
    hidePrice: true, // DON'T PUT PRICES HERE
    isCompatible: (companyConfig, subscriptions) => {
      return true;
    },
  },
  HYPERLOCK_MAYA: {
    id: 'HYPERLOCK_MAYA',
    name: 'HYPERLOCK_MAYA',
    type: STORE_ITEMS_TYPES.HARDWARE,
    category: STORE_ITEMS_CATEGORIES.BLUETOOTH_RFID_DEVICES,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 1594,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 1915,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 1919,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 1921,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 1925,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 1940,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3010,
    },
    visible: true,
    hidePrice: true, // DON'T PUT PRICES HERE
    isCompatible: (companyConfig, subscriptions) => {
      return true;
    },
  },
  INCA_CABLE_FREE: {
    id: 'INCA_CABLE_FREE',
    name: 'INCA_CABLE_FREE',
    type: STORE_ITEMS_TYPES.HARDWARE,
    category: STORE_ITEMS_CATEGORIES.BLUETOOTH_RFID_DEVICES,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 4641,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 4668,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 4670,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 4672,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 4674,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 4676,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 4666,
    },
    visible: true,
    hidePrice: true, // DON'T PUT PRICES HERE
    isCompatible: (companyConfig, subscriptions) => {
      return true;
    },
  },
  HYPERLOCK_LIBRA: {
    id: 'HYPERLOCK_LIBRA',
    name: 'HYPERLOCK_LIBRA',
    type: STORE_ITEMS_TYPES.HARDWARE,
    category: STORE_ITEMS_CATEGORIES.BLUETOOTH_RFID_DEVICES,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 1792,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 1944,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 1948,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 1950,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 1952,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 1956,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 3004,
    },
    visible: true,
    hidePrice: true, // DON'T PUT PRICES HERE
    isCompatible: (companyConfig, subscriptions) => {
      return true;
    },
  },
  HYPERLOCK_ARIES: {
    id: 'HYPERLOCK_ARIES',
    name: 'HYPERLOCK_ARIES',
    type: STORE_ITEMS_TYPES.HARDWARE,
    category: STORE_ITEMS_CATEGORIES.BLUETOOTH_RFID_DEVICES,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 1812,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 1958,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 1960,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 1964,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 1966,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 1968,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 2997,
    },
    visible: true,
    hidePrice: true, // DON'T PUT PRICES HERE
    isCompatible: (companyConfig, subscriptions) => {
      return true;
    },
  },
  HYPERLOCK_SMART_LOCKER: {
    id: 'HYPERLOCK_SMART_LOCKER',
    name: 'HYPERLOCK_SMART_LOCKER',
    type: STORE_ITEMS_TYPES.HARDWARE,
    category: STORE_ITEMS_CATEGORIES.BLUETOOTH_RFID_DEVICES,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 1829,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 1978,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 1980,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 1984,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 1988,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 1990,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 2990,
    },
    visible: true,
    hidePrice: true, // DON'T PUT PRICES HERE
    isCompatible: (companyConfig, subscriptions) => {
      return true;
    },
  },
  HYPERLOCK_1NCA: {
    id: 'HYPERLOCK_1NCA',
    name: 'HYPERLOCK_1NCA',
    type: STORE_ITEMS_TYPES.HARDWARE,
    category: STORE_ITEMS_CATEGORIES.BLUETOOTH_RFID_DEVICES,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 1845,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 1994,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 1998,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 2000,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 2002,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 2006,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 2981,
    },
    visible: true,
    hidePrice: true, // DON'T PUT PRICES HERE
    isCompatible: (companyConfig, subscriptions) => {
      return true;
    },
  },
  HYPERLOCK_MULTIBLINDO_SMART: {
    id: 'HYPERLOCK_MULTIBLINDO_SMART',
    name: 'HYPERLOCK_MULTIBLINDO_SMART',
    type: STORE_ITEMS_TYPES.HARDWARE,
    category: STORE_ITEMS_CATEGORIES.BLUETOOTH_RFID_DEVICES,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 4912,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 4938,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 4936,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 4928,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 4934,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 4932,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 4930,
    },
    visible: true,
    hidePrice: true, // DON'T PUT PRICES HERE
    isCompatible: (companyConfig, subscriptions) => {
      return true;
    },
  },
  HYPERLOCK_SMART_RELAY: {
    id: 'HYPERLOCK_SMART_RELAY',
    name: 'HYPERLOCK_SMART_RELAY',
    type: STORE_ITEMS_TYPES.HARDWARE,
    category: STORE_ITEMS_CATEGORIES.BLUETOOTH_RFID_DEVICES,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 1886,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 2024,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 2026,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 2028,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 2030,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 2032,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 2971,
    },
    visible: true,
    hidePrice: true, // DON'T PUT PRICES HERE
    isCompatible: (companyConfig, subscriptions) => {
      const isRFIDDomain = isLicenseRFID(subscriptions);
      return !isRFIDDomain;
    },
  },
  HYPERLOCK_X1R: {
    id: 'HYPERLOCK_X1R',
    name: 'HYPERLOCK_X1R',
    type: STORE_ITEMS_TYPES.HARDWARE,
    category: STORE_ITEMS_CATEGORIES.BLUETOOTH_RFID_DEVICES,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 1878,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 2010,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 2014,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 2016,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 2018,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 2022,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 2976,
    },
    visible: true,
    hidePrice: true, // DON'T PUT PRICES HERE
    isCompatible: (companyConfig, subscriptions) => {
      return true;
    },
  },
  HYPERDESK: {
    id: 'HYPERDESK',
    name: 'HYPERDESK',
    type: STORE_ITEMS_TYPES.HARDWARE,
    category: STORE_ITEMS_CATEGORIES.SENSORS_AND_ACCESSORIES,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 2123,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 2207,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 2209,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 2213,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 2215,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 2217,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 2959,
    },
    visible: true,
    hidePrice: true, // DON'T PUT PRICES HERE
    isCompatible: (companyConfig, subscriptions) => {
      return true;
    },
  },
  HYPERKEYS_F9000: {
    id: 'HYPERKEYS_F9000',
    name: 'HYPERKEYS_F9000',
    type: STORE_ITEMS_TYPES.HARDWARE,
    category: STORE_ITEMS_CATEGORIES.MECHATRONIC,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 2741,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 2761,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 2765,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 2784,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 2788,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 2792,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 2932,
    },
    visible: true,
    hidePrice: true, // DON'T PUT PRICES HERE
    isCompatible: (companyConfig, subscriptions) => {
      return true;
    },
  },
  HYPERTAGS: {
    id: 'HYPERTAGS',
    name: 'HYPERTAGS',
    type: STORE_ITEMS_TYPES.HARDWARE,
    category: STORE_ITEMS_CATEGORIES.SENSORS_AND_ACCESSORIES,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 2125,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 2219,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 2223,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 2227,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 2231,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 2235,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 2951,
    },
    visible: true,
    hidePrice: true, // DON'T PUT PRICES HERE
    isCompatible: (companyConfig, subscriptions) => {
      const isRFIDDomain = isLicenseRFID(subscriptions);
      return !isRFIDDomain;
    },
  },
  HYPERSENSOR_DS: {
    id: 'HYPERSENSOR_DS',
    name: 'HYPERSENSOR_DS',
    type: STORE_ITEMS_TYPES.HARDWARE,
    category: STORE_ITEMS_CATEGORIES.SENSORS_AND_ACCESSORIES,
    postIds: {
      [LANGUAGE_DETAILS.ENGLISH.storeLanguagePrefix]: 2127,
      [LANGUAGE_DETAILS.FRENCH.storeLanguagePrefix]: 2190,
      [LANGUAGE_DETAILS.GERMAN.storeLanguagePrefix]: 2194,
      [LANGUAGE_DETAILS.ITALIAN.storeLanguagePrefix]: 2198,
      [LANGUAGE_DETAILS.POLISH.storeLanguagePrefix]: 2202,
      [LANGUAGE_DETAILS.SPANISH.storeLanguagePrefix]: 2204,
      [LANGUAGE_DETAILS.DUTCH.storeLanguagePrefix]: 2965,
    },
    visible: true,
    hidePrice: true, // DON'T PUT PRICES HERE
    isCompatible: (companyConfig, subscriptions) => {
      return true;
    },
  },
}

export const STORE_ITEMS_PROVISIONING_TYPES = {
  AUTOMATIC: 'AUTOMATIC',
  SEMI_AUTOMATIC: 'SEMI_AUTOMATIC',
  MANUAL: 'MANUAL',
};

export const EXTERNAL_IDENTITY_PROVIDERS = {
  SAML: 'SAML2.0',
  NEXUDUS: 'NEXUDUS',
  OTP_EMAIL: 'OTP_EMAIL',
  COBOT: 'COBOT',
  OFFICERND: 'OFFICERND',
  CHAINELS: 'CHAINELS',
};

export const DEFAULT_CREDENTIAL_TIME_FRAME = {
  startDate: moment().startOf('day').valueOf(),
  endDate: moment().add(1, 'Y').endOf('day').valueOf(),
}

export const HYPERGATE_DEVICE_TYPES = {
  HYPERGATE_PRO: 'HYPERGATE_PRO',
  HYPERGATE_ISEO: 'HYPERGATE_ISEO'
}

export const RESERVATION_CONFIRMATION_STATUSES = {
  PENDING_CONFIRMATION: 'PENDING_CONFIRMATION',
  SUCCESSFUL: 'SUCCESSFUL',
}


export const RESERVATION_CONFIRMATION_STATUSES_MAP = {
  [RESERVATION_CONFIRMATION_STATUSES.PENDING_CONFIRMATION]: {
    title: 'reservationPendingConfirmationTitle',
    description: 'reservationPendingConfirmationDescription',
    color: ORANGE,
    icon: <WarningIcon style={{ fontSize: 25, color: ORANGE }} />,
  },
  [RESERVATION_CONFIRMATION_STATUSES.SUCCESSFUL]: {
    title: 'reservationSuccessfulTitle',
    description: 'reservationSuccessfulDescription',
    color: GREEN,
    icon: <CheckIcon style={{ fontSize: 25, color: GREEN }} />,
  },
}

export const CHAINELS_BASE_URL = {
  staging: 'https://demo.chainels.com',
  production: 'https://www.chainels.com',
}

export const VALIDATION_MODES = {
  ALWAYS_VALIDATED: 'ALWAYS_VALIDATED',
  ONE_MINUTE_VALIDATION: 'ONE_MINUTE_VALIDATION',
  ONE_HOUR_VALIDATION: 'ONE_HOUR_VALIDATION',
}

export const VALIDATION_MODES_SELECTION_OPTIONS = {
  ALWAYS_VALIDATED: 'ALWAYS_VALIDATED',
  TIME_CONSTRAINED_VALIDATION: 'TIME_CONSTRAINED_VALIDATION',
}

export const VALIDATION_MODES_TIME_UNIT_OPTIONS = {
  ONE_MINUTE_VALIDATION: 'ONE_MINUTE_VALIDATION',
  ONE_HOUR_VALIDATION: 'ONE_HOUR_VALIDATION',
}

export const VALIDATION_MODES_NAMES = {
  ALWAYS_VALIDATED: 'alwaysValidatedValidationName',
  ONE_MINUTE_VALIDATION: 'minutes',
  ONE_HOUR_VALIDATION: 'hours',
  TIME_CONSTRAINED_VALIDATION: 'timeConstrainedValidationName',
};

export const VALIDATION_STATUS = {
  ACTIVE: 'Active',
  INVALIDATED: 'Invalidated',
  DELETABLE: 'Deletable',
  TO_BE_ENCODED: 'ToBeEncoded',
  WAIT_FOR_INVITATION: 'WaitForInvitation',
}

export const VALIDATION_STATUS_MAP = {
  [VALIDATION_STATUS.ACTIVE]: {
    description: 'activeValidationStatusDescription',
    color: GREEN,
  },
  [VALIDATION_STATUS.INVALIDATED]: {
    description: 'invalidatedValidationStatusDescription',
    color: RED,
  },
  [VALIDATION_STATUS.DELETABLE]: {
    description: 'deletableValidationStatusDescription',
    color: ORANGE,
  },
  [VALIDATION_STATUS.TO_BE_ENCODED]: {
    description: 'toBeEncodedValidationStatusDescription',
    color: ORANGE,
  },
  [VALIDATION_STATUS.WAIT_FOR_INVITATION]: {
    description: 'waitForInvitationValidationStatusDescription',
    color: ORANGE,
  },
}

export const WALLET_STATUSES = {
  PENDING: 'PENDING',
  READY_TO_ACTIVATE: 'READY_TO_ACTIVATE',
  ACTIVE: 'ACTIVE'
}

export const WALLET_STATUS_MAP = {
  [WALLET_STATUSES.ACTIVE]: {
    description: 'walletActiveDescription',
    color: GREEN,
  },
  [WALLET_STATUSES.PENDING]: {
    description: 'walletPendingDescription',
    descriptionLong: 'systemPendingToActivateDescription',
    color: ORANGE,
  },
  [WALLET_STATUSES.READY_TO_ACTIVATE]: {
    description: 'walletReadyToActivateDescription',
    descriptionLong: 'systemPendingToActivateDescription',
    color: ORANGE,
  },
}

export const ZOOM_LEVEL = 0.8;

export const DND_ELEMENTS = {
  RESOURCE_POINT: 'RESOURCE_POINT',
  RESOURCE_ROW: 'RESOURCE_ROW',
  SMART_LOCK_POINT: 'SMART_LOCK_POINT',
  SMART_LOCK_ROW: 'SMART_LOCK_ROW',
}

export const TICKET_STATUS_COLORS = {
  "ALL": '27,151,194',
  "OPEN": '239, 108, 0',
  "PENDING": '27,151,194',
  "CLOSED": '100,100,100',
  "RESOLVED": '77, 182, 172',
};

export const TICKET_PRIORITY_COLORS = {
  "ALL": '27,151,194',
  "LOW": '50,50,50',
  "NORMAL": '27,151,194',
  "HIGH": '239, 108, 0',
  "URGENT": '205,92,92',
};

export const TICKET_CATEGORY_COLORS = {
  "ALL": '50,50,50',
  "FEEDBACK": '48,99,142',
  "GENERIC_ISSUE": '237,174,73',
  "REQUEST": '0,121,140',
  "ACCESS_ISSUE": '209,73,91',
};

export const TICKET_CATEGORY_COLORS_OTHERS = [
  "23, 130, 138",
  "156, 146, 163",
  "198, 185, 205",
  "214, 211, 240",
  "214, 211, 100",
]

export const TICKETS_PRIORITY_FILTER_OPTIONS = [{ id: 0, name: 'ALL', value: 'ALL', label: 'ticketPriority' }, { id: 1, name: 'LOW', value: 'LOW', label: 'lowTicketPriority' }, { id: 2, name: 'NORMAL', value: 'NORMAL', label: 'normalTicketPriority' }, { id: 3, name: 'HIGH', value: 'HIGH', label: 'highTicketPriority' }, { id: 4, name: 'URGENT', value: 'URGENT', label: 'urgentTicketPriority' }];
export const TICKETS_STATUS_FILTER_OPTIONS = [{ id: 0, name: 'ALL', value: 'ALL', label: 'ticketStatus' }, { id: 1, name: 'OPEN', value: 'OPEN', label: 'openTicketStatus' }, { id: 2, name: 'PENDING', value: 'PENDING', label: 'pendingTicketStatus' }, { id: 3, name: 'CLOSED', value: 'CLOSED', label: 'closedTicketStatus' }, { id: 4, name: 'RESOLVED', value: 'RESOLVED', label: 'resolvedTicketStatus' }];
export const TICKETS_DEFAULT_CATEGORIES = [{ id: 1, name: 'FEEDBACK', value: 'FEEDBACK', label: 'feedbackTicketCategory' }, { id: 2, name: 'GENERIC_ISSUE', value: 'GENERIC_ISSUE', label: 'genericIssueTicketCategory' }, { id: 3, name: 'REQUEST', value: 'REQUEST', label: 'requestTicketCategory' }, { id: 4, name: 'ACCESS_ISSUE', value: 'ACCESS_ISSUE', label: 'accessIssueTicketCategory' } ];
export const VISIBLE_TAGS_NUMBER = 20

export const CREDENTIALS_TYPES = {
  STANDARD: 'STANDARD',
  SMART: 'SMART',
  F9000: 'F9000',
};

export const LOCKS_OPEN_MODE = {
  BLE: 'BLE',
  REMOTE_OPEN_HT: 'REMOTE_OPEN_HT',
  REMOTE_OPEN_GATEWAY_BLUETOOTH: 'REMOTE_OPEN_GATEWAY_BLUETOOTH',
  GROUP_OPEN: 'GROUP_OPEN',
  KEEP_LOCK_OPEN: 'KEEP_LOCK_OPEN',
  END_PASSAGE_MODE: 'END_PASSAGE_MODE',
};

export const OCTORATE_AUTH_VARIABLES = {
  client_id: 'public_552c531311ae4df08fdef40889262a89',
  base_url: 'https://admin.octorate.com'
};

export const PDF_TEMPLATE_VARIABILES = {
  A4_WIDTH_PT: 595,
  A4_HEIGHT_PT: 842,
  MARGIN_TOP_PT: 70,
  MARGIN_LATERAL_PT: 38,
  MARGIN_BOTTOM_PT: 70,
}

export const PRIVATE_UNIT_TEMPLATE_LANGUAGES = {
  [LANGUAGES.ENGLISH]: { flag: 'United-Kingdom', value:LANGUAGES.ENGLISH, label: 'privateUnitTemplateLanguageEN' },
  [LANGUAGES.ITALIAN]: { flag: 'Italy', value:LANGUAGES.ITALIAN, label: 'privateUnitTemplateLanguageIT' },
  [LANGUAGES.SPANISH]: { flag: 'Spain', value:LANGUAGES.SPANISH, label: 'privateUnitTemplateLanguageES' },
  [LANGUAGES.FRENCH]: { flag: 'France', value:LANGUAGES.FRENCH, label: 'privateUnitTemplateLanguageFR' },
  [LANGUAGES.POLISH]: { flag: 'Polish', value:LANGUAGES.POLISH, label: 'privateUnitTemplateLanguagePL' },
  [LANGUAGES.GERMAN]: { flag: 'Germany', value:LANGUAGES.GERMAN, label: 'privateUnitTemplateLanguageDE' },
  [LANGUAGES.DUTCH]: { flag: 'Netherland', value:LANGUAGES.DUTCH, label: 'privateUnitTemplateLanguageNL' },
};

export const PRIVATE_UNIT_TEMPLATE_TYPES = {
  'ONBOARDING': 'ONBOARDING',
}

export const PRIVATE_UNIT_TEMPLATE_CUSTOM_PLACEHOLDERS = [
  {
    value: 'privateUnitTemplatePlaceholderPrivateUnitName',
    objFunc: (obj) => {
      return (obj.name || "")
    }
  },
  {
    value: 'privateUnitTemplatePlaceholderRedemptionCode',
    objFunc: (obj) => {
      return (obj.redemptionCode || "")
    }
  },
  {
    value: 'privateUnitTemplatePlaceholderCards',
    objFunc: (obj) => {
      if (!(obj.standardDevicesIds && obj.standardDevicesIds.length))
        return ""
      return obj.standardDevicesIds.join(" | ")
    }
  },
  {
    value: 'privateUnitTemplatePlaceholderCurrentDate',
    objFunc: (obj) => {
      return moment().format('DD/MM/YYYY')
    }
  },
]

export const LOCKER_LOCK_STATUS = {
  FREE: 'FREE',
  RESERVED: 'RESERVED',
};

export const LOCKER_TYPE = {
  LOCKER: 'LOCKER',
  PARCEL_ROOM: 'PARCEL_ROOM',
};

export const reservationSearchModeValues = {
  SELECT_RESOURCE: "SELECT",
  SEARCH_RESOURCES: "SEARCH",
}

export const reservationSearchModeOptions = [
  {
    label: "reservationFormSelectResource",
    value: reservationSearchModeValues.SELECT_RESOURCE,
  },
  {
    label: "reservationFormSearchResources",
    value: reservationSearchModeValues.SEARCH_RESOURCES,
  },
];

export const COMMUNICATION_EMAIL_STATUSES = {
  DISABLED: 'DISABLED',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  SUCCESS_WITH_FAILURES: 'SUCCESS_WITH_FAILURES',
  FAIL: 'FAIL',
}

export const COMMUNICATION_EMAIL_STATUSES_PARAMETERS = {
  [COMMUNICATION_EMAIL_STATUSES.DISABLED]: {
    hiddenInTable: true,
    color: 'grey',
    string: 'communicationEmailDisabled',
  },
  [COMMUNICATION_EMAIL_STATUSES.PENDING]: {
    hiddenInTable: false,
    color: '#ef6c00',
    string: 'communicationEmailPending',
  },
  [COMMUNICATION_EMAIL_STATUSES.SUCCESS]: {
    hiddenInTable: false,
    color: 'green',
    string: 'communicationEmailSuccess',
  },
  [COMMUNICATION_EMAIL_STATUSES.SUCCESS_WITH_FAILURES]: {
    hiddenInTable: false,
    color: '#ef6c00',
    string: 'communicationEmailSuccessWithFailures',
  },
  [COMMUNICATION_EMAIL_STATUSES.FAIL]: {
    hiddenInTable: false,
    color: 'red',
    string: 'communicationEmailFail',
  },
};

export const COMMUNICATION_TARGET_AUDIENCE_VALUES = {
  BROADCAST: 'BROADCAST',
  TARGET: 'TARGET',
};