import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CalendarNormalIcon from '@material-ui/icons/Event';
import LockIcon from '@material-ui/icons/LockOpen';
import UserIcon from '@material-ui/icons/Person';
import {
  ControlLabel,
  FormGroup,
  HelpBlock
} from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import Select, { Creatable } from 'react-select';
import { TIMED_TAG_STATUSES } from '../../../_config/consts';
import Tag from '../../Tag.jsx';

class CustomOption extends React.Component {
  handleMouseDown(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option, event);
  }

  handleMouseEnter(event) {
    this.props.onFocus(this.props.option, event);
  }

  handleMouseMove(event) {
    if (this.props.isFocused) return;
    this.props.onFocus(this.props.option, event);
  }

  render() {
    const { option } = this.props;
    const tag = {
      id: option.id,
      name: option.name,
      color: option.color || '#1B97C2',
      type: option.type,
    };
    const isUserTag = tag && tag.type === 'user';
    const isLockTag = tag && tag.type === 'lock';
    const showCategory = !isUserTag && !isLockTag;
    return (
      <div
        className={this.props.className}
        onMouseDown={e => this.handleMouseDown(e)}
        onMouseEnter={e => this.handleMouseEnter(e)}
        onMouseMove={e => this.handleMouseMove(e)}
        title={option.title}
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Tag tag={tag} />
        {showCategory ? (
          <span
            style={{
              color: tag.color,
              textDecoration: 'underline',
            }}
          >
            {option.type}
          </span>) : null}
      </div>
    );
  }
}

class CustomValue extends React.Component {

  render() {
    const { value, onRemove, disabled } = this.props;
    const isUserTag = value && value.type === 'user';
    const isLockTag = value && value.type === 'lock';
    return (
      <div
        className="Select-value"
        title={value.name}
        style={{ borderColor: value.color ? value.color : 'white', backgroundColor: 'white' }}
      >
        {onRemove &&
          <span
            className="Select-value-icon"
            style={{ color: value.color }}
            onClick={(e) => { e.stopPropagation(); e.preventDefault(); if(disabled) return; onRemove(value); }}
            onTouchStart={(e) => { e.stopPropagation(); e.preventDefault(); if(disabled) return; onRemove(value); }}
          >
            <CloseIcon style={{ fontSize: 13, marginBottom: -3 }} />
          </span>
        }
        <span
          className="Select-value-label"
          style={{ backgroundColor: value.color ? value.color : '#1B97C2', color: 'white' }}
        >
          <span>{value.name}</span>
        </span>
        <span
          className="Select-value-label"
          style={{ color: value.color ? value.color : '#1B97C2', borderRadius: 0 }}
        >
          {isUserTag && <UserIcon style={{ fontSize: 14, paddingTop: 2 }} /> }
          {isLockTag && <LockIcon style={{ fontSize: 13, paddingTop: 2 }} />}
          {!isLockTag && !isUserTag && <span>{value.type}</span>}
        </span>
        
      </div>
    );
  }
}

class CustomValueEdit extends React.Component {
  render() {
    const { value, onRemove, onClick, canSetDate, canEditTag, isDisabled } = this.props;
    let tagStyle = { borderColor: value.color, backgroundColor: 'white' };

    if (value.state === TIMED_TAG_STATUSES.EXPIRED || value.state === TIMED_TAG_STATUSES.NOT_VALID) {
      tagStyle = {
        ...tagStyle,
        opacity: 0.3,
      };
    }
    return (
      <div
        className="Select-value"
        style={tagStyle}
      >
        {onRemove && !isDisabled &&
          <span
            className="Select-value-icon"
            style={{ color: value.color }}
            onClick={(e) => { e.stopPropagation(); e.preventDefault(); onRemove(value); }}
            onTouchStart={(e) => { e.stopPropagation(); e.preventDefault(); onRemove(value); }}
          >
            <CloseIcon style={{ fontSize: 15, marginBottom: -3 }} />
          </span>
        }
        {canEditTag && !isDisabled &&
          <span
            title="Edit tag"
            className="Select-value-icon"
            style={{ color: value.color, paddingTop: 4 }}
            onClick={(e) => { e.stopPropagation(); e.preventDefault(); onClick(value, 'EDIT_TAG'); }}
            onTouchStart={(e) => { e.stopPropagation(); e.preventDefault(); onClick(value, 'EDIT_TAG'); }}
          >
            <EditIcon style={{ fontSize: 14, marginBottom: -1 }} />
          </span>
        }
        {canSetDate && !isDisabled &&
          <span
            title="Timing settings"
            className="Select-value-icon"
            style={{ color: value.color, paddingTop: 3 }}
            onClick={() => onClick(value, 'SET_DATE')}
            onTouchStart={() => onClick(value, 'SET_DATE')}
          >
            <CalendarNormalIcon style={{ fontSize: 15, marginBottom: -3 }} />
          </span>
        }
        <span
          className="Select-value-label"
          style={{ backgroundColor: value.color, color: 'white', marginLeft: 3, marginRight: 3, borderRadius: 2 }}
        >
          <span>{value.name}</span>
        </span>
        <span
          className="Select-value-label"
          style={{ color: value.color }}
        >
          <span>{value.type}</span>
        </span>
        
      </div>
    );
  }
}


export default class TagsSelectorField extends React.Component {
  onHandleChange(value, input) {
    const { onHandleChange, onSubmitOnSelect } = this.props;
    if (input) {
      input.onChange(value);
    }
    if (onHandleChange) {
      onHandleChange(value);
    }

    if (onSubmitOnSelect) {
      setTimeout(() => onSubmitOnSelect(value), 500);
    }
  }

  onInputChange(value, input) {
    const { onInputChange } = this.props;
    if (onInputChange) {
      onInputChange(value);
    }
    return value;
  }

  onBlur(input) {
    input.onBlur(input.value);
  }

  onValueClick(value, actionType) {
    const { onEditTag, onEditTagDates } = this.props;
    switch (actionType) {
      case 'EDIT_TAG': {
        if (onEditTag) {
          onEditTag(value);
        }
        break;
      }
      case 'SET_DATE': {
        if (onEditTagDates) {
          onEditTagDates(value);
        }
        break;
      }
      default:
        break;
    }
  }

  render() {
    const { input, meta, name, title, multi, tagType,
      id, placeholder, tags, onNewTag, onEditTag,
      helpText, mandatory, createNewOptionText, isDisabled, onRemove, containerStyle } = this.props;
    let validationState;
    if (meta.touched) {
      if (meta.error) {
        validationState = 'error';
      } else {
        validationState = 'success';
      }
    }

    let val = input.value;
    if (!multi) {
      if (_.isArray(input.value) && input.value.length) {
        val = _.first(input.value);
      }
    }

    const CustomValueLockEdit = props => <CustomValueEdit {...props} canEditTag isDisabled={this.props.isDisabled} />;
    const CustomValueGuestEdit = props => <CustomValueEdit {...props} canEditTag isDisabled={this.props.isDisabled} canSetDate />;

    let valueComponent = CustomValue;
    if (onEditTag) {
      valueComponent = tagType === 'GUEST' ? CustomValueGuestEdit : CustomValueLockEdit;
    }
    return (
      <FormGroup controlId={id} validationState={validationState} style={{ ...containerStyle }}>
        {title && <ControlLabel style={{ color: meta.touched && meta.error ? 'red' : '#3f3f3f', fontSize: 16 }}>
          {_.isString(title) ? <Entity entity={title} /> : title}{mandatory ? <span style={{ color: '#D71F4B' }}>*</span> : ''}</ControlLabel>}
        { onNewTag ?
          <Creatable
            multi={multi}
            style={{ borderColor: '#d2d2d2', borderWidth: 2, borderRadius: 5 }}
            placeholder={placeholder}
            onNewOptionClick={data => onNewTag(data)}
            options={tags ? tags.asMutable({ deep: true }) : []}
            labelKey="name"
            valueKey="id"
            promptTextCreator={tagName => `${createNewOptionText ? L20NContext.getSync(createNewOptionText): L20NContext.getSync('createNewTagOption')} "${tagName}"`}
            optionComponent={CustomOption}
            valueComponent={valueComponent}
            {...input}
            disabled={isDisabled}
            value={val}
            onValueClick={(value, actionType) => this.onValueClick(value, actionType)}
            onChange={(value) => { this.onHandleChange(value, input); }}
            onInputChange={(value) => this.onInputChange(value, input)}
            onBlur={(event) => this.onBlur(input)}
          /> :
          <Select
            multi={multi}
            placeholder={placeholder}
            labelKey="name"
            valueKey="id"
            options={tags ? tags.asMutable({ deep: true }) : []}
            optionComponent={CustomOption}
            style={{ borderColor: '#d2d2d2', borderWidth: 2, borderRadius: 5 }}
            valueComponent={valueComponent}
            {...input}
            disabled={isDisabled}
            value={val}
            onValueClick={(value, actionType) => this.onValueClick(value, actionType)}
            onChange={(value) => { this.onHandleChange(value, input); }}
            onInputChange={(value) => this.onInputChange(value, input)}
            onBlur={value => this.onBlur(input)}
          />
        }
        {meta.touched && meta.error &&
          <h6 style={{ color: 'red', marginTop: 4 }}>
            <Entity entity={meta.error} />
          </h6>
        }
        {helpText ? <HelpBlock style={{ lineHeight: 1.1, fontSize: 13, color: '#3f3f3f', fontWeight: '200', marginTop: 10 }}>{helpText}</HelpBlock> : null}

      </FormGroup>
    );
  }
}
