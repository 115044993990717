import axios from 'axios';
import _ from 'lodash';
import qs from 'qs';
import { getApiBaseUrl, CLIENT_ID, CLIENT_SECRET } from './conf';
import { executeRequestOrDefault } from './utils';
import { PERMISSION_ENTITIES, PERMISSIONS } from './consts';

export const axiosInstance = axios.create({
  timeout: 30000
});

export const authAxiosInstance = axios.create({
  timeout: 30000,
});

export function authenticate(username, password) {
  const config = {
    auth: {
      username: CLIENT_ID,
      password: CLIENT_SECRET,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  const credentials = {
    grant_type: 'password',
    username,
    password,
  };

  return authAxiosInstance.post('/oauth/token', qs.stringify(credentials), config);
}

export function refreshToken(refToken) {
  const config = {
    auth: {
      username: CLIENT_ID,
      password: CLIENT_SECRET,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  const credentials = {
    grant_type: 'refresh_token',
    client_id: CLIENT_ID,
    refresh_token: refToken,
  };

  return authAxiosInstance.post('/oauth/token', qs.stringify(credentials), config);
}

export function getSAMLRequest() {
  return axiosInstance.get('/api/v2/oauth/saml/request');
}

export function getTokensFromSAMLCode(code) {
  const data = {
    code,
    deviceType: 'WEB_PORTAL',
  };
  return axiosInstance.post('/api/v2/oauth/saml/code', data);
}

export function verifyOTPCode(userId, code) {
  const data = {
    userId,
    code,
    clientId: CLIENT_ID,
  };
  return axiosInstance.post('/api/v2/oauth/verify', data);
}

export function fetchCurrentUser() {
  return axiosInstance.get('/api/v2/users/me');
}

export function setUserLanguage(language) {
  const data = {
    language,
  };

  return axiosInstance.put('/api/v1/users/me', data);
}

export function confirmPassword(userId, data) {
  return authAxiosInstance.post(`/api/v1/register/${userId}/confirm`, data);
}

export function fetchUsers(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v1/users',
    params,
  };

  return executeRequestOrDefault(PERMISSION_ENTITIES.USER, [PERMISSIONS.READ], requestData);
}

export function fetchGuests(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/users',
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };

  return executeRequestOrDefault(PERMISSION_ENTITIES.USER, [PERMISSIONS.READ], requestData);
}

export function fetchPlatformUsers(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/users',
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };

  return executeRequestOrDefault(PERMISSION_ENTITIES.USER, [PERMISSIONS.READ], requestData);
}

export function fetchInstallers(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/users',
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };

  return executeRequestOrDefault(PERMISSION_ENTITIES.USER, [PERMISSIONS.READ], requestData);
}

export function fetchStandardDevices(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/standardDevices',
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };

  return executeRequestOrDefault(PERMISSION_ENTITIES.STANDARD_DEVICE, [PERMISSIONS.READ], requestData);
}

export function fetchStandardDeviceStatus(deviceId) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: `/api/v2/standardDevices/${deviceId}/status`,
  };

  return executeRequestOrDefault(PERMISSION_ENTITIES.STANDARD_DEVICE, [PERMISSIONS.READ], requestData);
}

export function createStandardDevice(data) {
  return axiosInstance.post('/api/v1/standardDevices', data);
}

export function createStandardDeviceWithAccessProfile(data) {
  return axiosInstance.post('/api/v2/standardDevices/fromAccessProfile', data);
}

export function editStandardDevice(deviceId, data) {
  return axiosInstance.put(`/api/v1/standardDevices/${deviceId}`, data);
}

export function editStandardDeviceValidationPeriod(deviceId, validationPeriodDTO) {
  return axiosInstance.put(`/api/v2/standardDevices/${deviceId}`, validationPeriodDTO);
}

export function addAdditionaCredentialRuleToStandardDevice(deviceId, data) {
  return axiosInstance.post(`/api/v2/standardDevices/${deviceId}/additionalCredentialRules`, data);
}

export function getStandardDeviceValidationModeDefaults() {
  return axiosInstance.get(`/api/v2/standardDevices/defaults`);
}

export function updateStandardDevicesValidationModeDefaults(data) {
  return axiosInstance.put(`/api/v2/standardDevices/defaults`, data);
}

export function editAdditionaCredentialRuleToStandardDevice(deviceId, additionaCredentialRuleId, data) {
  return axiosInstance.put(`/api/v2/standardDevices/${deviceId}/additionalCredentialRules/${additionaCredentialRuleId}`, data);
}

export function removeAdditionaCredentialRuleToStandardDevice(deviceId, additionaCredentialRuleId) {
  return axiosInstance.delete(`/api/v2/standardDevices/${deviceId}/additionalCredentialRules/${additionaCredentialRuleId}`);
}


export function deleteStandardDevice(deviceId) {
  return axiosInstance.delete(`/api/v1/standardDevices/${deviceId}`);
}

export function deleteStandardDeviceAndElectMaster(deviceId, masterPlant) {
  return axiosInstance.delete(`/api/v1/standardDevices/${deviceId}?master=${masterPlant}`);
}


export function validateStandardDevice(deviceId) {
  return axiosInstance.get(`/api/v2/internal/standardDevices/validate?deviceId=${deviceId}`);
}

export function disableUser(userId) {
  return axiosInstance.post(`/api/v1/users/${userId}/disable`);
}


export function deleteUser(userId, oblivionSelected = false, deleteAllStandardDevices = false) {
  return axiosInstance.delete(`/api/v2/users/${userId}?anonymizeAllEvents=${oblivionSelected}&deleteAllStandardDevices=${deleteAllStandardDevices}`);
}

export function getUserDetails(userId) {
  return axiosInstance.get(`/api/v2/users/${userId}`);
}

export function resetPassword(userId) {
  return axiosInstance.post(`/api/v1/users/${userId}/resetPassword`);
}

export function generatePasswordRecoveryLink(userId) {
  return axiosInstance.post(`/api/v2/users/${userId}/generateResetPasswordUrl`);
}

export function forgotPassword(data) {
  return axiosInstance.post('/api/v1/register/passwordRecovery', data);
}

export function removeMobileDevice(userId) {
  return axiosInstance.post(`/api/v1/users/${userId}/resetMobileUuid`);
}

export function fetchUsersByTags(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/users/tags',
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };

  return executeRequestOrDefault(PERMISSION_ENTITIES.USER, [PERMISSIONS.READ], requestData);
}

export function createUser(data) {
  return axiosInstance.post('/api/v2/users', data);
}

export function createPlatformUser(data) {
  return axiosInstance.post('/api/v2/users', data);
}

export function editUser(userId, data) {
  return axiosInstance.put(`/api/v2/users/${userId}`, data);
}

export function editPlatformUser(userId, data) {
  return axiosInstance.put(`/api/v2/users/${userId}`, data);
}

export function editInstaller(userId, data) {
  return axiosInstance.put(`/api/v2/users/${userId}`, data);
}

export function fetchGuestTags(params) {
  const restParams = {
    params,
  };
  return axiosInstance.get('/api/v1/guestTags', restParams);
}

export function fetchUserTags(params) {
  const restParams = {
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return axiosInstance.get('/api/v2/userTags', restParams);
}

export function fetchCredentials(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/credentials',
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return executeRequestOrDefault(PERMISSION_ENTITIES.CREDENTIAL, [PERMISSIONS.READ], requestData);
}

export function fetchGuestTagsCategories(params) {
  const restParams = {
    params,
  };
  return axiosInstance.get('/api/v1/guestTags/types', restParams);
}

export function createGuestTag(data) {
  return axiosInstance.post('/api/v1/guestTags', data);
}

export function updateGuestTag(guestTagId, data) {
  return axiosInstance.put(`/api/v1.1/guestTags/${guestTagId}`, data);
}

export function fetchGuestTagDetails(guestTagId) {
  return axiosInstance.get(`/api/v1/guestTags/${guestTagId}`);
}


export function fetchUserDetails(guestId) {
  return axiosInstance.get(`/api/v1/users/${guestId}`);
}


export function updateLock(smartLockId, data) {
  return axiosInstance.put(`/api/v2/smartLocks/${smartLockId}`, data);
}

export function fetchLockTagDetails(locktagId) {
  return axiosInstance.get(`/api/v1/lockTags/${locktagId}`);
}

export function fetchLocksRemoteOpen(params) {
  const restParams = {
    params,
  };
  return axiosInstance.get(`/api/v2/smartLocks/`, restParams);
}

export function fetchLocks(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/smartLocks',
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };

  return executeRequestOrDefault(PERMISSION_ENTITIES.SMART_LOCK, [PERMISSIONS.READ], requestData);
}

export function fetchLocksByTags(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/smartLocks/tags',
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };

  return executeRequestOrDefault(PERMISSION_ENTITIES.SMART_LOCK, [PERMISSIONS.READ], requestData);
}

export function fetchLockTags(params) {
  const restParams = {
    params,
  };
  return axiosInstance.get('/api/v1/lockTags', restParams);
}

export function fetchSmartLockTags(params) {
  const restParams = {
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return axiosInstance.get('/api/v2/smartLockTags', restParams);
}

export function fetchLockTagsCategories(params) {
  const restParams = {
    params,
  };
  return axiosInstance.get('/api/v1/lockTags/types', restParams);
}

export function createLockTag(data) {
  return axiosInstance.post('/api/v1/lockTags', data);
}

export function updateLockTag(tagId, data) {
  return axiosInstance.put(`/api/v1/lockTags/${tagId}`, data);
}

export function getLockDetails(lockId) {
  return axiosInstance.get(`/api/v1/smartLocks/${lockId}`);
}

export function fetchCredentialRules(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/credentialRules',
    params,
  };

  return executeRequestOrDefault(PERMISSION_ENTITIES.CREDENTIAL_RULE, [PERMISSIONS.READ], requestData);
}

export function fetchSmartphoneRules(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/credentialRules',
    params,
  };

  return executeRequestOrDefault(PERMISSION_ENTITIES.CREDENTIAL_RULE, [PERMISSIONS.READ], requestData);
}

export function createCredential(data) {
  return axiosInstance.post('/api/v1/credentialRules', data);
}

export function updateCredential(credentialId, data) {
  return axiosInstance.put(`/api/v1/credentialRules/${credentialId}`, data);
}

export function fetchCredentialRuleDetails(credentialRuleId) {
  return axiosInstance.get(`/api/v2/credentialRules/${credentialRuleId}`);
}

export function deleteCredential(credentialRuleId) {
  return axiosInstance.delete(`/api/v1/credentialRules/${credentialRuleId}`);
}

export function fetchSettings() {
  return axiosInstance.get('/api/v2/companies');
}

export function fetchCompanyMetrics() {
  return axiosInstance.get('/api/v2/companies/metrics');
}

export function fetchCompanyInfo() {
  return axiosInstance.get('/api/v2/companies/info');
}

export function fetchCompanyHolidays() {
  return axiosInstance.get('/api/v2/companies/holidays');
}

export function createCompanyHoliday(holidayDTO) {
  return axiosInstance.post('/api/v2/companies/holidays', holidayDTO);
}

export function deleteCompanyHoliday(holidayId) {
  return axiosInstance.delete(`/api/v2/companies/holidays/${holidayId}`);
}

export function fetchLogs(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v1/logs',
    params,
  };

  return executeRequestOrDefault(PERMISSION_ENTITIES.LOG, [PERMISSIONS.READ], requestData);
}

export function updateSettings(data) {
  return axiosInstance.put('/api/v2/companies', data);
}

export function postLogo(media) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  const fileData = new FormData();
  fileData.append('file', media[0]);
  return axiosInstance.post('/api/v2/companies/logo', fileData, config);
}

export function getLogo(imageName, isThumbnail) {
  return `${getBaseUrl()}/api/v2/companies/logo?logoName=${imageName}&thumbnail=${isThumbnail}`;
}

export function setRequestInterceptor(accessToken) {
  const interceptor = axiosInstance.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  }, error => {
    if (error instanceof Error) {
    }
  });
}

export function setBaseUrl(baseURL) {
  axiosInstance.defaults.baseURL = baseURL;
  authAxiosInstance.defaults.baseURL = baseURL;
}

export function getBaseUrl() {
  return axiosInstance.defaults.baseURL;
}

export function getRequestInterceptors() {
  return axiosInstance.interceptors.request ? axiosInstance.interceptors.request.handlers : [];
}

export function interceptorEjectRequest() {
  _.each(
    _.keys(axiosInstance.interceptors.request.handlers), (key) => {
      axiosInstance.interceptors.request.eject(key);
    }
  );
}

export function replayRequest(params) {
  axios(params.initialRequest).then(response => {
    params.resolve(response);
  }).catch(reason => {
    params.reject(reason);
  });
}


export function fetchUsersMetrics(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/users',
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };

  return executeRequestOrDefault(PERMISSION_ENTITIES.USER, [PERMISSIONS.READ], requestData);
}

export function fetchLocksMetrics(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/smartLocks',
    params,
  };
  return executeRequestOrDefault(PERMISSION_ENTITIES.SMART_LOCK, [PERMISSIONS.READ], requestData);
}

export function fetchStandardDevicesMetric(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/standardDevices',
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return executeRequestOrDefault(PERMISSION_ENTITIES.STANDARD_DEVICE, [PERMISSIONS.READ], requestData);
}

export function fetchCompanyConfiguration() {
  return axiosInstance.get('/api/v2/companies/config');
}

export function fetchCompanySubcompanies() {
  return axiosInstance.get('/api/v2/companies/subCompanies');
}

export function fetchUserDomains() {
  return axiosInstance.get('/api/v2/users/me/domains');
}

export function exchangeDomainToken(params) {
  return axiosInstance.post('/api/v2/users/me/changeToken', params);
}

export function fetchSubscriptionSummary(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/subscriptions/summary',
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return executeRequestOrDefault(PERMISSION_ENTITIES.COMPANY, [PERMISSIONS.READ], requestData);
}

export function authorizationCodeExchangeEagleEye(authCodeDTO) {
  return axiosInstance.post('/api/v2/integrations/eagleEye', authCodeDTO);
}

export function fetchCurrentActiveIntegrations() {
  return axiosInstance.get('/api/v2/integrations');
}


export function sendOTPViaEmail(email) {
  return axiosInstance.post('api/v2/oauth/otp/generate', { email });
}

export function verifyOTP(code) {
  const data = {
    code,
    deviceType: 'WEB_PORTAL',
  };
  return axiosInstance.post('api/v2/oauth/otp/verify', data);
}

export function synchronizeV364SmartLocks() {
  return axiosInstance.get('/api/v2/smartLocks/syncVega');
}

export function activateCobotIntegration(activationDTO) {
  return axiosInstance.post('/api/v2/integrations/cobot', activationDTO);
}

export function activateOfficeRndIntegration(activationDTO) {
  return axiosInstance.post('/api/v2/integrations/officernd', activationDTO);
}

export function activateOffice365Integration(activationDTO) {
  return axiosInstance.post('/api/v2/integrations/office365', activationDTO);
}

export function authorizationCodeExchangeCobot(code) {
  const data = {
    code,
  };
  return axiosInstance.post('/api/v2/integrations/cobot/accessToken', data);
}

export function authorizationCodeExchangeChainels(code, redirectUri) {
  const data = {
    code,
    redirectUri
  };
  return axiosInstance.post('/api/v2/integrations/chainels/accessToken', data);
}

export function authorizationCodeExchangeCloudbeds(code, redirectUri, clientId, clientSecret) {
  const data = {
    code,
    redirectUri,
    clientId,
    clientSecret
  };
  return axiosInstance.post('/api/v2/integrations/cloudbeds/accessToken', data);
}

export function fetchTickets(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/tickets',
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return executeRequestOrDefault(PERMISSION_ENTITIES.TICKET, [PERMISSIONS.ALL, PERMISSIONS.READ], requestData);
}

export function fetchTicketManagers(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/users',
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return executeRequestOrDefault(PERMISSION_ENTITIES.TICKET, [PERMISSIONS.ALL, PERMISSIONS.READ], requestData);
}

export function editTicketAdmin(ticketId, data) {
  return axiosInstance.put(`/api/v2/tickets/${ticketId}/admin`, data);
}

export function createTicket(data) {
  return axiosInstance.post(`/api/v2/tickets`, data);
}

/* ENDPOINT USATO DA GUESTS
export function closeTicket(ticketId) {
  return axiosInstance.delete(`/api/v2/tickets/${ticketId}`);
}
*/

/* ENDPOINT USATO DA GUESTS
export function resolveTicket(ticketId) {
  return axiosInstance.put(`/api/v2/tickets/${ticketId}`);
}
*/

export function ticketNewMessage(ticketId,data) {
  return axiosInstance.post(`/api/v2/tickets/${ticketId}/comment`, data);
}

export function fetchTicketCategories(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/tickets/category',
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return executeRequestOrDefault(PERMISSION_ENTITIES.TICKET, [PERMISSIONS.ALL], requestData);
}

export function createTicketCategory(data) {
  return axiosInstance.post(`/api/v2/tickets/category/manager`, data);
}

export function editTicketCategory(categoryId, data) {
  return axiosInstance.put(`/api/v2/tickets/category/${categoryId}/manager`, data);
}

export function deleteTicketCategory(categoryId) {
  return axiosInstance.delete(`/api/v2/tickets/category/${categoryId}/manager`);
}

export function fetchTicketDetails(ticketId) {
  return axiosInstance.get(`/api/v2/tickets/${ticketId}`);
}

export function fetchAreas(params) {
  const restParams = {
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return axiosInstance.get('/api/v2/areas', restParams);
}

export function editArea(areaId, data) {
  return axiosInstance.put(`/api/v2/areas/${areaId}`, data);
}

export function createArea(data) {
  return axiosInstance.post(`/api/v2/areas`, data);
}

export function deleteArea(areaId) {
  return axiosInstance.delete(`/api/v2/areas/${areaId}`);
}

export function fetchAreaOccupancy(areaId,params) {
  const restParams = {
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return axiosInstance.get(`/api/v2/areas/${areaId}/occupancy`, restParams);
}

export function fetchAreaOccupancyHourly(areaId,params) {
  const restParams = {
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return axiosInstance.get(`/api/v2/areas/${areaId}/occupancy/hourly`, restParams);
}

export function fetchAreaRules(areaId,params) {
  const restParams = {
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return axiosInstance.get(`/api/v2/areas/${areaId}/rules`, restParams);
}

export function createAreaRule(areaId,data) {
  return axiosInstance.post(`/api/v2/areas/${areaId}/rules`, data);
}

export function editAreaRule(areaId, areaRuleId, data) {
  return axiosInstance.put(`/api/v2/areas/${areaId}/rules/${areaRuleId}`, data);
}

export function deleteAreaRule(areaId, areaRuleId) {
  return axiosInstance.delete(`/api/v2/areas/${areaId}/rules/${areaRuleId}`);
}

export function fetchCompanyNotificationsSettings() {
  return axiosInstance.get('/api/v2/companies/companyNotifications/settings');
}

export function updateCompanyNotificationsSettings(data) {
  return axiosInstance.post('/api/v2/companies/companyNotifications/settings', data);
}

export function fetchPrivateUnits(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: '/api/v2/privateUnits/admin',
    params,
  };
  // TODO permissions
  return executeRequestOrDefault(PERMISSION_ENTITIES.USER, [PERMISSIONS.READ], requestData);
}

export function fetchPrivateUnitStandardDevices(privateUnitId,params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: `/api/v2/privateUnits/admin/${privateUnitId}/standardDevices`,
    params,
  };
  // TODO permissions
  return executeRequestOrDefault(PERMISSION_ENTITIES.USER, [PERMISSIONS.READ], requestData);
}

export function fetchPrivateUnitDetails(privateUnitId) {
  return axiosInstance.get(`/api/v2/privateUnits/admin/${privateUnitId}`);
}

export function editPrivateUnit(privateUnitId,data) {
  return axiosInstance.put(`/api/v2/privateUnits/admin/${privateUnitId}`, data);
}

export function deletePrivateUnit(privateUnitId) {
  return axiosInstance.delete(`/api/v2/privateUnits/admin/${privateUnitId}`);
}

export function fetchPrivateUnitsDefault() {
  return axiosInstance.get(`/api/v2/privateUnits/admin/default`);
}

export function editPrivateUnitsDefault(data) {
  return axiosInstance.put(`/api/v2/privateUnits/admin/default`, data);
}

export function createPrivateUnit(data) {
  return axiosInstance.post('/api/v2/privateUnits/admin', data);
}

export function createPrivateUnitsBatch(data) {
  return axiosInstance.post('/api/v2/privateUnits/admin/batch', data);
}

export function assignStandardDeviceToPrivateUnit(privateUnitId,data) {
  return axiosInstance.post(`/api/v2/privateUnits/admin/${privateUnitId}/standardDevices`, data);
}

export function removeStandardDeviceFromPrivateUnit(privateUnitId,standardDeviceId) {
  return axiosInstance.delete(`/api/v2/privateUnits/admin/${privateUnitId}/standardDevices/${standardDeviceId}`);
}

export function fetchPrivateUnitTemplates(params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: `/api/v2/privateUnits/admin/templates`,
    params,
  };
  // TODO permissions
  return executeRequestOrDefault(PERMISSION_ENTITIES.USER, [PERMISSIONS.READ], requestData);
}

export function editPrivateUnitTemplate(privateUnitTemplateId,data) {
  return axiosInstance.put(`/api/v2/privateUnits/admin/templates/${privateUnitTemplateId}`, data);
}

export function deletePrivateUnitTemplate(privateUnitTemplateId) {
  return axiosInstance.delete(`/api/v2/privateUnits/admin/templates/${privateUnitTemplateId}`);
}

export function createPrivateUnitTemplate(data) {
  return axiosInstance.post('/api/v2/privateUnits/admin/templates', data);
}

export function fetchLockers(params) {
  const restParams = {
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return axiosInstance.get('/api/v2/lockers', restParams);
}

export function editLocker(lockerId, data) {
  return axiosInstance.put(`/api/v2/lockers/${lockerId}`, data);
}

export function createLocker(data) {
  return axiosInstance.post(`/api/v2/lockers`, data);
}

export function deleteLocker(lockerId) {
  return axiosInstance.delete(`/api/v2/lockers/${lockerId}`);
}

export function addLockToLocker(lockerId, lockId) {
  return axiosInstance.put(`/api/v2/lockers/${lockerId}/smartLock/${lockId}`);
}

export function removeLockFromLocker(lockerId, lockId) {
  return axiosInstance.delete(`/api/v2/lockers/${lockerId}/smartLock/${lockId}`);
}

export function fetchLockerReservations(params) {
  const restParams = {
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return axiosInstance.get('/api/v2/lockers/reservations', restParams);
}

export function editLockerReservation(lockerId, lockerReservationId, data) {
  return axiosInstance.put(`/api/v2/lockers/${lockerId}/reservations/${lockerReservationId}`, data)
    .then((response) => response)
    .catch((error) => {
      if (error.response && error.response.status === 400) {
        const payload = error.response.data;
        return {
          data: payload,
          status: 400,
          statusText: "Bad Request",
          headers: error.response.headers,
          config: error.config,
        };
      }
      return Promise.reject(error);
    });
}

export function createLockerReservation(lockerId,data) {
  return axiosInstance.post(`/api/v2/lockers/${lockerId}/reservations`, data)
  .then((response) => response)
  .catch((error) => {
    if (error.response && error.response.status === 400) {
      const payload = error.response.data;
      return {
        data: payload,
        status: 400,
        statusText: "Bad Request",
        headers: error.response.headers,
        config: error.config,
      };
    }
    return Promise.reject(error);
  });
}

export function createLockerReservationAsDelivered(lockerId,data) {
  return axiosInstance.post(`/api/v2/lockers/${lockerId}/reservations/delivered`, data)
  .then((response) => response)
  .catch((error) => {
    if (error.response && error.response.status === 400) {
      const payload = error.response.data;
      return {
        data: payload,
        status: 400,
        statusText: "Bad Request",
        headers: error.response.headers,
        config: error.config,
      };
    }
    return Promise.reject(error);
  });
}

export function deleteLockerReservationByManager(lockerId,lockerReservationId,data) {
  return axiosInstance.delete(`/api/v2/lockers/${lockerId}/reservations/${lockerReservationId}/manager`, {
    data: data
  });
}

export function fetchCommunicationUsers(communicationId, params) {
  const requestData = {
    instance: axiosInstance,
    method: 'get',
    url: `/api/v2/communications/${communicationId}/users`,
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return executeRequestOrDefault(PERMISSION_ENTITIES.COMMUNICATION, [PERMISSIONS.ALL], requestData);
}

export function sendLockerReservationToDelivered(lockerId, reservationPin) {
  return axiosInstance.put(`/api/v2/lockers/${lockerId}/reservations/${reservationPin}/delivered`)
}

export function sendLockerReservationToPickedup(lockerId, reservationPin) {
  return axiosInstance.put(`/api/v2/lockers/${lockerId}/reservations/${reservationPin}/pickup`)
}

export function sendLockerReservationReminderEmail(lockerId, reservationId) {
  return axiosInstance.get(`/api/v2/lockers/${lockerId}/reservations/${reservationId}/remind`, {});
}

export function fetchAvailableLockers(params) {
  const restParams = {
    params,
    paramsSerializer: par => qs.stringify(par, { indices: false, encode: false }),
  };
  return axiosInstance.get('/api/v2/lockers/available', restParams);
}