import axios from 'axios';
import qs from 'qs';
import { axiosInstance } from './rest';
import { getChainelsBaeUrl } from './utils';


const nexudusAxiosInstance = axios.create({
  timeout: 30000,
  baseURL: 'https://spaces.nexudus.com/',
});


export function loginWithNexudus(username, password, clientId = null) {
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'client_id': clientId,
    },
  };
  const credentials = {
    grant_type: 'password',
    username,
    password,
  };
  return nexudusAxiosInstance.post('/api/token', qs.stringify(credentials), config);
}

export function getNexudusUserBusinesses(token) {
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${token}`,
    },
  };
  return nexudusAxiosInstance.get('/api/sys/businesses?page=1&size=100', config);
}


export function activateNexudusIntegration(token, refreshToken, businessIds, syncAllUsers, clientId) {
  const activationDTO = {
    token,
    refreshToken,
    businessIds,
    syncAllUsers,
    clientId,
  };
  return axiosInstance.post('/api/v2/integrations/nexudus', activationDTO);
}

const chainelsAxiosInstance = axios.create({
  timeout: 30000,
  baseURL: getChainelsBaeUrl(),
});


const cloudBedsAxiosInstance = axios.create({
  timeout: 30000,
  baseURL: 'https://api.cloudbeds.com/api/v1.1',
});

 
export function loginWithCloudbeds(clientId, clientSecret, code) {
  const redirect_uri = `${window.location.protocol}//${window.location.host}`;
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  const credentials = {
    grant_type: 'authorization_code',
    client_id: clientId,
    client_secret: clientSecret,
    redirect_uri,
    code
  };
  return cloudBedsAxiosInstance.post('/access_token', qs.stringify(credentials), config);
}


export function loginWithChainels(clientId, clientSecret, code) {
  const redirect_uri = `${window.location.protocol}//${window.location.host}`;
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  const credentials = {
    grant_type: 'authorization_code',
    client_id: clientId,
    client_secret: clientSecret,
    redirect_uri,
    code
  };
  return chainelsAxiosInstance.post('/oauth/access_token', qs.stringify(credentials), config);
}


export function getChainelsUser(token) {
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${token}`,
    },
  };
  return chainelsAxiosInstance.get('api/v2/accounts/me', config);
}

export function activateChainelIntegration(token, refreshToken, clientId, clientSecret, communityId) {
  const activationDTO = {
    token,
    refreshToken,
    clientId,
    clientSecret,
    communityId,
  };
  return axiosInstance.post('/api/v2/integrations/chainels', activationDTO);
}


const andcardsAxiosInstance = axios.create({
  timeout: 30000,
  baseURL: 'https://api.andcards.com',
});

export function getAndcardsOrganization(clientId, clientSecret) {
  const organizationsGetDTO = {
    clientId,
    clientSecret
  };
  return axiosInstance.post('/api/v2/integrations/andcards/getLocations', organizationsGetDTO);
}


export function activateAndcardsIntegration(clientId, clientSecret, organizationId) {
  const activationDTO = {
    clientId,
    clientSecret,
    organizationId,
  };
  return axiosInstance.post('/api/v2/integrations/andcards', activationDTO);
}


export const loginWithOauthProvider = (provider, token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return axiosInstance.post(`/api/v2/oauth/${provider}`, { token, deviceType: 'WEB_PORTAL' }, config);
};

export function disableIntegration(integrationId, token) {
  const deactivationDTO = {
    token,
  };
  return axiosInstance.put(`/api/v2/integrations/${integrationId}/disable`, deactivationDTO);
}

const officeRndAxiosInstance = axios.create({
  timeout: 30000,
  baseURL: 'https://app.officernd.com',
});


export function loginWithOfficeRnd(username, password) {
  const accessDTO = {
    username,
    password,
  };
  return officeRndAxiosInstance.post('/api/v1/auth/signin', accessDTO);
}


const microsoftAxiosInstance = axios.create({
  timeout: 30000,
  baseURL: 'https://graph.microsoft.com/v1.0',
});

export function loginWithOffice365(clientId, clientSecret, tenantId) {
  const credentials = {
    clientId,
    clientSecret,
    tenantId,
  };
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return axiosInstance.post(`/api/v2/integrations/office365/accessToken`, credentials, config);
}

export function getMicrosoftUserByPrincipalName(principalEmail, token) {
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${token}`,
    },
  };
  return microsoftAxiosInstance.get(`/users/${principalEmail}`, config);
}

export function getMicrosoftUserCalendars(principalId, token) {
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${token}`,
    },
  };
  return microsoftAxiosInstance.get(`/users/${principalId}/calendars`, config);
}

export function activateCloudbedsIntegration(token = null, refreshToken = null, clientId, clientSecret, timeZone) {
  const activationDTO = {
    token,
    refreshToken,
    clientId,
    clientSecret,
    timeZone
  };
  return axiosInstance.post('/api/v2/integrations/cloudbeds', activationDTO);
}

export function getMewsEnterprises(params) {
  return axiosInstance.post('/api/v2/integrations/mews/getEnterprises', params);
}

export function activateMewsIntegration(activationDTO) {
  return axiosInstance.post('/api/v2/integrations/mews', activationDTO);
}

export function fetchOptixResourceTypes(fetchDTO) {
  return axiosInstance.post('/api/v2/integrations/optixapp/getResourceTypes', fetchDTO);
}

export function fetchOctorateAccommodations(fetchDTO) {
  return axiosInstance.post('/api/v2/integrations/octorate/getAccommodations', fetchDTO);
}

export function activateOptixIntegration(activationDTO) {
  return axiosInstance.post('/api/v2/integrations/optixapp', activationDTO);
}

export function activateTenupIntegration(activationDTO) {
  return axiosInstance.post('/api/v2/integrations/tenup', activationDTO);
}

export function activateZapfloorIntegration() {
  return axiosInstance.post('/api/v2/integrations/zapfloor');
}

export function activateResHarmonicsIntegration(activationDTO) {
  return axiosInstance.post('/api/v2/integrations/resHarmonics', activationDTO);
}


export function activateOctorateIntegration(activationDTO) {
  return axiosInstance.post('/api/v2/integrations/octorate', activationDTO);
}

export function fetchCobotResourceCategories(fetchDTO) {
  return axiosInstance.post('/api/v2/integrations/cobot/resourceCategories', fetchDTO);
}

export function activateFluidaIntegration(activationDTO) {
  return axiosInstance.post('/api/v2/integrations/fluida', activationDTO);
}