import { axiosInstance } from './rest';

export function fetchAccessories(params) {
  const paramsToSend = {
    params,
  };
  return axiosInstance.get('/api/v2/accessories', paramsToSend);
}

export function createAccessory(accessoryDTO) {
  return axiosInstance.post('/api/v2/accessories', accessoryDTO);
}

export function fetchAccessoryDetails(accessoryId) {
  return axiosInstance.get(`/api/v2/accessories/${accessoryId}`);
}

export function fetchAccessoryDetailsByDeviceId(accessoryDeviceId) {
  return axiosInstance.get(`/api/v2/accessories/deviceId/${accessoryDeviceId}`);
}

export function deleteAccessory(accessoryId) {
  return axiosInstance.delete(`/api/v2/accessories/${accessoryId}`);
}

export function updateAccessory(accessoryId, accessoryDTO) {
  return axiosInstance.put(`/api/v2/accessories/${accessoryId}`, accessoryDTO);
}

export function fetchAccessoryHistory(accessoryId, params) {
  const paramsToSend = {
    params,
  };
  return axiosInstance.get(`/api/v2/accessories/${accessoryId}/history`, paramsToSend);
}
