import _ from 'lodash';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import { connect } from 'react-redux';
import LockOpenOutlined from '@material-ui/icons/LockOpenOutlined';
import * as LockActions from '../../redux/actions/lock.actions';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import LockDetailsRow from './LockDetailsRow.jsx';
import SmartLocksEventsIconCustom from '../CustomIcons/SmartLocksEventsIconCustom.jsx';
import { Card } from '@material-ui/core';
import { getLockImage } from '../../_config/utils.js';
import { LockOpen } from '@material-ui/icons';
import MDButton from '../MDButton/MDButton.jsx';
import { ACCESSORIES_TYPES, GREEN, SMARTLOCK_DOOR_STATUS, SMARTLOCK_DOOR_STATUS_MAP } from '../../_config/consts.js';

const RemoteOpenLockRow = ({ lock: { model, name, serialNumber, passageModeState, accessories }, onClick, onSetPassageModeLockByGateway }) => {
  const lockSensor = accessories ? _.find(accessories, accessory => accessory.type === ACCESSORIES_TYPES.DOOR_SENSOR) : null;
  let doorStatusVariables;
  if (lockSensor) {
    doorStatusVariables = lockSensor.value && SMARTLOCK_DOOR_STATUS_MAP[lockSensor.value] ? SMARTLOCK_DOOR_STATUS_MAP[lockSensor.value] : SMARTLOCK_DOOR_STATUS_MAP[SMARTLOCK_DOOR_STATUS.UNKNOWN];
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
      <Card
        style={{ padding: 5, borderRadius: 10, position: 'relative', flex: 1 }}
        className={`card card-rounded`}
        onClick={() => onClick()}
      >
        <div className="card-body" style={{ padding: 10, marginLeft: 10, position: 'relative' }}>
          <div>
            <div className="rounded-view">
              <img className="lock-icon" style={{ width: 60, height: 60 }} src={getLockImage(model)} />
            </div>
          </div>
          <div className="card-inner-body">
            <div className="card-header" style={{ paddingLeft: 0, paddingRight: 0, display: 'flex', flexDirection: 'column' }}>
              <h4 className="card-title" style={{ width: '89%', margin: 0, marginTop: 0, marginLeft: 5, color: '#3f3f3f', textOverflow: 'ellipsis', whiteSpace: 'break-spaces' }}>{name}</h4>
              <h5 className="card-title" style={{ marginBottom: 0, marginTop: 10, color: '#3f3f3f', fontWeight: 'normal', marginLeft: 5 }}>{serialNumber}</h5>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
            {passageModeState !== "OFF" ?
              <LockOpenOutlined
                data-tip={L20NContext.getSync('passageMode')}
                style={{ fontSize: 18, opacity: 1, marginRight: 5, color: GREEN }}
              />
            : null}
            {doorStatusVariables && doorStatusVariables.icon}
          </div>
        </div>
      </Card>
      {onSetPassageModeLockByGateway ? 
        <MDButton
          variant="outlined"
          color="primary"
          title={L20NContext.getSync('togglePassageMode')}
          onClick={() => onSetPassageModeLockByGateway()}
        /> : null}
    </div>
  );
}


@connect((state) => ({
  locks: state.locks,
  viewLoading: state.utils.viewLoading,
  themeName: state.settings.items.theme.data.themeName,
}))
class LockRemoteOpenListView extends React.Component {

  render() {
    const { locks: { remoteOpenSmartLocks }, listContainerStyle, onOpenLock, viewLoading, onSelectLock, onSetPassageModeLockByGateway } = this.props;
    return (
      <div style={{ height: '125vh', overflow: 'scroll', padding: 15 }}>
        {!_.isEmpty(remoteOpenSmartLocks) ?
          _.map(remoteOpenSmartLocks, lock =>
            <RemoteOpenLockRow
              key={lock.id}
              lock={lock}
              onSetPassageModeLockByGateway={onSetPassageModeLockByGateway ? () => onSetPassageModeLockByGateway(lock) : null}
              onClick={() => onOpenLock(lock)}
            />) : (
              <div>
                {!viewLoading ? (
                  <EmptyView
                    title="noLockCredential"
                    subtitle="verifyLockCredential"
                    icon={<SmartLocksEventsIconCustom style={{ color: '#a5a3a3', width: 50 }} />}
                    containerStyle={{ paddingLeft: 30, paddingRight: 30}}
                  />) : <ListLoadingView />}
              </div>
              )
          }
        </div>
    );
  }
}

export default LockRemoteOpenListView;