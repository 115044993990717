import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import CustomField from '../Fields/CustomField.jsx';

const validate = values => {
  const errors = {};

  if (!values.organizationId) {
    errors.organizationId = 'required';
  }

  if (!values.token) {
    errors.token = 'required';
  }


  return errors;
};

@reduxForm({ form: 'FluidaActivationForm', validate })
class FluidaActivationForm extends React.Component {
  
  render() {
    return (
      <div>
        <Entity
          name="organizationId"
          componentClass={Field}
          componentAttribute="placeholder"
          entity=""
          title="Fluida Company ID"
          component={CustomField}
        />
        <Entity
          name="token"
          componentClass={Field}
          componentAttribute="placeholder"
          entity=""
          title="Fluida API Key"
          component={CustomField}
        />
      </div>
    );
  }
} 

export default FluidaActivationForm;
