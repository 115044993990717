import { withStyles } from '@material-ui/core';
import { Col, Grid, Row } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { submit } from 'redux-form';
import moment from 'moment';
import { EXTERNAL_IDENTITY_PROVIDERS, GREEN } from '../../_config/consts.js';
import { isMobileBrowser } from '../../_config/utils';
import EmailSendIconCustom from '../../components/CustomIcons/EmailSendIconCustom.jsx';
import LoginFormContainer from '../../components/forms/LoginForm/LoginFormContainer.jsx';
import LoginWithChainels from '../../components/Login/LoginWithChainels.jsx';
import LoginWithCobot from '../../components/Login/LoginWithCobot.jsx';
import LoginWithEmailOTP from '../../components/Login/LoginWithEmailOTP.jsx';
import LoginWithNexudus from '../../components/Login/LoginWithNexudus.jsx';
import LoginWithOfficeRnd from '../../components/Login/LoginWithOfficeRnd.jsx';
import LoginWithSAML from '../../components/Login/LoginWithSAML.jsx';
import MDButton from '../../components/MDButton/MDButton.jsx';
import BasicModal from '../../components/Modals/BasicModal.jsx';
import PasswordRecoveryForm from '../../components/forms/LoginForm/PasswordRecoveryForm.jsx';
import * as ModalActions from '../../redux/actions/modal.actions';
import * as UserActions from '../../redux/actions/user.actions';
import * as CloudCreditsActions from '../../redux/actions/cloudCredits.actions';

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    overflow: 'hidden',
    backgroundColor: '#f5f5f5',
  },
  leftPanel: {
    flex: 1,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '0 80px',
    backgroundColor: '#157495',
    backgroundImage: 'url(/imgs/common/loginBackground.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'linear-gradient(to right, rgba(21, 116, 149, 0.7), rgba(21, 116, 149, 0.7))',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      width: '40px',
      height: '100%',
      background: 'linear-gradient(to right, rgba(21, 116, 149, 0), rgba(245, 245, 245, 0.2))',
      zIndex: 2,
    }
  },
  leftPanelContent: {
    position: 'relative',
    zIndex: 1,
    color: 'white',
    width: '100%',
    '& img': {
      maxWidth: '100%',
      height: 'auto'
    },
    '& *': {
      color: 'white !important'
    }
  },
  rightPanel: {
    flex: 1,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    position: 'relative',
    boxShadow: '-12px 0 20px -10px rgba(0, 0, 0, 0.15)',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '40px',
      height: '100%',
      background: 'linear-gradient(to right, rgba(0, 0, 0, 0.03), transparent)',
      zIndex: 1,
    }
  },
  copyright: {
    position: 'absolute',
    bottom: 20,
    color: '#666',
    fontSize: 12,
    textAlign: 'center',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  welcomeText: {
    color: 'white',
    fontSize: 48,
    fontWeight: 600,
    marginBottom: 16,
    position: 'relative',
    zIndex: 1
  },
  description: {
    color: 'rgba(255,255,255,0.9)',
    fontSize: 18,
    maxWidth: 500,
    position: 'relative',
    zIndex: 1
  },
  mobileContainer: {
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#157495',
    padding: 20
  }
});

@withRouter
@connect(state => ({ 
  userCredentials: state.user.userLogin, 
  companyConfigurations: state.user.companyConfigurations,
  loginContent: state.user.loginContent
}))
class Login extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    document.addEventListener('keydown', e => this.onSubmitPressed(e), false);
    dispatch(UserActions.fetchLoginContent());
  }
  
  componentWillUnmount() {
    document.removeEventListener('keydown', e => this.onSubmitPressed(e), false);
  }
  
  onSubmitPressed(e) {
    if (e.keyCode === 13) {
      const { dispatch } = this.props;
      dispatch(submit('LoginForm'));
    }
  }

  makeLogin(loginData) {
    const { dispatch } = this.props;
    const { email, password } = loginData;
    dispatch(UserActions.verifyUser(email, password));
  }

  getIDPLoginButton(idp) {
    if (!_.includes(idp.availablePlatforms, 'FE')) {
      return null;
    }

    if (idp && idp.type) {
      switch (idp.type) {
        case EXTERNAL_IDENTITY_PROVIDERS.SAML:
          return <LoginWithSAML idp={idp} />;
        case EXTERNAL_IDENTITY_PROVIDERS.NEXUDUS:
          return <LoginWithNexudus idp={idp} />;
        case EXTERNAL_IDENTITY_PROVIDERS.OTP_EMAIL:
          return <LoginWithEmailOTP idp={idp} />;
        case EXTERNAL_IDENTITY_PROVIDERS.COBOT:
          return <LoginWithCobot idp={idp} />;
        case EXTERNAL_IDENTITY_PROVIDERS.OFFICERND:
          return <LoginWithOfficeRnd idp={idp} />;
        case EXTERNAL_IDENTITY_PROVIDERS.CHAINELS:
          return <LoginWithChainels idp={idp} />;
        default:
          return null;
      }
    }
  }

  showForgotPassword() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        title: <Entity entity="passwordForget" />,
        content: (
          <div style={{ marginTop: 50, display: 'flex', flexDirection: 'column' }}>
            <EmailSendIconCustom style={{ width: 60, alignSelf: 'center', color: GREEN }} />
            <h4 style={{ color: '#3f3f3f', marginBottom: 0, textAlign: 'center' }}>
              <Entity entity="passwordForgetDescription" />
            </h4>
            <PasswordRecoveryForm
              onSubmit={recoveryData => this.sendResetPassowrd(recoveryData)}
            />
            <MDButton
              backgroundColor="#157495"
              title={<Entity entity="passwordRecoveryButton" />}
              onClick={() => dispatch(submit('PasswordRecoveryForm'))}
            />
          </div>
        ),
        onConfirmText: <Entity entity="gotcha" />,
        modalStyle: { height: '90% !important' },
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  async sendResetPassowrd(recoveryData) {
    const { dispatch } = this.props;
    dispatch(ModalActions.hideModal());
    await dispatch(UserActions.forgotPassword(recoveryData.email));
  }

  onGoToInvitationLogin() {
    const { dispatch } = this.props;
    dispatch(push('/loginInvitation'))
  }

  render() {
    const { classes, companyConfigurations, loginContent, dispatch } = this.props;
    const isMobile = isMobileBrowser();
    const externalIDP = !_.isEmpty(companyConfigurations) && companyConfigurations.externalIDP;
    const invitationsActive = companyConfigurations && companyConfigurations.invitationsActive;
    const loginHtmlContent = loginContent && loginContent.htmlContent;
    const isHTMLContentAvailable = loginHtmlContent && loginHtmlContent.length > 0;
    if (isMobile) {
      return (
        <div className={classes.mobileContainer}>
          <LoginFormContainer 
            onSubmit={loginData => this.makeLogin(loginData)}
            onForgotPassword={() => this.showForgotPassword()}
            onInvitationLogin={() => this.onGoToInvitationLogin()}
            externalIDP={externalIDP}
            invitationsActive={invitationsActive}
            getIDPLoginButton={idp => this.getIDPLoginButton(idp)}
          />
          <div className={classes.copyright} style={{ bottom: 50, marginLeft: 10, marginRight: 10 }}>
            <span style={{ color: 'white', fontSize: 10}}>Sofia part of ISEO© {moment().year()}. All rights reserved. <span style={{ fontSize: 10 }}><a href="https://sofialocks.com/en/privacy-policy/" target="_blank"><Entity entity="privacyPolicy" /></a></span></span>
            <span style={{ color: 'white', fontSize: 10 }}>Sofia SRL - VAT: IT09129330966</span>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <div className={classes.leftPanel} style={{ backgroundImage: loginContent && loginContent.featuredImage ? `url(${loginContent.featuredImage})` : 'url(/imgs/common/loginBackground.jpg)' }}>
          {loginHtmlContent ? (
            <div className={classes.leftPanelContent} dangerouslySetInnerHTML={{ __html: loginHtmlContent }} />
          ) : (
            <>
              <div className={classes.description}>
                <img src="/imgs/common/logoJagoWhite.png" alt="Logo" style={{ width: 250, height: 'auto' }} />
                <img src="/imgs/common/tagLine.png" alt="Logo" style={{ width: '100%', height: 'auto', marginTop: 10 }} />
                <p style={{ marginTop: 20 }}><Entity entity="loginDescription" /></p>
              </div>
            </>
          )}
        </div>
        <div className={classes.rightPanel}>
          <LoginFormContainer
            isHTMLContentAvailable={isHTMLContentAvailable}
            onSubmit={loginData => this.makeLogin(loginData)}
            onForgotPassword={() => this.showForgotPassword()}
            onInvitationLogin={() => this.onGoToInvitationLogin()}
            externalIDP={externalIDP}
            invitationsActive={invitationsActive}
            getIDPLoginButton={idp => this.getIDPLoginButton(idp)}
          />
          <div className={classes.copyright}>
            <span style={{ fontSize: 10 }}>Luckey Web {process.env.REACT_APP_FRONTEND_VERSION} - Sofia part of ISEO© {moment().year()}. All rights reserved. <span style={{ fontSize: 10 }}><a href="https://sofialocks.com/en/privacy-policy/" target="_blank"><Entity entity="privacyPolicy" /></a></span></span>
            <span style={{ fontSize: 10 }}>Sofia SRL - VAT: IT09129330966</span>
          </div>
        </div>
        <BasicModal
          ref={(m) => { this.passwordForgetModal = m; }}
          title={<h3 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0 }}><Entity entity="passwordForget" /></h3>}
          body={
            <div>
              <h4 style={{ color: '#3f3f3f', marginTop: 0, marginBottom: 20 }}>
                <Entity entity="passwordForgetDescription" />
              </h4>
              <PasswordRecoveryForm
                onSubmit={recoveryData => this.sendResetPassowrd(recoveryData)}
              />
              <MDButton
                backgroundColor="#157495"
                title={<Entity entity="passwordRecoveryButton" />}
                onClick={() => dispatch(submit('PasswordRecoveryForm'))}
              />
            </div>
          }
        />
      </div>
    );
  }
}

export default withStyles(styles)(Login);
