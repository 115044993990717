import InfoIcon from '@material-ui/icons/HelpOutline';
import { Form } from '@sketchpixy/rubix';
import _ from 'lodash';
import { connect } from 'react-redux';
import { ctx as L20NContext, Entity } from '@sketchpixy/rubix/lib/L20n';
import moment from 'moment';
import React from 'react';
import { change, Field, reduxForm } from 'redux-form';
import CustomField from '../Fields/CustomField.jsx';
import { BLUE, COMMUNICATION_EMAIL_STATUSES, COMMUNICATION_TARGET_AUDIENCE_VALUES, GREEN, MATCH_TAG_MODE, ORANGE, PERMISSION_ENTITIES, PERMISSIONS, RED, USER_COMMUNICATION_EMAIL_STATUSES_PARAMETERS } from '../../../_config/consts.js';
import DatePickerField from '../Fields/DatePickers/SingleDatePicker/DatePickerField.jsx';
import MDSwitchView from '../Fields/MDSwitch/MDSwitchView.jsx';
import TimePickerField from '../Fields/TimePicker/TimePickerField.jsx';
import WarningIcon from '@material-ui/icons/Warning';
import FormFlatContainer from '../Elements/FormFlatContainer.jsx';
import ReservationEmailIconCustom from '../../CustomIcons/ReservationEmailIconCustom.jsx';
import CloseCircleIconCustom from '../../CustomIcons/CloseCircleIconCustom.jsx';
import { CheckCircleOutline, SmartphoneOutlined } from '@material-ui/icons';
import MDButton from '../../MDButton/MDButton.jsx';
import FormCardContainer from '../Elements/FormCardContainer.jsx';
import TagsSelectorListField from '../Elements/TagsSelectorListField.jsx';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider.js';
import { hasFormWritePermission, isUserWithoutEmail } from '../../../_config/utils.js';
import * as CredentialActions from '../../../redux/actions/credential.actions.js';
import * as TagActions from '../../../redux/actions/tag.actions.js';
import * as CommunicationsActions from '../../../redux/actions/communications.actions';
import CommunicationIconCustom from '../../CustomIcons/CommunicationIconCustom.jsx';
import SendVariantClockOutlineIconCustom from '../../CustomIcons/SendVariantClockOutlineIconCustom.jsx';
import { Divider } from '@material-ui/core';
import UsersIconCustom from '../../CustomIcons/UsersIconCustom.jsx';
import MDRadioButtonsGroupField from '../Elements/MDRadioButtonsGroup/MDRadioButtonsGroupField.js';
import * as ModalActions from '../../../redux/actions/modal.actions.js';
import CommunicationTableView from '../../Communications/CommunicationTableView.jsx';
import PresentationalViewHeader from '../../PresentationalView/PresentationalViewHeader.jsx';
import CommunicationUsersTableView from '../../Communications/CommunicationUsersTableView.jsx';
import CommunicationTargetAudienceIconCustom from '../../CustomIcons/CommunicationTargetAudienceIconCustom.jsx';
import TranslatableOption from '../Fields/TranslatableOption.jsx';

const validate = (values) => {
  const errors = {};
  if (values) {
    if (!values.name) {
      errors.name = 'required';
    }
    if (!values.description) {
      errors.description = 'required';
    }
    if (!values.startDate) {
      errors.startDate = 'required';
    }
    if (!values.endDate) {
      errors.endDate = 'required';
    }
    if (values.showInApp) {
      const startDate = moment(values.startDate);
      const endDate = moment(values.endDate);
      if (endDate.isSameOrBefore(startDate))
        errors.endDate = 'required';
    }
    if (values.emailStatus === COMMUNICATION_EMAIL_STATUSES.PENDING && !values.emailObject) {
      errors.emailObject = 'required';
    }
    if (!values.targetAudienceMode) {
      errors.targetAudienceMode = 'required';
    }
    if (values.targetAudienceMode === COMMUNICATION_TARGET_AUDIENCE_VALUES.TARGET && (!values.guestTags || values.guestTags.length === 0)) {
      errors.guestTags = 'required';
    }
  }
  return errors;
};

let filterTimeout;
@reduxForm({ form: 'CommunicationForm', validate })
@connect(state => ({ form: state.form.CommunicationForm, communications: state.communications, themeName: state.settings.items.theme.data.themeName, userTags: state.tags.user.data, credential: state.credentials.selectedCredential, language: state.settings.language }))
class CommunicationForm extends React.Component {

  constructor(props) {
    super(props);

    const guestTags = (props && props.form && props.form.values && props.form.values.guestTags) || [];
    const guestTagMatchingMode = (props && props.form && props.form.values && props.form.values.guestTagMatchingMode) || MATCH_TAG_MODE.AT_LEAST_ONE_TAG;
    this.fetchGuestsByTags(guestTags, guestTagMatchingMode, 0, false)

    this.state = {};
  }

  componentDidUpdate(prevProps) {
    const { form: currentForm } = this.props;
    const { form: previousForm } = prevProps;
    if (currentForm && currentForm.values && previousForm && previousForm.values && currentForm.values.id !== previousForm.values.id) {
      const guestTags = (currentForm.values.guestTags) || [];
      const guestTagMatchingMode = (currentForm.values.guestTagMatchingMode) || MATCH_TAG_MODE.AT_LEAST_ONE_TAG;
      this.fetchGuestsByTags(guestTags, guestTagMatchingMode, 0, false)
    }
  }

  onChangeStartDate(value) {
    const { dispatch } = this.props;
    if (value)
      dispatch(change('CommunicationForm', 'startDate', moment(value).valueOf()));
  }

  onChangeStartTime(value) {
    const { dispatch } = this.props;
    if (value)
      dispatch(change('CommunicationForm', 'startTime', moment(value).valueOf()));
  }
  
  onChangeEndDate(value) {
    const { dispatch } = this.props;
    if (value)
      dispatch(change('CommunicationForm', 'endDate', moment(value).valueOf()));
  }

  onToggleScheduleSending(selected) {
    const { dispatch } = this.props;
    dispatch(change('CommunicationForm', 'scheduleSending', selected));
    if (!selected) {
      dispatch(change('CommunicationForm', 'startDate', moment().valueOf()));
      dispatch(change('CommunicationForm', 'startTime', moment().valueOf()));
    }
  }

  onChangeTargetAudienceMode(value) {
    const { dispatch } = this.props;
    if (value === COMMUNICATION_TARGET_AUDIENCE_VALUES.BROADCAST) {
      dispatch(change('CommunicationForm', 'guestTags', []));
      dispatch(change('CommunicationForm', 'guestTagMatchingMode', MATCH_TAG_MODE.AT_LEAST_ONE_TAG));
      this.fetchGuestsByTags([], MATCH_TAG_MODE.AT_LEAST_ONE_TAG, 0, false);
    }
  }

  onToggleShowInApp(selected) {
    const { dispatch } = this.props;
    dispatch(change('CommunicationForm', 'showInApp', selected));
  }

  onToggleSendEmail(selected) {
    const { dispatch } = this.props;
    dispatch(change('CommunicationForm', 'emailStatus', selected ? COMMUNICATION_EMAIL_STATUSES.PENDING : COMMUNICATION_EMAIL_STATUSES.DISABLED));
  }

  onRemoveEmailRequest() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        body: <Entity entity="communicationRemoveEmailRequest" />,
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => this.onRemoveEmailConfirm(),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  onRemoveEmailConfirm() {
    const { dispatch } = this.props;
    dispatch(change('CommunicationForm', 'emailStatus', COMMUNICATION_EMAIL_STATUSES.DISABLED));
    dispatch(ModalActions.hideModal())
  }

  fetchGuestsByTags(guestTags, guestTagMatchingMode, page, append) {
    const { dispatch } = this.props;
    dispatch(CredentialActions.cancelFetchGuestsByTags());
    if (guestTags && guestTags.length > 0)
      dispatch(CredentialActions.fetchGuestsByTags(guestTags, guestTagMatchingMode, page, append));
    else 
      dispatch(CredentialActions.setGuestsFilteredByTagsEpic([], { pagination: { totalElements: 0 } }, false));
  }

  filterGuestTags(value) {
    const { dispatch } = this.props;
    const includeSpecialTags = true;
    dispatch(TagActions.cancelFetchGuestTagsByFilter());
    dispatch(TagActions.fetchGuestTagsByFilter(value, 50, includeSpecialTags));
  }

  onFetchCommunicationUsersOnPage(page) {
    const { dispatch, form } = this.props;
    const communicationId = form && form.values && form.values.id;
    if (communicationId) {
      dispatch(CommunicationsActions.fetchCommunicationUsers(communicationId, page));
    }
  }

  async onFetchCommunicationUsers() {
    const { dispatch, form } = this.props;  
    const communicationId = form && form.values && form.values.id;
    if (communicationId) {
      try {
        await dispatch(CommunicationsActions.fetchCommunicationUsers(communicationId));
      } 
      catch (error) {}
      finally {}
    }
  }

  onSetFilter(field, value) {
    const { dispatch, form } = this.props;
    const communicationId = form && form.values && form.values.id;
    if (communicationId) {
      dispatch(CommunicationsActions.setCommunicationUsersFilter(field, value));
      if (filterTimeout) clearTimeout(filterTimeout);
      filterTimeout = setTimeout(async () => {
        await this.onFetchCommunicationUsers();
      }, 500);
    }
  }

  onSearchReset() {
    const { dispatch, onFetchCommunications } = this.props;
    dispatch(CommunicationsActions.resetCommunicationsFilters());
    dispatch(CommunicationsActions.resetCommunicationUsersFilters());
    this.onFetchCommunicationUsers();
    onFetchCommunications();
  }


  onExportElements(format) {
    const { dispatch, form } = this.props;
    const communicationId = form && form.values && form.values.id;
    if (communicationId) {
      dispatch(CommunicationsActions.exportCommunicationUsers(communicationId, format));
    }
  }

  render() {
    const { form, userTags: availableUserTags, credential } = this.props;

    const { communications: { selectedCommunicationUsers: { pagination }, selectedCommunicationUsersTotal }, themeName } = this.props;
    const numberOfCommunicationUsers = pagination && pagination.totalElements ? pagination.totalElements : 0;
    const numberOfCommunicationUsersTotal = selectedCommunicationUsersTotal;

    const scheduleSendingCurrent = form && form.values && form.values.scheduleSending;

    const guestTagsCurrent = form && form.values && form.values.guestTags;

    const guestsFilteredByTags = credential.guestsFilteredByTags;
    const guestsFilteredByTagsLength = (guestsFilteredByTags && guestsFilteredByTags.pagination &&  guestsFilteredByTags.pagination.totalElements) || 0

    const isEdit = form && form.values && form.values.id;
    
    const startDateInitial = form && form.initial && form.initial.startDate
    const startTimeInitial = form && form.initial && form.initial.startTime
    const scheduledDateInitial = moment(startDateInitial).hour(moment(startTimeInitial).hour()).minute(moment(startTimeInitial).minute()).valueOf()

    const startDate = form && form.values && form.values.startDate
    const startTime = form && form.values && form.values.startTime
    const endDate = form && form.values && form.values.endDate
    const emailSentDate = form && form.values && form.values.emailSentDate
    const startDateString = moment(startDate).locale(moment().locale())
    const startDateTimeString = moment(startDate).hour(moment(startTime).hour()).minute(moment(startTime).minute()).locale(moment().locale())
    const endDateString = moment(endDate).locale(moment().locale());
    const emailSentDateString = moment(emailSentDate).locale(moment().locale());

    const emailStatus = form && form.values && form.values.emailStatus;
    const emailEnabled = emailStatus && emailStatus !== COMMUNICATION_EMAIL_STATUSES.DISABLED
    const emailSectionVisible = !isEdit || emailEnabled

    const emailSentSuccess = emailStatus && emailStatus === COMMUNICATION_EMAIL_STATUSES.SUCCESS
    const emailSentSuccessWithFailures = emailStatus && emailStatus === COMMUNICATION_EMAIL_STATUSES.SUCCESS_WITH_FAILURES
    const emailSentFail = emailStatus && emailStatus === COMMUNICATION_EMAIL_STATUSES.FAIL
    const emailSent = emailSentSuccess || emailSentSuccessWithFailures || emailSentFail
    
    const emailSentFailErrorMessage = emailSentFail && form && form.values && form.values.emailError

    const communicationDateApproachingOrPassed = isEdit && scheduledDateInitial && moment(scheduledDateInitial).isSameOrBefore(moment().add(10, 'minutes'))
    const emailAlmostDue = isEdit && emailStatus === COMMUNICATION_EMAIL_STATUSES.PENDING && 
      scheduledDateInitial && moment(scheduledDateInitial).isSameOrBefore(moment().add(10, 'minutes'))
    const emailAlreadySent = isEdit && (emailSentSuccess || emailSentSuccessWithFailures || emailSentFail)

    const canCancelEmailSending = isEdit && !emailAlmostDue && !emailAlreadySent
    const canEditStartDate = !communicationDateApproachingOrPassed && !emailAlmostDue && !emailAlreadySent
    const canEditMessage = !emailAlmostDue && !emailAlreadySent
    const canEditMailObject = !emailAlmostDue && !emailAlreadySent
    const canEditTargetAudience = !communicationDateApproachingOrPassed && !emailAlmostDue && !emailAlreadySent
    const canEditShowInApp = !emailAlmostDue
    const canEditEndDate = !emailAlmostDue
    const canEditTitle = !emailAlmostDue

    const showInApp = form && form.values && form.values.showInApp;

    const canReadUser = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.USER);

    const communicationGuestOptions = [
      {
        label: <Entity entity="communicationTargetAudienceBroadcast" />,
        value: COMMUNICATION_TARGET_AUDIENCE_VALUES.BROADCAST,
      },
      {
        label: <Entity entity="communicationTargetAudienceTarget" />,
        value: COMMUNICATION_TARGET_AUDIENCE_VALUES.TARGET,
      },
    ];

    const targetAudienceModeCurrent = form && form.values && form.values.targetAudienceMode;

    const communicationUsersHaveBeenDefined = isEdit && numberOfCommunicationUsersTotal > 0;

    const communicationHasEmail = _.includes([COMMUNICATION_EMAIL_STATUSES.PENDING, COMMUNICATION_EMAIL_STATUSES.SUCCESS, COMMUNICATION_EMAIL_STATUSES.SUCCESS_WITH_FAILURES, COMMUNICATION_EMAIL_STATUSES.FAIL], emailStatus);

    return (
      <Form>
        <input style={{ display: 'none' }} type="username" name="username" />
        <FormCardContainer title="communicationDataTitle">
          {(emailAlmostDue || emailAlreadySent) && 
            <div style={{ 
              display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: 'rgba(255, 152, 0, 0.08)', border: '1px solid orange', 
              borderRadius: '4px', padding: '16px', margin: '20px 0 30px 0'
            }}>
              <WarningIcon style={{ color: 'orange', marginRight: 16,fontSize: 28 }} />
              <h4 style={{ color: '#3f3f3f', lineHeight: '1.5em', fontWeight: 'bold', margin: 0, fontSize: 16 }}>
                <Entity entity={emailAlmostDue ? "communicationPendingEmailAlmostDue" : "communicationPendingEmailAlreadySent"}/>
              </h4>
            </div>
          }
          <FormFlatContainer
            title="communicationContentTitle"
            titleStyle={{ fontSize: 20 }}
            icon={<CommunicationIconCustom style={{ width: 22, marginTop: 2, marginRight: 2 }} />}
          >
            <Entity
              componentClass={Field}
              name="name"
              componentAttribute="placeholder"
              entity="insertCommunicationName"
              title={<Entity entity="communicationName" />}
              component={CustomField}
              type="text"
              mandatory={canEditTitle}
              disabled={!canEditTitle}
            />
            <Entity
              componentClass={Field}
              name="htmlContent"
              componentAttribute="placeholder"
              entity="insertCommunicationName"
              title={<Entity entity="communicationDescription" />}
              key={form?.values?.id || '0'}
              component={CustomField}
              type="htmlEditor"
              plainTextInput={{
                onChange: (value) => {
                  this.props.change('description', value);
                }
              }}
              mandatory={canEditMessage}
              disabled={!canEditMessage}
            />
            {/* 
              <Entity
                componentClass={Field}
                name="description"
                title={<Entity entity="communicationDescription" />}
                componentAttribute="placeholder"
                entity="insertCommunicationDescription"
                component={CustomField}
                type="textarea"
                className="form-control-custom"
                mandatory={canEditMessage}
                disabled={!canEditMessage}
              />
            */}
          </FormFlatContainer>
          <FormFlatContainer
            title={!isEdit ? "communicationScheduleTitle" : "communicationDate"}
            subtitle={!isEdit ? "communicationScheduleSubtitle" : null}
            key={isEdit}
            titleStyle={{ fontSize: 20 }}
            icon={<SendVariantClockOutlineIconCustom style={{ width: 22, marginTop: 2, marginRight: 2 }} />}
          >
            {!isEdit && 
              <MDSwitchView
                label={<Entity key={scheduleSendingCurrent} entity={scheduleSendingCurrent ? 'communicationScheduleSwitchON' : 'communicationScheduleSwitchOFF'} />}
                checked={scheduleSendingCurrent}
                titleStyle={{ fontWeight: scheduleSendingCurrent ? 'bold' : 'normal', color: scheduleSendingCurrent ? GREEN : '#3f3f3f', fontSize: 17, marginBottom: 1 }}
                onSelect={selected => this.onToggleScheduleSending(selected)}
                containerStyle={{ marginTop: 10, marginBottom: 10 }}
              />
            }
            {isEdit || scheduleSendingCurrent ? (
              <>
                {!isEdit && 
                  <h4 style={{ color: '#3f3f3f', fontWeight: 'bold', margin: 0, marginTop: 10, fontSize: 16 }}>
                    {<Entity entity="communicationDate" />}
                    <span style={{ color: 'red' }}>*</span>
                  </h4>
                }
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Field
                    title={null}
                    id="startDate"
                    name="startDate"
                    dateContainer={{ width: 120, marginRight: 20, marginBottom: 8, opacity: canEditStartDate ? 1 : 0.7 }}
                    onHandleChange={value => this.onChangeStartDate(value)}
                    component={DatePickerField}
                    isOutsideRange={date => date.isBefore(moment().startOf('day'))}
                    mandatory
                    readOnly={!canEditStartDate}
                    useCursorStyleNotAllowed={!canEditStartDate}
                  />
                  <h4 style={{ color: '#3f3f3f', margin: 0, marginTop: 10, marginBottom: 15, marginRight: 16, fontSize: 25, fontWeight: 'bold' }}>
                    -
                  </h4>
                  <Field
                    key={(form && form.values && form.values.startTime) || ''}
                    title={null}
                    id="startTime"
                    name="startTime"
                    component={TimePickerField}
                    onHandleChange={value => this.onChangeStartTime(value)}
                    disabled={!canEditStartDate}
                  />
                </div>
              </>
            ) : null}
            {!isEdit && !scheduleSendingCurrent ? (
              <div style={{ marginTop: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 5, marginRight: 5 }}>
                <InfoIcon style={{ color: '#3f3f3f', fontSize: 20, marginRight: 12 }} />
                <h5 style={{ fontSize: 15, lineHeight: '1.5em', color: '#3f3f3f' }}>
                  <Entity entity="communicationScheduledOffInfo"/>
                </h5>
              </div>
            ) : null}
          </FormFlatContainer>
          <FormFlatContainer
            title="communicationChannelsTitle"
            subtitle="communicationChannelsSubtitle"
            titleStyle={{ fontSize: 20 }}
            containerStyle={{ paddingTop: 20 }}
          >
            <FormFlatContainer
              title="communicationShowInApp"
              subtitle={showInApp ? "communicationShowInAppDescription" : null}
              titleStyle={{ fontSize: 20 }}
              icon={<SmartphoneOutlined style={{ fontSize: 22, marginTop: 2, marginRight: 2 }} />}
              containerStyle={{ marginTop: 0 }}
              titleContainerStyle={{ alignItems: 'end' }}
              titleSideComponent={ 
                <MDSwitchView
                  label={null}
                  checked={showInApp}
                  titleStyle={{ fontWeight: showInApp ? 'bold' : 'normal', color: showInApp ? GREEN : '#3f3f3f', fontSize: 17, marginBottom: 1 }}
                  onSelect={selected => this.onToggleShowInApp(selected)}
                  containerStyle={{ marginTop: -10, marginBottom: -14, marginLeft: 20 }}
                  disabled={!canEditShowInApp}
                />
              }
            >
              {showInApp && 
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 5, marginRight: 5 }}>
                  <h4 style={{ color: '#3f3f3f', margin: 0, marginTop: 10, marginBottom: 5, marginRight: 10,fontSize: 15, fontWeight: 'light' }}>
                    {<Entity entity="communicationScheduledEndDate" />}
                  </h4>
                  <Field
                    title={null}
                    id="endDate"
                    name="endDate"
                    dateContainer={{ width: 120, marginRight: 20, marginBottom: 8 }}
                    onHandleChange={value => this.onChangeEndDate(value)}
                    component={DatePickerField}
                    isOutsideRange={date => date.isBefore(moment().startOf('day'))}
                    mandatory
                    disabled={!canEditEndDate}
                  />
                </div>
              }
              {showInApp && startDate && endDate && endDate>startDate && 
                <div style={{ marginTop: 10, marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 5, marginRight: 5 }}>
                  <InfoIcon style={{ color: '#3f3f3f', fontSize: 20, marginRight: 12 }} />
                  <h5 style={{ fontSize: 15, lineHeight: '1.5em', color: '#3f3f3f' }}>
                    <Entity
                      entity="communicationDateDescription"
                      key={`${startDate}-${endDate}`}
                      data={{
                        startDate: startDateString.format('LL'),
                        endDate: endDateString.format('LL'),
                      }}
                    />
                  </h5>
                </div>
              }
              {showInApp && startDate && endDate && endDate<=startDate && 
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10, marginTop: 10, marginLeft: 5, marginRight: 5 }}>
                  <WarningIcon style={{ color: 'orange', marginRight: 10, fontSize: 24 }} />
                  <h4 style={{ color: '#3f3f3f', lineHeight: '1.5em', fontWeight: 'bold', margin: 0, fontSize:16 }}>
                    <Entity entity={"communicationScheduledEndDateError"}/>
                  </h4>
                </div>
              }
              {showInApp && 
                <Divider/>
              }
            </FormFlatContainer>
            {emailSectionVisible &&
              <FormFlatContainer
                title="communicationSendEmail"
                subtitle={emailEnabled ? "communicationSendEmailDescription" : null}
                titleStyle={{ fontSize: 20 }}
                containerStyle={{ marginTop: 0 }}
                titleContainerStyle={{ alignItems: 'end' }}
                icon={<ReservationEmailIconCustom style={{ width: 22, marginTop: 2, marginRight: 2 }} />}
                titleSideComponent={!isEdit && 
                  <MDSwitchView
                    label={null}
                    checked={emailEnabled}
                    titleStyle={{ fontWeight: emailEnabled ? 'bold' : 'normal', color: emailEnabled ? GREEN : '#3f3f3f', fontSize: 17, marginBottom: 1 }}
                    onSelect={selected => this.onToggleSendEmail(selected)}
                    containerStyle={{ marginTop: -10, marginBottom: -14, marginLeft: 20 }}
                  />
                }
              >
                {emailEnabled &&
                  <div style={{marginLeft: 5, marginRight: 5}}>
                    <Entity
                      componentClass={Field}
                      name="emailObject"
                      componentAttribute="placeholder"
                      entity="communicationEmailObjectDescription"
                      title={<Entity entity="communicationEmailObject" />}
                      component={CustomField}
                      type="text"
                      mandatory={canEditMailObject}
                      disabled={!canEditMailObject}
                    />
                  </div>
                }
                {emailEnabled && !emailSent && 
                  <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 5, marginRight: 5 }}>
                    <InfoIcon style={{ color: '#3f3f3f', fontSize: 20, marginRight: 12 }} />
                    <h5 style={{ fontSize: 15, lineHeight: '1.5em', color: '#3f3f3f' }}>
                      <Entity
                        entity="communicationDateEmailDescription"
                        key={`${startDateTimeString}`}
                        data={{
                          startDate: startDateTimeString.format('LLL'),
                        }}
                      />
                    </h5>
                  </div>
                }
                {emailEnabled && emailSentSuccess && 
                  <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 5, marginRight: 5 }}>
                    <CheckCircleOutline style={{ color: GREEN, fontSize: 20, marginRight: 12 }} />
                    <h5 style={{ fontSize: 15, lineHeight: '1.5em', color: GREEN, fontWeight: 'bold' }}>
                      <Entity
                        entity="communicationDateEmailSentSuccess"
                        key={`${emailSentDateString}`}
                        data={{
                          startDate: emailSentDateString.format('LLL'),
                        }}
                      />
                    </h5>
                  </div>
                }
                {emailEnabled && emailSentSuccessWithFailures && 
                  <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 5, marginRight: 5 }}>
                    <WarningIcon style={{ color: ORANGE, fontSize: 20, marginRight: 12 }} />
                    <h5 style={{ fontSize: 15, lineHeight: '1.5em', color: 'black', fontWeight: 'bold' }}>
                      <Entity
                        entity="communicationDateEmailSentSuccessWithFailures"
                        key={`${emailSentDateString}`}
                        data={{
                          startDate: emailSentDateString.format('LLL'),
                        }}
                      />
                    </h5>
                  </div>
                }
                {emailEnabled && emailSentFail && 
                  <>
                    <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 5, marginRight: 5 }}>
                      <CloseCircleIconCustom style={{ color: RED, width: 20, marginRight: 12 }} />
                      <h5 style={{ fontSize: 15, lineHeight: '1.5em', color: RED, fontWeight: 'bold' }}>
                        <Entity
                          entity="communicationDateEmailSentFail"
                          key={`${emailSentDateString}`}
                          data={{
                            startDate: emailSentDateString.format('LLL'),
                          }}
                        />
                      </h5>
                    </div>
                    {emailSentFailErrorMessage && 
                      <h5 style={{ fontSize: 15, lineHeight: '1.5em', color: RED, fontWeight: 'bold', margin: 0, marginLeft: 36 }}>
                        <Entity
                          entity="communicationEmailSentFailError"
                          key={`${emailSentFailErrorMessage}`}
                          data={{
                            emailError: emailSentFailErrorMessage,
                          }}
                        />
                      </h5>
                    }
                  </>
                }
                {canCancelEmailSending &&
                  <MDButton
                    title={<Entity entity="communicationRemoveEmail" />}
                    backgroundColor={RED}
                    onClick={() => this.onRemoveEmailRequest()}
                  />
                }
              </FormFlatContainer>
            }
            {!isEdit && !emailEnabled && !showInApp && 
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom:10, marginTop: 20, marginLeft: 16 }}>
                <WarningIcon style={{ color: 'orange', marginRight: 10, fontSize: 24 }} />
                <h4 style={{ color: '#3f3f3f', lineHeight: '1.5em', fontWeight: 'bold', margin: 0, fontSize:16 }}>
                  <Entity entity={"communicationErrorSelectAtLeastOne"}/>
                </h4>
              </div>
            }
          </FormFlatContainer>
          {canReadUser &&
            <FormFlatContainer
              title="communicationGuestTitle"
              subtitle="communicationGuestSubtitle"
              titleStyle={{ fontSize: 20 }}
              icon={<CommunicationTargetAudienceIconCustom style={{ width: 22, marginTop: 2, marginRight: 2 }} />}
            >
              <Field
                name="targetAudienceMode"
                title={<Entity entity="deviceUUIDMode" />}
                component={MDRadioButtonsGroupField}
                containerstyle={{ paddingLeft: 15, marginTop: 0 }}
                showErrorLabel
                options={communicationGuestOptions}
                onHandleChange={value => this.onChangeTargetAudienceMode(value)}
                disabled={!canEditTargetAudience}
              />
              {targetAudienceModeCurrent === COMMUNICATION_TARGET_AUDIENCE_VALUES.TARGET &&
                <div style={{ marginTop: 10 }}>
                  <h4 style={{ fontSize: 17, lineHeight: 1.4, marginTop: 0, marginLeft: 0, color: '#3f3f3f' }}>
                    <Entity entity={"communicationTargetAudienceDescription"} />
                  </h4>
                  <TagsSelectorListField
                    formName="CommunicationForm"
                    name="guestTags"
                    selectorFieldName="guestTagMatchingMode"
                    selectedTagsFieldName="selectedGuestTags"
                    tags={availableUserTags}
                    selectedTags={guestsFilteredByTags}
                    form={form}
                    tagsType="GUEST"
                    title="usersTags"
                    placeholder="insertGuestTagsForCredential"
                    listTitle="selectedUsers"
                    emptyTagSelectionText={null}
                    onFetchMoreItems={page => this.fetchGuestsByTags(form && form.values && form.values.guestTags, form && form.values && form.values.guestTagMatchingMode, page, true)}
                    onTagsChange={values => this.fetchGuestsByTags(values, form && form.values && form.values.guestTagMatchingMode, 0, false)}
                    onInputChange={value => this.filterGuestTags(value)}
                    itemHasErrorCondition={item => isUserWithoutEmail(item)}
                    onFilterModeChange={values => this.fetchGuestsByTags(form && form.values && form.values.guestTags, values, 0, false)}
                    disabled={!canEditTargetAudience}
                    alwaysHideList={communicationUsersHaveBeenDefined}
                  />
                </div>
              }
              {targetAudienceModeCurrent === COMMUNICATION_TARGET_AUDIENCE_VALUES.BROADCAST && 
                <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 5, marginRight: 5 }}>
                  <InfoIcon style={{ color: '#3f3f3f', fontSize: 20, marginRight: 12 }} />
                  <h5 style={{ fontSize: 15, lineHeight: '1.5em', color: '#3f3f3f' }}>
                    <Entity entity={"communicationSentToAllUsers"}/>
                  </h5>
                </div>
              }
              {targetAudienceModeCurrent === COMMUNICATION_TARGET_AUDIENCE_VALUES.TARGET && guestsFilteredByTagsLength>0 && !communicationUsersHaveBeenDefined && 
                <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 5, marginRight: 5 }}>
                  <InfoIcon style={{ color: '#3f3f3f', fontSize: 20, marginRight: 12 }} />
                  <h5 style={{ fontSize: 15, lineHeight: '1.5em', color: '#3f3f3f' }}>
                    <Entity
                      entity={"communicationSentToSpecificUsers"}
                      key={`${targetAudienceModeCurrent} - ${guestsFilteredByTagsLength}`}
                      data={{
                        numberOfGuests: guestsFilteredByTagsLength,
                      }}
                    />
                  </h5>
                </div>
              }
              {targetAudienceModeCurrent === COMMUNICATION_TARGET_AUDIENCE_VALUES.TARGET && guestTagsCurrent && guestTagsCurrent.length > 0 && !guestsFilteredByTagsLength && !communicationUsersHaveBeenDefined ? (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom:30, marginTop: 20 }}>
                  <WarningIcon style={{ color: 'orange', marginRight: 10, fontSize: 24 }} />
                  <h4 style={{ color: '#3f3f3f', lineHeight: '1.5em', fontWeight: 'bold', margin: 0, fontSize:16 }}>
                    <Entity entity={"communicationNoUsersForSelectedTag"}/>
                  </h4>
                </div>
              ) : null}
            </FormFlatContainer>
          }
          {canReadUser && communicationUsersHaveBeenDefined && 
            <FormFlatContainer
              title="communicationUsersSectionTitle"
              subtitle="communicationUsersSectionSubtitle"
              titleStyle={{ fontSize: 20 }}
              icon={<UsersIconCustom style={{ width: 22, marginTop: 2, marginRight: 2 }} />}
            >
              <div>
                <style>
                  {`
                    .transparent-presentational-view-header {
                      background-color: transparent !important;
                    }
                  `}
                </style>
                <PresentationalViewHeader
                  additionalThemeClass={"transparent-presentational-view-header"}
                  containerStyle={{ position: 'unset', boxShadow: 'unset', paddingLeft: 0, paddingRight: 0 }}
                  themeName={themeName}
                  onUserCommunicationSearchChange={value => this.onSetFilter('search', _.trim(value))}
                  onNewEntity={null}
                  onSearchSubmit={() => {this.onFetchCommunicationUsers()}}
                  onSearchReset={() => {this.onSearchReset()}}
                  searchGuestContainerStyle={{ width: 280 }}
                  newEntityTitle="createNewF"
                  searchPlaceholderEntityName="search"
                  newEntityIconName="icon-simple-line-icons-user-following"
                  onExportElements={format => {this.onExportElements(format)}}
                  numberOfElements={numberOfCommunicationUsers}
                  selectableFieldName={'emailStatus'}
                  selectableFieldTitle={undefined}
                  onSelectOption={communicationHasEmail ? (value) => this.onSetFilter('emailStatus', value==="__ANY__"?undefined:value, true) : null}
                  exportIconStyle={{ color: BLUE }}
                  exportTitleStyle={{ color: BLUE }}
                  selectableOptions={[
                    (<Entity
                      key={"userCommunicationAnyStatus"}
                      componentClass={TranslatableOption}
                      value={"__ANY__"}
                      componentAttribute="text"
                      entity={"userCommunicationAnyStatus"}
                    />),
                    ..._.map(USER_COMMUNICATION_EMAIL_STATUSES_PARAMETERS, status =>
                      <option value={status.value} key={status.value}>
                        {L20NContext.getSync(status.string)}
                      </option>
                    )
                  ]}
                />
                <CommunicationUsersTableView
                  onFetchCommunicationUsersOnPage={page => {this.onFetchCommunicationUsersOnPage(page)}}
                  communicationHasEmail={communicationHasEmail}
                />
              </div>
            </FormFlatContainer>
          }
        </FormCardContainer>
      </Form>
    );
  }
} 

export default CommunicationForm;
