// @ts-nocheck
import { Card, Divider } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { InfoOutlined, ShareOutlined } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import WarningIcon from '@material-ui/icons/Warning';
import { Form, Row } from '@sketchpixy/rubix';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import momentTz from 'moment-timezone';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray, change, reduxForm } from 'redux-form';
import { ACCESSORIES_TYPES, BLUE, GREEN, LICENSE_TYPES, RED, RESOURCE_CAPACITY_SLOTS, RESOURCE_CAPACITY_TYPE } from '../../../_config/consts.js';
import { elaborateReservationCheckedInterval, formatDomainHostNameToFriendlyName, localizeHelpCenterLink, minutesOfDay } from '../../../_config/utils';
import AbilityProvider from '../../../permissionsUtils/AbilityProvider.js';
import * as UtilsActions from '../../../redux/actions/utils.actions.js';
import CalendarClockIconCustom from '../../CustomIcons/CalendarClockIconCustom.jsx';
import CalendarEditIconCustom from '../../CustomIcons/CalendarEditIconCustom.jsx';
import ClockLockIconCustom from '../../CustomIcons/ClockLockIconCustom.jsx';
import ClockRemoveIconCustom from '../../CustomIcons/ClockRemoveIconCustom.jsx';
import ClockUnlockIconCustom from '../../CustomIcons/ClockUnlockIconCustom.jsx';
import ReservationEmailIconCustom from '../../CustomIcons/ReservationEmailIconCustom.jsx';
import ShareIconCustom from '../../CustomIcons/ShareIconCustom.jsx';
import SmartPhoneKeyIconCustom from '../../CustomIcons/SmartPhoneKeyIconCustom.jsx';
import UserSettingIconCustom from '../../CustomIcons/UserSettingIconCustom.jsx';
import MDAccordion from '../../MDAccordion/MDAccordion.jsx';
import MDButton from '../../MDButton/MDButton.jsx';
import FormCardContainer from '../Elements/FormCardContainer.jsx';
import FormFlatContainer from '../Elements/FormFlatContainer.jsx';
import MDRadioButtonsGroupField from '../Elements/MDRadioButtonsGroup/MDRadioButtonsGroupField.js';
import TagsSelectorListField from '../Elements/TagsSelectorListField.jsx';
import CustomField from '../Fields/CustomField.jsx';
import MDCheckBoxField from '../Fields/MDCheckBox/MDCheckBoxField.js';
import MDSwitchView from '../Fields/MDSwitch/MDSwitchView.jsx';
import ResourceAvatarSelector from '../Fields/ResourceAvatarSelector.jsx';
import SelectableField from '../Fields/SelectableInput/SelectableField.jsx';
import SelectableRowField from '../Fields/SelectableRows/SelectableRowField.jsx';
import SentenceSelectorInputField from '../Fields/SentenceSelectorInputField.jsx';
import TagsSelectorField from '../Fields/TagsSelectorField.jsx';
import TranslatableOption from '../Fields/TranslatableOption.jsx';
import ResourcesTimeSelector from './ResourcesTimeSelector.jsx';
import ClockAlertIconCustom from '../../CustomIcons/ClockAlertIconCustom.jsx';
import ClockCheckIconCustom from '../../CustomIcons/ClockCheckIconCustom.jsx';
import LinkedResourceIconCustom from '../../CustomIcons/LinkedResourceIconCustom.jsx';
import ClockTimerIconCustom from '../../CustomIcons/ClockTimerIconCustom.jsx';
import TimezoneIconCustom from '../../CustomIcons/TimezoneIconCustom.jsx';

const onRenderNotificationEmails = (props) => {
  const { fields, formData, onInitializeNewNotificationEmail } = props;
  const numberOfNotificationEmails = _.size(fields);
  return (
    <div style={{}}>
      {fields && fields.map((notificationEmail, index) => (
        <div key={`notificationEmail${index + 1}`} elevation={2} style={{width:'fit-content'}}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: -10, paddingRight: 10 }}>
            <Row style={{marginLeft:0, marginRight:0 }}>
              <div style={{ paddingLeft: 0, width: 400 }}>
                <Entity
                  id={`notificationEmails[${index}]`}
                  name={`notificationEmails[${index}]`}
                  componentClass={Field}
                  componentAttribute="placeholder"
                  entity="insertEmail"
                  title={null}
                  component={CustomField}
                />
              </div>
            </Row>
            <IconButton
              style={{ marginLeft: 30, marginBottom: 15 }}
              disabled={props.disabled}
              onClick={(e) => {
                e.stopPropagation();
                if (!formData.isEditing) {
                  fields.remove(index);
                }
              }}
            >
              <DeleteIcon style={{ color: RED, fontSize: 20 }} />
            </IconButton>
          </div>
        </div>
      ))}
      {!props.disabled ? (
        <Card
          style={{ height: 40, marginBottom: 20, padding: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width:'fit-content' }}
          className="card card-rounded"
          onClick={() => {
            fields.push({});
            onInitializeNewNotificationEmail(numberOfNotificationEmails);
          }}
        >
          <AddIcon style={{ color: '#4DB6AC', marginRight: 15, fontSize: 25 }} />
          <h4 style={{ fontWeight: 'bold', marginTop:0, marginBottom:0 }}><Entity entity="newResourceReservationAutomaticEmail" /></h4>
        </Card>
      ) : null}
    </div>
  );
}

const validate = (values, props) => {
  const errors = [];
  if (values) {
    if (!values.name) {
      errors.name = 'required';
    }
    if (!values.type) {
      errors.type = 'required';
    }

    if (!values.type || (values.type && values.type.length === 0)) {
      errors.type = 'required';
    }

    if (!values.credentialTimeframe
        || !values.credentialTimeframe.startDate
        || !values.credentialTimeframe.endDate) {
      errors.credentialTimeframe = 'required';
    }

    if (parseInt(values.deleteByUserWithPenaltyBeforeMinutes, 10) < 0) {
      errors.deleteByUserWithPenaltyBeforeMinutes = 'nonNegative';
    }
    
    if (parseInt(values.checkInWindowMinutes, 10) < 0) {
      errors.checkInWindowMinutes = 'nonNegative';
    }

    if (parseInt(values.checkInWindowBeforeMinutes, 10) < 0) {
      errors.checkInWindowBeforeMinutes = 'nonNegative';
    }

    if (parseInt(values.locksOpenValidAfterMinutes, 10) < 0) {
      errors.locksOpenValidAfterMinutes = 'nonNegative';
    }
    if (parseInt(values.reservationCutoffTimeMinutes, 10) < 0) {
      errors.reservationCutoffTimeMinutes = 'nonNegative';
    }
    if (parseInt(values.maximumReservationTimeHours, 10) < 0) {
      errors.maximumReservationTimeHours = 'nonNegative';
    }

    if (!values.timeIntervalFrom) {
      errors.timeIntervalFrom = 'required';
    }

    if (!values.timeIntervalTo) {
      errors.timeIntervalTo = 'required';
    }
    if (values.timeIntervalTo && values.timeIntervalFrom) {
      if (minutesOfDay(values.timeIntervalTo) <= minutesOfDay(values.timeIntervalFrom)) {
        errors.timeIntervalTo = 'Interval not valid';
      }
    }

    const isMultidayBookeyReservationsEnabled = props.isMultidayBookeyReservationsEnabled;
    if (isMultidayBookeyReservationsEnabled) {
      if (parseInt(values.maximumReservationTimeHours, 10) >= 24) {
        if (values.timeIntervalFrom != null && minutesOfDay(values.timeIntervalFrom) !== 0) {
          errors.timeIntervalFrom = 'timeIntervalFromMustBeBeginningOfDay';
        }
        if (values.timeIntervalTo != null && minutesOfDay(values.timeIntervalTo) !== (24 * 60 - 1)) {
          errors.timeIntervalTo = 'timeIntervalToMustBeEndOfDay';
        }

        if (values.credentialTimeframe && values.credentialTimeframe.startDate && values.credentialTimeframe.endDate) {
          const startDate = moment(values.credentialTimeframe.startDate);
          const endDate = moment(values.credentialTimeframe.endDate);
          const hoursDifference = endDate.diff(startDate, 'hours');
          
          if (parseInt(values.maximumReservationTimeHours, 10) > hoursDifference) {
            errors.maximumReservationTimeHours = 'nonMoreThanReservationTimeInterval';
          }
        }
      } else {
        if (parseInt(values.maximumReservationTimeHours, 10) * 60 > minutesOfDay(values.timeIntervalTo) - minutesOfDay(values.timeIntervalFrom)) {
          errors.maximumReservationTimeHours = 'nonMoreThanReservationTimeInterval';
        }
      }
    } else {
      if (parseInt(values.maximumReservationTimeHours, 10) * 60 > minutesOfDay(values.timeIntervalTo) - minutesOfDay(values.timeIntervalFrom)) {
        errors.maximumReservationTimeHours = 'nonMoreThanReservationTimeInterval';
      }
    }

    if (parseInt(values.freeBeforeMinutes, 10) < 0) {
      errors.freeBeforeMinutes = 'nonNegative';
    }

    if (parseInt(values.freeAfterMinutes, 10) < 0) {
      errors.freeAfterMinutes = 'nonNegative';
    }
    
    if (isNaN(parseInt(values.capacity, 10)) || parseInt(values.capacity, 10) <= (values.capacityType===RESOURCE_CAPACITY_TYPE.MULTI ? 1:0)) {
      errors.capacity = ' ';
    }
  }
  return errors;
};


@reduxForm({ form: 'ResourcesForm', validate })
@connect(state => ({ form: state.form.ResourcesForm, locksDetailsMap: state.locks.cachedLocksMap, allLocks: state.locks.allLocks.content, }))
class ResourcesForm extends React.Component {

  constructor(props) {
    super(props);
    const hasTagsSelected = props && props.form && props.form.values && props.form.values.resourceUserTags && !_.isEmpty(props.form.values.resourceUserTags);
    const hasLimitedReservations = props && props.form && props.form.values && props.form.values.maxActiveReservationsPerUser && props.form.values.maxActiveReservationsPerUser > 1;
    const workspacesOptions =  _.map(_.compact(props.availableWorkspaces), workspace => ({ label: workspace, value: workspace }));
    const allLocksCompatible = (props.allLocks || []).filter(e=>e.configuration && e.configuration.remoteOpenEnabled)
    const hasOpenLockBeforeReservation = props && props.form && props.form.values && props.form.values.openIfReservationStartsInMinutes && props.form.values.smartLockIdToOpenIfReservationStartsInMinutes
    const hasOpenLockAfterReservation = props && props.form && props.form.values && props.form.values.openIfNoReservationStartsInMinutes && props.form.values.smartLockIdToOpenIfNoReservationStartsInMinutes
    const locksBeforeReservationOptions = _.map(allLocksCompatible, lock => ({ label: `${lock.name} [${lock.serialNumber||""}]`, value: lock.id }));
    const locksAfterReservationOptions = _.map(allLocksCompatible, lock => ({ label: `${lock.name} [${lock.serialNumber||""}]`, value: lock.id }));
    this.state = {
      showLimitVisibility: hasTagsSelected,
      showLimitReservations: hasLimitedReservations,
      workspacesOptions: workspacesOptions,
      showOpenLockBeforeReservation: Boolean(hasOpenLockBeforeReservation),
      showOpenLockAfterReservation: Boolean(hasOpenLockAfterReservation),
      locksBeforeReservationOptions: locksBeforeReservationOptions,
      locksAfterReservationOptions: locksAfterReservationOptions,
      timezones: momentTz.tz.names(),
      guessedTimezone: momentTz.tz.guess(),
    };
  }

  async componentDidMount() {
    const { resource } = this.props;
    this.initResourceTimezone(resource)
  }

  componentWillReceiveProps(nextProps) {
    const { resource: resourceOld } = this.props;
    const { resource } = nextProps;
    if (resource.id!==resourceOld.id)
      this.initResourceTimezone(resource)
  }

  async initResourceTimezone(resource) {
    const { dispatch } = this.props;
    const { guessedTimezone } = this.state;
    let timezone_initial = resource.timeZone?resource.timeZone:guessedTimezone
    dispatch(change('ResourcesForm', 'timeZone', timezone_initial));
  }
  
  onFilterTimezones(value) {
    const timezones = momentTz.tz.names().filter(timezone => timezone.toLowerCase().includes(value.toLowerCase()));
    this.setState({ timezones });
  }

  onSelectTimezone(selectedOption) {
    const { dispatch } = this.props;
    const selectedTimezone = selectedOption && selectedOption.value ? selectedOption.value : momentTz.tz.guess();
    dispatch(change('ResourcesForm', 'timeZone', selectedTimezone));
    this.setState({ guessedTimezone: selectedTimezone });
  }

  onToggleLimitVisibility(selected) {
    this.setState({ showLimitVisibility: selected });
    if (!selected) {
      const { dispatch } = this.props;
      dispatch(change('ResourcesForm', 'resourceUserTags', []));
    }
  }

  onToggleLimitReservation(selected) {
    this.setState({ showLimitReservations: selected });
    const { dispatch } = this.props;
    if (!selected) {
      dispatch(change('ResourcesForm', 'maxActiveReservationsPerUser', 0));
    } else {
      dispatch(change('ResourcesForm', 'maxActiveReservationsPerUser', 1));
    }
  }

  onToggleOpenLockBeforeReservation(selected) {
    this.setState({ showOpenLockBeforeReservation: selected });
    const { dispatch } = this.props;
    if (!selected) {
      dispatch(change('ResourcesForm', 'openIfReservationStartsInMinutes', 0));
      dispatch(change('ResourcesForm', 'smartLockIdToOpenIfReservationStartsInMinutes', null));
    } else {
      dispatch(change('ResourcesForm', 'openIfReservationStartsInMinutes', 30));
    }
  }

  onToggleOpenLockAfterReservation(selected) {
    this.setState({ showOpenLockAfterReservation: selected });
    const { dispatch } = this.props;
    if (!selected) {
      dispatch(change('ResourcesForm', 'openIfNoReservationStartsInMinutes', 0));
      dispatch(change('ResourcesForm', 'smartLockIdToOpenIfNoReservationStartsInMinutes', null));
    } else {
      dispatch(change('ResourcesForm', 'openIfNoReservationStartsInMinutes', 30));
    }
  }

  onToggleCheckInButtonInAppDisabled(selected) {
    this.setState({ checkInButtonInAppDisabled: selected });
    const { dispatch } = this.props;
    dispatch(change('ResourcesForm', 'checkInButtonInAppDisabled', selected));
  }

  onToggleEnableInvitedEmails(selected) {
    this.setState({ enableInvitedEmails: selected });
    const { dispatch } = this.props;
    dispatch(change('ResourcesForm', 'enableInvitedEmails', selected));
  }

  onOpenCheckinLockInfoPage() {
    const helpURL = localizeHelpCenterLink('bookey-add-on-smart-check-in');
    window.open(helpURL);
  }

  onFilterWorkspaces(value) {
    const { availableWorkspaces } = this.props;
    const filteredWorkspaces = _.filter(_.compact(availableWorkspaces), workspace => workspace.toLowerCase().includes(value.toLowerCase()));
    const workspacesOptions =  _.map(filteredWorkspaces, workspace => ({ label: workspace, value: workspace }));
    this.setState({ workspacesOptions });
    return value;
  }

  onFilterLocksBeforeReservation(value) {
    const { allLocks } = this.props;
    const allLocksCompatible = (allLocks || []).filter(e=>e.configuration && e.configuration.remoteOpenEnabled)
    const filteredLocksBeforeReservation = _.filter(allLocksCompatible, lock => lock.name.toLowerCase().includes(value.toLowerCase()) || (lock.serialNumber || "").toLowerCase().includes(value.toLowerCase()));
    const locksBeforeReservationOptions =  _.map(filteredLocksBeforeReservation, lock => ({ label: `${lock.name} [${lock.serialNumber||""}]`, value: lock.id }));
    this.setState({ locksBeforeReservationOptions });
    return value;
  }

  onFilterLocksAfterReservation(value) {
    const { allLocks } = this.props;
    const allLocksCompatible = (allLocks || []).filter(e=>e.configuration && e.configuration.remoteOpenEnabled)
    const filteredLocksAfterReservation = _.filter(allLocksCompatible, lock => lock.name.toLowerCase().includes(value.toLowerCase()) || (lock.serialNumber || "").toLowerCase().includes(value.toLowerCase()));
    const locksAfterReservationOptions =  _.map(filteredLocksAfterReservation, lock => ({ label: `${lock.name} [${lock.serialNumber||""}]`, value: lock.id }));
    this.setState({ locksAfterReservationOptions });
    return value;
  }

  onLockBeforeReservationSelected(value) {
    const { dispatch } = this.props;
    dispatch(change('ResourcesForm', 'smartLockIdToOpenIfReservationStartsInMinutes', value && value.value ? value.value : null));
  }

  onLockAfterReservationSelected(value) {
    const { dispatch } = this.props;
    dispatch(change('ResourcesForm', 'smartLockIdToOpenIfNoReservationStartsInMinutes', value && value.value ? value.value : null));
  }

  onResourceTypeChanged(value) {
    const { dispatch } = this.props;
    if (value === RESOURCE_CAPACITY_TYPE.ONE) {
      dispatch(change('ResourcesForm', 'capacity', "1"));
    }
    else {
      dispatch(change('ResourcesForm', 'capacity', "2"));
      dispatch(change('ResourcesForm', 'freeBeforeMinutes', "0"));
      dispatch(change('ResourcesForm', 'freeAfterMinutes', "0"));
      dispatch(change('ResourcesForm', 'minimumReservationTimeHours', RESOURCE_CAPACITY_SLOTS[_.keys(RESOURCE_CAPACITY_SLOTS)[0]].value / 60));
      dispatch(change('ResourcesForm', 'maximumReservationTimeHours', RESOURCE_CAPACITY_SLOTS[_.keys(RESOURCE_CAPACITY_SLOTS)[0]].value / 60));
      dispatch(change('ResourcesForm', 'maximumReservationSlotsNumber', 1));
    }
  }

  onResourceSlotChange(value) {
    const { dispatch, form } = this.props;
    const slotMinutesValue = value / 60;
    const maximumReservationSlotsNumberValue = form?.values?.maximumReservationSlotsNumber;
    dispatch(change('ResourcesForm', 'minimumReservationTimeHours', slotMinutesValue));
    dispatch(change('ResourcesForm', 'maximumReservationTimeHours', (maximumReservationSlotsNumberValue?maximumReservationSlotsNumberValue:1)*slotMinutesValue));
    if (!maximumReservationSlotsNumberValue)
      dispatch(change('ResourcesForm', 'maximumReservationSlotsNumber', 1));
  }

  onResourceSlotsMaxChange(value) {
    const { dispatch, form } = this.props;
    const slotMinutesValue = form?.values?.slotMinutes;
    const maximumReservationSlotsNumberValue = value;
    dispatch(change('ResourcesForm', 'maximumReservationTimeHours', (slotMinutesValue?slotMinutesValue:RESOURCE_CAPACITY_SLOTS.MINUTES_15.value)*value / 60));
    if (!slotMinutesValue)
      dispatch(change('ResourcesForm', 'slotMinutes', RESOURCE_CAPACITY_SLOTS.MINUTES_15.value));
  }

  onInitializeNewNotificationEmail(emailId, fieldName) {
    const { dispatch } = this.props;    
    dispatch(change('ResourcesForm', `${fieldName}[${emailId}]`, ""));
  }

  render() {
    const {
      availableLockTags,
      availableUserTags,
      availableTypesTags,
      form,
      locksFilteredByTags,
      guestsFilteredByTags,
      onFetchMoreGuests,
      onGuestTagsChange,
      onGuestInputChange,
      onFetchMoreLocks,
      onLockTagsChange,
      onLockInputChange,
      onLockFilterModeChange,
      onGuestFilterModeChange,
      onNewTag,
      onTypeInputChange,
      onDeleteResource,
      onSelectCheckInLock,
      resourcesOptions,
      onFetchMoreAccessories,
      onFetchMoreResources,
      accessoriesData,
      dispatch,
      onWorkspaceSelected,
      subcompanies,
      onOpenResourceSharingSettings,
      resource,
      timeIntervalsOK,
    } = this.props;
    const { 
      showLimitVisibility, showLimitReservations, workspacesOptions, 
      showOpenLockAfterReservation, showOpenLockBeforeReservation,
      locksBeforeReservationOptions, locksAfterReservationOptions, 
      timezones,
    } = this.state;

    const formData = form && form.values;

    const resourceID = form && form.values && form.values.id;
    const checkinLockId = form && form.values && form.values.checkInSmartLockId;
    const selectedLockData = _.find(locksFilteredByTags && locksFilteredByTags.data, { id: checkinLockId });
    
    const checkedInterval = form && form.values && form.values.credentialTimeframe && elaborateReservationCheckedInterval(form.values.credentialTimeframe.startDate, form.values.credentialTimeframe.endDate);
    const checkInButtonInAppDisabled = form && form.values && form.values.checkInButtonInAppDisabled || false;
    const enableInvitedEmails = form && form.values && form.values.enableInvitedEmails || false;
    const checkInAccessories = accessoriesData && accessoriesData.content ? _.filter(accessoriesData.content, accessory => accessory.type && accessory.type !== ACCESSORIES_TYPES.DOOR_SENSOR) : [];

    const workspaceTitleLabel = dispatch(UtilsActions.getResourcesWorkspaceLabel());
    const isOffice365IntegrationActive = dispatch(UtilsActions.isOffice365IntegrationActive());
    const isBookeyCapacityActive = dispatch(UtilsActions.isBookeyCapacityActive());
    const isAvoidAppCheckinActive = dispatch(UtilsActions.isAvoidAppCheckinActive());

    const automaticCheckIn = form && form.values && form.values.automaticCheckIn;
    const isSharedFromOtherSystem = form && form.values && form.values.sharedFromHostname;
    const isSharedWithOtherSystem = form && form.values && form.values.shared;
    const canEditResource = !AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.ENGINE]);

    const isMultiCapacity = form && form.values && form.values.capacityType===RESOURCE_CAPACITY_TYPE.MULTI

    const currentCapacityValue = form && form.values && form.values.capacity && parseInt(form.values.capacity, 10);
    const currentMaximumReservationValue = form && form.values && form.values.maxActiveReservationsPerUser && parseInt(form.values.maxActiveReservationsPerUser, 10);
    
    const slotMinutesValue = form?.values?.slotMinutes;
    const maximumReservationSlotsNumberValue = form?.values?.maximumReservationSlotsNumber;
    const maximumReservationSlotsMinutes = slotMinutesValue && maximumReservationSlotsNumberValue ? (slotMinutesValue*maximumReservationSlotsNumberValue) : undefined;
    const maximumReservationSlotsString = maximumReservationSlotsMinutes ? (
      `${(maximumReservationSlotsMinutes>=60)?(maximumReservationSlotsMinutes/60):(maximumReservationSlotsMinutes)}
      ${(maximumReservationSlotsMinutes>=60)?(L20NContext.getSync('hours')):(L20NContext.getSync('minutes'))}`
    ) : undefined

    return (
      <Form>
        <FormCardContainer
          title="resourceDetails"
          subtitle="resourceDetailsSubtitle"
          containerStyle={{ position: 'relative' }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
            <Field
              id="imageUrl"
              name="imageUrl"
              component={ResourceAvatarSelector}
            />
            <div style={{ width: '100%', marginLeft: 20 }}>
              <Entity
                id="name"
                name="name"
                componentClass={Field}
                componentAttribute="placeholder"
                entity="insertName"
                title={<Entity entity="resourceName" />}
                component={CustomField}
                disabled={isSharedFromOtherSystem || !canEditResource}
                mandatory
              />
              <Entity
                id="description"
                name="description"
                componentClass={Field}
                title={<Entity entity="description" />}
                componentAttribute="placeholder"
                entity="insertDescription"
                type="text"
                disabled={isSharedFromOtherSystem || !canEditResource}
                component={CustomField}
              />
              <Field
                name="workspace"
                title={<Entity entity={workspaceTitleLabel} />}
                component={SelectableField}
                options={workspacesOptions}
                canCreate={true}
                placeholder={<Entity entity="insertresourceWorkspace" />}
                containerstyle={{ marginBottom: 15, marginTop: -10 }}
                onNewOption={workspace => onWorkspaceSelected(workspace)}
                helpText={<Entity entity="resourceWorkspaceDescription" />}
                onSelect={value => onWorkspaceSelected(value)}
                onInputChange={name => this.onFilterWorkspaces(name)}
                disabled={isSharedFromOtherSystem || !canEditResource}
              />
              <Entity
                componentClass={Field}
                name="type"
                componentAttribute="placeholder"
                entity="insertType"
                title={<Entity entity="type" />}
                component={TagsSelectorField}
                helpText={<Entity entity="resourceTypeTagDescription" />}
                onNewTag={onNewTag}
                tags={availableTypesTags}
                onInputChange={onTypeInputChange}
                disabled={isSharedFromOtherSystem || !canEditResource}
                mandatory
              />
              {isOffice365IntegrationActive ? (
                <Entity
                  id="email"
                  name="email"
                  componentClass={Field}
                  componentAttribute="placeholder"
                  entity="insertEmail"
                  helpText={<Entity entity="resourceEmailOutlookDescription" />}
                  title={<Entity entity="resourceEmailOutlook" />}
                  component={CustomField}
                  mandatory
                />
              ) : null}
            </div>
          </div>
            {isSharedFromOtherSystem ? (
              <div style={{ display: 'flex', marginTop: 6, flexDirection: 'row', alignItems: 'center', position: 'absolute', right: 20, top: 20 }}>
                <ShareOutlined style={{ width: 19, color: '#3f3f3f', marginRight: 5 }} />
                <h5 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}>{<Entity entity="sharedFromCompany" data={{ company: formatDomainHostNameToFriendlyName(form.values.sharedFromHostname) }} />}</h5>
              </div>
            ) : null}
        </FormCardContainer>
        <FormCardContainer
          title="resourceSmartLocks"
          subtitle="resourceSmartLocksSubtitle"
          iconButtonAction={() => this.onOpenCheckinLockInfoPage()}
          icon={<InfoOutlined style={{ fontSize: 25, color: '#3f3f3f' }} />}
          iconButtonActionStyle={{ marginBottom: 5 }}
        >
          <TagsSelectorListField
            name="resourceSmartLockTags"
            tags={availableLockTags}
            selectedTags={locksFilteredByTags}
            form={form}
            tagsType="LOCK"
            title="locksTags"
            placeholder="insertLockTagsForCredential"
            listTitle="selectedLocksForResource"
            selectionSectionTitle="lockCheckinSelectionTitle"
            selectionSectionSubtitle="lockCheckinSelectionSubtitle"
            selectionSectionEmptySubtitle="selectionLockEmptySubtitle"
            isOptional
            isSingleSelection
            initialValues={selectedLockData ? [selectedLockData] : []}
            onFetchMoreItems={page => onFetchMoreLocks(page)}
            onTagsChange={values => onLockTagsChange(values)}
            onInputChange={value => onLockInputChange(value)}
            disabled={!canEditResource}
            onFilterModeChange={values => onLockFilterModeChange(values)}
            onSelectItem={lock => onSelectCheckInLock(lock)}
          />
        </FormCardContainer>
        <FormCardContainer
          title="checkIn"
          subtitle="checkInDescription"
          containerStyle={{ paddingBottom: 30 }}
        >
          <Field
            name="automaticCheckIn"
            label={<Entity entity="avoidBookeyCheckin" />}
            subtitle={<Entity entity="avoidBookeyCheckinDescription" />}
            titleStyle={{ fontSize: 16, marginBottom: 4, fontWeight: 'bold', color: '#3f3f3f' }}
            component={MDCheckBoxField}
            disabled={!canEditResource}
          />
          <Divider style={{ marginTop: 15, marginBottom: 15 }} />
          <div style={{ opacity: automaticCheckIn ? 0.5 : 1 }}>
            <h4 style={{ fontWeight: 'bold', marginBottom: 5 }}>{<Entity entity="checkInWindow" />}</h4>
            <h4 style={{ color: '#3f3f3f', fontWeight: 200, marginTop: 0, fontSize: 16 }}>{<Entity entity="checkInExplanation" />}</h4>
            <div style={{ marginTop: 20, marginLeft: 10, padding: 20, borderRadius: 10, backgroundColor: '#f6f6f6' }}>
              <Field
                id="checkInWindowBeforeMinutes"
                name="checkInWindowBeforeMinutes"
                component={SentenceSelectorInputField}
                disabled={automaticCheckIn || !canEditResource}
                sentence={L20NContext.getSync('checkInWindowBeforeMinutesSentence')}
              />
              <Field
                id="checkInWindowMinutes"
                name="checkInWindowMinutes"
                component={SentenceSelectorInputField}
                disabled={automaticCheckIn || !canEditResource}
                sentence={L20NContext.getSync('checkInWindowMinutesSentence')}
              />
            </div>
          </div>
          <Divider style={{ marginTop: 15, marginBottom: 15 }} />
          {isAvoidAppCheckinActive ? (
            <div style={{ opacity: automaticCheckIn ? 0.5 : 1, pointerEvents: automaticCheckIn ? 'none': 'unset' }}>
              <h4 style={{ fontWeight: 'bold', marginBottom: 5 }}>{<Entity entity="checkInDisabledOnApp" />}</h4>
              <h4 style={{ color: '#3f3f3f', fontWeight: 200, marginTop: 0, fontSize: 16 }}>{<Entity entity="checkInDisabledOnAppDescription" />}</h4>
              <div style={{ marginTop: 20, marginLeft: 10, padding: 20, paddingTop:10, paddingBottom:10, borderRadius: 10, backgroundColor: '#f6f6f6' }}>
                <MDSwitchView
                  label={<Entity key={checkInButtonInAppDisabled} entity={'checkInDisabledOnApp'} />}
                  checked={checkInButtonInAppDisabled}
                  disabled={!canEditResource}
                  titleStyle={{ fontWeight: checkInButtonInAppDisabled ? 'bold' : 'normal', color: checkInButtonInAppDisabled ? GREEN : '#3f3f3f', fontSize: 17, marginBottom: 1 }}
                  onSelect={selected => this.onToggleCheckInButtonInAppDisabled(selected)}
                />
              </div>
            </div>
          ) : null}
        </FormCardContainer>
        <FormCardContainer
          title="reservationCheckinRules"
          containerStyle={{ paddingBottom: 30 }}
        >
          {isBookeyCapacityActive ? (
            <FormFlatContainer
              title="reservationTypes"
              subtitle="resourceCapacityTypeDescription2"
              icon={<CalendarEditIconCustom style={{ width: 22, marginTop: 2, marginRight: 2 }} />}
            >
              <div>
                <Field
                  name={'capacityType'}
                  title={<Entity entity="resourceCapacityType" />}
                  component={MDRadioButtonsGroupField}
                  disabled={resourceID || !canEditResource}
                  onHandleChange={(values) => {this.onResourceTypeChanged(values)}}
                  containerstyle={{ paddingLeft: 15, marginTop: 10 }}
                  options={[
                    {
                      label: <h4 style={{ fontSize: 16 }}><Entity key={'ONE'} entity={'resourceCapacityOne'} /></h4>,
                      value: RESOURCE_CAPACITY_TYPE.ONE,
                    },
                    {
                      label: <h4 style={{ fontSize: 16 }}><Entity key={'MULTI'} entity={'resourceCapacityMulti'} /></h4>,
                      value: RESOURCE_CAPACITY_TYPE.MULTI,
                    },
                  ]}
                />
                {isMultiCapacity &&
                  <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 0, marginTop: 10 }}>
                    <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 11, fontSize: 16 }}>{<Entity entity="resourceCapacityFormText"/>}</h4>
                    <Entity
                      id="capacity"
                      name="capacity"
                      componentClass={Field}
                      componentAttribute="placeholder"
                      type="number"
                      textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
                      component={CustomField}
                      disabled={!canEditResource}
                      containerStyle={{ width: 100 }}
                      className="form-control-custom"
                    />
                    {currentCapacityValue <= 1 ? (
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom:14 }}>
                        <WarningIcon style={{ color: 'orange', marginRight: 5, marginLeft: 10, fontSize: 16 }} />
                        <h4 style={{ color: '#3f3f3f', lineHeight: '1.2em', margin: 0, fontSize:16, fontWeight:'bold' }}>
                          <Entity
                            entity="resourceCapacityMultiWarning"
                          />
                        </h4>
                      </div>
                    ):null}
                  </div>
                }
                {isMultiCapacity &&
                  <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 0, marginTop: 10 }}>
                    <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 11, fontSize: 16 }}>{<Entity entity="resourceCapacitySlot"/>}</h4>
                    <Field
                      id="slotMinutes"
                      name="slotMinutes"
                      title=""
                      component={CustomField}
                      className="form-control-select"
                      disabled={!canEditResource}
                      componentClass="select"
                      onHandleChange={(values) => {this.onResourceSlotChange(values)}}
                    >
                      {_.map(RESOURCE_CAPACITY_SLOTS, item =>
                        <Entity
                          key={item.value}
                          componentClass={TranslatableOption}
                          value={item.value}
                          componentAttribute="text"
                          entity={'resourceSlotDurations'}
                          data={{ type: item.label }}
                        />
                      )}
                    </Field>
                  </div>
                }
                {isMultiCapacity &&
                  <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 0, marginTop: 10 }}>
                    <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 11, fontSize: 16 }}>{<Entity entity="resourceCapacitySlotsForReservation"/>}</h4>
                    <Field
                      id="maximumReservationSlotsNumber"
                      name="maximumReservationSlotsNumber"
                      title=""
                      component={CustomField}
                      className="form-control-select"
                      disabled={!canEditResource}
                      componentClass="select"
                      onHandleChange={(values) => {this.onResourceSlotsMaxChange(values)}}
                    >
                      {_.map(Array.from({ length: 10 }, (_, i) => i + 1), item =>
                        <option value={item}>
                          {item}
                        </option>
                      )}
                    </Field>
                  </div>
                }
                {isMultiCapacity && maximumReservationSlotsString && 
                  <h5 style={{ fontSize: 14, lineHeight: '1.5em', color: '#3f3f3f', margin:0 }}>
                    <Entity
                      entity="resourceCapacitySlotsForReservationDescription"
                      key={`${maximumReservationSlotsString}`}
                      data={{
                        maximumTime: maximumReservationSlotsString,
                      }}
                    />
                  </h5>
                }
                </div>
              </FormFlatContainer>
            ) : null}
            {!isSharedFromOtherSystem ? (
              <FormFlatContainer
                title="reservationTimeRules"
                subtitle="reservationTimeRulesDescription"
                icon={<CalendarClockIconCustom style={{ width: 22, marginTop: 2, marginRight: 2 }} />}
              >
                <ResourcesTimeSelector
                  checkedInterval={checkedInterval}
                  form={form}
                  formData={formData}
                  formName="ResourcesForm"
                  timeIntervalsOK={timeIntervalsOK}
                />
              </FormFlatContainer>
            ) : null}
          {!isMultiCapacity ? (
            <FormFlatContainer
              title="reservationTimeLimitationOptions"
              titleStyle={{ fontSize: 20 }}
              icon={<ClockUnlockIconCustom style={{ width: 22, marginTop: 2, marginRight: 2 }} />}
            >
              <div>
                <Entity
                  id="maximumReservationTimeHours"
                  name="maximumReservationTimeHours"
                  componentClass={Field}
                  componentAttribute="sentence"
                  component={SentenceSelectorInputField}
                  unit="hours"
                  disabled={isSharedFromOtherSystem || !canEditResource}
                  entity="maximumReservationTimeMinutes"
                  containerStyle={{ marginTop: 15 }}
                />
                <Entity
                  id="minimumReservationTimeHours"
                  name="minimumReservationTimeHours"
                  componentClass={Field}
                  componentAttribute="sentence"
                  component={SentenceSelectorInputField}
                  unit="hours"
                  disabled={isSharedFromOtherSystem || !canEditResource}
                  entity="minimumReservationTimeHours"
                  containerStyle={{ marginTop: 10 }}
                />
              </div>
            </FormFlatContainer>
            ): null}
          <FormFlatContainer
            title="resourceUsersTitle"
            subtitle="resourceUsersDescription"
            titleStyle={{ fontSize: 20 }}
            icon={<UserSettingIconCustom style={{ width: 22, marginTop: 2, marginRight: 2 }} />}
          >
            <div>
              <MDSwitchView
                label={<Entity key={showLimitReservations} entity={!showLimitReservations ? 'limitUsersReservation' : 'userReservationActiveLimitation'} />}
                checked={showLimitReservations}
                titleStyle={{ fontWeight: showLimitReservations ? 'bold' : 'normal', color: showLimitReservations ? GREEN : '#3f3f3f', fontSize: 17, marginBottom: 1 }}
                onSelect={selected => this.onToggleLimitReservation(selected)}
              />
              {showLimitReservations ? (
                <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 0, marginTop: 15 }}>
                  <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 11, fontSize: 16 }}>{<Entity entity="limitUsersReservationFormText"/>}</h4>
                  <Entity
                    id="maxActiveReservationsPerUser"
                    name="maxActiveReservationsPerUser"
                    componentClass={Field}
                    componentAttribute="placeholder"
                    type="number"
                    textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
                    component={CustomField}
                    disabled={!canEditResource}
                    containerStyle={{ width: 100 }}
                    className="form-control-custom"
                  />
                  {currentMaximumReservationValue <= 0 ? (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 14, marginLeft: 15 }}>
                      <WarningIcon style={{ color: 'orange', marginRight: 5, marginLeft: 10, fontSize: 16 }} />
                      <h4 style={{ color: '#3f3f3f', lineHeight: '1.2em', margin: 0, fontSize: 13, fontWeight: 'bold' }}>
                        <Entity
                          entity="limitUsersReservationWarning"
                        />
                      </h4>
                    </div>
                  ) : null}
                </div>
              ) : null}
              <Divider style={{ margin: 10 }} />
              <MDSwitchView
                label={<Entity key={showLimitVisibility} entity={!showLimitVisibility ? 'limitUsersVisibility' : 'userVisibilityActiveLimitation'} />}
                checked={showLimitVisibility}
                titleStyle={{ fontWeight: showLimitVisibility ? 'bold' : 'normal', color: showLimitVisibility ? GREEN : '#3f3f3f', fontSize: 17, marginBottom: 1 }}
                onSelect={selected => this.onToggleLimitVisibility(selected)}
              />
              {showLimitVisibility ? (
                <TagsSelectorListField
                  name="resourceUserTags"
                  selectorFieldName="userTagMatchingMode"
                  tags={showLimitVisibility ? availableUserTags : []}
                  selectedTags={showLimitVisibility ? guestsFilteredByTags : []}
                  form={form}
                  tagsType="GUEST"
                  title="resourceUsersTags"
                  placeholder="insertGuestTagsForCredential"
                  listTitle="selectedUsers"
                  isOptional
                  showList
                  onFetchMoreItems={page => onFetchMoreGuests(page)}
                  onFilterModeChange={values => onGuestFilterModeChange(values)}
                  onTagsChange={values => onGuestTagsChange(values)}
                  onInputChange={value => onGuestInputChange(value)}
                />
              ) : null}
            </div>
          </FormFlatContainer>
          <FormFlatContainer
            title="reservationLockOptions"
            titleStyle={{ fontSize: 20 }}
            icon={<ClockRemoveIconCustom style={{ width: 22, marginTop: 2, marginRight: 2 }} />}
          >
            <div>
              <Field
                id="locksOpenValidAfterMinutes"
                name="locksOpenValidAfterMinutes"
                component={SentenceSelectorInputField}
                disabled={isSharedFromOtherSystem || !canEditResource}
                containerStyle={{ marginTop: 10 }}
                sentence={L20NContext.getSync('locksOpenValidAfterMinutesSentence')}
              />
              <Field
                id="reservationCutoffTimeMinutes"
                name="reservationCutoffTimeMinutes"
                component={SentenceSelectorInputField}
                disabled={isSharedFromOtherSystem || !canEditResource}
                containerStyle={{ marginTop: 10 }}
                sentence={L20NContext.getSync('reservationCutoffTimeMinutes')}
              />
              {!isMultiCapacity ? (
                <div>
                  <Field
                    id="freeBeforeMinutes"
                    name="freeBeforeMinutes"
                    component={SentenceSelectorInputField}
                    disabled={isSharedFromOtherSystem || !canEditResource}
                    containerStyle={{ marginTop: 10, marginLeft: 0 }}
                    sentence={L20NContext.getSync('freeBeforeMinutesSentence')}
                  />
                  <Field
                    id="freeAfterMinutes"
                    name="freeAfterMinutes"
                    component={SentenceSelectorInputField}
                    disabled={isSharedFromOtherSystem || !canEditResource}
                    containerStyle={{ marginTop: 10 }}
                    sentence={L20NContext.getSync('freeAfterMinutesSentence')}
                  />
              </div>
              ): null}
            </div>
          </FormFlatContainer>
          <FormFlatContainer
            title="resourceReservationAutomaticEmails"
            titleStyle={{ fontSize: 20 }}
            subtitle="resourceReservationAutomaticEmailsDescription"
            icon={<ReservationEmailIconCustom style={{ width: 22, marginTop: 2, marginRight: 2 }} />}
          >
            <div style={{ marginTop: 20 }}>
              <FieldArray
                name={`notificationEmails`}
                component={onRenderNotificationEmails}
                formData={formData}
                form={form}
                disabled={!canEditResource}
                onInitializeNewNotificationEmail={emailId => this.onInitializeNewNotificationEmail(emailId, `notificationEmails`)}
              />
            </div>
          </FormFlatContainer>
          <FormFlatContainer
            title="resourceAllowInvitedEmails"
            titleStyle={{ fontSize: 20 }}
            subtitle="resourceAllowInvitedEmailsDescription"
            icon={<SmartPhoneKeyIconCustom style={{ width: 22, marginTop: 2, marginRight: 2 }} />}
          >
            <MDSwitchView
              label={<Entity key={enableInvitedEmails} entity={!enableInvitedEmails ? 'resourceAllowInvitedEmails' : 'resourceAllowInvitedEmailsActive'} />}
              checked={enableInvitedEmails}
              titleStyle={{ fontWeight: enableInvitedEmails ? 'bold' : 'normal', color: enableInvitedEmails ? GREEN : '#3f3f3f', fontSize: 17, marginBottom: 1 }}
              onSelect={selected => this.onToggleEnableInvitedEmails(selected)}
            />
          </FormFlatContainer>
        </FormCardContainer>
        {isSharedFromOtherSystem ? (
          null
        ) : (
          <MDAccordion
            title={<Entity entity="advancedSettings" />}
            containerstyle={{ marginTop: 10, marginLeft: 2 }}
            innerContainerStyle={{ backgroundColor: '#fdfdfd', paddingTop: 0, paddingLeft: 10, paddingRight: 10 }}
            titleStyle={{ fontSize: 27 }}
          >
            {isSharedWithOtherSystem || subcompanies ? (
              <FormFlatContainer
                title="shareResource"
                subtitle="shareResourceDescription"
                icon={<ShareIconCustom style={{ width: 22, marginTop: 2, marginRight: 2 }} />}
                containerStyle={{ zIndex: 0 }}
              >
                <div>
                  <h4><Entity entity="shareResourceDescriptionLong" /></h4>
                  {isSharedWithOtherSystem && form && form.values && form.values.subcompanies && !_.isEmpty(form.values.subcompanies) ? (
                    _.map(form.values.subcompanies, subcompany => (
                      <div style={{ display: 'flex', marginTop: 20, flexDirection: 'row', alignItems: 'center', padding: 20, backgroundColor: '#f6f6f6', borderRadius: 10 }}>
                        <ShareOutlined style={{ width: 22, color: '#3f3f3f', marginRight: 5 }} />
                        <h4 style={{ margin: 0, color: '#3f3f3f', fontWeight: 'bold' }}>{<Entity entity="sharedWithCompany" data={{ company: formatDomainHostNameToFriendlyName(subcompany.hostname) }} />}</h4>
                      </div>
                    ))
                  ) : null}
                  <MDButton
                    title={<Entity entity="shareResource" />}
                    containerStyle={{ margin: 0, marginRight: 20, marginTop: 25 }}
                    icon={<ShareIconCustom style={{ width: 15, color: 'white', marginLeft: 10, marginTop: 0 }} />}
                    style={{ minHeight: 40 }}
                    backgroundColor={BLUE}
                    disabled={!canEditResource}
                    onClick={() => onOpenResourceSharingSettings(resource)}
                  />
                </div>
              </FormFlatContainer>
            ) : null}
            <FormFlatContainer
              title="resourceVirtualLockOpenEvents"
              titleStyle={{ fontSize: 20 }}
              subtitle="resourceVirtualLockOpenEventsDescription"
              icon={<ClockLockIconCustom style={{ width: 22, marginTop: 2, marginRight: 2 }} />}
            >
              <div>
                <MDSwitchView
                  label={<Entity key={showOpenLockBeforeReservation} entity={!showOpenLockBeforeReservation ? 'resourceVirtualLockOpenBeforeReservation' : 'resourceVirtualLockOpenBeforeReservationActive'} />}
                  checked={showOpenLockBeforeReservation}
                  disabled={!canEditResource}
                  titleStyle={{ fontWeight: showOpenLockBeforeReservation ? 'bold' : 'normal', color: showOpenLockBeforeReservation ? GREEN : '#3f3f3f', fontSize: 17, marginBottom: 1 }}
                  onSelect={selected => this.onToggleOpenLockBeforeReservation(selected)}
                />
                {showOpenLockBeforeReservation ? (
                  <>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 0, marginTop: 15 }}>
                      <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 11, fontSize: 16 }}>{<Entity entity="resourceVirtualLockOpenBeforeReservationLock"/>}</h4>
                      <Field
                        name="smartLockIdToOpenIfReservationStartsInMinutes"
                        title={null}
                        component={SelectableField}
                        options={locksBeforeReservationOptions}
                        canCreate={false}
                        placeholder={<Entity entity="resourceVirtualLockOpenEventsLockPlaceholder" />}
                        containerstyle={{ minWidth:500 }}
                        helpText={null}
                        onSelect={value => {this.onLockBeforeReservationSelected(value)}}
                        onInputChange={name => this.onFilterLocksBeforeReservation(name)}
                      />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 0, marginTop: 15 }}>
                      <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 11, fontSize: 16 }}>{<Entity entity="resourceVirtualLockOpenBeforeReservationMinutes"/>}</h4>
                      <Entity
                        id="openIfReservationStartsInMinutes"
                        name="openIfReservationStartsInMinutes"
                        componentClass={Field}
                        componentAttribute="placeholder"
                        type="number"
                        textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
                        component={CustomField}
                        containerStyle={{ width: 100 }}
                        className="form-control-custom"
                      />
                      <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 11, fontSize: 16, marginLeft: 10 }}>{<Entity entity="minutes"/>}</h4>
                    </div>
                  </>
                ) : null}
                <Divider style={{ margin: 10 }} />
                <MDSwitchView
                  label={<Entity key={showOpenLockAfterReservation} entity={!showOpenLockAfterReservation ? 'resourceVirtualLockOpenAfterReservation' : 'resourceVirtualLockOpenAfterReservationActive'} />}
                  checked={showOpenLockAfterReservation}
                  disabled={!canEditResource}
                  titleStyle={{ fontWeight: showOpenLockAfterReservation ? 'bold' : 'normal', color: showOpenLockAfterReservation ? GREEN : '#3f3f3f', fontSize: 17, marginBottom: 1 }}
                  onSelect={selected => this.onToggleOpenLockAfterReservation(selected)}
                />
                {showOpenLockAfterReservation ? (
                  <>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 0, marginTop: 15 }}>
                      <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 11, fontSize: 16 }}>{<Entity entity="resourceVirtualLockOpenAfterReservationLock"/>}</h4>
                      <Field
                        name="smartLockIdToOpenIfNoReservationStartsInMinutes"
                        title={null}
                        component={SelectableField}
                        options={locksAfterReservationOptions}
                        canCreate={false}
                        placeholder={<Entity entity="resourceVirtualLockOpenEventsLockPlaceholder" />}
                        containerstyle={{ minWidth:500 }}
                        helpText={null}
                        onSelect={value => {this.onLockAfterReservationSelected(value)}}
                        onInputChange={name => this.onFilterLocksAfterReservation(name)}
                      />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 0, marginTop: 15 }}>
                      <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 11, fontSize: 16 }}>{<Entity entity="resourceVirtualLockOpenAfterReservationMinutes"/>}</h4>
                      <Entity
                        id="openIfNoReservationStartsInMinutes"
                        name="openIfNoReservationStartsInMinutes"
                        componentClass={Field}
                        componentAttribute="placeholder"
                        type="number"
                        textFormattingFunction={value => value.replace(/[\s.]+/g, '')}
                        component={CustomField}
                        containerStyle={{ width: 100 }}
                        className="form-control-custom"
                      />
                      <h4 style={{ color: '#3f3f3f', marginRight: 10, marginTop: 11, fontSize: 16, marginLeft: 10 }}>{<Entity entity="minutes"/>}</h4>
                    </div>
                  </>
                ) : null}
              </div>
            </FormFlatContainer>
            <FormFlatContainer
              title="resourceTimezoneTitle"
              subtitle="resourceTimezoneExplanation"
              icon={<TimezoneIconCustom style={{ width: 22, marginTop: 2, marginRight: 2 }} />}
              containerStyle={{ paddingBottom: 30 }}
            >
              <div style={{ display:'flex' }}>
                <Field
                  name="timeZone"
                  title={null}
                  component={SelectableField}
                  options={_.map(timezones, timezone => ({ value: timezone, label: timezone }))}
                  placeholder={<Entity entity="search" />}
                  containerstyle={{ minWidth:500 }}
                  onInputChange={value => this.onFilterTimezones(value)}
                  onSelect={value => this.onSelectTimezone(value)}
                  disabled={!canEditResource}
                />
              </div>
            </FormFlatContainer>
            <FormFlatContainer
              title="penaltyInWindow"
              subtitle="penaltyWindowExplanation"
              icon={<ClockAlertIconCustom style={{ width: 22, marginTop: 2, marginRight: 2 }} />}
              containerStyle={{ paddingBottom: 30, zIndex: 0 }}
            >
              <Field
                id="deleteByUserWithPenaltyBeforeMinutes"
                name="deleteByUserWithPenaltyBeforeMinutes"
                component={SentenceSelectorInputField}
                disabled={!canEditResource}
                sentence={L20NContext.getSync('penaltyWindowSentence')}
              />
            </FormFlatContainer>
            <FormFlatContainer
              title="confirmationWindowMinutesTitle"
              subtitle="confirmationWindowMinutesDescription"
              icon={<ClockCheckIconCustom style={{ width: 22, marginTop: 2, marginRight: 2 }} />}
              containerStyle={{ paddingBottom: 30, zIndex: 0 }}
            >
              <Field
                id="confirmationWindowMinutes"
                name="confirmationWindowMinutes"
                component={SentenceSelectorInputField}
                disabled={!canEditResource}
                sentence={L20NContext.getSync('confirmationWindowMinutesSentence')}
              />
            </FormFlatContainer>
            <FormFlatContainer
              title="linkedResources"
              icon={<LinkedResourceIconCustom style={{ width: 22, marginTop: 2, marginRight: 2 }} />}
              subtitle="linkedResourcesSubtitle"
              containerStyle={{ zIndex: 0 }}
            >
              <div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10 }}>
                <Field
                  name="linkedResourcesIds"
                  component={SelectableRowField}
                  options={resourcesOptions}
                  listContainerStyle={{ marginTop: 0 }}
                  listStyle={{ marginTop: 0 }}
                  disabled={!canEditResource}
                  emptyOptionsText={<Entity entity="availableResourcesSelectDates" />}
                  onFetchMoreItems={page => onFetchMoreResources(page)}
                />
              </div>
            </FormFlatContainer>
            {checkInAccessories && !_.isEmpty(checkInAccessories) ? (
              <FormFlatContainer
                title="linkedAccessory"
                subtitle="linkedAccessorySubtitle"
                icon={<ClockTimerIconCustom style={{ width: 22, marginTop: 2, marginRight: 2 }} />}
                containerStyle={{ zIndex: 0 }}
              >
                <div style={{ padding: 5, backgroundColor: '#f1f1f1', borderRadius: 10 }}>
                  <Field
                    name="resourceAccessoryIds"
                    component={SelectableRowField}
                    options={{
                      content: _.map(checkInAccessories, (accessory) => (
                        {
                          ...accessory,
                          type: <Entity entity="accessoryTypes" data={{ type: accessory.type }} />,
                        }
                      )),
                      pagination: accessoriesData.pagination,
                    }}
                    listContainerStyle={{ marginTop: 0 }}
                    listStyle={{ marginTop: 0 }}
                    emptyOptionsText={<Entity entity="availableResourcesSelectDates" />}
                    onFetchMoreItems={page => onFetchMoreAccessories(page)}
                  />
                </div>
              </FormFlatContainer>
            ) : null}
          </MDAccordion>
        )}
        {resourceID && canEditResource && 
          <FormCardContainer
            title="deleteResource"
            subtitle="deleteResourceWarning"
          >
            <MDButton
              title={<Entity entity="deleteResource" />}
              backgroundColor={RED}
              onClick={() => onDeleteResource(resource)}
            />
          </FormCardContainer>
        }
      </Form>
    );
  }
} 

export default ResourcesForm;
