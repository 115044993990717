import { IconButton } from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import WarningIcon from '@material-ui/icons/ReportProblem';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import { AES } from 'crypto-js';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import * as GatewaysActions from '../../redux/actions/gateways.actions';
import * as UtilsActions from '../../redux/actions/utils.actions.js';
import { GATEWAYS_NOTIFICATION_PAYLOAD_TYPES, GATEWAYS_NOTIFICATION_STATUSES, GATEWAYS_NOTIFICATION_TYPES } from '../../_config/consts';
import PukCodeForm from '../forms/GatewayForm/PukCodeForm.jsx';
import GatewayNotificationStatusView from '../Gateways/GatewayNotificationStatusView/GatewayNotificationStatusView.jsx';
import NotificationStatusProgressBar from '../Gateways/GatewayNotificationStatusView/NotificationStatusProgressBar.jsx';

let pollingTiming;
@connect((state) => ({ gateways: state.gateways, locks: state.locks }))
class LockRemoteOpenView extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isLoadingResponse: false,
      showWarningError: false,
    };
  }

  componentDidMount() {
    const { gateways: { selectedGateway: gateway } } = this.props;
    if (gateway && gateway.highFlex) {
      this.onOpenWithoutPuk();
    }
  }


  componentWillUnmount() {
    const { dispatch } = this.props;
    if (pollingTiming) clearTimeout(pollingTiming);
    dispatch(GatewaysActions.setSelectedGatewayNotification(null));
    this.setState({ showWarningError: false });
  }

  async onOpenConfirm(pukData) {
    const { dispatch, locks: { selectedLock }, gateways: { selectedGateway: gateway } } = this.props;
    try {
      const lockDTO = {
        serialNumber: selectedLock.serialNumber,
      };
      const encryptedOpenPayload = AES.encrypt(JSON.stringify(lockDTO), pukData.pukCode);
      const notificationDTO = {
        data: encryptedOpenPayload.toString(),
        payloadType: GATEWAYS_NOTIFICATION_PAYLOAD_TYPES.SMARTLOCK_OPEN,
        type: GATEWAYS_NOTIFICATION_TYPES.ACTION,
      };
      const notification = await dispatch(GatewaysActions.sendGatewayNotification(gateway.id, notificationDTO));
      this.setState({ isLoadingResponse: true });
      dispatch(GatewaysActions.setSelectedGatewayNotification(notification));
      this.onPollingForNotificationResponse(gateway.id, notification);
    } catch (error) {
      this.setState({ isLoadingResponse: false });
    }
  }

  async onOpenWithoutPuk() {
    const { dispatch, locks: { selectedLock }, gateways: { selectedGateway: gateway } } = this.props;
    try {
      const lockDTO = {
        serialNumber: selectedLock.serialNumber,
      };
      this.setState({ isLoadingResponse: true });
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const notification = await dispatch(GatewaysActions.openSmartLockHyperGateHighFlex(gateway.id, lockDTO));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ isLoadingResponse: true });
      dispatch(GatewaysActions.setSelectedGatewayNotification(notification));
      this.onPollingForNotificationResponse(gateway.id, notification);
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ isLoadingResponse: false });
    }
  }

  async onSetPassageModeWithoutPuk() {
    const { dispatch, locks: { selectedLock }, gateways: { selectedGateway: gateway } } = this.props;
    try {
      const lockDTO = {
        serialNumber: selectedLock.serialNumber,
      };
      this.setState({ isLoadingResponse: true });
      dispatch(UtilsActions.setSpinnerVisibile(true));
      const notification = await dispatch(GatewaysActions.togglePassageModeSmartLockHyperGateHighFlex(gateway.id, lockDTO));
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ isLoadingResponse: true });
      dispatch(GatewaysActions.setSelectedGatewayNotification(notification));
      this.onPollingForNotificationResponse(gateway.id, notification);
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      this.setState({ isLoadingResponse: false });
    }
  }

  async onPollingForNotificationResponse(gatewayId, notification) {
    const { dispatch } = this.props;
    const { showWarningError } = this.state;
    if (pollingTiming) clearTimeout(pollingTiming);
    const timeStart = moment().valueOf();
    pollingTiming = setInterval(async () => {
      try {
        const timeElapsedMilliseconds = moment().diff(timeStart)
        if (timeElapsedMilliseconds >= 20000 && !showWarningError) this.setState({ showWarningError: true }); // After 20 seconds show warning
        const notificationUpdated = await dispatch(GatewaysActions.getGatewayNotificationDetails(gatewayId, notification.id));
        dispatch(GatewaysActions.setSelectedGatewayNotification(notificationUpdated));
        if (notificationUpdated.status === GATEWAYS_NOTIFICATION_STATUSES.APPLIED || notificationUpdated.status === GATEWAYS_NOTIFICATION_STATUSES.FAILED) {
          clearTimeout(pollingTiming);
          this.setState({ showWarningError: false });
        }
      } catch (error) {
        this.setState({ showWarningError: false });
        clearTimeout(pollingTiming);
      }
    }, 2000);
  }

  onCloseModal() {
    const { dispatch, onCloseModal } = this.props;
    onCloseModal();
    if (pollingTiming) clearTimeout(pollingTiming);
    dispatch(GatewaysActions.setSelectedGatewayNotification(null));
  }

  render() {
    const { isLoadingResponse, showWarningError } = this.state;
    const { gateways: { notifications: { selectedGatewayNotification: notification }, selectedGateway } } = this.props;
    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h3 style={{ margin: 0, fontWeight: 'bold', color: '#3f3f3f' }} ><Entity entity="openLockRemote" /></h3>
          <IconButton onClick={() => this.onCloseModal()}>
            <Cancel style={{ color: '#e95841', fontSize: 35 }} />
          </IconButton>
        </div>
        <div className="remote-open-loading-container" style={{ opacity: isLoadingResponse ? 1 : 0.5 }}>
          <NotificationStatusProgressBar notification={notification} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 40 }}>
          {!isLoadingResponse && (selectedGateway && !selectedGateway.highFlex) && (
            <PukCodeForm
              gateway={selectedGateway}
              onSubmit={pukData => this.onOpenConfirm(pukData)}
            />
          )}
          {isLoadingResponse && (
            <GatewayNotificationStatusView
              notification={notification}
              onClose={() => this.onCloseModal()}
            />
          )}
        </div>
        {showWarningError && (
          <div style={{ display: 'flex', marginTop: 15, alignItems: 'center', justifyContent: 'center' }}>
            <WarningIcon style={{ fontSize: 18, marginRight: 5, color: '#3f3f3f' }} />
            <h4 style={{ color: '#3f3f3f', margin: 0, fontSize: 13 }}><Entity entity="slowNotification" /></h4>
          </div>
        )}
      </div>
    );
  }
} 

export default LockRemoteOpenView;
