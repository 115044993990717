import { Form } from '@sketchpixy/rubix';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import CodeInputField from '../Fields/ CodeInputField.jsx';

const validate = values => {
  const errors = {};
  if (values && !values.twoFactorCode) {
    errors.twoFactorCode = 'required';
  } else if (values && values.twoFactorCode.length < 6) {
    errors.twoFactorCode = 'required';
  }
  return errors;
};

@reduxForm({ form: 'InsertCodeForm', validate })
class InsertCodeForm extends React.Component {
  render() {
    const { inputStyle, showInputValues, codeInputStyle } = this.props;
    return (
      <Form style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Field
            name="twoFactorCode"
            component={CodeInputField}
            inputStyle={inputStyle}
            codeInputStyle={codeInputStyle}
            showInputValues={showInputValues}
          />
        </div>
      </Form>
    );
  }
} 

export default InsertCodeForm;
