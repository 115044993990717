
import { CssBaseline } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { createTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { WarningOutlined } from '@material-ui/icons';
import LocationIcon from '@material-ui/icons/Business';
import MenuIcon from '@material-ui/icons/Menu';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import { NavItem } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import { forbiddenManagerEmailAddresses, LICENSE_TYPES } from '../_config/consts';
import { getHeaderColorFromThemeName, getLuckeyLogoSource, getPlantName, isProductionDemoSystem, localizeHelpCenterLink } from '../_config/utils';
import NewspaperIconCustom from '../components/CustomIcons/NewspaperIconCustom.jsx';
import StoreOutlinedIconCustom from '../components/CustomIcons/StoreOutlinedIconCustom.jsx';
import HelpMenuDropdown from '../components/HelpMenuDropdown.jsx';
import LanguageSelector from '../components/LanguageSelector.jsx';
import LocationsMenu from '../components/LocationsMenu.jsx';
import AbilityProvider from '../permissionsUtils/AbilityProvider';
import * as ModalActions from '../redux/actions/modal.actions';
import * as UserActions from '../redux/actions/user.actions';
import * as UtilsActions from '../redux/actions/utils.actions';
import AppSidebar from './AppSidebar.jsx';
import AppSidebarLimited from './AppSidebarLimited.jsx';

const drawerWidth = 250;

const styles = theme => ({
  root: {
    height: 70,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    top: 65,
    zIndex: 900,
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
});

const theme = createTheme({
  palette: {
    primary: { 500: '#f5f6f7' },
  },
  typography: {
    useNextVariants: true,
  },
});

@connect(state => ({ themeName: state.settings.items.theme.data.themeName, userData: state.user.data, domains: state.user.domains, userLicense: state.user.license, whatsNew: state.settings.whatsNew}))
class AppHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHeader: false,
    };
  }

  toggleHeaderMenu() {
    const { showHeader } = this.state;
    this.setState({ showHeader: !showHeader });
  }

  onLogout() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'confirmToLogout',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => {
          dispatch(UserActions.logout());
          dispatch(ModalActions.hideModal());
        },
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  onOpenOnlineGuide() {
    const formattedURL = localizeHelpCenterLink('');
    window.open(formattedURL);
  }

  onOpenStore() {
    const { dispatch } = this.props;
    dispatch(push('/luckeyStore'));
  }

  async onShowWhatsNew() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setIsWhatsNewVisible(true));
  }
  
  onOpenSupportTicket() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setSupportTicketFormVisible(true));
  }

  render() {
    const { classes, themeName, domains, userData, dispatch, whatsNew: { hasUpdate } } = this.props;
    const { showHeader } = this.state;
    const color = getHeaderColorFromThemeName(themeName);
    const plantName = getPlantName();
    const isSupportUser = dispatch(UtilsActions.isSupportUser());
    const isLuckeyLite = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
    const isLuckeyRFID = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.RFID]);
    const isLuckeyEngine = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.ENGINE]);
    const isMarketPlaceEnabled = dispatch(UserActions.isMarketPlaceEnabled());
    const isFakeCloudCredits = dispatch(UserActions.isFakeCloudCredits());
    const isLuckeyDemoSystem = isProductionDemoSystem();
    const isWalletPendingActivationOrReadyToActivate = dispatch(UserActions.isWalletPendingActivationOrReadyToActivate());
    const luckeyLogoSource = isWalletPendingActivationOrReadyToActivate ? '/imgs/common/logoJagoWhite.png' : getLuckeyLogoSource('white');
    const showLocationMenu = _.indexOf(forbiddenManagerEmailAddresses, _.trim(userData.email)) === -1 && !isLuckeyLite && !isLuckeyRFID && domains && !_.isEmpty(domains) && !isSupportUser;
    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <AppBar position="static" style={{ backgroundColor: color }}>
          <Toolbar>
            <div style={{ display: 'flex', flex: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between'}}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <img alt="logo" style={{ marginTop: (isLuckeyLite || isLuckeyRFID || isLuckeyEngine || isWalletPendingActivationOrReadyToActivate) ? 0 : -10, height: (isLuckeyLite || isLuckeyRFID || isLuckeyEngine || isWalletPendingActivationOrReadyToActivate) ? 45 : 55 }} src={luckeyLogoSource} />
                {isWalletPendingActivationOrReadyToActivate ? (
                  <div onClick={() => dispatch(push('/settings?showWallet=true'))} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', marginLeft: 90, alignItems: 'center', padding: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 10, backgroundColor: 'chocolate' }}>
                    <WarningOutlined style={{ color: 'white', fontSize: 27 }} />
                    <div>
                      <h4 style={{ color: 'white', fontWeight: 'bold', margin: 0, marginLeft: 10 }}><Entity entity="systemPendingToActivate" /></h4>
                      <h5 style={{ color: 'white', fontWeight: 'bold', margin: 0, marginLeft: 10 }}><Entity entity="systemPendingToActivateDescription" /></h5>
                    </div>
                  </div>
                ): null}
                {isLuckeyDemoSystem ? (
                  <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 90, alignItems: 'center', padding: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 10, backgroundColor: 'chocolate' }}>
                    <WarningOutlined style={{ color: 'white', fontSize: 27 }} />
                    <div>
                      <h4 style={{ color: 'white', fontWeight: 'bold', margin: 0, marginLeft: 10 }}><Entity entity="systemIsDemo" /></h4>
                      <h5 style={{ color: 'white', fontWeight: 'bold', margin: 0, marginLeft: 10 }}><Entity entity="systemIsDemoDescription" /></h5>
                    </div>
                  </div>
                ) : null}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 4 }}>
                {isMarketPlaceEnabled && !isFakeCloudCredits ? (
                  <NavItem onClick={() => this.onOpenStore()} style={{ marginRight: 20, marginTop: -17 }}>
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: 0, alignItems: 'center' }}>
                    <StoreOutlinedIconCustom style={{ color: 'white', width: 27, marginRight: 10 }} />
                    <h4 style={{ color: 'white', margin: 0, marginTop: 0, fontWeight: 'bold' }}><Entity entity="luckeyStore" /></h4>
                  </div>
                </NavItem>): null}
                <NavItem onClick={() => this.onShowWhatsNew()} style={{ marginRight: 5, marginTop: -17, position: 'relative' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: 0, alignItems: 'center' }}>
                    <NewspaperIconCustom style={{ color: 'white', width: 27, marginRight: 10 }} />
                    <h4 style={{ color: 'white', margin: 0, marginTop: 0, fontWeight: 'bold' }}>{'What\'s new!'}</h4>
                    {hasUpdate ? <div style={{ width: 15, height: 15, right: -9, top: 20, borderRadius: 20, backgroundColor: 'gold', position: 'absolute' }} /> : null}
                  </div>
                </NavItem>
                <HelpMenuDropdown
                  onOpenOnlineGuide={() => this.onOpenOnlineGuide()}
                  onOpenSupportTicket={() => this.onOpenSupportTicket()}
                />
                {/* <NavItem onClick={() => this.onOpenOnlineGuide()} style={{ marginRight: 10, marginTop: -17 }}>
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: 0, alignItems: 'center' }}>
                    <ManualIcon style={{ color: 'white', fontSize: 25, marginRight: 10 }} />
                    <h4 style={{ color: 'white', margin: 0, marginTop: 0, fontWeight: 'bold' }}><Entity entity="onlineManual" /></h4>
                  </div>
                </NavItem> */}
                {showLocationMenu ? (
                  <LocationsMenu domains={domains} />
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 4, marginRight: 20, marginLeft: 20 }}>
                      <LocationIcon style={{ fontSize: 23, marginRight: 12, color: 'white',  }} />
                      <h4 style={{ color: 'white', fontWeight: 'bold', marginTop: 12 }}>{plantName}</h4>
                    </div>
                )}
                <div style={{ marginLeft: 25, marginRight: 30, marginBottom: 5 }}>
                  <LanguageSelector />
                </div>
                <IconButton onClick={() => this.onLogout()}>
                  <LogoutIcon style={{ color: 'white', fontSize: 30 }} />
                </IconButton>
              </div>
            </div>
          </Toolbar>
          </AppBar>
          <CssBaseline />
          <Hidden smUp implementation="css">
            <Toolbar style={{ backgroundColor: color, paddingLeft: 0 }}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={() => this.toggleHeaderMenu()}
              className={classes.menuButton}
            >
              <div style={{ marginTop: 3, }}>
                <MenuIcon style={{ color: 'white', fontSize: 20 }} />
                <img alt="luckey" src="/imgs/common/logoJagoWhite.png" style={{ width: 100, marginLeft: 10 }} />
              </div>
            </IconButton>
          </Toolbar>
          </Hidden>
          <div className={classes.drawer}>
            {/* SHOW ON MOBILE */}
            <Hidden smUp implementation="css">
              <Drawer
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={showHeader}
                onClose={() => this.toggleHeaderMenu()}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
              {isWalletPendingActivationOrReadyToActivate ? <AppSidebarLimited /> : <AppSidebar />}
              </Drawer>
            </Hidden>
            {/* HIDE ON MOBILE */}
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {isWalletPendingActivationOrReadyToActivate ? <AppSidebarLimited /> : <AppSidebar />}
              </Drawer>
            </Hidden>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  utils: state.utils,
  user: state.user,
});

export default connect(mapStateToProps)(withRouter(withStyles(styles)(AppHeader)));
